import React from 'react'
import "./styles/spinner_dots.css"

class MyUI_SpinnerDots extends React.Component {    
  render(){
    return (
      <div className="myui-spinnerdots myui-spinnerdots--primary myui-spinnerdots--sm">
        <span key={0} className="myui-spinnerdots--first"></span>
        <span key={1} className="myui-spinnerdots--second"></span>
        <span key={2} className="myui-spinnerdots--third"></span>
      </div>
    )
  } 
}

export default MyUI_SpinnerDots