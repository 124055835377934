import React from 'react'
import {MyUI, MyLoadingApp} from 'apps/Components';
import PageExt from 'components/MyPage/Types/_ext';
import {HApiAxios, HFormat, HAuth, HUtils, HStoreRedux, HText} from 'apps/Helpers'
import {Redirect} from 'react-router-dom';
import {Helmet} from "react-helmet";
import './styles/common.css'

class SC_Bill_Detail extends PageExt{
    constructor(props){
        super(props)
        this.state={
            data: null,
            deliveryTimePlan: [],
        }
    }
    componentDidMount(){
        const optionList = HUtils.Obj.get(HStoreRedux.getState().shop, "optionList.DeliveryTimePlan");
        if(optionList){
            this.setState({
                deliveryTimePlan: optionList
            })
        }
        this.requestGetBill()
    }
    shouldComponentUpdate(){
        const optionList = HUtils.Obj.get(HStoreRedux.getState().shop, "optionList.DeliveryTimePlan");
        if(this.state.deliveryTimePlan !== optionList){
            this.setState({
                deliveryTimePlan: optionList
            })
        }
        return true;
    }
    getId=()=>{
        const param = this.props.match && this.props.match.params;
        if(param?.GUID){
            return param.GUID
        }
        return null
    }
    requestGetBill=()=>{
        const Id = this.getId()
        if(Id){
            this.showLoadingApp()
            HApiAxios.generic({
                request:{
                    method: 'POST',
                    url: `BillFromWeb/GetBillInfoWebWithGuid/${Id}`
                },
                data: {},
                successCallBack: (response)=>{
                    if(response.Data){
                        this.setState({
                            data: response.Data
                        })
                    }
                    this.hideLoadingApp()
                },
                errorCallBack: (error, response)=>{
                    MyLoadingApp.Helpers.show({error: error || response.Msg})
                }
            })
        }
    }
    renderTimeline=(item, {isLast}={})=>{
        if(item){
            return(
                <div className="timeline-item" style={isLast == true ? {height: 'auto'}: {}}>
                    <div className="shape">
                        <div className="circle" style={isLast == true ? {backgroundColor: "var(--color-secondary)"} : {}}></div>
                        {
                            !isLast &&
                            <div className="line"></div>
                        }
                    </div>
                    <div className="text" style={isLast == true ? {color: "var(--color-secondary)"} : {}}>{item.DeliveryStatus} - {HFormat.Date.formatDate(item.DeliveryDatetime)}</div>
                </div>
            )
        }
        return <div></div>
    }
    renderBillInfo = (data) =>{
        const {deliveryTimePlan} = this.state;
        const _CustomerName = data.CustomerName;
        const _CustomerEmail = data.CustomerEmail;
        const _CustomerPhone = data.CustomerPhone;
        const _CustomerAddress = data.CustomerAddress;
        const _Note = data.Note;
        const _DeliveryTimePlanId = data.DeliveryTimePlanId;
        const _IsDisplayCustomerInfo = data.IsDisplayCustomerInfo;
        const _CustomerNameDelivery = data.CustomerNameDelivery;
        const _CustomerPhoneDelivery = data.CustomerPhoneDelivery;
        const _CustomerAddressDelivery = data.CustomerAddressDelivery;
        let _deliveryTimePlanText = null;
        if(_DeliveryTimePlanId!==null && deliveryTimePlan?.length>0){
            _deliveryTimePlanText = deliveryTimePlan.find((v)=>v.Value === _DeliveryTimePlanId)?.Text_Filter;
        }
        return (
          <div className="bill-info">
            <div className="title">
              {HText.get("text_bill_detail_delivery_info")}
            </div>
            <div style={{ paddingTop: "20px", fontSize: 13 }}>
                {
                    _IsDisplayCustomerInfo && (
                        <div className="dFaSjSw100 mb-05rem">
                            <strong style={{ fontWeight: 100, minWidth: 85 }}>
                            {HText.get("text_bill_detail_customer")}:{" "}
                            </strong>
                            <div>
                                {`${_CustomerName}  -  ${_CustomerPhone}`}
                                {_CustomerEmail ? `  -  ${_CustomerEmail}` : null}
                                {_CustomerAddress ? `  -  ${_CustomerAddress}` : null}
                            </div>
                        </div>
                    )
                }
              <div className="dFaSjSw100 mb-05rem">
                <strong style={{ fontWeight: 100, minWidth: 85 }}>
                  {HText.get("text_bill_detail_receiver")}:{" "}
                </strong>
                <div>
                  {`${_CustomerNameDelivery}  -  ${_CustomerPhoneDelivery}  -  ${_CustomerAddressDelivery}`}
                </div>
              </div>
              {/* <div>{HText.get("text_bill_detail_receiver")}: {_CustomerName}</div>
                    {
                        _CustomerEmail &&
                        <div>{HText.get("text_bill_detail_email")}: {_CustomerEmail}</div>
                    }
                    <div>{HText.get("text_bill_detail_phone")}: {_CustomerPhone}</div>
                    {
                        _CustomerAddress &&
                        <div>{HText.get("text_bill_detail_address")}: {_CustomerAddress}</div>
                    } */}
              <div className="dFaSjSw100">
                <strong style={{ fontWeight: 100, minWidth: 85 }}>
                  {HText.get("text_bill_detail_note")}:{" "}
                </strong>
                <div>
                  {_deliveryTimePlanText &&
                    `${HText.get(
                      "text_bill_detail_delivery_time"
                    )}: ${_deliveryTimePlanText}`}
                  {_Note && (
                    <span>{" - "}{_Note}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
    }
    renderBillStatus = (data) =>{
        const _DeliveryInfo = data.DeliveryInfo;
        const _BillStatus = data.BillStatus;
        return(
            <div className="bill-status">
                <div className="title">{HText.get("text_bill_detail_delivery_status")}</div>
                <div className="container" style={{paddingTop: '10px'}}>
                    {
                        _DeliveryInfo?.length>0 && Array.isArray(_DeliveryInfo) && _DeliveryInfo.map((v,i)=>{
                            let _last = false;
                            if(i == _DeliveryInfo.length - 1){
                                _last = true;
                            }
                            return this.renderTimeline(v, {isLast: _last})
                        })
                    }
                    {
                        _DeliveryInfo?.length>0 && typeof _DeliveryInfo == 'string' &&
                        <div>{_DeliveryInfo}</div>
                    }
                    {
                        !_DeliveryInfo && _BillStatus &&
                        <div>{_BillStatus}</div>
                    }
                </div>
            </div>
        )
    }
    renderInfoBill=()=>{
        const {data} = this.state;
        if(data){
            return(
                <div className="bill">
                    {this.renderBillInfo(data)}
                    {this.renderBillStatus(data)}
                </div>
            )
        }
        return <div></div>
    }
    renderMaterials=()=>{
        const {data} = this.state;
        return(
            <MyUI type="sc_bill_card" data={data} isDetail={true}/>
        )
    }
    render(){
        const {data} = this.state;
        if(HAuth.isLoggedIn() != true && this._isPublic!==true){
            return <Redirect to="/"/>
        }
        return(
            <div className="master-wrapper-content">
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                {
                    this._isPublic!==true &&
                    <MyUI type="sc_breakcumb" data={[{Title: HText.get("text_bill_title"), Link: '/account-bills'},{Title: data?.BillNo}]}/>
                }                
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="page bill-page">
                            <div className="page-title">
                                <h1 style={{fontStyle: 'italic'}}>{HText.get("text_bill_detail_title")} { data?.BillNo}</h1>
                            </div>

                            <div className="page-body">
                                {
                                    this.renderInfoBill()
                                }
                                {
                                    this.renderMaterials()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_Bill_Detail