import React from 'react'
import HGA from '../../../../../helpers/GA';
import HLink from '../../../../../helpers/Link';
import "./styles/sc_go_quickorder_user.css";

class SC_GoTop extends React.Component{
    render(){
        return(
            <div className="sc_go_quickorder_user" onClick={()=>{
              HLink.push("/lien-he-dat-hang")
              HGA.trackingEventClick({
                action:'click_item_go_quickorder_user',
              })
            }}>
              <div className="dFaCjCw100"><i className="fa fa-shopping-cart"/></div>
            </div>
        )
    }
}
export default SC_GoTop