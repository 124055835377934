import React from "react";
import { MyUI } from "../../../../Components";
import { HApiAxios, HUtils, HUI } from "../../../../Helpers";
import { CButton } from "../../../../../components/MyCore";

class SCEditorFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: null,
    };
  }
  componentDidMount() {
    this.requestGetFooter();
  }
  requestGetFooter = () => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "ConfigSimple/List",
      },
      data: {},
      successCallBack: (response) => {
        const _data = HUtils.Obj.get(response, "Data.Data");
        if (_data) {
          this.setState({
            footer: _data.find(
              (v) => v.ConfigType === "MaterialPageContentInfoFooter"
            ),
          });
        }
      },
      errorCallBack: (error, response) => {
        console.warn("SCEditorFooter error, response", error, response);
      },
    });
  };
  requestUpdate = (content) => {
    const { footer } = this.state;

    HApiAxios.generic({
      request: {
        method: "POST",
        url: "ConfigSimple/UpdateFields",
      },
      data: {
        Id: footer.Id,
        Values: [
          {
            FieldName: "Name",
            NewValue: content,
          },
        ],
      },
      successCallBack: (response) => {
        const _data = HUtils.Obj.get(response, "Data");
        if (_data) {
          this.setState(
            {
              footer: _data,
            },
            () => {
              HUI.Toast.showSuccess(HUtils.Obj.get(response, "Msg"));
            }
          );
        }
      },
      errorCallBack: (error, response) => {
        console.warn("SCEditorFooter error, response", error, response);
      },
    });
  };
  onSave = () => {
    // this.requestUpdateBlog()
    if (this._editor_html && this._editor_html.getData) {
      let _content = this._editor_html.getData();
      if (_content) {
        this.requestUpdate(_content);
      }
    }
  };
  render() {
    const { footer } = this.state;
    if (footer) {
      return (
        <div style={{ position: "relative" }}>
          <CButton
            color="primary"
            style={{
              position: "absolute",
              zIndex: 1072,
              right: 0,
              height: "38.67px",
            }}
            onClick={() => this.onSave()}
          >
            Cập nhật
          </CButton>
          <MyUI
            type="html_editor_ckeditor"
            html={footer.Name}
            className="html-content"
            innerRef={(r) => {
              this._editor_html = r;
            }}
          />
        </div>
      );
    }
    return <div></div>;
  }
}
export default SCEditorFooter;
