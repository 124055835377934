import React from 'react'
import {
  CInput,
} from '../../MyCore'
class MyUI_InputText extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // console.warn("getDerivedStateFromProps",props,state);
    if (props.defaultValue !== state.defaultValue) {
      return {
        defaultValue: props.defaultValue,
        value: props.defaultValue!=null?props.defaultValue:'',
      };
    }
    return null;
  }
  state = {
    value: this.props.defaultValue!=null?this.props.defaultValue:'',
    defaultValue: this.props.defaultValue
  }; 
  render(){    
    const {style,placeholder,onBlur,disabled,className,inputMode,beforeOnChange, inputType,onKeyPress, autoFocus, onChange} = this.props;
    // console.warn("MyUI_InputText render:",this.props,this.state);
    return (
      <CInput 
        type={inputType || 'text'} 
        value={this.state.value} 
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        className={className}
        inputMode={inputMode}
        autoFocus = {autoFocus}
        onBlur={(ev)=>{
          // this._onChange(ev, this.state.value);
          // CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value});
          if(onBlur){
            onBlur(ev,ev.currentTarget.value);
          }
        }}
        onKeyPress={onKeyPress}
        onChange={ (ev) => { 
          // console.warn('onChange',ev,ev.currentTarget.value,this.state.value);
          let _value = ev.currentTarget.value;
          if(beforeOnChange){
            _value = beforeOnChange(_value);
          }
          this.setState({ 
            value: _value 
          },()=>{
            onChange && onChange(ev, _value)
          });
        }}
      />
    )
  } 
}

export default MyUI_InputText