import React from 'react'
import classnames from 'classnames'
import HUtils from '../../helpers/Utils';
import HLink from '../../helpers/Link';

const MyButtonHelpers = {
  parseConfigToProps(cButtonProps){
    const {config,state,style,className} = cButtonProps;
    const _more = MyButtonHelpers.getMore({props:cButtonProps});
    const _style = style ||(config.More && config.More.style )|| {}
    let _obj = {
      color: config.Color || 'primary',
      variant: '',
      style: _style,
    };
    if(config){
      if(state && state.isLoading){
        _obj.disabled = true;
      }
      if(config.Color_BG){
        _obj.style = {..._obj.style, backgroundColor: config.Color_BG}
      }
    }
    let _className = classnames(className,_more.className);
    if(_className){
      _obj.className = _className;
    }
    // console.warn("parseConfigToProps:",cButtonProps,_more);
    return _obj;
  },
  checkNeedReload(cButton){
    const {config,fnList} = cButton.props;
    if(config && config.NeedReload==true){
      if(fnList && fnList.fnReloadPage){
        fnList.fnReloadPage();
      }
      else{
        window.location.reload();
      }
    }
    if(config && config.NeedReloadList==true){
      if(fnList && fnList.fnReloadList){
        fnList.fnReloadList()
      }
    }
  },
  parseRespone(cButton,response){
    const {config,fnList} = cButton.props;
    let _linkAfterSuccess = MyButtonHelpers.getObjectFromMore(cButton, "linkAfterSuccess");
    if(_linkAfterSuccess && response && response.Data){
      _linkAfterSuccess = HUtils.SquareBracket.replace(_linkAfterSuccess, response.Data);
      let _linkAfterSuccessNewWindow = MyButtonHelpers.getObjectFromMore(cButton, "linkAfterSuccessNewWindow");
      console.warn("link, config", _linkAfterSuccess, config)
      HLink.push(_linkAfterSuccess,_linkAfterSuccessNewWindow===true?true:false);
    }
  },
  getMore(cButton){
    const {config} = cButton.props;
    let _more = {};
    if(config && config.More){
      if(typeof(config.More)==="string"){
        console.warn("string")
        try {
          _more =JSON.parse(config.More)
        } catch (error) {
          console.warn("parse error", error)
        }
      }
      else{
        _more = config.More
      }      
    }
    return _more
  },
  getObjectFromMore(cButton, key){
    const {config,fnList} = cButton.props;
    const _more = MyButtonHelpers.getMore(cButton);

    if(_more && _more[key]){
      return _more[key]
    }
  },
  getUrlConfig(cButton){ //link có param
    const {config,fnList} = cButton.props;
    let _url = config && config.Url;
    if(_url && _url.indexOf('[')>-1){
      if(fnList && fnList.fnGetDataObj){
        let _obj = fnList.fnGetDataObj({
          cButton, config
        });
        if(_obj){
          _url = HUtils.SquareBracket.replace(_url,_obj);
        }
      }      
    }
    return _url
  },
  getRequestConfig(cButton,{isDependData}={}){
    const {config,fnList} = cButton.props;
    //
    if(config.APIName && fnList && fnList.fnGetApiController){
      if(config.APIName.indexOf("/")==-1){
        config.APIName = `${fnList.fnGetApiController()}/${config.APIName}`
      }
    }
    //
    let _requestConfig = {
      method: config.method || config.MethodType,
      url: config.url || config.APIName    
    };
    if(isDependData==true){
      let _dependData = MyButtonHelpers.getObjectFromMore(cButton, "dependData");
      if(_dependData){
        _requestConfig = {
          method: _dependData.method || config.method || config.MethodType,
          url: _dependData.apiUrl || config.url || config.APIName    
        };
      }
    }
    if(_requestConfig.url && _requestConfig.url.indexOf('[')>-1){
      if(fnList && fnList.fnGetDataObj){
        let _obj = fnList.fnGetDataObj({
          cButton, config
        });
        if(_obj){
          _requestConfig.url = HUtils.SquareBracket.replace(_requestConfig.url,_obj);
        }
      }      
    }
    if(fnList && fnList.fnGetRequestConfig){
      let _custom = fnList.fnGetRequestConfig({
        cButton, config
      })
      if(_custom){
        _requestConfig = _custom;
      }
    }
    return _requestConfig;
  },
  getRequestData(cButton,{moreRequest}={}){
    const {config,fnList} = cButton.props;
    let _requestData = {};
    if(config && config.RequestData){
      _requestData = {..._requestData, ...config.RequestData}
    }
    if(fnList && fnList.fnGetRequestData){
      _requestData = {..._requestData, ...fnList.fnGetRequestData({
        cButton, config
      })}
    }
    if(moreRequest){
      _requestData = {..._requestData, ...moreRequest}
    }
    return _requestData;
  },
  getDisplay(cButtonProps){    
    const {config,state} = cButtonProps;
    // console.warn("btn:",cButton,config,state);
    if(config){
      let _ui = [];
      if(state && state.isLoading==true){
        _ui.push(
          <i key="iconloading" className={"fa fa-spinner fa-spin"}/> 
        )
      }
      else{
        if(config.Icon){
          _ui.push(
            <i key="icon" className={config.Icon}/> 
          )
        }
      }
      if(config.Name){
        _ui.push(
          config.Name 
        )
      }  
      return _ui;
    }       
    return "Btn"
  },
}

export default MyButtonHelpers;