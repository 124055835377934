import React from 'react'
import {MyUI, MyModal} from 'apps/Components';
import PageExt from 'components/MyPage/Types/_ext';
import {HApiAxios, HUtils, HText} from 'apps/Helpers'
import AjumaUtils from '../../../_utils'
import './styles/common.css'

class SC_Blog extends PageExt{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            related_products: null,
        }
    }
    componentDidMount(){
        const {configPage} = this.props;
        if(configPage && configPage.Data){
            const _data = HUtils.Obj.get(configPage, "Data.Data.BlogPost");
            this.setState({
                data: _data,
            },()=>{
                AjumaUtils.setOnErrorImg(".page-body img");
                this.getRelativeProduct(_data.Id)
            })
        }
    }
    getRelativeProduct=(blogId)=>{
        if(blogId){
            HApiAxios.generic({
                request: {
                    method: "POST",
                    url: `DashboardForWebsite/GetDataListMaterialRelationWithBlog4Website/${blogId}`
                },
                data: {
                    // BlogId: blogId
                },
                successCallBack: (response)=>{
                    this.setState({
                        related_products: HUtils.Obj.get(response, "Data")
                    })
                },
                errorCallBack: (error, response)=>{
                    console.warn("error, response", error, response)
                }
            })
        }
    }
    openModalEdit=({isNew}={})=>{
        const {data} = this.state;
        if(data){
            MyModal.Helpers.showMyUI("sc_editor_blog",{
                data: isNew===true?null:data,
                isNew: isNew,
                fnList: {
                    updateBlog: (newData)=>{
                        if(newData){
                            this.setState({
                                data: newData
                            })
                        }
                    }
                }
            },{
                title: `Chỉnh sửa bài viết - ${data.Title}`,
                className: 'sc_modal_editor',
                closeOnBackdrop: false,
                closeButton: true
            })
        }
    }
    buildSnippets=()=>{
        const {data} = this.state;
        let _snippet = {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline": data.MetaTitle,            
            "publisher": {
              "@type": "Organization",
              "name": "Ajuma Garden",
              "logo": {
                  "@type": "ImageObject",
                  "url": "https://ajumagarden.com/images/ajuma/logo.png"
              }
            },
            "keywords": (data.MetaKeyWords  && data.MetaKeyWords.length>0 )? data.MetaKeyWords.split(','):[],
            "datePublished": "11/23/2020 8:34:53 PM",
            "dateModified": "11/23/2020 8:34:53 PM",
            // "articleSection": "SEO",
            "creator": "ajumagarden",
            "author": {
                "@type": "Organization",
                "name": "Ajuma Garden",
                "url": "https://www.facebook.com/ajumagarden/"
            },
            "description": data.BodyOverview,
            "mainEntityOfPage":{
                "@type":"WebPage",
                "@id": window.location.href
            }            
        }
        let _imgUrl = HUtils.Obj.get(data,"Files_0.Photos[0].FileUrl_FullSize");
        if(_imgUrl){
            _snippet.image = {
                "@type": "ImageObject",
                "url": _imgUrl,
                "width": 696,
                "height": 450
            }
        }
        return _snippet;
    }
    renderHelmet = () =>{
        const {data} = this.state;
        return <MyUI type="sc_helmet" data = {data} buildSnippets={this.buildSnippets}/>
    }
    renderToolbarAdmin = () =>{
        const {data} = this.state;
        if(data){
            const _IsActive = data.IsActive;
            const _Slug = data.Slug;
            return(
                <MyUI type="sc_toolbar_admin" ShowBtnCreatePost={false} status={_IsActive} slug={_Slug} title="Bài viết" fnList={{
                    onEdit: ()=>{
                        this.openModalEdit()
                    },
                    onAddNew: ()=>{
                        this.openModalEdit({isNew: true})
                    }
                }}/>
            )
        }
    }
    renderProductRelative=()=>{
        const {related_products} = this.state;
        console.warn("related_products",related_products)
        return <div style={{marginTop: 20}}>
            <MyUI type="sc_product_relative" data={related_products}/>
        </div>
    }
    renderBlogContent = () =>{
        const {data} = this.state;
        if(data){
            const _image =  HUtils.Obj.get(data, "Files_0.Photos[0].FileUrl_FullSize");
            const _CreatedDate_Text = data.CreatedDate_Text;
            const _AllowComment = data.AllowComment;
            const _Title = data.Title;
            const _Body = data.Body;
            return(
                <div className="page-body">
                    <div className="post">
                        <div className="page-title">
                            <h1>{_Title}</h1>
                        </div>
                        <div className="post-date">
                            {_CreatedDate_Text}
                        </div>
                        {
                            _image &&
                            <div className="rich-blog-image">
                                <img src={_image} title={_Title} alt={_Title}/>
                            </div>
                        }
                        <div className="post-body html-content" dangerouslySetInnerHTML={{__html: _Body}}></div>
                        {
                            _AllowComment === true &&
                            <div className="fieldset new-comment">
                                <div className="title">
                                    <strong>{HText.get("text_blog_comment_title")}</strong>
                                </div>
                                <form>
                                    <div className="form-fields" style={{padding:'0px'}}>
                                        <div className="inputs">
                                            <label htmlFor="AddNewComment_CommentText">{HText.get("text_blog_comment")}:</label>
                                            <div 
                                                className="fb-comments" 
                                                data-href={window.location.href} 
                                                data-numposts="5" 
                                                data-width="100%">

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }                                                
                    </div>
                </div>
            )
        }
        return <div></div>
    }
    render(){
        const {data} = this.state;
        if(data){
            return(
                <div className="master-wrapper-page ">
                    { this.renderHelmet() }
                    <div className="master-wrapper-content">
                        <div className="master-column-wrapper">
                            <div className="rich-blog-body">
                                <div className="center-2">
                                    <div className="page blogpost-page">
                                        { this.renderToolbarAdmin() }
                                        { this.renderBlogContent() }
                                        { this.renderProductRelative() }
                                    </div>
                                </div>                        
                            </div>
                        </div>    
                    </div>                   
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_Blog