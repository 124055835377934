import React from 'react'
import './styles/sc_blog_post.css'
import {MyUI} from '../../../../Components'
import {HUtils} from '../../../../Helpers'

class SC_BlogPost extends React.Component{
    render(){
        const {data, onClick} = this.props;
        const _image = HUtils.Obj.get(data, "Files_0.Photos[0].FileUrl");

        if(data){
            return(
                <div className="post">
                    <div className="post-head">
                        {
                            data.IsActive == false &&
                            <div className="marker-inactive">Chưa đăng</div>
                        }
                        {
                            _image &&
                            <div className="rich-blog-image">
                                <a href={data.Slug} alt={data.Title} onClick={()=>{
                                    onClick && onClick()
                                }}>
                                    <img src={_image} title={data.Title} alt={data.Title} onClick={()=>{
                                    onClick && onClick()
                                    }}/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className="post-body">
                        <span className="post-date">{data.CreatedDate_Text}</span>
                        <a href={data.Slug} className="read-comments">0</a>
                        <a className="post-title" href={data.Slug}>{data.Title}</a>
                        <div className="post-preview">
                            {/* <MyUI type="html" html={data.BodyOverview}/> */}
                            {data.MetaDescription}
                        </div>
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_BlogPost