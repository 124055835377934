import React from "react";
import { MyUI } from "apps/Components";
import Slider from "libs/ReactSlick/index";
import "./styles/sc_product_relative.css";

class SC_ProductRelative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getConfig);
  }
  componentDidMount() {
    this.getConfig();
    window.addEventListener("resize", this.getConfig);
  }
  getConfig = () => {
    const _screenWidth = window.innerWidth;
    let _st = {
      dots: false,
      arrows: true,
      infinite: false,
      autoplay: false,
      speed: 2000,
    };
    if (_screenWidth >= 1281) {
      _st.slidesToShow = 5;
      _st.slidesToScroll = 5;
    } else if (_screenWidth <= 1280 && _screenWidth >= 769) {
      _st.slidesToShow = 4;
      _st.slidesToScroll = 4;
    } else if (_screenWidth <= 768 && _screenWidth >= 576) {
      _st.slidesToShow = 3;
      _st.slidesToScroll = 3;
    } else {
      _st.slidesToShow = 1;
      _st.slidesToScroll = 1;
    }
    this.setState({
      settings: _st,
    });
  };
  renderSlide = (data) => {
    return data.map((v, i) => {
      return <MyUI type="sc_card_product" data={v} key={i} />;
    });
  };
  render() {
    const { data } = this.props;
    const { settings } = this.state;
    if (data?.length > 0) {
      return (
        <div className="sc_product_relative product-grid">
          <div className="title">Sản phẩm liên quan</div>
          {data.length < settings.slidesToShow && this.renderSlide(data)}
          {data.length >= settings.slidesToShow && (
            <Slider {...settings}>{this.renderSlide(data)}</Slider>
          )}
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_ProductRelative;
