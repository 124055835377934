import React from "react";
import { MyModal } from "../../../../Components";
import { HUtils, HFormat, HConfig, HText } from "../../../../Helpers";
import HGA from "../../../../../helpers/GA";
import HShopUtils from "../../../helpers/Utils";
import { connect } from "react-redux";
import "./styles/sc_card_product.css";

class CardProduct extends React.Component {
  onClickAddCart = (data) => {
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
    if (_haveProductOptions) {
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart_with_options",
        {
          data: data,
          link: "/cart",
        },
        {
          title: data.MaterialName,
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    } else {
      let _productUpdate = {
        Quantity: 1,
      };
      HShopUtils.addProductToCart({
        product: data,
        productUpdate: _productUpdate,
      });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_cart"),
          product: data,
          link: "/cart",
          modalType: "cart",
        },
        {
          title: HText.get("text_modal_title_add_cart"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    }

    HGA.trackingEventClick({
      action: "click_addcart_product",
      label: data.MaterialName,
    });
  };
  onClickAddWishList = (data) => {
    let _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    if (_isInWishList) {
      HShopUtils.removeProductWishList({ product: data });
      HGA.trackingEventClick({
        action: "click_removewish_product",
        label: data.MaterialName,
      });
    } else {
      HShopUtils.addProductToWishList({ product: data });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_wishlist"),
          product: data,
          link: "/wishlist",
          modalType: "wishlist",
        },
        {
          title: HText.get("text_modal_title_add_wish"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );

      HGA.trackingEventClick({
        action: "click_addwish_product",
        label: data.MaterialName,
      });
    }
    this.forceUpdate();
  };
  renderProductImage = (data) => {
    const { onClick } = this.props;
    const _MaterialName = data.MaterialName;
    const _Slug = data.Slug;
    const _ImageUrlThumnail =
      data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
    const _Inventory = data.Inventory;
    const _AllowOrder = _Inventory?.AllowOrder;

    return (
      <div className="picture">
        <a
          title={`${HText.get("text_product_title")} ${_MaterialName}`}
          href={_Slug}
          onClick={() => {
            onClick && onClick();
          }}
        >
          <img
            loading="lazy"
            alt={`${HText.get("text_product_title_image")} ${_MaterialName}`}
            title={`${HText.get("text_product_title_image")} ${_MaterialName}`}
            src={_ImageUrlThumnail}
            onClick={() => {
              onClick && onClick();
            }}
          />
        </a>
        {
          (_AllowOrder === false) && (
            <span className="product-out-of-stock">*{HText.get("text_product_out_of_stock")}</span>
          )
        }
      </div>
    );
  };
  renderProductDetail = (data) => {
    const _MaterialName = data.MaterialName;
    const _Slug = data.Slug;
    const _Rating = data.Rating;
    const _SellPriceText = data.SellPriceText;
    const _ShortDescription = data.ShortDescription;
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _SellPrice = data.SellPrice;
    const _QuantityMin = HUtils.Obj.get(data, "DiscountInfo.QuantityMin");
    const _DiscountMoney = HUtils.Obj.get(data, "DiscountInfo.DiscountMoney");
    const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
    const _Discount_Text = HUtils.Obj.get(data, "DiscountInfo.Discount_Text");

    return (
      <div className="details">
        <div className="product-rating-box" title={_Rating}>
          <div className="rating">
            <div style={{ width: `${_Rating * 20}%` }} />
          </div>
        </div>
        <div className="attribute-squares-wrapper"></div>
        <h2 className="product-title">
          <a href={_Slug} title={_MaterialName}>
            {_MaterialName}
          </a>
        </h2>
        <div className="add-info">
          <div className="prices">
            {_QuantityMin <= 1 && (
              <span
                content={_PriceAfterDiscount}
                className="price discount-price"
              >
                {HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}
              </span>
            )}
            <span
              className={`price actual-price${
                _QuantityMin <= 1 ? " origin" : ""
              }`}
              style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
            >
              {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
            </span>
          </div>
          {_QuantityMin > 1 && _Discount_Text && (
            <div className="product-coupon">
              <div className="text" title={_Discount_Text}>
                {_Discount_Text} {_Discount_Text}
              </div>
            </div>
          )}
          <div className="description">{_ShortDescription}</div>
          {this.renderProductActions(data)}
        </div>
      </div>
    );
  };
  renderProductActions = () => {
    const { data, adminButtons } = this.props;
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _IsActive = data.IsActive;
    const _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    const _Inventory = data.Inventory;
    const _AllowOrder = _Inventory?.AllowOrder;
    const _disabledBtn = _AllowOrder === false ? true : false;
    return (
      <>
        <div className="buttons-upper">
          {adminButtons && _IsActive !== null && (
            <button
              className={
                _IsActive === true ? "product-active" : "product-inactive"
              }
            >
              <i className="fa fa-circle" />
            </button>
          )}
          <button
            title={HText.get("text_product_add_wishlist")}
            className={`button-2 add-to-wishlist-button${
              _isInWishList ? " active" : ""
            }`}
            onClick={() => this.onClickAddWishList(data)}
          >
            <i className="fa fa-heart" />
          </button>
        </div>
        <div className="buttons-lower">
          <div className="ajax-cart-button-wrapper">
            {_IsCall4WebOrder !== true && (
              <button
                type="button"
                disabled={_disabledBtn}
                title={_disabledBtn ? "" : HText.get("text_modal_btn_add_cart")}
                className={`button-2 product-box-add-to-cart-button${
                  _disabledBtn ? " disabled" : ""
                }`}
                onClick={() => this.onClickAddCart(data)}
              >
                {_AllowOrder === false && (
                  <div className="product-out-of-stock">
                  {HText.get("text_product_out_of_stock")}
                </div>
                )}
                  <i className="fa fa-shopping-basket" />
                  <span>{HText.get("text_product_add_cart")}</span>
              </button>
            )}
          </div>
        </div>
      </>
    );
  };
  render() {
    const { data } = this.props;
    if (data) {
      return (
        <div className="item-box">
          <div className="product-item">
            {this.renderProductImage(data)}
            {this.renderProductDetail(data)}
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
const mapStateToProps = (state) => {
  if (state.shop && state.shop.adminButtons) {
    return {
      adminButtons: state.shop.adminButtons,
    };
  }
  return {};
};
export default connect(mapStateToProps, null)(CardProduct);
