import React from 'react'
import './styles/sc_product_tag.css'

class SC_ProductTag extends React.Component{
    render(){
        const {data} = this.props;
        if(data && data.length>0){
            return(
                <div className="sc_product_tag">
                    <div className="title">Thẻ sản phẩm</div>
                    <div className="product_taglist">
                        {
                            data.map((v,i)=>{
                                return(
                                    <a key={i} href={v.Slug} className="tag">{v.TagName}{i!=(data.length-1) && <span className="separator">,</span>}</a>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_ProductTag