import React from 'react'
import MyForm from '../../MyForm/MyForm'
class MyUI_Form extends React.Component {    
  render(){
    const {config,formType,onSubmit,getOptionOfKey} = this.props;
    return (
      <div>
        <MyForm 
          config={config} 
          onSubmit={onSubmit}
          getOptionOfKey={getOptionOfKey}
          {...this.props}
          type={formType}
        />
      </div>      
    )
  } 
}

export default MyUI_Form