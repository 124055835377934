import React from "react";
import MyLoading from "../../../../components/MyLoading/MyLoadingComponent";

const Update = React.lazy(() => import("./Types/update"));
const Google = React.lazy(() => import("./Types/google"));
const Check = React.lazy(() => import("./Types/check"));
const Plus = React.lazy(() => import("./Types/plus"));
const Sale = React.lazy(() => import("./Types/sale"));
const Flash = React.lazy(() => import("./Types/flash"));
const Minus = React.lazy(() => import("./Types/minus"));
const Heart = React.lazy(() => import("./Types/heart"));
const Bag = React.lazy(() => import("./Types/bag"));
const Times = React.lazy(() => import("./Types/times"));
const Info = React.lazy(() => import("./Types/info"));

const MyIconSVGTypes = {
  addType(key, fnComponent) {
    if (MyIconSVGTypes.Types[key] == null) {
      MyIconSVGTypes.Types[key] = fnComponent;
    }
  },
  Types: {
    update(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Update {...props} />
        </React.Suspense>
      );
    },
    google(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Google {...props} />
        </React.Suspense>
      );
    },
    check(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Check {...props} />
        </React.Suspense>
      );
    },
    plus(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Plus {...props} />
        </React.Suspense>
      );
    },
    sale(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Sale {...props} />
        </React.Suspense>
      );
    },
    flash(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Flash {...props} />
        </React.Suspense>
      );
    },
    minus(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Minus {...props} />
        </React.Suspense>
      );
    },
    heart(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Heart {...props} />
        </React.Suspense>
      );
    },
    bag(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Bag {...props} />
        </React.Suspense>
      );
    },
    times(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Times {...props} />
        </React.Suspense>
      );
    },
    info(props) {
      return (
        <React.Suspense fallback={<MyLoading />}>
          <Info {...props} />
        </React.Suspense>
      );
    },
  },
};

export default MyIconSVGTypes;
