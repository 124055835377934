import React from 'react'
import {MyUI, MyLoadingApp} from '../../../../Components';
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {HApiAxios, HUtils, HLink} from '../../../../Helpers'
import {Helmet} from "react-helmet";
import './styles/common.css'

class SC_BlogHome extends PageExt{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            total: 0,
            meta: null
        }
    }
    componentDidMount(){
        this.requestGetBlogList()
    }
    getPageParam=()=>{
        const _params = HLink.getAllUrlParams(window.location.href);
        let _page = 1 ;
        if(_params && _params.page){
            _page = parseInt(_params.page)
        }
        return _page
    }
    requestGetBlogList=()=>{
        HApiAxios.generic({
            request: {
                method: 'POST',
                url: 'DashboardForWebsite/GetDataListBlog4Website'
            },
            data: {
                PageSize: 10,
                Page: this.getPageParam(),
            },
            successCallBack: (response)=>{
                const _data = HUtils.Obj.get(response, "Data.Data");
                const _total = HUtils.Obj.get(response, "Data.Total");
                const _meta = HUtils.Obj.get(response, "Data.ExtraData.MetaDataInfo")
                if(_data){
                    this.setState({
                        data: _data
                    })
                }
                if(_meta){
                    this.setState({
                        meta: _meta
                    })
                }
                if(_total){
                    this.setState({
                        total: _total
                    })
                }

            },
            errorCallBack: (error, response)=>{
                MyLoadingApp.Helpers.show({error: error || response.Msg})
            }
        })
    }
    renderHelmet = () =>{
        const {meta} = this.state;
        return <MyUI type="sc_helmet" data = {meta}/>
    }
    renderContent=()=>{
        const {data} = this.state
        let _ui = [];
        let _first = null;
        if(data && data.length>0){
            _first = (<div className="first-blog-post" key={0}><MyUI type="sc_blog_post" data={data[0]}/></div>)
            for(let i=1; i<data.length; i++){
                const _item = data[i];
                _ui.push(<MyUI type="sc_blog_post" key={i} data={_item}/>)
            }
        }
        return (
            <div className="blog-posts">
                {_first}
                <div className="blog-posts-list">
                    {_ui}
                </div>
            </div>
        )
    }
    renderPagination=()=>{
        const {total} = this.state;
        if(total > 10){
            return (
                <MyUI type='react_pagination_box' 
                    useLink={true}
                    forcePage={this.getPageParam()}
                    pageSize={10} 
                    pageTotal={this.state.total||0}
                    containerClassName="pager"
                    hrefBuilder={(p)=>{
                        return `/blog?page=${p}`
                    }}
                    onPageChange={({selected})=>{
                        // this.changePage(selected + 1)
                    }}/>
            )
        }
        return <div></div>
    }
    render(){
        return(
            <div className="rich-blog-body">
                <div className="master-wrapper-content">
                    { this.renderHelmet() }
                    <div className="master-column-wrapper">
                        <div className="center-2">
                            <div className="page blog-page">
                                <div className="page-body">
                                    { this.renderContent() }
                                    { this.renderPagination() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_BlogHome