import React from 'react'
import {MyUI} from '../../../../Components'
import {HFormat, HText} from '../../../../Helpers'
import './styles/sc_modal_mini_shoppingcart.css'

class SC_ModalMiniShoppingCart extends React.Component{
    render(){
        const {style, items, total} = this.props;
        return(
            <div className="flyout-cart" style={style}>
                {
                    items && items.length>0 &&
                    <div className="mini-shopping-cart">
                        <div className="count">{HText.get("text_mini_preview_describe_1")}<a href="/cart">{items && items.length} {HText.get("text_mini_preview_unit")}</a>{HText.get("text_mini_preview_describe_2")}</div>
                        <div className="items">
                            {
                                items && items.map ((v,i)=>{
                                    return <MyUI key={i} data={v} type="sc_card_product_minishopping"/>
                                })
                            }
                        </div>
                        { total!==0 && <div className="totals">{HText.get("text_mini_preview_total")}: <strong>{HFormat.Money.formatMoneyForAjuma(total)}</strong></div>}
                        <div className="buttons">
                            <a href="/cart">
                                <button className="button-1 cart-button">{HText.get("text_mini_preview_btn_go_cart")}</button>
                            </a>
                        </div>
                    </div>
                }
                {
                    items && items.length == 0 &&
                    <div className="mini-shopping-cart" style={{padding: "25px"}}>{HText.get("text_mini_preview_empty")}</div>
                }
            </div>
        )
    }
}
export default SC_ModalMiniShoppingCart