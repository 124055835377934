import React from 'react'
import Select2 from '../../../libs/ReactSelectV1.1.0/index'
import '../../../libs/ReactSelectV1.1.0/scss/react-select.css'

/**
 * document: https://v1.react-select.com/
 */

class MyUI_Select2 extends React.Component {    
  render(){
    return (
      <Select2 
        labelKey={"Text"} 
        valueKey={"Value"} 
        multi={true} 
        delimiter={','} 
        {...this.props}
      />
    )    
  } 
}

export default MyUI_Select2