import * as Routers from "./_routes";
import HGA from "../../helpers/GA";

/** Custom Component */
import AppMyUI from "./components/MyUI";
import AppMyPage from "./components/MyPage";
import TheLayout from "./containers/TheLayout"; //import de khoi tach file
import AppMyLib from "./components/MyLib";

/** Custom store */
import * as CustomStore from "./_store";

/** Css */
import "./styles.css";

/** Helpers */
import {
  HGlobalSetting,
  HLink,
  HUtils,
  HAuth,
  HConfig,
  HStoreRedux,
  HLocalStorage,
  HText,
} from "../Helpers";
import { SApiAxios } from "../Services";
import { initFacebookSdk } from "../../libs/FacebookLogin";
import { initZaloScript } from "libs/ZaloChat";
import BuildVersion from "./_version";
import AjumaDefault from "../../scss/img/ajuma_placeholder.png";
import LogoAjuma from "../../scss/img/ajuma_logo2.png";
import Shop_Language from "./helpers/Text";

const ConfigBuild = {
  Routers: Routers,
  CustomStore,
  ConfigApp: {
    defaultHomePage: "",
    // BaseUrl: 'https://api_ajumashopdev.allianceitsc.com',
    BaseUrl: "https://posapi.allianceitsc.com",
    // BaseUrl: "https://webapi.ajumagarden.com",
    isUseHash: false, //Ko su dung router Hash,
    listDomainDebug: ["ajumashopdev.allianceitsc.com"], //Check debug
    facebookAppId: 591255338475745,
    facebookPageId: 1581613735271883,
    linkFB: "https://www.facebook.com/ajumagarden/",
    googleClientId:
      "994017580175-sdcpdvgja86oe0pu6kv86f5oj67tmfoa.apps.googleusercontent.com",
    isShowZaloChat: true,
    zaloOAID: "4067690760735446689",
    appName: "ajumashop",
    GAId: "UA-64446070-6",
    GAId_Dev: "UA-156318542-10",
    theme: "theme-ajumashop",
    metaName: "Ajuma Garden",
    metaUrl: "https://ajumagarden.com",
    metaDiscription: "THE BEST FOOD TO SHARE",
    metaKeyWords:
      "Ajuma Garden của chúng mình là một góc nhỏ, nơi chúng mình sẽ quy tụ những trái cây ngon nhất Việt Nam được trồng đảm bảo tiêu chuẩn sạch, an toàn. Ngoài ra, hoa, bánh mì và ti tỉ các loại rau củ quả cũng đều được chúng mình mang về",
    uiCopyRightFooter:
      "Copyright © 2020 Ajuma Garden | 31-33 Nguyễn Văn Đậu, P.6, Bình Thạnh, TP.HCM. Tất cả quyền được bảo lưu.",
    uiHotline: "0978487735",
    tabContact: {
      Title: "Liên hệ - Tư vấn",
      Content:
        '<div><p>Cảm ơn bạn đã ghé thăm Ajuma Garden. Mọi vấn đề thắc mắc hay cần tư vấn, đừng ngại liên lạc trực tiếp với chúng tôi qua:</p><p>FaceBook: <a href="https://www.facebook.com/ajumagarden/">Ajuma Garden</a></p><p>Website: https://ajumagarden.com </p><p>Email: ajumagarden@gmail.com </p><p>Di động: 0978487735</p><p>Liện lạc trực tiếp tại cửa hàng</p><p style="font-size: 16px; font-weight: 500; color: var(--color-text-bold)">Cửa hàng 1:</p><p>Địa chỉ</p><li style="margin-left: 1rem">31 - 33 Nguyễn Văn Đậu, Bình Thạnh, TP.HCM</li><p style="margin-top: 1rem">Bản đồ</p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15676.275610651184!2d106.6867725!3d10.8060348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0ba8299291a8dae!2sAjuma%20Garden!5e0!3m2!1sen!2s!4v1615778021570!5m2!1sen!2s" width="600" height="450" style="border:0;width: 100%" allowfullscreen="" loading="lazy"></iframe></div>',
    },
    imageProductDefault: AjumaDefault, // ảnh mặc định của sản phẩm,
    zoomLevelProductPicture: 3,
    productCartView: 0, // Kiểu hiển thị product trang cart, 0: table, 1: card
    temporarilyStopWorking: false,
    temporarilyStopWorkingTitle: "THÔNG BÁO TẠM NGỪNG NHẬN ĐƠN",
    temporarilyStopWorkingDes:
      "<div><p>Ajuma Garden xin kính chào quý khách!</p><p> Do tình hình dịch bệnh diễn biến ngày càng phức tạp, để phối hợp với các cơ quan ban ngành trong phòng chống dịch, Ajuma Garden xin phép ngừng nhận đơn từ hôm nay và đóng cửa cho tới 06/09/2021. </p> <p>Ajuma Garden chân thành cảm ơn quý khách hàng đã thương mến và đồng hành cùng tiệm. Hẹn gặp lại quý khách khi tình hình dịch bệnh đã được kiểm soát. </p><p>Ajuma Garden thương chúc quý khách và gia đình sức khỏe, bình an! Cùng nhau chúng ta sẽ sớm vượt qua!</p><p>From ajumagarden with love!</p></div>",
    logoApp: LogoAjuma,
    socialList: [
      {
        Icon: "fa fa-facebook",
        Link: "https://www.facebook.com/ajumagarden/",
        Style: { fontSize: 20 },
      },
    ],
    categoryConfig: {
      pageSizeOptions: [
        { Value: 6, Text: "6" },
        { Value: 12, Text: "12" },
      ],
      sortOptions: [
        { Value: "1", Text: "Mới nhất" },
        { Value: "2", Text: "Giá: Thấp đến cao" },
        { Value: "3", Text: "Giá: Cao đến thấp" },
      ],
      filterPriceRange: {
        min: 0,
        max: 2000000,
        step: 10000,
      },
    },
    footer: {
      imgDeclareMIT: "/images/ajuma/logo_so_cong_thuong.png",
      urlDeclareMIT: "khai-bao-ten-mien-cong-thuong",
    },
    configText: {
      text_accept_policy: {
        vi: `<div>Tôi đã đọc và đồng ý với các điều khoản và quy định <a href="/privacy-policy" target="_blank">tại đây</a></div>`,
        en: `<div>I read and accepted your privacy policy <a href="/privacy-policy" target="_blank">at here</a></div>`,
      },
    },
    language: "shop_vi",
  },
  fnList: {
    afterInit(HConfig) {
      console.warn("RunAfterInit:", Routers);
      const _cart = HLocalStorage.getObject("cart");
      const _wishlist = HLocalStorage.getObject("wishlist");
      const _recent = HLocalStorage.getObject("recent");
      if (_cart) {
        HStoreRedux.dispatch({ type: "setObjInShop", cartProducts: _cart });
      }
      if (_wishlist) {
        HStoreRedux.dispatch({ type: "setObjInShop", wishlist: _wishlist });
      }

      /** GA */
      HGA.initialize();
      Shop_Language.addTextFrmConfig(HConfig.fnList.getConfig("configText"));
      HText.addCustomText(Shop_Language.vi, "shop_vi");
      HText.addCustomText(Shop_Language.en, "shop_en");
      //init facebook
      initFacebookSdk();
      //init zalo
      if (HConfig.fnList.getConfig("isShowZaloChat")) {
        initZaloScript();
      }
      if (_recent) {
        HStoreRedux.dispatch({ type: "setObjInShop", recent: _recent });
      }
    },
    afterLostSession() {
      console.warn("afterLostSession");
      HAuth.logOut();
    },
    afterLogout() {
      console.warn("afterLogout");
      SApiAxios.updateToken(null);
      HLink.push("/");
      //Khi mat section tu dong logout roi tro ve trang home
    },
    afterLogin() {
      console.warn("afterLogin");
      SApiAxios.updateToken(HAuth.getAuthToken());
    },
    afterErrorApi(error, opts) {
      console.warn("AA:", error, opts);
      let _url = HUtils.Obj.get(opts, "options.options.url") || "no_url";
      let _errorStatus =
        HUtils.Obj.get(error, "response.status") || "no_status";
      let _data = HUtils.Obj.get(opts, "options.data") || {};
      if (_data && typeof _data == "object") {
        let _query = Object.keys(_data)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(_data[key])}`
          )
          .join("&");
        if (_query && typeof _query == "string") {
          _url += "?" + _query;
        }
      }
      let _error_message =
        HUtils.Obj.get(opts, "error_message") || "no_message";
      console.warn("LogError:", _url, _error_message, _errorStatus);
      HGA.trackingEventErrorApi({
        apiUrl: _url,
        label: _error_message,
      });
    },
  },
};

export default ConfigBuild;
