import React from 'react'
import {
  CTextarea,
} from '../../MyCore'

class MyUI_Textarea extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // console.warn("getDerivedStateFromProps",props,state);
    if (props.defaultValue !== state.defaultValue) {
      return {
        defaultValue: props.defaultValue,
        value: props.defaultValue!=null?props.defaultValue:'',
      };
    }
    return null;
  }
  state = {
    value: this.props.defaultValue!=null?this.props.defaultValue:'',
    defaultValue: this.props.defaultValue
  }; 
  render(){    
    const {style,placeholder,onBlur,disabled,className} = this.props;
    // console.warn("MyUI_Textarea render:",this.props.defaultValue);
    return (
        <CTextarea 
            value={this.state.value} 
            style={style}  
            placeholder={placeholder} 
            disabled={disabled}
            className={className}
            onChange={ (ev) => { 
                // console.warn('onChange',ev,ev.currentTarget.value,this.state.value);
                this.setState({ 
                value: ev.currentTarget.value 
                });
            }}
            onBlur={(ev)=>{
                // this._onChange(ev, this.state.value);
                // CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value});
                if(onBlur){
                onBlur(ev,ev.currentTarget.value);
                }
            }}
        />
    )
  } 
}

export default MyUI_Textarea