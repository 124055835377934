import React from 'react'
import HGA from '../../../../../helpers/GA'

class SC_GoTop extends React.Component{
    scrollToTop=()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    componentDidMount(){
        window.onscroll = function(){
          const _button = document.getElementById("goToTop");
          if(_button){
              if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
                _button.style.display = "block";
              }
              else{
                _button.style.display = "none";
              }
          }
        };
    }
    render(){
        return(
            <div id="goToTop" onClick={()=>{
              this.scrollToTop();
              HGA.trackingEventClick({
                action:'click_item_gotop',
              })
            }}></div>
        )
    }
}
export default SC_GoTop