import React from 'react'
import PageExt from './SC_QuickOrder_ext';
import {HApiAxios, HFormat, HStoreRedux, HLocalStorage, HUtils, HLink, HUI, HConfig} from 'apps/Helpers';
import {MyUI, MyTableFilter, MyTableFilterData} from 'apps/Components'
import {connect} from 'react-redux'
import './styles/common.css'
import './styles/sc_quickordergroup.css'

// Jira https://allianceitscvn.atlassian.net/browse/AWO-187
class SC_QuickOrder extends PageExt {
    constructor(props){
        super(props);
        this.state = {
            category: null,
            ctgDetail: null,
            categories: null,
            categorySynthesis: null,
            isNodata: false,
            isReview: false,
            isLoading: false,
            pageIndex: 1,
            tabIndex: 0,
            pageReviewIndex: 1,
            pageInFilter: 1,
            sortType: 1,
            dataFiltered: null,
            selectedArray: null, // lưu lại sản phẩm đã chọn khi filter server không mất
        }
        this.numberOfFilterResult = [];
        this.tabStyleType = HConfig.fnList.getIsDevMode() ? 1 : 0;
        this.searchType = 1; // 0 là search client, 1 search server;
        this.isFirstLoad = false;
    }
    componentDidMount(){
        const {configPage} = this.props;
        const _data = HUtils.Obj.get(configPage, "Data.Data.Detail");

        this.setState({
            sortType: this.getSortTypeDefault()
        })
        if(_data){
            this.setState({
                categories: _data,
                categorySynthesis: this.getDataSynthesis(_data),
                selectedArray: this.getSelectedListInSession()
            },()=>{
                this.getData()
            })
            const _extra = HUtils.Obj.get(configPage, "Data.ExtraData");
            const _titlePage = HUtils.Obj.get(configPage, "Data.Data.Description");
            if(_extra){
                const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
                const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
                const _pageConfig = HUtils.Obj.get(_extra, "PageConfig")
                this.setState({
                    ctgDetail: {..._grpInfo, ..._slugInfo, titlePage: _titlePage},
                    pageConfig: _pageConfig
                })
            }
        }
        else {
            this.requestList();
        }
    }
    requestList=({customQuery}={})=>{
        this.setState({
            isLoading: true
        },()=>{
            HApiAxios.generic({
                request: this.getRequest(),
                data: {
                    Slug: this.getSlug(),
                    PageSize: 1000,
                    ...customQuery
                    // Page: 1,
                },
                successCallBack: (response)=>{
                    const _data = HUtils.Obj.get(response, "Data.Data.Detail")
                    if(_data?.length > 0){
                        this.setState({
                            categories: _data,
                            categorySynthesis: this.getDataSynthesis(_data)
                        },()=>{
                           this.getData();
                        })
                        const _extra = HUtils.Obj.get(response, "Data.ExtraData");
                        const _titlePage = HUtils.Obj.get(response, "Data.Data.Description")
                        if(_extra){
                            const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
                            const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
                            const _pageConfig = HUtils.Obj.get(_extra, "PageConfig")
                            this.setState({
                                ctgDetail: {..._grpInfo, ..._slugInfo, titlePage: _titlePage},
                                pageConfig: _pageConfig
                            })
                        }
                        this.setState({
                            isLoading: false
                        })
                    }
                    else{
                        this.setState({
                            isNodata: true
                        })
                    }
                },
                errorCallBack: (err, response)=>{
                    this.setState({
                        isLoading: false
                    })
                }
            })
        })
    }
    requestSearch=({customQuery})=>{
        switch (this.searchType) {
            case 0:
                return;
            case 1:
                this.setState({
                    isLoading: true
                },()=>{
                    HApiAxios.generic({
                        request: this.getRequest(),
                        data: {
                            Slug: this.getSlug(),
                            PageSize: 1000,
                            ...customQuery
                            // Page: 1,
                        },
                        successCallBack: (response)=>{
                            if(response.Data){
                                const _data = HUtils.Obj.get(response, "Data.Data.Detail")
                                const _synthesisGrp = this.getDataSynthesis(_data)
                                this.setState({
                                    categorySynthesis: _synthesisGrp,
                                },()=>{
                                   this.getData()
                                })
                                this.setState({
                                    isLoading: false
                                })
                            }
                        },
                        errorCallBack: (err, response)=>{
                            this.setState({
                                isLoading: false
                            })
                        }
                    })
                })
                return;
            default:
                break;
        }
    }
    getRequest=()=>{
        return{
            method: 'POST',
            url: 'DashboardForWebsite/GetListDataWithSlug'
        }
    }   
    getSlug=()=>{
        const {ctgDetail} = this.state;
        return HUtils.Obj.get(ctgDetail, "Slug", window.location.pathname && window.location.pathname.split("/")?.[1]);
    }
    getDataRender=(index)=>{
        const {isReview, selectedArray} = this.state;
        // console.warn("selectedArray", selectedArray)
        if(isReview){
            return selectedArray
        }
        return this.getDataByIndex(index)
    }
    getDataByIndex = (tabIndex) =>{
        const {categories, categorySynthesis} = this.state;
        let _data = [];
        if(tabIndex === -1){
            _data = categorySynthesis;
        }
        else if(categories?.[tabIndex]?.MaterialForWebInfo){
            _data = categories[tabIndex].MaterialForWebInfo
        }
        return this.updateQuantity4Data(_data)
    }
    getData=()=>{
        const _data = this.getElmTabActiveByKey("MaterialForWebInfo", []);
        if(_data?.length>0){
            this.setState({
                category: this.updateQuantity4Data(_data),
            })
        }
    }
    getDataSynthesis = (categories) =>{
        let _rs = []
        if(categories?.length>0){
            categories.forEach((v)=>{
                if(v?.MaterialForWebInfo?.length >0){
                    if(this.searchType == 0){
                        this.numberOfFilterResult.push({
                            Grp: v.Description,
                            Num: v.MaterialForWebInfo.length
                        })
                    }
                    v.MaterialForWebInfo.forEach((a)=>{
                        const itmDuplicate = _rs.find((k)=> k.MaterialId === a.MaterialId);
                        if(!itmDuplicate){
                            _rs.push(a);
                        }
                    })
                }
            })
        }
        if(this.searchType === 1){
            this.numberOfFilterResult = _rs.length;
        }
        return _rs
    }
    getElmTabActiveByKey=(key, df)=>{
        const {tabIndex, categories, categorySynthesis} = this.state;
        let _elm = df;
        if(tabIndex === -1){
            _elm = categorySynthesis;
        }
        else if(categories && categories[tabIndex] && categories[tabIndex][key]){
            _elm = categories[tabIndex][key];
        }
        return _elm
    }
    getData4Paging=()=>{
        const {isReview, category, dataFiltered, selectedArray} = this.state;
        let _data = [];
        if(isReview == true){
            _data = selectedArray;
        }
        else if(isReview == false && dataFiltered && this.getIsBeingFilter()){
            _data = dataFiltered;

        }
        else{
           _data = category;

        }
        return _data;
    }
    getTotalMoneySelected=()=>{
        const {selectedArray} = this.state;
        return this.getTotalMoney(selectedArray);
    }
    getTotalData=()=>{
        const _data = this.getData4Paging()
        return _data && _data.length;

    }
    getTotalSelected = () =>{
        const {selectedArray} = this.state;
        return selectedArray?.length
    }
    getForcePage=()=>{
        const {isReview, pageIndex, pageReviewIndex, pageInFilter} = this.state;
        if(isReview){
            return pageReviewIndex
        }
        else if(this.getIsBeingFilter()){
           return pageInFilter
        }
        return pageIndex
    }
    getIsBeingFilter=()=>{
        if(this._currentFilter && Object.keys(this._currentFilter).length > 0){
            return true;
        }
        return false;
    }
    getRequestFilter = () =>{
        const {sortType} = this.state;
        const textSearch = HUtils.Obj.get(this._currentFilter, "_SearchText.value");
        const isFilter = this.getIsBeingFilter();
        let _query = {}
        if(this.searchType === 1 && isFilter){
            _query = {
                CommandType: sortType,
                TextSearch: textSearch
            }
        }
        return _query
    }
    getSortTypeDefault = () =>{
        const _appName = HConfig.fnList.getConfig("appName");
        const _sortType = HUtils.Obj.get(HLocalStorage.getObject(`${_appName}_defaultOptions`, {}), "CommandType", 1)
        if(_sortType !== null) {
            return _sortType;
        }
        return 1
    }
    getSelectedListInSession = () =>{
        if(this.isFirstLoad === false){
            const _appName = HConfig.fnList.getConfig("appName");
            const _url = window.location.pathname;
            let _list = sessionStorage.getItem(`${_appName}_${_url ? _url.split("/")[1] : null}`);
            if(_list){
                try {
                    _list = JSON.parse(_list);
                } catch (error) {
                    console.warn("parse session: ", error)
                }
            }
            return _list || null;
        }
    }
    //AWO-196
    updateQuantity4Data=(data)=>{
        const {selectedArray} = this.state;
        if(selectedArray && data?.length>0){
            for(let i=0; i<data.length; i++){
                let _item = data[i];
                let _itemSelected = selectedArray.find((v)=> v.MaterialId == _item.MaterialId);
                if(_itemSelected){
                    _item.Quantity = _itemSelected.Quantity
                }
                else{
                    _item.Quantity = 0
                }
            }
        }
        return data
    }
    addProductsToCart=()=>{
        const {category, selectedArray} = this.state;
        const _productsAddCart = selectedArray;//category && category.filter((i)=>i.Quantity > 0);

        if(_productsAddCart?.length>0){
            for(let i=0; i<_productsAddCart.length; i++){
                const _product = _productsAddCart[i];
                this.saveToLocalStorage(_product)
            }
            HUI.Toast.showSuccess(`Đã thêm ${_productsAddCart.length} sản phẩm vào giỏ hàng`);
            this.resetQuantityData();
            setTimeout(()=>{HLink.push('/cart')},500)
        }
        else{
            HUI.Toast.showWarn("Bạn chưa chọn sản phẩm nào!")
        }
    }
    saveToLocalStorage=(item)=>{
        let _cart = HLocalStorage.getObject("cart",[]);
        let _item = _cart.find((i)=>i.MaterialId == item.MaterialId);
        if(_item){
            _item.Quantity += item.Quantity;
        }
        else{
            _cart.push(item)
        }
        HLocalStorage.setObject("cart", _cart);
        HStoreRedux.dispatch({type: "setObjInShop", cartProducts: _cart});
    }
    saveSelectedListIntoSession = (array) =>{
        const _appName = HConfig.fnList.getConfig("appName");
        const _url = window.location.pathname;
        if(array){
            try {
                sessionStorage.setItem(`${_appName}_${_url ? _url.split("/")[1] : null}`, JSON.stringify(array));
            } catch (error) {
                console.warn("stringify session: ", error)
            }
        }
    }
    removeSelectedListInSession = () =>{
        const _appName = HConfig.fnList.getConfig("appName");
        const _url = window.location.pathname;
        sessionStorage.removeItem(`${_appName}_${_url ? _url.split("/")[1] : null}`);
    }
    resetQuantityData=()=>{
        let {category, selectedArray} = this.state;
        if(category?.length > 0){
            for(let i=0; i<category.length; i++){
                let _item = category[i];
                _item.Quantity = 0;
            }
            selectedArray = []
        }
        this.setState({
            category: [...category],
            selectedArray: selectedArray
        },()=>{
            this.removeSelectedListInSession();
        })
    }
    findItemInCategories=(item)=>{
        const {categories, categorySynthesis, tabIndex, isReview} = this.state;
        let _result = null;
        let _currentCat = categories;
        if(categorySynthesis?.length>0){
            categorySynthesis.forEach((v)=>{
                if(v.MaterialId === item.MaterialId){ 
                    _result = v
                }
            })

        }
        if(!_result && categories?.length>0){
            categories.forEach((v)=>{
                if(v?.MaterialForWebInfo?.length>0){
                    v.MaterialForWebInfo.forEach((a)=>{
                        if(a.MaterialId === item.MaterialId){ 
                            _result = a
                        }
                    })
                }
            })
        }
        // if(this.searchType === 1 && tabIndex === -1){
        //     _currentCat = categorySynthesis;
        // }
        // if(_currentCat?.length>0 && item){
        //     for(let i=0; i<_currentCat.length; i++){
        //         let _matArr = HUtils.Obj.get(_currentCat, `[${i}].MaterialForWebInfo`);
                
        //         if(isReview){
        //             if(this.searchType === 1 && tabIndex === -1){
        //                 _matArr = _currentCat[i];
        //                 if(_matArr.MaterialId === item.MaterialId){ 
        //                     _result = _matArr
        //                 }
        //             }
        //             if(_result === null){
        //                 _matArr = HUtils.Obj.get(_currentCat, `[${i}].MaterialForWebInfo`);
        //                 const _item = _matArr &&  _matArr.find((v)=>v.MaterialId === item.MaterialId);
        //                 if(_item){
        //                     _result = _item
        //                 }
        //             }
        //         }
        //         else {
        //             if(this.searchType === 1 && tabIndex === -1){
        //                 _matArr = _currentCat[i];
        //                 if(_matArr.MaterialId === item.MaterialId){ 
        //                     _result = _matArr
        //                 }
        //             }
        //             else {
        //                 const _item = _matArr &&  _matArr.find((v)=>v.MaterialId === item.MaterialId);
        //                 if(_item){
        //                     _result = _item
        //                 }
        //             }
        //         }
        //    }
        // }
        return _result
    }
    paginate=(array, page_number)=> {
        const {pageConfig} = this.state;
        const pageSize = HUtils.Obj.get(pageConfig, "PageSize", 10);
        if(array && array.length>0){
          return array.slice((page_number - 1) * pageSize, page_number * pageSize);
        }
        return [];
    }
    scrollTo=()=>{
        const _el = document.getElementById("quickorder-table");
        if(_el){
            window.scrollTo(0, _el.offsetParent.offsetTop + _el.offsetTop - 60);
        }
    }
    updateNumberOfFilterResult = (obj) =>{
        if(obj?.Grp){
            if(this.numberOfFilterResult.length > 0){
                let _isHas = false;
                for(let i=0; i<this.numberOfFilterResult.length; i++){
                    if(this.numberOfFilterResult[i]?.Grp === obj.Grp && this.numberOfFilterResult[i]?.Grp !== obj.Num){
                        this.numberOfFilterResult[i] = obj;
                        _isHas = true;
                    }
                }
                if(!_isHas){
                    this.numberOfFilterResult.push(obj)
                }
            }
            else{
                this.numberOfFilterResult.push(obj)
            }
        }
    }
    onChangeItemQuantity=(item, value)=>{
        let {selectedArray} = this.state;
        let _itemWillUpdate =  this.findItemInCategories(item);
        let _itemInQuantityArr = selectedArray && selectedArray.find((i)=>i.MaterialId == item.MaterialId);
        // console.warn("_itemWillUpdate, _itemInQuantityArr", _itemWillUpdate, _itemInQuantityArr)
        if(_itemWillUpdate){
            _itemWillUpdate.Quantity = value;
            if(value>0){
                if(_itemInQuantityArr){
                    _itemInQuantityArr.Quantity = value;
                }
                else if(selectedArray){
                    selectedArray.push({...item, Quantity: value})
                }
                else{
                    selectedArray = [];
                    selectedArray.push({...item, Quantity: value})
                }
            }
            else{
                if(selectedArray){
                    selectedArray = selectedArray.filter((v)=> v.MaterialId !== item.MaterialId)
                }
            }
        }
        
        this.setState({
            selectedArray: selectedArray
        },()=>{
            this.getData();
            this.saveSelectedListIntoSession(selectedArray)
        })
    }
    onChangeSortType=(value)=>{
        const isFilter = this.getIsBeingFilter();
        this.setState({
            sortType: value
        },()=>{
            if(this.searchType === 1 && isFilter){
                this.requestSearch({customQuery: this.getRequestFilter()})
            }
            else if(value != "0"){
                this.requestList({customQuery: {CommandType: value}})
            }
        })
    }
    onChangeSearch=()=>{
        const isFilter = this.getIsBeingFilter();
        if(isFilter){
            this.setState({
                sortType: "0"
            },()=>{
                this.requestSearch({customQuery: this.getRequestFilter({isChangeSearch: true})})
            })
        }
        else{
            const _defaultSortType = this.getSortTypeDefault();
            this.setState({
                sortType:  _defaultSortType
            },()=>{
                this.requestList({customQuery: {CommandType: _defaultSortType}});
            })
        }
    }
    onChangePage=(value)=>{
        const {isReview} = this.state;
        if(isReview){
            this.setState({
                pageReviewIndex: value
            })
        }
        else if(this.getIsBeingFilter()){
            this.setState({
                pageInFilter: value
            })
        }
        else{
            this.setState({
                pageIndex: value
            })
        }
        this.scrollTo()
    }
    onChangeReview=(value)=>{
        if(value == true){
            this.setState({
                pageReviewIndex: 1
            })
        }
        this.setState({
            isReview: value
        },()=>{
            this.getData()
        })
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index,
            pageIndex: 1,
            pageInFilter: 1
        },()=>{
            this.getData()
        })
    }
    renderHelmet=()=>{
        const {ctgDetail} = this.state;
        const meta = {
            MetaTitle: HUtils.Obj.get(ctgDetail, "MetaKeyTitle", "Ajuma Garden - Đặt hàng nhanh"),
            MetaKeyWords: HUtils.Obj.get(ctgDetail, "MetaKeyword", "Ajuma Garden - Quick Order"),
            MetaDescription: HUtils.Obj.get(ctgDetail, "Description", "Ajuma Garden - Đặt hàng nhanh")
        }
        return <MyUI type="sc_helmet" data = {meta}/>
    }
    renderFilter=()=>{
        const {optionList} = this.props;
        const {isReview, sortType, category} = this.state;
        const sortList = HUtils.Obj.get(optionList, "CommandType", [
            { Value: "1", Text: "Mới nhất" },
            { Value: "2", Text: "Giá: Thấp đến cao" },
            { Value: "3", Text: "Giá: Cao đến thấp" },
        ])
        const totalSelected = this.getTotalSelected();
        const isFilter = this.getIsBeingFilter();
        return(
            <div className={`sc_quickorder_filter search-box-search${isReview ? " is-review": ""}`}>
                <MyTableFilter 
                    contents={[
                        {
                            type: 'search', 
                            more: '{"ui":{"showSuffix":true, "showPre":false}, "placeholder": "Tìm kiếm"}'
                        }
                    ]} 
                    dataList={category} 
                    styleContainer={isReview ? {display: "none"}: {}}
                    onChangeFilter={(filter)=>{
                        this._currentFilter = filter;
                        if(this.searchType === 1){
                            if(this._currentFilter?._SearchText){
                                this.onChangeTab(-1)
                            }
                            else{
                                this.onChangeTab(0)
                            }
                            this.onChangeSearch();
                        }
                        else{
                            this.setState({
                                pageInFilter: 1
                            })
                        }
                        this.forceUpdate()
                    }}
                />
                <div className="product-sorting" style={isReview ? {display: "none"}: {}}>
                    <span>Sắp xếp theo</span>
                    <select 
                        className="sortOptionsDropDown" 
                        value={sortType} 
                        onChange={(e)=>this.onChangeSortType(e.currentTarget.value)}
                    >
                        { isFilter && <option value="0"></option>}
                        {
                            sortList?.length > 0 && sortList.map((v, i)=>{
                                return <option key={i} value={v.Value}>{v.Text}</option>
                            })
                        }
                    </select>
                </div>
                {
                    isReview && (
                        <button className="review" onClick={()=>this.onChangeReview(false)}>
                            Chọn thêm hàng hóa
                        </button>
                    )
                }
                <button className={`review ${isReview && 'active'}`} onClick={()=>this.onChangeReview(true)}>
                    Đã chọn {totalSelected>0 && ` (${totalSelected})`}
                </button>
            </div>
        )
    }
    renderTab=()=>{
        const {categories, tabIndex, isReview} = this.state;
        const tabList = categories;
        if(!isReview && tabList?.length>1){
            const isFilter = this.getIsBeingFilter();
            const tabStyle = this.tabStyleType;
            const tabClassName = tabStyle == 1 ? "tab-header-2" : "tab-header";
            return(
                <div className={tabClassName}>
                    { (this.searchType === 1 && isFilter) && (
                        <div key={-1} className="filter-result-container">
                            <div
                                className={`filter-result${tabIndex === -1 ? " active" : ""}`}
                                onClick={()=>this.onChangeTab(-1)}
                            >
                                <div className={`label ${tabIndex === -1 ? "" : "inactive"}`}>
                                    <i className="fa fa-search"/>
                                    <strong>Kết quả tìm kiếm</strong>
                                    { this.numberOfFilterResult > 0 &&  <strong>({this.numberOfFilterResult})</strong>}
                                </div>
                                {this.renderNumberOfFilterResult(-1)}
                            </div>
                        </div>
                    )}
                    {
                        tabList.map((v,i)=>{
                            switch (tabStyle) {
                                case 0:
                                    return (
                                        <div key={i} className={`title-grp${tabIndex === i ? " active" : ""}`}>
                                            <div className={`label ${tabIndex === i ? "" : "inactive"}`} onClick={()=>this.onChangeTab(i)}>
                                                {HUtils.Obj.get(v,"Description")}
                                            </div>
                                            {this.renderNumberOfFilterResult(i, v)}
                                        </div>
                                    )
                                case 1: 
                                    const _text = HUtils.Obj.get(v,"Description");
                                    const _img = HUtils.Obj.get(v,"ImageUrl")
                                    return (
                                        <div key={i} className={`title-grp${tabIndex === i ? " active" : ""}`}>
                                            <div 
                                                className="label"
                                                onClick={()=>this.onChangeTab(i)}
                                            >
                                                {_text}
                                            </div>
                                            <div className="avatar-grp-wrapper" onClick={()=>this.onChangeTab(i)}>
                                                <img 
                                                    src={_img} 
                                                    className="avatar-grp"
                                                    alt="" 
                                                />
                                            </div>
                                            {this.renderNumberOfFilterResult(i, v)}
                                        </div>
                                    )
                                default:
                                    break;
                            }
                        })
                    }
                </div>
            )
        }
    }
    renderNumberOfFilterResult=(i, grp)=>{
        const numberOfFilterResult = this.numberOfFilterResult;
        switch (this.searchType) {
            case 0:
                if(grp?.Description){
                    let _grp = numberOfFilterResult.find(v => v.Grp === grp.Description);
                    if(_grp?.Num > 0){
                        return <span className="number_result_filter">{_grp.Num}</span>  
                    }
                }
                break;
            case 1: 
                if(i === -1 && numberOfFilterResult){
                    return <span className="number_result_filter">{numberOfFilterResult}</span>
                }
                break;
            default:
                break;
        }
    }
    renderTableHeader=()=>{
        const {showAdmin} = this.props;
        return(
            <thead>
                <tr className="cart-header-row">
                    <th className="item-count" style={{display: 'table-cell'}}>
                        {/* <span className="item-count">#</span> */}
                    </th>
                    <th className="product-picture">Hình ảnh</th>
                    <th className="product">Sản phẩm</th>
                    <th className="unit-price">Giá</th>
                    <th className="quantity">Số lượng </th>
                    <th className="subtotal footable-last-column">Tổng tiền</th>
                    {showAdmin && <th className="admin">Ghi chú</th>}
                </tr>
            </thead>
        )
    }
    renderTableContent=(dataList)=>{
        const {showAdmin} = this.props;
        const {tabIndex} = this.state;
        const data = this.paginate(dataList, this.getForcePage())
        let _ui = [];
        // console.warn("dataList, data", dataList, data, this.getForcePage())
        if(data?.length > 0){
            for(let i=0; i<data.length; i++){
                let _item = data[i];
                if(_item.hasOwnProperty("Quantity") != true){
                    _item.Quantity = 0;
                }
                const _Slug = _item.Slug;
                const _MaterialCode = _item.MaterialCode;
                const _MaterialName = _item.MaterialName;
                const _MaterialId = _item.MaterialId;
                const _ImageUrlThumnail = _item.ImageUrlThumnail ||  HConfig.fnList.getConfig("imageProductDefault");
                const _IsCall4WebOrder = _item.IsCall4WebOrder;
                const _StepPrice = _item.StepPrice;
                const _Quantity = _item.Quantity;
                const _SellPrice =_item.SellPrice;
                const _AdminNote = _item.AdminNote;

                _ui.push(
                    <tr key={`${i}_p_${tabIndex}`} className="cart-item-row row-product-name">
                        <td className="item-count">                        
                        </td>
                        <td className="product" colSpan={5} style={{padding: "10px 10px",paddingBottom: "0px", display: 'table-cell'}}>
                            <a href={_Slug} className="product-name">
                                {_MaterialCode} - {_MaterialName}
                            </a>
                            {this.renderProductCoupon(_item)}
                        </td>
                    </tr>
                )
                _ui.push(
                    <tr key={`${i}_${_MaterialId}_${tabIndex}`} className="cart-item-row" >
                        <td className="item-count" style={{display: 'table-cell'}}>
                        </td>
                        <td className="product-picture">
                            <a href={_Slug}>
                                <img alt={`Hình ảnh của ${_MaterialName}`} src={_ImageUrlThumnail} title={`Hiển thị chi tiết ${_MaterialName}`}/>
                            </a>
                        </td>
                        <td className="product">
                            {/* <a href={_Slug} className="product-name">{_MaterialCode}<br/>{_MaterialName}</a> */}
                            {this.renderProductDetail(_item)}
                        </td>
                        <td className="unit-price">
                            {/* <span 
                                className="product-unit-price" 
                                style={_IsCall4WebOrder ? {color: "var(--color-danger)"} : {}}
                            >
                                {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
                                {(!_IsCall4WebOrder && _UnitName)&& ` / ${_UnitName}`}
                            </span> */}
                            {this.renderPriceProduct(_item)}
                        </td>
                        <td className="quantity">
                            <div className="dFfdRaiCjcC">
                                <MyUI 
                                    type="sc_input_quantity" 
                                    className = "quantity-control" 
                                    forceMinValue="0.0"
                                    step={_StepPrice}
                                    minQuantity={0} 
                                    clearable={true}
                                    disabled={_IsCall4WebOrder} 
                                    value={_Quantity} 
                                    onChange={(value)=>{
                                        this.onChangeItemQuantity(_item, value);
                                    }}
                                />
                            </div>
                        </td>
                        <td className="subtotal footable-last-column">
                            {/* <span className="product-subtotal">{HFormat.Money.formatMoneyForAjuma(_SellPrice * _Quantity)}</span> */}
                            {this.renderProductTotal(_item)}
                        </td>
                        {
                            showAdmin && (
                                <td className="admin">
                                    {_AdminNote}
                                </td>
                            )
                        }
                    </tr>
                )

                showAdmin && _ui.push(
                    <tr key={`${i}_l_${tabIndex}`} className="cart-item-row admin">
                        <td colSpan={3}>{_AdminNote}</td>
                    </tr>
                )
            }
            return(
                <tbody>
                    {_ui}
                </tbody>
            )
        }
    }
    renderTable=(data, index)=>{
        if(data?.length>0){
            return(
                <table className="cart" id="quickorder-table" key={index}>   
                    {
                        this.renderTableHeader()
                    }
                    {
                        this.renderTableContent(data)
                    }
                </table>
            )
        }
        return <div className="section"><div className="title">Danh sách rỗng!</div></div>
    }
    renderContent = () =>{
        const {categories, tabIndex, isReview} = this.state;
        const tabList = categories;
        if(tabList?.length>0){
            return(
                <div className="quickorder-content">
                    {
                        (tabIndex === -1 && !isReview) && this.renderContentFilter()
                    }
                    {
                        isReview && this.renderContentReview()
                    }
                    {
                        (tabIndex !== -1 && !isReview) && 
                        tabList.map((v, i)=>{
                            let  _data = this.getDataRender(i);
                            switch (this.searchType) {
                                case 0:
                                    return(
                                        <MyTableFilterData 
                                            key={i} 
                                            data={_data} 
                                            currentFilter={!isReview ? this._currentFilter : null} 
                                            getDataFilter={(data)=>{
                                                if(tabIndex === i){
                                                    this.state.dataFiltered = data;
                                                }
                                            }}
                                        >
                                            {
                                                ({dataFiltered})=>{
                                                    if(!isReview){
                                                        this.updateNumberOfFilterResult({
                                                            Grp: v.Description,
                                                            Num: dataFiltered.length
                                                        })
                                                    }
                                                    if(tabIndex === i){
                                                        return this.renderTable(dataFiltered, i)
                                                    }
                                                    return null;
                                                }
                                            }
                                        </MyTableFilterData>
                                    )
                                case 1:
                                    if(tabIndex === i){
                                        return this.renderTable(_data, i);
                                    }
                                    return null;
                                default:
                                    break;
                            }
                            
                        })
                    }
                </div>
            )
        }
    }
    renderContentFilter = () =>{
        const _data = this.getDataRender(-1);
        switch (this.searchType) {
            case 0:
                return(
                    <MyTableFilterData
                        data={_data} 
                        currentFilter={this._currentFilter} 
                        getDataFilter={(data)=>{
                            this.state.dataFiltered = data;
                        }}
                    >
                        {
                            ({dataFiltered})=>{
                                this.numberOfFilterResult = dataFiltered?.length;
                                return this.renderTable(dataFiltered, -1)
                            }
                        }
                    </MyTableFilterData>
                )
            case 1:
                this.state.dataFiltered = _data;
                return this.renderTable(_data, -1)
            default:
                break;
        }
    }
    renderContentReview = () =>{
        const _data = this.getDataRender()
        return this.renderTable(_data, -2)
    }
    renderPagination=()=>{
        const {pageConfig} = this.state;
        const total = this.getTotalData();
        const pageSize = HUtils.Obj.get(pageConfig, "PageSize", 10)
        if(total > pageSize){
            return (
                <MyUI type='react_pagination_box' 
                    useLink={false}
                    forcePage={this.getForcePage()}
                    pageSize={pageSize} 
                    pageTotal={total}
                    containerClassName="pagination_style"
                    onPageChange={({selected})=>{
                        this.onChangePage(selected + 1)
                    }}/>
            )
        }
        return <div></div>
    }
    renderFooter=()=>{
        return(
            <div className="footer">
                <div className="total-money">
                    Tổng tiền: 
                    <strong>
                        {HFormat.Money.formatMoneyForAjuma(this.getTotalMoneySelected())}
                    </strong>
                </div>
                <button className="add-to-cart-button" onClick={()=>this.addProductsToCart()}>
                    <i className="fa fa-shopping-basket" style={{marginRight: "0.5rem"}}/> Thêm vào giỏ hàng
                </button>
            </div>
        )
    }
    renderTitleGroup=()=>{
        const {tabIndex, categories, isReview} = this.state;
        const tabList = categories;
        const titleGroup = tabList?.[tabIndex]?.Description;
        if(!isReview  && titleGroup){
            return(
                <div className="title-group-desc">{titleGroup}</div>
            )
        }
    }
    renderBelowTab = () =>{
        if(this.tabStyleType !== 0){
            return this.renderTitleGroup()
        }
    }
    render(){
        const {isNodata, isLoading, ctgDetail} = this.state;
        const titleHeader = HUtils.Obj.get(ctgDetail,"titlePage", "Đặt hàng nhanh");//this.getElmTabActiveByKey("QuickOrderInfo",null)
        
        return(
            <div className="master-wrapper-content">
                {
                    this.renderHelmet()
                }
                <div className="master-column-wrapper">
                    <div className="center-1 quick-order">
                        <div className="page-title">
                            <h1>{ titleHeader }</h1>
                        </div>
                        { this.renderFilter() }
                        {
                            isLoading == false && isNodata == false &&
                            <div className="page-body">                               
                                { this.renderTab() }
                                { this.renderBelowTab()}
                                { this.renderContent() }
                                { this.renderPagination() }
                                { this.renderFooter() }
                            </div>
                        }
                        {
                            isLoading == false && isNodata == true &&
                            <div className="section"><div className="title">Không có dữ liệu!</div></div>
                        }
                        {
                            isLoading == true &&
                            <MyUI type="sc_loading"/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state =>{
    return{
        optionList: state.shop.optionList,
        showAdmin: state.shop.adminButtons?.ShowBtnAdmin
    }
}
export default connect(mapStateToProps, null)(SC_QuickOrder)