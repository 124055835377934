import React from "react";
import { CButton } from "components/MyCore";
import { MyModal } from "apps/Components";
import { HUI } from 'apps/Helpers'
import "./styles/sc_editor_upload_image.css";

class SC_ModalOptionUploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
    };
  }
  dataURItoBlob = (dataURI) => {
    var byteString = unescape(dataURI.split(',')[1]);
    var array = []
    for(var i = 0; i < byteString.length; i++) {
      array.push(byteString.charCodeAt(i));
    }
    return new Blob([new Uint32Array(array)]);    
  }
  onUploadImageAvailable = (arrUrl) =>{
    const { fnList, fnFomModal } = this.props;
    if(arrUrl?.length > 0){
      let _files = [];
      arrUrl.forEach((v)=>{
        if(typeof v === "string"){
          let _dataObj = this.dataURItoBlob(v);
          const _filename = v.substring(v.lastIndexOf('/')+1);
          const _file = new File( [_dataObj], _filename, { type: 'image/jpeg' });
          _files.push(_file);
        }
      })
      console.warn("_files", _files);
      // this.setState(
      //   {
      //     isUploading: true,
      //   },
      //   () => {
      //     fnList?.fnRequestUpload &&
      //       fnList.fnRequestUpload(_files, {
      //         cbSuccess: () => {
      //           fnFomModal?.fnClose && fnFomModal.fnClose();
      //           this.setState({ isUploading: false });
      //         },
      //     });
      //   }
      // );
    }
  }
  renderUploadFromDevice = () => {
    const { fnList, fnFomModal } = this.props;
    const { isUploading } = this.state;
    return (
      <div className="upload-local">
        <label htmlFor="#sc_editor_upload_image">
          {isUploading && (
            <i
              className="fa fa-spinner fa-spin"
              style={{ marginRight: "0.5rem" }}
            />
          )}
          Chọn ảnh từ thiết bị
        </label>
        {!isUploading && (
          <input
            id="#sc_editor_upload_image"
            type="file"
            accept="*"
            multiple
            disabled={isUploading}
            onChange={(e) => {
              let _files = e.target.files;
              this.setState(
                {
                  isUploading: true,
                },
                () => {
                  fnList?.fnRequestUpload &&
                    fnList.fnRequestUpload(_files, {
                      cbSuccess: () => {
                        fnFomModal?.fnClose && fnFomModal.fnClose();
                        this.setState({ isUploading: false });
                      },
                    });
                }
              );
            }}
            style={{ display: "none" }}
          />
        )}
      </div>
    );
  };
  renderUploadFromAvailableImage = () => {
    const {isUploading} = this.state;
    const {product} = this.props;
    return (
      <div className="upload-available">
        <CButton
          disabled={isUploading}
          onClick={() => {
            MyModal.Helpers.showMyUI3(
              "sc_modal_select_photos",
              {
                multipleSelect: true,
                textSubmit: "Tải ảnh",
                data: product,
                submit: (arr) => {
                  this.onUploadImageAvailable(arr);
                  // HUI.Toast.showWarn("Đang chờ api!")
                },
              },
              {
                title: "Chọn ảnh",
                className: "mmui2",
              }
            );
          }}
        >
          Chọn ảnh có sẵn
        </CButton>
      </div>
    );
  };
  render() {
    return (
      <div className="sc_editor_upload_image upload-options">
        {this.renderUploadFromDevice()}
        {this.renderUploadFromAvailableImage()}
      </div>
    );
  }
}
export default SC_ModalOptionUploadImage;
