import React from 'react'
import {ImageZoom} from '../../../../../libs/MC/index';

class MyLib_ImageZoom extends React.Component{
    render(){
        return(
            <ImageZoom {...this.props}/>
        )
    }
}
export default MyLib_ImageZoom