import React from 'react';
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {MyUI, MyLoadingApp, MyModal} from '../../../../Components';
import {HApiAxios, HLocalStorage, HStoreRedux,HUtils, HUI, HConfig, HLink, HText} from '../../../../Helpers';
import AjumaUtils from '../../../_utils';
import './styles/common.css'


class SC_Product_Detail extends PageExt{
    constructor(props){
        super(props)
        this.state = {
            imageWithAttribute: null,
            related_products: [],
            product_subMatrialList: [],
            product_tags: [],
            product_detail: {},
            productTabs: [],
            grpInfo: {},
        }
    }
    componentDidMount(){
        const {configPage} = this.props;
        let _dataDetail = HUtils.Obj.get(configPage,"Data.Data.MaterialForWebInfo");
        let _grpInfo = HUtils.Obj.get(configPage,"Data.ExtraData.GrpMaterial");
        let _subMaterialList = HUtils.Obj.get(configPage,"Data.ExtraData.SubMaterialList", []);
        if(_dataDetail){
            this.setState({
                product_detail: _dataDetail,
                product_subMatrialList: _subMaterialList,
                productTabs: this.getTabs(_dataDetail),
                grpInfo: _grpInfo
            },()=>{                
                this.addRecentProduct(_dataDetail);
                this.requestRelatedProduct();
                this.requestTagsProduct();
                this.getAttributeProductInUrl();
                AjumaUtils.setOnErrorImg(".page-body img"); 
            })
        }
        // else{
        //     this.requestProductDetail()
        // }
    }
    getAttributeProductInUrl = () =>{
        const haveProductOptions = HConfig.fnList.getConfig("haveProductOptions")
        if(haveProductOptions){
            const params = HLink.getAllUrlParams();
            let {product_detail} = this.state;
            if(Object.keys(params).length>0){
                product_detail.OrderDetail_AttributeList = params;
                this.setState({
                    product_detail: product_detail
                })
            }
        }
    }
    getTabs=(data)=>{
        let _tabList = []
        if(data){
            let _tabContact = HConfig.fnList.getConfig("tabContact");
            if(data.FullDescription_DisplayInfo){
                _tabList.push({
                    Title: HText.get("text_detail_tab_info_title"),
                    Content: data.FullDescription_DisplayInfo
                })
            }
            if(_tabContact){
                _tabContact= { ..._tabContact, Title: HText.get("text_detail_tab_contact_title")}
                _tabList.push(_tabContact)
            }
        }
        return _tabList
    }
    getId=()=>{
        const {configPage} = this.props;
        const params = this.props.match && this.props.match.params;

        if(configPage && configPage.Data){
            const _Id = HUtils.Obj.get(configPage, "Data.ExtraData.SlugInfo.EntityId")
            return _Id
        }
        else if(params && params.ProductId){
            return params.ProductId
        }
    }
    getInitMaterialOnWebList = (data) =>{
        const {related_products} = this.state;
        if(data && related_products.length > 0){
            for(let i=0; i<related_products.length; i++){
                const _rlp = related_products[i];
                const _mit = data.find((v)=>v.Value == _rlp.MaterialId);
                if(_mit){
                    _mit.checked = true;
                }
            }
        }
        return data;
    }
    requestProductDetail=()=>{
        const param = this.getId()
        if(param){
            this.showLoadingApp()
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: `DashboardForWebsite/GetOneMaterial4Website/${param}`
                },
                data: {},
                successCallBack: (response)=>{
                    if(response.Data){
                        const _data = response.Data;
                        this.setState({
                            product_detail: _data,
                            productTabs: this.getTabs(_data)
                        },()=>{
                            this.addRecentProduct(_data)
                        })
                    }
                    this.hideLoadingApp()
                },
                errorCallBack: (err, response)=>{
                    MyLoadingApp.Helpers.show({error: err || response.Msg})
                }
            })
        }
       
    }
    requestRelatedProduct=()=>{
        const param = this.getId();
        if(param){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: `DashboardForWebsite/GetDataListMaterialRelation4Website/${param}`
                },
                data: {},
                successCallBack: (response)=>{
                    if(response.Data){
                        this.setState({
                            related_products: response.Data
                        })
                    }
                },
                errorCallBack: (err, response)=>{
                    MyLoadingApp.Helpers.show({error: err || response.Msg})
                }
            })
        }
    }
    requestTagsProduct=()=>{
        const param = this.getId();
        if(param){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: "EzyWeb_ListMaterialTag4Web/List"
                },
                data: {
                    MaterialId: param
                },
                successCallBack: (response)=>{
                    if(response.Data && response.Data.Data){
                        this.setState({
                            product_tags: response.Data.Data
                        })
                    }
                },
                errorCallBack: (err, response)=>{
                    MyLoadingApp.Helpers.show({error: err || response.Msg})
                }
            })
        }
    }
    requestHeaderProduct=({cbSuccess, }={})=>{
        const param = this.getId()
        if(param){
            this.showLoadingApp()
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: `Material_Page_Content_Info/Options`
                },
                data: {
                    MaterialId: param
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        let {product_detail} = this.state;
                        product_detail.HeaderAttributeList = HUtils.Obj.get(response, "Data.AttributeList")
                        this.setState({
                            product_detail: product_detail,
                        },()=>{
                            HApiAxios.generic({
                                request: {
                                    method: 'POST',
                                    url: `Material_Page_Content_Info/List`
                                },
                                data: {
                                    MaterialId: param
                                },
                                successCallBack: (response)=>{
                                    if(response.Data){
                                        product_detail.HeaderList = HUtils.Obj.get(response, "Data.Data")
                                        this.setState({
                                            product_detail: product_detail,
                                        },()=>{
                                            cbSuccess && cbSuccess(product_detail)
                                        })
                                    }
                                },
                                errorCallBack: (err, response)=>{
                                    MyLoadingApp.Helpers.show({error: err || response.Msg})
                                }
                            })
                        })
                    }
                    this.hideLoadingApp();
                },
                errorCallBack: (err, response)=>{
                    MyLoadingApp.Helpers.show({error: err || response.Msg})
                }
            })
        }
    }
    requestGetRelativeMaterialList = ({cbSuccess}={}) =>{
        HApiAxios.generic({
          request: {
            method: "POST",
            url: "GrpMaterial_Material_Mapping_ByMaterial/Options"
          },
          successCallBack: (response)=>{
            const materialList = HUtils.Obj.get(response, "Data.MaterialOnWebList");
            if(materialList){
                let {product_detail} = this.state;
                product_detail.MaterialOnWebList = this.getInitMaterialOnWebList(materialList);
                this.setState({
                    product_detail: product_detail,
                },()=>{
                    cbSuccess && cbSuccess(this.getInitMaterialOnWebList(materialList))
                })
            }
          },
          errorCallBack: (error, response)=>{
            console.warn("SC_Product_Detail error, response", error, response);
          }
        })
    }
    addRecentProduct=(item)=>{
        const _new = {
          MaterialId: item.MaterialId,
          MaterialName: item.MaterialName,
          LinkDetail: item.Slug,
          ImageUrlThumnail: item.ImageUrlThumnail,
        };
        let _recent = HLocalStorage.getObject("recent") || [];
        let _index = null;
        let _existed = _recent.find((v, i) => {
          if (v.MaterialId == item.MaterialId) {
            _index = i;
            return v;
          }
        });
        if (!_existed) {
          if (_recent.length == 5) {
            _recent.unshift(_new);
            _recent.pop();
          } else {
            _recent.unshift(_new);
          }
          HLocalStorage.setObject("recent", _recent);
          HStoreRedux.dispatch({ type: "setObjInShop", recent: _recent });
        } else {
          if (_existed.LinkDetail !== item.Slug) {
            _recent[_index] = _new;
            HLocalStorage.setObject("recent", _recent);
            HStoreRedux.dispatch({ type: "setObjInShop", recent: _recent });
          }
        }
    }
    buildSnippets=()=>{//nen dung
        const {product_detail, grpInfo} = this.state;
        let _snippet = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": `${product_detail.MaterialName}`,
            // "image": [],//bo sung sau
            "image":product_detail.ImageUrlThumnail,
            "description": `${product_detail.ShortDescription}`,
            "url": window.location.href,
            "sku": `${product_detail.MaterialCode}`,
            // "mpn": "925872",
            // "brand": {
            //     "@type": "Thing",
            //     "name": "No brand"
            // },
            "brand": "No brand",
            "aggregateRating": {
                "@type": "AggregateRating",
                "bestRating":5,
                "worstRating":1,
                "ratingValue": `${product_detail.Rating}`,
                "reviewCount": "89"
            },
            "offers": {
                "@type":"Offer",
                "url": window.location.href,
                "image":product_detail.ImageUrlThumnail,
                "priceCurrency": "VND",
                "price": `${product_detail.SellPrice}`,
                // "priceValidUntil": "2020-11-20",
                "itemCondition": "https://schema.org/UsedCondition",
                "availability": "https://schema.org/InStock"
            }
        }

        _snippet.image = [];
        let _thumnails = HUtils.Obj.get(product_detail, 'ImageUrlList',[]);
        if(_thumnails && Array.isArray(_thumnails)){
            for(let i=0;i<_thumnails.length;i++){
                if(_thumnails[i].ImageUrlThumnail){
                    _snippet.image.push(_thumnails[i].ImageUrlThumnail);
                }
            }
        }      
        // console.warn("aa:",_snippet);  
        return _snippet;
    }
    openModalEdit=({isNew}={})=>{
        let {product_detail, productTabs, product_subMatrialList} = this.state;
        if(product_detail){
            MyModal.Helpers.showMyUI("sc_editor_product",{
                data: isNew===true?null:product_detail,
                options: {
                    optionsImageByAttribute: product_subMatrialList
                },
                isNew: isNew,
                fnList: {
                    updateProductInfo: (newData)=>{
                        if(newData){
                            let _content = productTabs.find((i)=>i.Title == "Thông tin sản phẩm");
                            if(_content){
                                _content.Content = newData.FullDescription_DisplayInfo
                            }
                            else{
                                productTabs.unshift({
                                    Title: "Thông tin sản phẩm",
                                    Content: newData.FullDescription_DisplayInfo
                                })
                            }
                            HUtils.updateDataWithNewData(product_detail,newData);//chhi update nhung filed o newData
                            this.setState({
                                product_detail: {...product_detail},
                                productTabs: [...productTabs]
                            })
                        }
                    },
                    requestHeaderProduct: ({cbSuccess}={})=>{
                        return this.requestHeaderProduct({cbSuccess});
                    },
                    requestUpdateMaterialRelative: ({cbSuccess}={})=>{
                        this.requestRelatedProduct();
                        return this.requestGetRelativeMaterialList({cbSuccess})
                    },
                    requestGetRelativeMaterialList: ({cbSuccess}={})=>{
                        return this.requestGetRelativeMaterialList({cbSuccess});
                    }
                }
            },{
                title: "Chỉnh sửa sản phẩm",
                className: 'sc_modal_editor',
                closeOnBackdrop: false,
                closeButton: false
            })
        }
    }
    setNewest=()=>{
        let {product_detail} = this.state;
        const Id = product_detail.MaterialId;
        if(Id){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: `AlliancePosMaterial/UpdateNewestMaterialFromWeb/${Id}`
                },
                data: {},
                successCallBack: (response)=>{
                    response && response.Msg && HUI.Toast.showSuccess(response.Msg)
                },
                errorCallBack: (err, response)=>{}
            })
        }
    }
    selectImageByAttribute = (item) =>{
        const {product_detail} = this.state;
        const imgList = product_detail.ImageUrlList;
        let _img = null;
        if(imgList && item?.ImageGuid){
            _img = imgList.find((v)=>v.ImageGuid == item.ImageGuid);
            if(_img?.ImageUrlOrigin){
                this.setState({imageWithAttribute: _img.ImageUrlOrigin})
            }
        }
    }
    reSetImageAttribute = () =>{
        this.setState({imageWithAttribute: ""})
    }
    renderHelmet = () =>{
        const {product_detail} = this.state;
        const meta = {
            MetaTitle: product_detail.MetaKeyTitle || `${HConfig.fnList.getConfig("metaName")} - ${product_detail.MaterialName}`,
            MetaKeyWords: product_detail.MetaKeyWords || `${HConfig.fnList.getConfig("metaName")} - ${product_detail.MaterialName}`,
            MetaDescription: product_detail.MetaKeyDescription || `${HConfig.fnList.getConfig("metaName")} - ${product_detail.MaterialName}`
        }
        return <MyUI type="sc_helmet" data = {meta} buildSnippets={this.buildSnippets}/>
    }
    renderBreakcumb = () =>{
        const {product_detail, grpInfo} = this.state;
        if(product_detail && grpInfo){
            return(
                <MyUI type="sc_breakcumb" data={[
                    {Link: grpInfo.Slug, Title: grpInfo.GrpMaterialName},
                    {Title: product_detail.MaterialName}
                ]}/>
            )
        }
    }
    renderToolbarAdmin = () =>{
        const {product_detail} = this.state;
        if(product_detail){
            const _IsActive = product_detail.IsActive;
            const _Slug = product_detail.Slug
            return (
                <MyUI 
                    type="sc_toolbar_admin" 
                    status={_IsActive} 
                    slug={_Slug} 
                    title="Sản phẩm" 
                    ShowBtnCreatePost={false} 
                    ShowBtnNewest={true} 
                    fnList={{
                        onEdit: ()=>{
                            this.openModalEdit()
                        },
                        onAddNew: ()=>{
                            this.openModalEdit({isNew: true})
                        },
                        setNewest: ()=>{
                            this.setNewest()
                        },
                    }}
                />
            )
        }
    }
    renderProductInfo=()=>{
        const {product_detail, imageWithAttribute} = this.state;
        return(
            <div className="product-essential">
                <MyUI 
                    type="sc_product_images" 
                    data={product_detail} 
                    imageWithAttribute={imageWithAttribute} 
                    fnList={{
                        fnReSetImageAttribute: ()=>{
                            this.reSetImageAttribute()
                        }
                    }}
                />
                <MyUI 
                    type="sc_product_shortdesc" 
                    data={product_detail} 
                    fnList={{
                        fnSelectAttribute: (item)=>{
                            return this.selectImageByAttribute(item)
                        }
                    }}
                />
            </div>
        )
    }
    renderTabHelper=()=>{
        const {productTabs} = this.state;
        return <MyUI type="sc_tab" data={productTabs}/>
    }
    renderProductRelative=()=>{
        const {related_products} = this.state;
        return <MyUI type="sc_product_relative" data={related_products}/>
    }
    renderProductTag=()=>{
        const {product_tags} = this.state;
        return <MyUI type="sc_product_tag" data={product_tags}/>
    }
    render(){
        const {product_detail, grpInfo} = this.state;
        if(product_detail && grpInfo){
            return (
                <div id="detail-page-wrapper" className="master-wrapper-content">
                        {this.renderHelmet()}
                        {this.renderToolbarAdmin()}
                        {this.renderBreakcumb()}
                        <div className="master-column-wrapper">
                            <div className="center-1">
                                <div className="page product-details-page">
                                    <div className="page-body">
                                        <div>
                                            {this.renderProductInfo()}
                                            {this.renderTabHelper()}
                                            {this.renderProductTag()}
                                            {this.renderProductRelative()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            );
        }
        return <div></div>
    }
}
export default SC_Product_Detail