import React from "react";
import MyIconSVG from "../../MyIconSvg/MyIconSvg";
import "./styles/sc_order_progress.css";

class SC_OrderProgress extends React.Component {
  onPreviousStep = (index) => {
    const { fnList } = this.props;
    fnList?.fnOPreviousStep && fnList.fnOPreviousStep(index);
  };
  render() {
    const { stepList, stepActive } = this.props;
    if (stepList?.length > 0) {
      return (
        <div className="sc_order_progress">
          {stepList.map((v, i) => {
            return (
              <div
                key={i}
                className="step"
                style={
                  i > 0 ? { width: `${100 / (stepList.length - 1)}%` } : {}
                }
              >
                <div
                  className={`line${
                    stepActive == i
                      ? " active"
                      : stepActive > i
                      ? " passed"
                      : ""
                  }`}
                ></div>
                <div
                  className={`circle${
                    stepActive == i
                      ? " active"
                      : stepActive > i
                      ? " passed"
                      : ""
                  }`}
                  onClick={() => this.onPreviousStep(i)}
                >
                  {stepActive > i ? (
                    <MyIconSVG type="check" className="ico-tick" />
                  ) : (
                    <i className={v.Icon} style={{fontSize: v.FontSize}}/>
                  )}
                  <div className="description">{v.Text}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_OrderProgress;
