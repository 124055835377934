import React, { Suspense } from 'react'
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { CContainer, CFade } from '../../../components/MyCore'
import {HRouter,HConfig} from '../../Helpers';
import {MyLoadingComponent} from '../../Components';
import {TheBanner} from './index'

const TheContent = (props) => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <TheBanner/>
        <Suspense fallback={<MyLoadingComponent />}>
          <Switch>
            {
              HRouter.renderRouterInLayout()
            }
            {/* <Redirect from="/" to={HConfig.fnList.getPathHomePageLogged(props, '/home')} /> */}
          </Switch>
        </Suspense>
      </CContainer>
    </main>)
}

export default React.memo(TheContent)
