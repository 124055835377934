import React from 'react'
import {
  CInputCheckbox,
} from '../../MyCore'

class MyUI_InputCheck extends React.Component {
  static getDerivedStateFromProps(props, state) {
    // console.warn("getDerivedStateFromProps",props,state);
    if (props.defaultValue !== state.defaultValue) {
      return {
        defaultValue: props.defaultValue,
        value: props.defaultValue!=null?props.defaultValue:false,
      };
    }
    return null;
  }
  state = {
    value: this.props.defaultValue!=null?this.props.defaultValue:false,
    defaultValue: this.props.defaultValue
  }; 
  render(){    
    const {style,onBlur,onChange,disabled,className} = this.props;
    // console.warn("MyUI_InputText render:",this.props,this.state);
    return (
        <CInputCheckbox 
            checked={this.state.value} 
            style={style}  
            disabled={disabled}
            className={className}
            onChange={ (ev) => { 
                // console.warn('onChange',ev,ev.currentTarget.checked,this.state.value);
                this.setState({ 
                    value: ev.currentTarget.checked 
                },()=>{
                    if(onChange){
                        onChange(ev,this.state.value);
                    }
                });
            }}
            onBlur={(ev)=>{
                // this._onChange(ev, this.state.value);
                // CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value});
                if(onBlur){
                onBlur(ev,ev.currentTarget.checked);
                }
            }}
        />
    )
  } 
}

export default MyUI_InputCheck