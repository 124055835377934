import React from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '../../../../../components/MyCore'
import {HApiAxios, HText, HAuth} from '../../../../Helpers';

class LoginPage extends React.Component{
    constructor(props){
        super(props);
        this.state={
           value:{
            //   username: "dung.buiminh@allianceitsc.com",
            //   password: '123$56',
            username: "",
            password: '',
           }
        }
    }
    onChangeValue=(field,e)=>{
       const newState = {...this.state};
       newState.value[field] = e.target.value;
       this.setState(newState);
    }

    requestLogin=()=>{
        const {value} = this.state;
        let _requestData={
            grant_type: 'password',
            username: value.username,
            password: value.password,
            ...this.props.isSendLanguageCode == true && {LanguageCode: HText.language,}
        }
        HApiAxios.generic({
            request:{
              method: 'FORM',
              url: '/oauth2/token'
            },
            data: _requestData,
            successCallBack:(response)=>{
              HAuth.login(response,this.props);
              window.location.reload()
            },
            errorCallBack:(error,response)=>{
              console.warn('err:',error,response,value);
            }
          })
    }
    render(){  
        return(
            <div className="c-app c-default-layout flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="8">
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>{HText.get('text_login')}</h1>
                                            <p className="text-muted">{HText.get('text_login_des')}</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                    <i className="fa fa-envelope-o" style={{width: 20}}/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="text"  placeholder={HText.get('text_type_user')} autoComplete="username" onChange={(e)=>this.onChangeValue("username",e)}/>
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                    <i className="fa fa-lock" style={{width: 20}}/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="password" placeholder={HText.get('text_type_pw')} autoComplete="current-password" onChange={(e)=>this.onChangeValue("password",e)} 
                                                onKeyPress={(ev)=>{
                                                    if(ev.key === 'Enter'){
                                                        this.requestLogin()
                                                    }
                                                }}/>
                                            </CInputGroup>
                                            <CRow  style={{alignItems: "center"}}>
                                                <CCol xs="6">
                                                    <CButton color="primary" className="px-4" onClick={this.requestLogin} style={{whiteSpace:'nowrap'}}>{HText.get('btn_login')}</CButton>
                                                </CCol>                                            
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>                                
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
  
        )
    }
}
export default LoginPage