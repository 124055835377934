import React, {cloneElement, forwardRef} from 'react';
import {preserveRef} from './utils';

export default (Tippy, defaultProps) =>
  forwardRef(function TippyWrapper({children, ...props}, ref) {
    if(typeof children === "object" && typeof children.type === "function"){
      console.warn("AA",children);
      return (
        <Tippy {...{...defaultProps, ...props}}>
          {children
            ? 
            children.type({...children.props,...{
              innerRef(node) {
                preserveRef(ref, node);
                preserveRef(children.ref, node);
              },
            }})
            : null}
        </Tippy>
      );
    }
    return (
      // If I spread them separately here, Babel adds the _extends ponyfill for
      // some reason
      <Tippy {...{...defaultProps, ...props}}>
        {children
          ? cloneElement(children, {
              ref(node) {
                preserveRef(ref, node);
                preserveRef(children.ref, node);
              },
            })
          : null}
      </Tippy>
    );
  });
