import React from "react";
import {HConfig, HFormat, HUtils, HText} from 'apps/Helpers'
import HShopUtils from 'apps/shoppingCart/helpers/Utils'
import "./styles/sc_card_product_minishopping.css";

class SC_CardProductInMiniShopping extends React.Component {
  renderProductImage = (data) =>{
    const _MaterialName = data.MaterialName;
    const _Slug = data.Slug;
    const _ImageUrlThumnail = data.ImageCurrentAttribute || data.ImageUrlThumnail ||  HConfig.fnList.getConfig("imageProductDefault");
    return(
      <div className="picture">
        <a title={`${HText.get("text_product_title")} ${_MaterialName}`} href={_Slug}>
          <img
            alt={`${HText.get("text_product_title_image")} ${_MaterialName}`}
            src={_ImageUrlThumnail}
            title={`${HText.get("text_product_title")} ${_MaterialName}`}
          />
        </a>
      </div>
    )
  }
  renderProductPrice = (data) =>{
    const _QuantityMin = HUtils.Obj.get(data, 'DiscountInfo.QuantityMin');
    const _DiscountMoney = HUtils.Obj.get(data, 'DiscountInfo.DiscountMoney');
    const _Quantity = data.Quantity;
    const _SellPriceText = data.SellPriceText;
    const _SellPrice = data.SellPrice;
    const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
    let _isDiscount = false;

    if(HShopUtils.checkHasValue(_QuantityMin)){
      if(_QuantityMin <= 1 || _QuantityMin <= _Quantity){
        _isDiscount = true;
      }
    }
    return (
      <>
        {
          (_isDiscount) ? <strong>{HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}</strong> : null
        }
        <strong style={(_isDiscount) ? {textDecoration: "line-through", marginLeft: "0.5rem", fontWeight: 500, fontSize: 11} : {}}>{_SellPriceText}</strong>
      </>
    )

  }
  renderProductDescription = (data) =>{
    const _Slug = data.Slug;
    const _MaterialName = data.MaterialName;
    const _SellPriceText = data.SellPriceText;
    const _Quantity = _SellPriceText ? data.Quantity : `${HText.get("text_mini_preview_quantity")}: ${data.Quantity}`;
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
    return(
      <div className="product">
        <div className="name">
          <a href={_Slug} >{_MaterialName}</a>
        </div>
        {
          _haveProductOptions && this.renderProductAttribute(data)
        }
        <div className="price">
          <label>{_Quantity}</label>
          {
            _SellPriceText && <span>x</span>

          }
          {this.renderProductPrice(data)}
        </div>
      </div>
    )
  }
  renderProductAttribute = (product) =>{
    const curAtbs = product.OrderDetail_AttributeList;
    const itmAtbs = product.AttributeList;
    if(curAtbs && itmAtbs){
      let _kAtb = Object.keys(curAtbs);
      return <div className="attribute">
        {
          _kAtb.map((v, i)=>{
            let _valueId = curAtbs[v];
            let _value = null;
            let _text = null;
            let _itmAtb = itmAtbs.find((a)=>a.Code === v);
            if(_itmAtb?.Children){
              let _itmAtbCrn = _itmAtb.Children.find((b)=>b.Id === _valueId);
              if(_itmAtbCrn){
                  _value = _itmAtbCrn.TextDisplay;
                  _text = _itmAtb.TextDisplay;
              }
            }
            if(_value){
              return <div key={i}>{_value}{i!==_kAtb.length - 1 ? <span>-</span> : null}</div>
            }
          })
        }
      </div>
    }
    return <div></div>
  }  
  render() {
    const { data } = this.props;
    if(data){
      return (
        <div className="item">
          {this.renderProductImage(data)}
          {this.renderProductDescription(data)}
        </div>
      );
    }
    return <div></div>
  }
}
export default SC_CardProductInMiniShopping;
