import React from 'react';
import {MyUI} from '../../../../Components';
import PageCategoryExt from './SC_Category';
import { HUtils } from '../../../../Helpers';
import './styles/common.css'

class SC_Tags extends PageCategoryExt{
    constructor(props){
        super(props);
    }
    renderHelmet=()=>{
        const {configPage} = this.props;
        const meta = HUtils.Obj.get(configPage, "Data.ExtraData.MetaDataInfo")
        return <MyUI type="sc_helmet" data={meta}/>
    }
    renderBreakcumb=()=>{
        const {categoryDetail} = this.state;
        if(categoryDetail){
            return <MyUI type="sc_breakcumb" data={[{Title: categoryDetail.Slug}]}/>
        }
        return <div></div>
    }

} 
export default SC_Tags