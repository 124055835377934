import React from 'react'
import './styles/sc_tab.css'
import {MyUI} from '../../../../Components'

class SC_ProductTab extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tabIndex: 0
        }
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index
        })
    }
    renderTabHeader=()=>{
        const _tabArr = this.props.data;
        let _ui = []
        if(_tabArr && _tabArr.length>0){
            for(let i=0; i<_tabArr.length; i++){
                const _item = _tabArr[i];
                _ui.push(
                    <div key={`tab_${i}`} className={this.state.tabIndex == i ? "nav_tab active" : "nav_tab"} onClick={()=>this.onChangeTab(i)}>{_item.Title}</div>
                )
            }
        }
        return(
            <div className="productTab_header">
               {_ui}
            </div>
        )
    }
    renderContent=()=>{
        const _tabArr = this.props.data;
        let _ui = []
        if(_tabArr && _tabArr.length>0){
            for(let i=0; i<_tabArr.length; i++){
                const _item = _tabArr[i];
                _ui.push(
                    <div key={`tabContent_${i}`} className="tab_content" style={this.state.tabIndex == i ? {display: 'block'} : {display: 'none'}}>
                        <MyUI type="html" html={_item.Content}/>
                    </div>
                )
            }
        }
        return(
            <div className="productTab_content html-content">
               {_ui}
            </div>
        )
    }
    render(){
        return(
            <div className="sc_tab">
                {
                    this.renderTabHeader()
                }
                {
                    this.renderContent()
                }
            </div>
        )
    }
}
export default SC_ProductTab