import React from "react";
import { HConfig, HText } from "apps/Helpers";
import { Redirect } from "react-router-dom";
import { MyUI } from "apps/Components";
import "./styles/sc_stop_working.css";

class SC_Stop_Working extends React.Component {
  render() {
    const isShowStopWorking = HConfig.fnList.getConfig(
      "temporarilyStopWorking"
    );
    const title = HConfig.fnList.getConfig("temporarilyStopWorkingTitle");
    const des = HConfig.fnList.getConfig("temporarilyStopWorkingDes");
    const logoApp = HConfig.fnList.getConfig("logoApp");
    const metaName = HConfig.fnList.getConfig("metaName");
    if (isShowStopWorking) {
      return (
        <div className="sc_stop_working">
          <div className="content">
            <div className="logo">
              <img src={logoApp} alt="Ajuma stopped working temporarily" />
            </div>
            <div className="notify">
              <span>{title}</span>
              <div className="des" style={{maxWidth:"500px",textAlign:"justify"}}>
                <MyUI type="html" html={des} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Redirect to={HConfig.fnList.getPathHomePageLogged(this.props, "/")} />
      );
    }
  }
}
export default SC_Stop_Working;
