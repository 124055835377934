import React from "react";
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import "./styles/sc_product_attribute.css"

class sc_product_attribute extends React.Component {
  getClassName = (data) =>{
    const _isActive = data.isActive;
    const _disabled = data.disabled;
    if(_isActive && !_disabled){
      return "productAddedToCartOptions-item active"
    }
    else if(_disabled && !_isActive){
      return "productAddedToCartOptions-item disabled"
    }
    else{
      return "productAddedToCartOptions-item"
    }
  }
  render() {
    const { data, onChangeAttribute } = this.props;
    if (data) {
      const _text = data.text;
      const _isActive = data.isActive;
      const _disabled = data.disabled;

      return (
        <div
          className={this.getClassName(data)}
          onClick={() => _disabled == false && onChangeAttribute && onChangeAttribute()}
        >
          {_text}
          {(_isActive && !_disabled)? (
            <div className="product-variation__tick">
              <MyIconSvg type="check" className="icon-tick-bold"/>
            </div>
          ) : null}
        </div>
      );
    }
    return <div></div>
  }
}
export default sc_product_attribute;
