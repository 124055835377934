import React from 'react'
import './styles/sc_card_banner.css'
import HGA from '../../../../../helpers/GA'

class CardBanner extends React.Component{
    render(){
        const {data} = this.props;
        return(
            <div className="card_banner">
                <a href={data.link} onClick={()=>{
                    HGA.trackingEventClick({
                        action:'click_item_home_banner'
                    })
                    // localStorage.setItem('aaa','aaa');
                }}>
                    <img src={data.imageUrl}/>
                </a>                
            </div>
        )
    }
}
export default CardBanner