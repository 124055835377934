import React from 'react';
import {MyModal, MyUI} from 'apps/Components';
import {useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {CButton} from 'components/MyCore'
import {HText, HAuth, HConfig, HFormat, HLink, HApiAxios} from 'apps/Helpers';
import HGA from 'helpers/GA'
import '../styles.css';


const TheHeader = (props) => {
  const ref = useRef();
  const [showAccount, setShowAccount] = React.useState(false);
  const [showMiniCart, setShowMiniCart] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);

  const appLogo = useSelector(state => state.shop.appLogo);
  const config = useSelector(state => state.shop.config);
  const headerLink = useSelector(state => state.shop.headerLink)
  const categoryMenu = useSelector(state => state.shop.categoryMenu)
  const adminButtons = useSelector(state => state.shop.adminButtons)
  const wishlist = useSelector(state => state.shop.wishlist)
  const productInCart = useSelector(state => state.shop.cartProducts)
  const sumCart = useSelector(state => state.shop.total)
  const sumCartDiscount = useSelector(state => state.shop.totalAfterDiscount)
  const sumCartText = HFormat.Money.formatMoneyForAjuma(sumCartDiscount || sumCart)
  const isLoggedIn = HAuth.isLoggedIn();
  const name = HConfig.fnList.getAuthName();
  const topNotify = config?.topNotify;

  const showHoverAccount=(value)=>{
    setShowAccount(value)
  }
  const showHoverMiniCart=(value)=>{
    setShowMiniCart(value)
  }
  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      showHoverAccount(false)
      showHoverMiniCart(false)
      // setShowSidebar(false)
      setShowSearch(false)
    }
  };
  const showMobileMenu=(menu, value)=>{
    if(menu == 'account'){
      setShowAccount(value);
      setShowSearch(!value);
      setShowMiniCart(!value);
      setShowSidebar(!value)
    }
    else if(menu == 'cart'){
      setShowAccount(!value);
      setShowSearch(!value);
      // setShowMiniCart(value);
      setShowSidebar(!value)
    }
    else if(menu == 'sidebar'){
      setShowAccount(!value);
      setShowSearch(!value);
      setShowMiniCart(!value);
      setShowSidebar(value)
    }
    else if(menu == 'search'){
      setShowAccount(!value);
      setShowSearch(value);
      setShowMiniCart(!value);
      setShowSidebar(!value)
    }
  }
  const refreshCache =()=>{
    HApiAxios.generic({
      request:{
        method: 'POST',
        url: 'DashboardForWebsite/RefreshCache'
      },
      data:{
        Id: 'ALL'
      },
      successCallBack: (response)=>{
        window.location.reload()
      },
      errorCallBack: (error, response)=>{
        
      }
    })
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    props?.afterRender && props.afterRender();
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <div className={`header${topNotify ? " has-notify" : ""}`}>
        {
          topNotify && (
            <div className="header-above-fixed center" style={showSidebar ? {zIndex: '1020'} : {}}>
              <div className="notification" id="header-notify">
                <i className="fa fa-exclamation-circle" />
                <a id="txt-notify" href="/help" dangerouslySetInnerHTML={{ __html: topNotify }} />
              </div>
            </div>
          )
        }
        <div className="header-upper">
            <div className="center" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div className="header-links-wrapper" onMouseEnter={()=>showHoverAccount(true)} onMouseUp={()=>showHoverAccount(false)}>
                    <i className="fas fa-user-alt"></i>
                    <label>{isLoggedIn ? `${HText.get("text_account_user")} ${name}` : HText.get("text_account")}</label>
                    <div ref={ref} className="header-links" style={showAccount?{display: "block"}:{display: "none"}} onMouseLeave={()=>showHoverAccount(false)}>
                      <ul>
                        {
                          isLoggedIn == false &&
                          <li><a className="ico-login modal-login" onClick={()=>{
                            MyModal.Helpers.showMyUI("sc_login",{},{
                              title: HText.get("text_welcom_login"),
                              closeButton: true,
                              size: 'lg'
                            });
                            HGA.trackingEventClick({
                              action:'click_header_login'
                            })
                          }}>{HText.get("text_menu_login")}</a></li>
                        }
                        <li>
                          <a className="ico-wishlist" style={{backgroundPosition:'left -196px'}} href="/wishlist" onClick={()=>{
                            HGA.trackingEventClick({
                              action:'click_header_wish'
                            })
                          }}>
                            <span className="wishlist-label">{HText.get("text_menu_wishlist")}</span>
                            <span className="wishlist-qty">({wishlist && wishlist.length})</span>
                          </a>
                        </li>
                        {
                          isLoggedIn == true &&
                          <li>
                            <a href="/account-address" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                              HGA.trackingEventClick({
                                action:'click_header_address'
                              })
                            }}>
                              <i className="fa fa-address-book" style={{marginLeft: -23, marginRight: 10, color: '#898989'}}/>
                              <span>{HText.get("text_menu_address_book")}</span>
                            </a>
                          </li>
                        }
                        {
                          isLoggedIn == true &&
                          <li>
                            <a href="/account-bills" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                              HGA.trackingEventClick({
                                action:'click_history_bill'
                              })
                            }}>
                              <i className="fa fa-tasks" style={{marginLeft: -23, marginRight: 10, color: '#898989'}}/>
                              <span>{HText.get("text_menu_bills")}</span>
                            </a>
                          </li>
                        }
                        {
                          isLoggedIn == true &&
                          <li><a className="ico-logout" onClick={()=>HAuth.logOut()}>{HText.get("text_menu_logout")}</a></li>
                        }
                      </ul>
                    </div>
                </div>
                {!HAuth.isLoggedIn() && <MyUI type="sc_login" uiType={1}/>}
                {
                  (adminButtons?.ShowBtnRefreshCache == true) &&
                  <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                    <CButton color="secondary" onClick={()=>refreshCache()}><i className="fa fa-refresh"/></CButton>
                  </div>
                }
                {
                  (adminButtons?.ShowBtnAdmin == true) &&
                  <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                    <CButton color="secondary" onClick={()=>HLink.push('/admin-content-material')}><i className="fa fa-pencil-square"/></CButton>
                  </div>
                }
                {
                  HConfig.fnList.getIsDevMode() == true &&
                  <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                    <CButton color="secondary" onClick={()=>{
                      MyModal.Helpers.showMyUI("sc_editor_testapi",{},{
                        title: 'Test api',
                        closeButton: true,
                        closeOnBackdrop: false,
                      })
                    }}>Test API</CButton>
                  </div>
                }
                
            </div>
        </div>
        <div className="header-middle">
          <div className="center">
            <div className="header-logo">
              <a href="/" className="logo">
                <img src={appLogo}/>
              </a>
            </div>
            <div className="header-menu-parent">
              <div className={`header-menu categories-in-side-panel ${showSidebar ? 'open': ''}`}>
                <MyUI 
                  type="sc_menu_mobile" 
                  headerList={headerLink}
                  categoryList={categoryMenu}
                  showSidebar={showSidebar}
                  fnList={{
                    fnSetShowSidebar: (value)=>{
                      setShowSidebar(value)
                    }
                  }}
                />
              </div>
            </div>
            {
              productInCart && 
              <div className="flyout-cart-wrapper" onMouseEnter={()=>showHoverMiniCart(true)} onMouseLeave={()=>showHoverMiniCart(false)}>
                <a className="cart-trigger" href="/cart" onClick={()=>{
                  HGA.trackingEventClick({
                    action:'click_header_cart'
                  })
                }}>
                  <span className="cart-qty">({productInCart.length})</span>
                  <span className="cart-label">{" " + HText.get("text_mini_preview_unit")}</span>
                  {
                    productInCart.length > 0 &&
                    <span className="cart-ttl">{sumCart!==0 ?` - ${sumCartText}`: null}</span>
                  }
                </a>
                <MyUI type="sc_modal_mini_shoppingcart" style={(productInCart && productInCart.length>0 && showMiniCart) ? {display: "block"} : {display: 'none'}} items={productInCart} total={sumCartDiscount || sumCart}/>
              </div>
            }
          </div>
        </div>
        <div className="header-lower">
          <div className="center">
            <MyUI type="sc_menu" data={categoryMenu}/>
            <MyUI type="sc_header_search"/>
          </div>
        </div>
        <div className={`overlayOffCanvas ${(showSidebar || showMiniCart || showAccount || showSearch)? 'show' : ''}`} onClick={()=>{
          showHoverAccount(false)
          showHoverMiniCart(false)
          setShowSidebar(false)
          setShowSearch(false)
        }}></div>
        <div className="responsive-nav-wrapper-parent" ref={ref} style={showSidebar ? {zIndex: '1020'} : {}}>
          <div className="responsive-nav-wrapper stick">
            <div className="menu-title" onClick={()=>showMobileMenu("sidebar", true)}>
              <span>Menu</span>
            </div>
            <div className="shopping-cart-link" onClick={()=> {
              showMobileMenu("cart", true) ;
              setShowMiniCart(!showMiniCart)
            }}>
              <span>Giỏ hàng</span>
              {productInCart?.length > 0 && <span className="cart-badge">{productInCart.length}</span>}
              <div className="flyout-cart-wrapper" style={showMiniCart ? {display: "block"} : {display: 'none'}}>
                <MyUI type="sc_modal_mini_shoppingcart" items={productInCart} total={sumCart}/>
              </div>
            </div>
            <div className="personal-button"  onClick={()=>showMobileMenu("account", true)}>
              <span>Personal menu</span>
              <div className="header-links" style={showAccount ? {display: "block"} : {display: 'none'}}>
                <ul>
                  {
                    isLoggedIn == false &&
                    <li><a className="ico-login modal-login" onClick={()=>{
                      MyModal.Helpers.showMyUI("sc_login",{},{
                        title: HText.get("text_welcom_login"),
                        closeButton: true,
                        size: 'lg'
                      });
                      HGA.trackingEventClick({
                        action:'click_header_login_mobile'
                      })
                    }}>{HText.get("text_menu_login")}</a></li>
                  }
                  <li>
                    <a href="/wishlist" className="ico-wishlist" onClick={()=>{
                      HGA.trackingEventClick({
                        action:'click_header_wish_mobile'
                      })
                    }}>
                      <span className="wishlist-label">{HText.get("text_menu_wishlist")}</span>
                      <span className="wishlist-qty">({wishlist && wishlist.length})</span>
                    </a>
                  </li>
                  {
                    isLoggedIn == true &&
                    <li>
                      <a href="/account-address" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                        HGA.trackingEventClick({
                          action:'click_header_address_mobile'
                        })
                      }}>
                        <i className="fa fa-address-book" style={{marginLeft: -30, marginRight: 15, color: '#898989'}}/>
                        <span>{HText.get("text_menu_address_book")}</span>
                      </a>
                    </li>
                  }
                  {
                    // (isLoggedIn == true && HConfig.fnList.getIsDevMode() == true) &&
                    isLoggedIn == true &&
                    <li>
                      <a href="/account-bills" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                        HGA.trackingEventClick({
                          action:'click_history_bill_mobile'
                        })
                      }}>
                        <i className="fa fa-tasks" style={{marginLeft: -30, marginRight: 15, color: '#898989'}}/>
                        <span>{HText.get("text_menu_bills")}</span>
                      </a>
                    </li>
                  }
                  {
                    isLoggedIn == true &&
                    <li><a className="ico-logout" onClick={()=>HAuth.logOut()}>{HText.get("text_menu_logout")}</a></li>
                  }
                </ul>
              </div>
            </div>
            <div className="search-wrap" onClick={()=>showMobileMenu("search", true)}>
              <span>Tìm kiếm</span>
              <MyUI type="sc_header_search" style={showSearch ? {display: "block"} : {display: 'none'}}/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default TheHeader