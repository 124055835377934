import React from "react";
import { MyUI } from "apps/Components";
import HGA from "helpers/GA";
import "./styles/sc_home.css";

class HomeBestSeller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 6,
      column: 2,
      row: 3,
      widthCol: 395,
      style: {},
      settings: [
        { width: 1100, column: 3 },
        { width: 600, column: 2 },
        { width: 0, column: 1 },
      ],
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
  }
  componentDidMount() {
    this.setWidthItem();
    window.addEventListener("resize", this._resize);
  }
  _resize = () => {
    this.setWidthItem();
  };
  _getData = (pageNumber) => {
    const { data } = this.props;
    const { pageSize } = this.state;
    if (data && data.length > 0) {
      return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    return [];
  };
  _getPageArray = () => {
    const { data } = this.props;
    const { pageSize } = this.state;
    let _arrTab = [];
    if (data && data.length > 0 && pageSize > 0) {
      let _totalPage = parseInt(data.length / pageSize) + 1;
      for (let i = 0; i < _totalPage; i++) {
        _arrTab.push({
          data: this._getData(i + 1),
        });
      }
    }
    // console.warn("_getPageArray:",_arrTab,data);
    return _arrTab;
  };
  _renderColInTab = (arr) => {
    const { pageSize, column, row, widthCol } = this.state;
    let start = 0;
    let end = 0;
    let _ui = [];

    if (arr && arr.length > 0) {
      const _arrSize = arr.length;
      if (_arrSize <= row) {
        let _col = [];
        for (let i = 0; i < _arrSize; i++) {
          const _item = arr[i];
          _col.push(
            <MyUI
              type="sc_card_product_in_home"
              key={i}
              data={_item}
              from="home_best_seller"
              onClick={() => {
                HGA.trackingEventClick({
                  action: "click_item_home_product_of_bestseller",
                  label: _item.MaterialName,
                });
              }}
            />
          );
        }
        _ui.push(
          <div
            className="owl-item active"
            style={{ marginRight: "0px", width: widthCol }}
          >
            <div className="three-items-holder">{_col}</div>
          </div>
        );
      } else if (_arrSize > row && _arrSize <= pageSize) {
        for (let i = 0; i < column; i++) {
          let _col = [];
          if (i == 0) {
            start = i;
            end = row;
          } else if (end + row > _arrSize) {
            start += row;
            end += _arrSize;
          } else {
            start += row;
            end += row;
          }
          for (let j = start; j < end; j++) {
            const _item = arr[j];
            _col.push(
              <MyUI
                type="sc_card_product_in_home"
                key={j}
                data={_item}
                from="home_best_seller"
              />
            );
          }
          _ui.push(
            <div
              className="owl-item active"
              style={{ marginRight: "0px", width: widthCol }}
              key={`col_${i}`}
            >
              <div className="three-items-holder">{_col}</div>
            </div>
          );
        }
      } else {
        for (let i = 0; i < column; i++) {
          let _col = [];
          if (i == 0) {
            start = i;
            end = row;
          } else {
            start += row;
            end += row;
          }
          for (let j = start; j < end; j++) {
            const _item = arr[j];
            _col.push(
              <MyUI
                type="sc_card_product_in_home"
                key={j}
                data={_item}
                from="home_best_seller"
              />
            );
          }
          _ui.push(
            <div
              className="owl-item active"
              style={{ marginRight: "0px", width: widthCol }}
              key={`col_${i}`}
            >
              <div className="three-items-holder">{_col}</div>
            </div>
          );
        }
      }
    }
    return _ui;
  };
  onPrevious = () => {
    const { pageNumber, pageSize } = this.state;
    const { data } = this.props;
    let _current = pageNumber;
    let _previous = _current - 1;
    let _maxPage = Math.ceil(data.length / pageSize) || 1;
    if (_previous == 0) {
      _previous = _maxPage;
    }
    this.setState(
      {
        pageNumber: _previous,
      },
      () => {
        let _stage = document.getElementById("bestseller-stage");
        if (_stage) {
          _stage.style.transform = `translate3d(${
            _previous > 0 ? -this.getWidthItem() * (_previous - 1) : 0
          }px, 0px, 0px)`;
        }
      }
    );
  };
  getWidthItem = () => {
    const { column, widthCol } = this.state;
    let _width = 790;
    // const _itemArray = document.getElementsByClassName("owl-item active");
    if (widthCol) {
      _width = widthCol * column;
    }
    return _width;
  };
  setWidthItem = () => {
    const { settings, row } = this.state;
    let _width = 395;
    const _componentElm = document.getElementsByClassName("owl-stage-outer");
    const _itemWrapper = document.getElementsByClassName("owl-stage-outer");
    const _itemArray = document.getElementsByClassName("owl-item active");
    const _blogHome = document.getElementsByClassName("rich-blog-homepage");
    if (_blogHome.length == 0) {
      this.setState({ style: { width: "100%" } });
    }
    if (
      _componentElm?.length > 0 &&
      _itemWrapper?.length > 0 &&
      _itemArray?.length > 0
    ) {
      let _cpnWidth = _componentElm[0].offsetWidth;
      if (settings) {
        for (let i = 0; i < settings.length; i++) {
          if (_cpnWidth >= settings[i].width) {
            _width = _cpnWidth / settings[i].column;
            this.setState({
              pageSize: settings[i].column * row,
              column: settings[i].column,
              widthCol: _width,
            });
            break;
          }
        }
      }
      //   console.warn("_width", _width, _cpnWidth);
      for (let i = 0; i < _itemArray.length; i++) {
        _itemArray[i].style.width = `${_width}px`;
      }
    }
  };
  onNext = () => {
    const { pageNumber, pageSize } = this.state;
    const { data } = this.props;
    let _current = pageNumber;
    let _next = _current + 1;
    let _maxPage = Math.ceil(data.length / pageSize) || 1;
    if (_next > _maxPage) {
      _next = 1;
    }
    this.setState(
      {
        pageNumber: _next,
      },
      () => {
        let _stage = document.getElementById("bestseller-stage");
        if (_stage) {
          _stage.style.transform = `translate3d(${
            _next > 0 ? -this.getWidthItem() * (_next - 1) : 0
          }px, 0px, 0px)`;
        }
      }
    );
  };
  render() {
    const { config } = this.props;
    const { style, widthCol, column } = this.state;
    const _arrTab = this._getPageArray();
    const _title = config?.Title;
    const _fullWidth = config?.FullWidth;

    return (
      <div
        className="bestsellers carousel"
        style={{ ...style, ...(_fullWidth && { width: "100%" }) }}
      >
        <div className="title">
          <strong>{_title}</strong>
        </div>
        <div className="item-grid owl-carousel owl-theme owl-loaded">
          <div className="owl-stage-outer">
            <div
              id="bestseller-stage"
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: " all 0.25s ease 0s",
                width: widthCol * column * _arrTab?.length,
              }}
            >
              {/* {_arrTab?.length > 0 &&
                    _arrTab.map((tab, index) => {
                      return tab?.data && this._renderColInTab(tab.data);
                    })} */}
              <MyUI
                type="sc_grid_view"
                settings={[{ width: 0, column: _arrTab?.length }]}
                layoutStyle={{
                  gap: 0,
                }}
                renderComponent={() => {
                  return (
                    _arrTab?.length > 0 &&
                    _arrTab.map((v, i) => {
                      return (
                        <MyUI
                          key={i}
                          type="sc_grid_view"
                          id="home-bestseller"
                          settings={[
                            { width: 681, column: 2 },
                            { width: 0, column: 1 },
                          ]}
                          layoutStyle={{
                            gap: 0,
                          }}
                          renderComponent={() => {
                            return (
                              v?.data.length > 0 &&
                              v.data.map((product, index) => {
                                return (
                                  <MyUI
                                    type="sc_card_product_in_home"
                                    key={index}
                                    data={product}
                                    from="home_best_seller"
                                    onClick={() => {
                                      HGA.trackingEventClick({
                                        action:
                                          "click_item_home_product_of_cat",
                                        label: product.MaterialName,
                                      });
                                    }}
                                  />
                                );
                              })
                            );
                          }}
                          fnList={{
                            updateStyle: () =>{
                                document.getElementById("home-recent-blog").style.height = `${document.getElementById("home-bestseller")?.offsetHeight}px`
                            }
                          }}
                        />
                      );
                    })
                  );
                  //   return (
                  //     _arrTab?.[pageNumber - 1]?.data.length > 0 &&
                  //     _arrTab[pageNumber - 1].data.map((product, index) => {
                  //       return (
                  //         <MyUI
                  //             type="sc_card_product_in_home"
                  //             key={index}
                  //             data={product}
                  //             from="home_best_seller"
                  //             onClick={() => {
                  //               HGA.trackingEventClick({
                  //                 action: "click_item_home_product_of_cat",
                  //                 label: product.MaterialName,
                  //               });
                  //             }}
                  //           />
                  //       );
                  //     })
                  //   );
                }}
              />
            </div>
          </div>
          <div className="owl-controls">
            <div className="owl-nav">
              <div className="owl-prev" onClick={() => this.onPrevious()}></div>
              <div className="owl-next" onClick={() => this.onNext()}></div>
            </div>
            <div className="owl-dots" style={{ display: "none" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeBestSeller;
