import React from 'react'
import {HApiAxios,HUtils, HText, HLink, HUI} from '../../../../Helpers'
import {MyLoadingApp, MyModal, MyUI} from '../../../../Components'
import './styles/sc_editor_blog.css'

class SC_Editor_TestAPI extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data: [
                {FieldName: null, Value: null}
            ],
            URL: null,
            tabIndex: 0,
        }
    }
    requestApi=()=>{
       const {URL, data} = this.state;
       if(URL){
           let _query = {};
           for(let i=0; i <data.length; i++){
               _query[data[i].FieldName] = data[i].Value
           }
           HApiAxios.generic({
               request:{
                   method: 'POST',
                   url: URL
               },
               data: _query,
               successCallBack: (response)=>{},
               errorCallBack: (error, response)=>{}
           })
       }
    }
    spam=()=>{
        const _query = {
            CustomerId: "91",
            CustomerName: "Test0",
            CustomerPhone: "123456",
            Items: [
                {
                    ImageUrl: "https://api.ajumagarden.com/upload/material_ext_4_website/1634_files_0/bap-nep-than-trai.jpeg",
                    LinkDetail: "bap-nep-than-trai",
                    MaterialId: 11185,
                    MaterialName: "Bắp Nếp Trắng Tím - Hữu Cơ",
                    Quantity: 1,
                    Rating: 4.6,
                    SellPrice: 9000,
                    SellPriceText: "9.000 ₫",
                    ShortDescription: "Bắp được trồng hữu cơ từ hạt giống Non- GMO. Với bắp của vườn nhà mình đều có thể ăn sống trực tiếp, nhất là bắp tím nữ hoàng làm salad ăn rất thích.",
                }
            ]
        }
        for(let i=0; i<50; i++){
            HApiAxios.generic({
                request:{
                    method: 'POST',
                    url: "EzyWeb_OrderWithoutLogin/ConfirmToOrder"
                },
                data: _query,
                successCallBack: (response)=>{},
                errorCallBack: (error, response)=>{}
            })
        }
    }
    addNewRow=()=>{
        let {data} = this.state;
        data.push({FieldName: null, Value: null})
        this.setState({
            data: [...data]
        })
    }

    onSave=()=>{
        console.warn("data", this.state.data)
        this.requestApi();
    }
    
    onChange=({index, FieldName, Value}={})=>{
        let {data} = this.state;
        let _item = data[index];
        if(_item){
            _item.FieldName = FieldName;
            _item.Value = Value;
        }
        this.setState({
            data: [...data]
        })
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index
        })
    }
    render(){
        const {URL, tabIndex, data} = this.state;
        return(
            <div className="sc_editor_blog">
                <div className="blog-row">
                    <div className="label" style={{marginRight:'0.5rem', width: '150px'}}>URL</div>
                    <MyUI type="input_text" defaultValue={URL} onBlur={(e, newValue)=>this.setState({URL: newValue})}/>
                </div>
                <div className="blog-row">
                    <div>
                        <div style={{width: '135px'}} className={`label ${tabIndex==0?'':'inactive'}`} onClick={()=>{this.onChangeTab(0)}}>Data</div>
                        <div style={{width: '135px'}} className={`label ${tabIndex==1?'':'inactive'}`} onClick={()=>{this.onChangeTab(1)}}>Update Field</div>
                        <div style={{width: '135px'}} className={`label ${tabIndex==2?'':'inactive'}`} onClick={()=>{this.onChangeTab(2)}}>Test</div>                     
                    </div>
                    <div style={{minHeight:'120px',width:'100%',marginLeft:'0.5rem'}}>
                        {
                            tabIndex == 0 &&
                            <div>
                               {
                                   data && data.length > 0 && data.map((v,i)=>{
                                       return(
                                        <div className="blog-row" key={i}>
                                            <MyUI type="input_text" placeholder="FieldName" defaultValue={v.FieldName} onBlur={(e, newValue)=>this.onChange({index: i, FieldName: newValue, Value: v.Value})}/>
                                            <MyUI type="input_text" placeholder="Value" defaultValue={v.Value} onBlur={(e, newValue)=>this.onChange({index: i, FieldName: v.FieldName, Value: newValue})}/>
                                        </div>
                                       )
                                   })
                               }
                               <button className="btn-save-edit" style={{width: "100%", height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none'}} onClick={()=>this.addNewRow()}>
                                   <i className="fa fa-plus"/>
                                </button>
                            </div>
                        }

                        {
                            tabIndex ==  1 &&
                            <div className="dropzone">
                                
                            </div>
                        }
                        {
                            tabIndex == 2 &&
                            <div className="blog-row">
                                <button onClick={()=>this.spam()} className="btn-save-edit">GO</button>
                            </div>
                        }
                    </div>
                </div>                              
                <div className="blog-row">
                    <div></div>
                    <button onClick={()=>this.onSave()} className="btn-save-edit" style={{float: 'right'}}>Test</button>
                </div>
            </div>
        )
    }
}
export default SC_Editor_TestAPI