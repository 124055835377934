import React from 'react'
import {HUtils, HApiAxios, HConfig, HFormat, HText} from 'apps/Helpers'
import HShopUtils from 'apps/shoppingCart/helpers/Utils'
import {MyUI} from 'apps/Components'

class ExtAddCart extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isValidAttributeProduct: null,
            imageActive: null,
            atbL: [],
            product: {
                Quantity: 1
            },
        }
    }
    initAttributeState = (attributes) =>{
        const _attributes = this.getGroupAttribute(attributes);
        let _obj = {}
        if(_attributes){
            for(let i = 0; i<_attributes.length; i++){
                const _field = _attributes[i];
                _obj[_field] = null;
            }
        }
        return _obj
    }
    initRequestAttribute = (data) => {
        let {OrderDetail_AttributeList} = data;
        if(data &&  OrderDetail_AttributeList){
            let _keys = Object.keys(OrderDetail_AttributeList);
            for(let i=0; i<_keys.length; i++){
                const _k = _keys[i];
                this.requestAttribute({
                    id: data.MaterialId,
                    attributeCode: _k,
                    attributeId: OrderDetail_AttributeList[_k]
                })
            }
        }
    }       
    getGroupAttribute = (data) =>{
        const _AttributeList = HUtils.Obj.get(data, "AttributeList");
        let _group = [];
        if (_AttributeList) {
          for(let i = 0; i<_AttributeList.length; i++){
              const _item = _AttributeList[i];
              if( _item?.Children.length > 0){
                _group.push(_item.Code)
            }
          }
        }
        return _group;
    }
    getAttributeIdBasedOnAttributeActive = (data, attributeCode) =>{
        const {product} = this.state;
        let {OrderDetail_AttributeList} = product;
        let _rs = [];
        if(data && attributeCode && OrderDetail_AttributeList){
            let _keys = Object.keys(OrderDetail_AttributeList);
            for(let i=0; i<_keys.length; i++){
                const _k = _keys[i];
                if(_k !== attributeCode && OrderDetail_AttributeList[_k]){
                    const _atbCorrespondingActive = this.getAttributeProductFromAttributeListStore({
                        idProduct: data.MaterialId,
                        attributeParrentCode: _k, 
                        attributeChildId: OrderDetail_AttributeList[_k], 
                        correspondingCode: attributeCode
                    });
                    if(_atbCorrespondingActive){
                        _rs = HShopUtils.getArrayDifference(_rs, _atbCorrespondingActive)
                    }
                }
            }
        }
        return _rs;
    }
    getAttributeProductFromAttributeListStore=({idProduct, attributeParrentCode, attributeChildId, correspondingCode})=>{
        if(idProduct && attributeParrentCode && attributeChildId && correspondingCode){
            let {atbL} = this.state;  // list
            let _atbP = atbL.find((v)=>v.MaterialId == idProduct); // product
            if(_atbP){
            return HUtils.Obj.get(_atbP, `${attributeParrentCode}.${attributeChildId}.${correspondingCode}`)
            }
            return null;
        }
    }
    checkAttributes = (data, currentProductAttribute) =>{
        const _attributes = this.getGroupAttribute(data);
        if(_attributes && currentProductAttribute){
            for(let i = 0; i<_attributes.length; i++){
                const _field = _attributes[i];
                if(!currentProductAttribute[_field]){
                    return false;
                }
            }
            return true;
        }
    }
    checkHasValue = (value) =>{
        return HShopUtils.checkHasValue(value)
    }
    addCart=({product, productUpdate, attributes}={})=>{
        HShopUtils.addProductToCart({product, productUpdate, attributes});
    }
    addAttributeProductToAttributeListStore=({idProduct, attributeParrentCode, attributeChildId, correspondingAttribute}={})=>{
        if(idProduct && attributeParrentCode && attributeChildId && correspondingAttribute){
            // let _atbL = HLocalStorage.getObject(`_${_appName}_attributeList`, []);
            let {atbL} = this.state;  // list
            let _atbP = atbL.find((v)=>v.MaterialId == idProduct); // product
            if(_atbP){
              if(_atbP[attributeParrentCode]){
                if(_atbP[attributeParrentCode][attributeChildId]){
                  if(correspondingAttribute.Code && correspondingAttribute.List){
                    if(!_atbP[attributeParrentCode][attributeChildId][correspondingAttribute.Code]){
                      _atbP[attributeParrentCode][attributeChildId][correspondingAttribute.Code] = correspondingAttribute.List;
                    }
                  }
                }
                else{
                  _atbP[attributeParrentCode][attributeChildId] = {};
                  if(correspondingAttribute.Code && correspondingAttribute.List){
                    _atbP[attributeParrentCode][attributeChildId][correspondingAttribute.Code] = correspondingAttribute.List
                  }
                }
              }
              else{
                _atbP[attributeParrentCode] = {};
                _atbP[attributeParrentCode][attributeChildId] = {};
                if(correspondingAttribute.Code && correspondingAttribute.List){
                  _atbP[attributeParrentCode][attributeChildId][correspondingAttribute.Code] = correspondingAttribute.List
                }
              }
            }
            else{
              let _newAtb = {};
              _newAtb.MaterialId = idProduct;
              _newAtb[attributeParrentCode] = {};
              _newAtb[attributeParrentCode][attributeChildId] = {};
              if(correspondingAttribute.Code && correspondingAttribute.List){
                _newAtb[attributeParrentCode][attributeChildId][correspondingAttribute.Code] = correspondingAttribute.List
              }
              atbL.push(_newAtb)
            }
            this.setState({
                atbL: JSON.parse(JSON.stringify(atbL))
            })
        }
    }    
    addProductToWishList = (product) =>{
        HShopUtils.addProductToWishList({product})
    }
    updateProductInCart = ({product, index}={}) =>{
        HShopUtils.updateProductInCart({product,index})
    }
    onChangeQuantity = (value) =>{
        let {product} = this.state;
        product.Quantity = value
        this.setState({product: product})
    }
    onChangeAttribute = (field, value, data, {afterChange, item}={}) =>{
        let {product, isValidAttributeProduct} = this.state;
        if(!product.OrderDetail_AttributeList){
            product.OrderDetail_AttributeList = this.initAttributeState(data)
        }
        if(product.OrderDetail_AttributeList[field] === value){
            product.OrderDetail_AttributeList[field] = null
        }
        else{
            product.OrderDetail_AttributeList[field] = value;
        }
        isValidAttributeProduct = this.checkAttributes(data, product.OrderDetail_AttributeList);
        this.setState({product: product, isValidAttributeProduct},()=>{
            afterChange && afterChange();
            this.onChangeImageByAttribute(data, item);
            this.onChangeCorrespondingAttribute(data, field, value);
        })
    }
    onChangeImageByAttribute = (data, itemAttribute) =>{
        if(data){
            if(itemAttribute?.ImageGuid){
                const _ImageUrlList = data.ImageUrlList;
                const _imgWAttr = _ImageUrlList && _ImageUrlList.find((v)=> v.ImageGuid === itemAttribute.ImageGuid);
                if(_imgWAttr){
                    this.setState({imageActive: _imgWAttr.ImageUrlThumnail})
                }
            }
        }
    }
    onChangeCorrespondingAttribute = (data, attributeCode, attributeId) =>{
        let _atbG = this.getGroupAttribute(data);
        if(_atbG?.length>0){
            let _atbCorresponding = _atbG.filter((v)=> v !== attributeCode);
            if(_atbCorresponding?.length>0){
                for(let i=0; i<_atbCorresponding.length; i++){
                    let _atb = _atbCorresponding[i];
                    if(!this.getAttributeProductFromAttributeListStore({
                        idProduct: data.MaterialId,
                        attributeParrentCode: attributeCode, 
                        attributeChildId: attributeId, 
                        correspondingCode: _atb
                    })){
                        this.requestAttribute({
                            id: data.MaterialId,
                            attributeCode, 
                            attributeId
                        })
                    }
                }
            }
        }
    }
    requestAttribute = ({id, attributeCode, attributeId}={}) =>{
        if(id && attributeCode && attributeId){
            HApiAxios.generic({
                request: {
                    method: "POST",
                    url: "DashboardForWebsite/GetAttributeOfMaterial"
                },
                data: {
                    MaterialId: id,
                    ParentAttributeCode: attributeCode,
                    ChildAttributeId: attributeId,
                },
                successCallBack: (response)=>{
                    const res = HUtils.Obj.get(response, "Data");
                    if(res?.length>0){
                        for(let i = 0; i< res.length; i++){
                            let _obj = {
                                Code: res[i].Code,
                                List: HShopUtils.getArrayValueFromArrayObjectByField(res[i].Children, "Id")
                            };
                            this.addAttributeProductToAttributeListStore({
                                idProduct: id,
                                attributeParrentCode: attributeCode, 
                                attributeChildId: attributeId, 
                                correspondingAttribute: _obj
                            })
                        }
                    }
                    // this.forceUpdate()
                },
                errorCallBack: (error, response)=>{
                    console.warn("requestAttribute error, response", error, response)
                }
            })
        }
    }

    /* general render */
    renderImageProduct = (data) =>{
        const {imageActive} = this.state;
        const _Slug = data.Slug;
        const _MaterialName = data.MaterialName;
        const _ImageUrlThumnail = imageActive || data.ImageCurrentAttribute || data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
        return(
            <div className="productAddedToCartWindowImage">
                <a href={_Slug} title={`${HText.get("text_product_title")} ${_MaterialName}`}>
                    <img 
                        alt={`${HText.get("text_product_title_image")} ${_MaterialName}`} 
                        src={_ImageUrlThumnail} 
                        title={`${HText.get("text_product_title")} ${_MaterialName}`}
                    />
                </a>
            </div>
        )
    }
    renderAttributeGroup = (data, fnList, {hideTitle}={}) =>{
        const {product} = this.state;
        const {OrderDetail_AttributeList} = product;
        const _AttributeList = data.AttributeList;
        const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");

        return (_haveProductOptions && _AttributeList) ? (
            <div className="productAddedToCartOptions">
                {
                    _AttributeList.map((v, i)=>{
                        if(v.Children?.length>0){
                            return(
                                <div key={i} className="productAddedToCartOptions-group">
                                    { hideTitle !== true && <div className="title-group">{v.TextDisplay}</div>}
                                    <div className="items">
                                    {
                                        v.Children.map((a, b)=>{
                                            const _activeList = this.getAttributeIdBasedOnAttributeActive(data, v.Code);
                                            let _item = {
                                                text: a.TextDisplay,
                                                isActive: ( OrderDetail_AttributeList?.[v.Code] == a.Id) ? true :false,
                                                disabled: (_activeList.length > 0 && !_activeList.includes(a.Id)) ? true : false
                                            }
                                            return (
                                                <MyUI
                                                    key={b} 
                                                    type="sc_product_attribute"
                                                    data={_item}
                                                    onChangeAttribute={()=>{
                                                        this.onChangeAttribute(v.Code, a.Id, data, 
                                                            {
                                                                item: a,
                                                                afterChange: ()=>{
                                                                    fnList?.fnAfterChange && fnList.fnAfterChange(a);
                                                                }
                                                            })
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        ): <div></div>
    }
    renderAttributeMobile = (data) =>{
        const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
        const _AttributeList = data.AttributeList;
        if(_haveProductOptions && _AttributeList){
            return(
                <div className="productAddedToCartMobile">
                    {this.renderAttributeGroup(data,{})}
                </div>
            )
        }
    }
    renderPriceProduct = (data) =>{
        const {product} = this.state;
        const _SellPriceText = data.SellPriceText;
        const _SellPrice= data.SellPrice;
        const _QuantityMin = HUtils.Obj.get(data, 'DiscountInfo.QuantityMin');
        const _DiscountMoney = HUtils.Obj.get(data, 'DiscountInfo.DiscountMoney');
        const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
        const _Quantity = data.Quantity || product.Quantity
        if(_SellPriceText){
            return (
                <div className="dFaCjFSw100">
                    {
                        (this.checkHasValue(_QuantityMin) && _Quantity >= _QuantityMin) && (
                            <strong className="price price-value-coupon">{HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}</strong>
                        )
                    }
                    <strong className={`price price-value${(this.checkHasValue(_QuantityMin) && _Quantity >= _QuantityMin)? " origin" : ""}`}>{_SellPriceText}</strong>
                </div>
            )
        }
    }
}
export default ExtAddCart