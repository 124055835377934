import React from 'react'
import {MyUI, MyModal, MyLib} from '../../../../Components'
import {HUtils, HConfig} from '../../../../Helpers'
import './styles/sc_product_image.css'

class SC_ProductImages extends React.Component{
    constructor(props){
        super(props);
        this.state={
            mainImg: null,
            thumbnails: null,
        }
    }
    componentDidMount(){
        const {data, imageWithAttribute} = this.props;
        if(data && data.ImageUrlList){
            this.setState({
                mainImg: this.getImageShow(this.props),
                thumbnails: data.ImageUrlList
            })
        }
    }
    shouldComponentUpdate(nextProps){
        if(nextProps.data){
            if(this.state.thumbnails != nextProps.data.ImageUrlList){
                this.setState({
                    mainImg: this.getImageShow(nextProps),
                    thumbnails: nextProps.data.ImageUrlList
                })
            }
        }
        if(this.props.imageWithAttribute !== nextProps.imageWithAttribute){
            this.setState({mainImg: this.getImageShow(nextProps)})
        }
        return true;
    }
    getImageShow = (props) =>{
        const {imageWithAttribute,  data} = props;
        return imageWithAttribute || HUtils.Obj.get(data,"ImageUrlOrigin") || HUtils.Obj.get(data,"ImageUrlList[0].ImageUrlOrigin")
    }
    onChangeImg=(item)=>{
        const {imageWithAttribute, fnList} = this.props;
        this.setState({
            mainImg: item.ImageUrlOrigin
        },()=>{
            if(imageWithAttribute){
                fnList?.fnReSetImageAttribute && fnList.fnReSetImageAttribute()
            }
        })
    }
    renderClose = () =>{
        return(
            <div className="close-gallery"><span onClick={()=>MyModal.Helpers.hideModalGallery()}><i className="fa fa-times"/></span></div>
        )
    }

    render(){
        const {mainImg, thumbnails} = this.state;
        // console.warn("thumbnails", thumbnails)
        const configSlide={
            dots: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            swipeToSlide: true,
            focusOnSelect: true,
            speed: 1000
        }
        const zoomLevel = HConfig.fnList.getConfig("zoomLevelProductPicture") || 3
        return(
            <div className="gallery sevenspikes-cloudzoom-gallery">
                <div className="picture-wrapper">
                    {
                        mainImg && 
                        <div className="picture" id="picture-container" data-zoom-window-width="400" data-zoom-window-height="360" onClick={()=>{
                            let _gallery = [];
                            let _currentIndex = 0;
                            if(thumbnails && thumbnails.length>0){
                                for(let img in thumbnails){
                                    if(thumbnails[img].ImageUrlOrigin == mainImg){
                                        _currentIndex = img
                                    }
                                    _gallery.push({original: thumbnails[img].ImageUrlOrigin, thumbnail: thumbnails[img].ImageUrlThumnail})
                                }
                            }
                            MyModal.Helpers.showImageGallery(_gallery,
                                {
                                    showIndex: true, 
                                    startIndex: parseInt(_currentIndex),
                                    renderCustomControls: ()=>this.renderClose()
                                },
                                {
                                    className: "sc_product_gallery mmd-image-gallery"
                                }
                            )
                        }}>
                            <div className="picture-link">
                                <MyLib type="imagezoom" zoom={zoomLevel} idImgZoom="zoom" imgOrigin={{
                                    id: "cloudzoom-img",
                                    className: "cloudzoom",
                                    src: mainImg
                                }}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="picture-thumbs in-carousel">
                    {
                        thumbnails && thumbnails.length>0 &&
                        <MyUI type="m_list_horizontal" data={thumbnails} config={configSlide} customComponent={(item)=>{
                            return(
                                <div key={new Date().getTime()} className="picture-thumbs-item" style={{width: "85px"}} onClick={()=>this.onChangeImg(item)}>
                                    <a className={`cloudzoom-gallery thumb-item${mainImg === item.ImageUrlOrigin ? " active": ""}`}>
                                        <img className="cloud-zoom-gallery-img" src={item.ImageUrlThumnail}/>
                                    </a>
                                </div>
                            )
                        }}/>
                    }
                </div>
            </div>
        )
    }
}
export default SC_ProductImages