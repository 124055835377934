import React from "react";
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import { MyModal } from "apps/Components";
import "./styles/sc_editor_upload_image.css";

class SC_EditorUploadImage extends React.Component {
  onClickUpload = () => {
    const {fnList, product} = this.props;
    MyModal.Helpers.showMyUI2("sc_modal_option_upload_image",{
      product: product,
      fnList: fnList
    },{
      size: "md",
      className: "mmui2",
      title: "Upload",
      closeButton: true
    });
  };
  render() {
    return (
      <div className="sc_editor_upload_image" onClick={()=>this.onClickUpload()}>
        <MyIconSvg
          type="plus"
          width={20}
          height={20}
          fill={"var(--color-secondary)"}
        />
      </div>
    );
  }
}
export default SC_EditorUploadImage;
