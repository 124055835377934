import React from "react";
import { MyUI, MyModal } from "../../../../Components";
import { HUtils } from "../../../../Helpers";
import { CButton } from "../../../../../components/MyCore";
import "./styles/sc_user_quickorder.css";

class SC_UserQuickOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      materialsList: [{ ...this.getDefaultMaterial() }],
    };
  }
  getDefaultMaterial = () => {
    return {
      MaterialId: null,
      MaterialCode: "TT",
      MaterialName: "",
      Quantity_Text: "",
    };
  };
  getListFromOptions = (key) => {
    const { options } = this.props;
    return HUtils.Obj.get(options, key, null);
  };
  checkHasValue = (value) => {
    if (value == undefined) {
      return false;
    } else if (value == null) {
      return false;
    } else if (value == "") {
      return false;
    }
    return true;
  };
  onChangeValue = (field, value, index) => {
    const { onChange } = this.props;
    let { materialsList } = this.state;
    materialsList[index][field] = value;
    if (this.checkHasValue(value) && index === materialsList.length - 1) {
      materialsList.push(this.getDefaultMaterial());
    }
    this.setState(
      {
        materialsList: materialsList,
      },
      () => {
        onChange && onChange(materialsList);
      }
    );
  };
  onDeleteItem = (index) => {
    const { onChange } = this.props;
    let { materialsList } = this.state;
    if (index == 0 && materialsList.length == 1) {
      MyModal.Helpers.showMsg("Đơn hàng phải tối thiểu 1 sản phẩm!", {
        title: "Thông báo",
      });
      materialsList[index] = this.getDefaultMaterial();
    } else {
      materialsList.splice(index, 1);
    }
    this.setState(
      {
        materialsList: materialsList,
      },
      () => {
        onChange && onChange(materialsList);
      }
    );
  };
  onSelectMaterial = (item, index) => {
    const { materialsList } = this.state;
    if (
      materialsList[index].MaterialId == item.Value &&
      materialsList[index].MaterialName == item.Text
    ) {
      materialsList[index] = this.getDefaultMaterial();
    } else {
      materialsList[index].MaterialId = item.Value;
      materialsList[index].MaterialName = item.Text;
    }
    if (index === materialsList.length - 1) {
      materialsList.push(this.getDefaultMaterial());
    }
    this.setState(
      {
        materialsList: materialsList,
      },
      () => {
        this.forceUpdate();
        MyModal.Helpers.hide();
      }
    );
  };
  openModalSelectMaterial = (index) => {
    const { materialsList } = this.state;
    const _availableMaterialList = this.getListFromOptions(
      "availableMaterialList"
    );
    console.warn("openModalSelectMaterial", this._modal);
    const _mt = materialsList[index];
    MyModal.Helpers.showMyUI(
      "sc_list",
      {
        data: _availableMaterialList,
        renderItem: (item, i) => {
          return (
            <div key={i} className="dFaCjSw100 item">
              <div>{item.Text}</div>
              <CButton
                className={`btn-select${
                  _mt.MaterialId === item.Value ? " active" : ""
                }`}
                onClick={() => this.onSelectMaterial(item, index)}
              >
                {_mt.MaterialId == item.Value ? (
                  <i className="fa fa-check-square" />
                ) : (
                  <i className="fa fa-square-o" />
                )}
              </CButton>
            </div>
          );
        },
      },
      {
        title: "Chọn hàng",
        showFooter: false,
        closeButton: true,
        ref: (r) => (this._modal = r),
      }
    );
  };
  renderItem = (item, index) => {
    if (item) {
      return (
        <div
          key={index}
          className="dFaEjSw100"
          style={{ marginBottom: "0.5rem" }}
        >
          <div className="dFfCaSjCw100" style={{ marginRight: 4 }}>
            {index === 0 && (
              <label style={{ fontWeight: "bold", color: "var(--color-text-bold)" }}>
                Tên sản phẩm
              </label>
            )}
            <div className="dFaCjSw100">
              <MyUI
                type="input_text"
                defaultValue={item.MaterialName}
                onBlur={(e, newValue) => {
                  this.onChangeValue("MaterialName", newValue, index);
                }}
              />
              <CButton
                color="primary"
                className="dFaCjCw100 btn-select-material"
                onClick={() => {
                  this.openModalSelectMaterial(index);
                }}
              >
                <span />
                {/* <i className="fa fa-search" /> */}
              </CButton>
            </div>
          </div>
          <div
            className="dFfCaSjCw100"
            style={{ marginRight: 4, width: "60%" }}
          >
            {index === 0 && (
              <label style={{ fontWeight: "bold", color: "var(--color-text-bold)" }}>
                Số lượng
              </label>
            )}
            <MyUI
              type="input_text"
              defaultValue={item.Quantity_Text}
              onBlur={(e, newValue) => {
                this.onChangeValue("Quantity_Text", newValue, index);
              }}
            />
          </div>

          <div className="dFaCjEw100 delete">
            <CButton
              color="dangerous"
              className="dFaCjCw100"
              style={{ height: "36px", width: "36px" }}
              onClick={() => {
                MyModal.Helpers.showConfirm(
                  "Bạn chắc chắn muốn xóa sản phẩm này?",
                  {
                    title: "Xác nhận",
                    onOK: (cModal, { fnClose }) => {
                      this.onDeleteItem(index);
                      fnClose && fnClose();
                    },
                  }
                );
              }}
            >
              <i className="fa fa-times" />
            </CButton>
          </div>
        </div>
      );
    }
  };
  renderMaterialList = () => {
    const { materialsList } = this.state;
    return materialsList.map((v, i) => {
      return this.renderItem(v, i);
    });
  };
  render() {
    return (
      <div className="sc_user_quickorder">
        <div className="section select-shipping-address">
          <div className="title">
            <strong>Hướng dẫn đặt hàng nhanh</strong>
          </div>
          <div className="address-grid" style={{ textAlign: "justify" }}>
            Để đặt hàng, bạn có thể nhập tên sản phẩm và số lượng muốn đặt, hoặc
            tìm kiếm những sản phẩm đang có tại Vườn nhé!
            Vườn sẽ tiếp nhận đơn và liên hệ với bạn trong thời gian sớm nhất.
            <br />
            Chân thành cảm ơn quý khách!
          </div>
        </div>
        <div className="section select-shipping-address">
          <div className="title">
            <strong>Chọn hàng</strong>
          </div>
          <div className="address-grid">{this.renderMaterialList()}</div>
        </div>
      </div>
    );
  }
}
export default SC_UserQuickOrder;
