import React from 'react'
import './styles/sc_card_category_in_search.css'

class SC_CardCategoryInSearch extends React.Component{
    render(){
        const {data} = this.props;
        if(data){
            const _Link  = data.Link;
            const _ImageUrl = data.ImageUrl;
            const _GrpMaterialName = data.GrpMaterialName;
            return(
                <div className="item-box sc_card_search_cat">
                    <a href={_Link} title={_GrpMaterialName}>
                        {
                            _ImageUrl && <img src={_ImageUrl}/>
                        }
                        {
                            _GrpMaterialName && <span>{_GrpMaterialName}</span>
                        }
                    </a>
                </div>
            )
        }
    }
}
export default SC_CardCategoryInSearch