import React from 'react'
import "./styles/spinner_dots.css"
import MessengerCustomerChat from '../../../libs/FacebookMessenger'
import HLocalStorage from '../../../helpers/LocalStorage'

class MyUI_FBChatMessenger extends React.Component {   
  constructor(props){
    super(props);
    this.state = {
      showFBChat: false
    }
  } 
  componentDidMount(){
    const {timeout} = this.props;
    if(timeout){
      setTimeout(() => {
        if(this.state.showFBChat==false){
          this.setState({
            showFBChat: true
          })
        }
      }, timeout);
    }
    else{
      this.setState({
        showFBChat: true
      })
    }
    
  }
  _getShouldShowDialog=()=>{
    let _fb_chat = HLocalStorage.getObject('__fb_chat_plugin');
    if(_fb_chat && _fb_chat.visibility=='hidden'){
      return false;
    }
    return false;
  }
  render(){
    const {pageId,appId,htmlRef} = this.props;
    if(this.state.showFBChat==true){
      return (
        <MessengerCustomerChat 
          pageId={pageId}
          appId={appId}
          htmlRef={htmlRef}
          shouldShowDialog={this._getShouldShowDialog()}
          greetingDialogDisplay="hide"
        />
      )
    }
    return (
      <div></div>      
    )
  } 
}

export default MyUI_FBChatMessenger