import React from 'react';
import {HConfig} from '../../../../Helpers'

class BlogPost extends React.Component{
    getFormatData=()=>{
        const {data} = this.props
        return {
            BlogUrl: data.Slug,
            Image: data?.Files_0?.Photos?.[0]?.FileUrl || HConfig.fnList.getConfig("imageProductDefault"),
            Description: data.BodyOverview || "Không có",
            Title: data.Title || 'Không có',
            CreatedDate_Text: data.CreatedDate_Text
        }
    }
    render(){
        const data = this.getFormatData();
        return(
            <div className="blog-post">
                <div className="post-picture">
                    <a href={data.BlogUrl}>
                        <img src={data.Image} alt={data.Title} title={data.Title}/>
                    </a>
                </div>
                <div className="post-details">
                    <span className="post-date">{data.CreatedDate_Text}</span>
                        <a href={data.BlogUrl} className="read-comments">0</a>
                    <h3 className="post-title">
                        <a href={data.BlogUrl}>{data.Title}</a>
                    </h3>
                    <div className="post-description">
                        <div dangerouslySetInnerHTML={{__html: data.Description}} style={{ height: "100%"}}></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BlogPost