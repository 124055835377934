import React from "react";
import "./styles/sc_modal_select_photos.css";
import { MyModal, MyUI } from "../../../../Components";
import { HApiAxios, HUtils, HUI } from "../../../../Helpers";
import { PaginationWithData } from "../../../../../libs/ReactPaginate/index";
import MyTableFilterData from "../../../../../components/MyTableFilter/MyTableFilterData";
import MyTableFilter from "../../../../../components/MyTableFilter/MyTableFilter";

class SCModalSelectPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      all: [],
      one: [],
      pageSize: 12,
      options: null,
      currentPage: 1,
    };
  }
  componentDidMount() {
    const { data, isBlog } = this.props;
    this.getOptions({
      cbSuccess: (response) => {
        this.getList({
          cbSuccess: (response) => {
            this.setState({
              all: HUtils.Obj.get(response, "Data.Data"),
              extraDataInListAll: HUtils.Obj.get(response, "Data.ExtraData"),
              ...(isBlog === true && { tabIndex: 0 }),
            });
          },
        });
      },
    });
    if (data && isBlog !== true) {
      this.getList({
        customQuery: {
          MaterialId: data.MaterialId,
        },
        cbSuccess: (response) => {
          this.setState({
            one: HUtils.Obj.get(response, "Data.Data"),
          });
        },
      });
    }
  }
  getOptions = ({ cbSuccess } = {}) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        HApiAxios.generic({
          request: {
            method: "POST",
            url: "Website_File_Mobile/Options",
          },
          data: {},
          successCallBack: (response) => {
            this.setState({
              options: response.Data,
              isLoading: false,
            });
            cbSuccess && cbSuccess(response);
          },
          errorCallBack: (error, response) => {
            console.warn("SCModalSelectPhotos error", error, response);
            this.setState({
              isLoading: false,
            });
          },
        });
      }
    );
  };
  getList = ({ customQuery, cbSuccess } = {}) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        HApiAxios.generic({
          request: {
            method: "POST",
            url: "Website_File_Mobile/List",
          },
          data: customQuery,
          successCallBack: (response) => {
            this.setState({
              currentPage: 1,
              isLoading: false,
            });
            cbSuccess && cbSuccess(response);
          },
          errorCallBack: (error, response) => {
            console.warn("SCModalSelectPhotos error", error, response);
            this.setState({
              isLoading: false,
            });
          },
        });
      }
    );
  };
  getConfigFromScreenConfig = (key) => {
    let screenConfig = HUtils.Obj.get(
      this.state.options,
      "Configs.ScreenConfig"
    );
    if (screenConfig && typeof screenConfig === "string") {
      try {
        screenConfig = JSON.parse(screenConfig);
      } catch (error) {
        console.warn("parse error", error);
      }
    }
    if (screenConfig && screenConfig[key]) {
      return screenConfig[key];
    }
    return null;
  };
  onChangeTab = (index) => {
    this.setState({
      tabIndex: index,
    });
  };
  onSelectOneImage = (_item) => {
    let { all, one, tabIndex } = this.state;
    // data[index].selected = !data[index].selected;
    if (tabIndex === 0) {
      for (let i = 0; i < all.length; i++) {
        let item = all[i];
        if (item.Id !== _item.Id && item.selected === true) {
          item.selected = false;
        } else if (item.Id === _item.Id) {
          item.selected = !item.selected;
        }
      }
      this.setState({
        all: all,
      });
    } else if (tabIndex === 1) {
      for (let i = 0; i < one.length; i++) {
        let item = one[i];
        if (item.Id !== _item.Id) {
          item.selected = false;
        } else {
          item.selected = !item.selected;
        }
      }
      this.setState({
        one: one,
      });
    }
  };
  onSelectMultiImage = (_item) => {
    let { all, one, tabIndex } = this.state;
    // data[index].selected = !data[index].selected;
    if (tabIndex === 0) {
      for (let i = 0; i < all.length; i++) {
        let item = all[i];
        if (item.Id === _item.Id) {
          item.selected = !item.selected;
        }
      }
      this.setState({
        all: all,
      });
    } else if (tabIndex === 1) {
      for (let i = 0; i < one.length; i++) {
        let item = one[i];
        if (item.Id == _item.Id) {
          item.selected = !item.selected;
        }
      }
      this.setState({
        one: one,
      });
    }
  };
  onSelectImage = (_item) => {
    const { multipleSelect } = this.props;
    if (multipleSelect) {
      this.onSelectMultiImage(_item);
    } else {
      this.onSelectOneImage(_item);
    }
  };
  onSubmit = () => {
    const { submit } = this.props;
    const { all, one, tabIndex } = this.state;
    let _arr = [];
    let data = tabIndex === 0 ? all : tabIndex === 1 ? one : [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.selected === true) {
          let _img =
            item.ImageUrlCompressLink ||
            item.ImageUrlWaterMarkLink ||
            item.ImageUrlOriginLink;
          if (_img) {
            _arr.push(_img);
          } else {
            HUI.Toast.showWarn("Không có ảnh nào được chọn!");
          }
        }
      }
      submit && submit(_arr);
    }
    this.closeModal();
  };
  closeModal = () => {
    const { fnFomModal } = this.props;
    fnFomModal && fnFomModal.fnClose && fnFomModal.fnClose();
  };
  getDataWithTab = () => {
    const { tabIndex, all, one } = this.state;
    if (tabIndex === 0) {
      return all;
    }
    if (tabIndex === 1) {
      return one;
    }
  };
  renderLoading = () => {
    return (
      <div
        className="content"
        style={{
          padding: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MyUI type="spinner_dots" />
      </div>
    );
  };
  renderTabs = () => {
    const { data, isBlog } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className="tab-header">
        {isBlog !== true && (
          <div
            className={`label ${tabIndex == 1 ? "" : "inactive"}`}
            onClick={() => this.onChangeTab(1)}
          >{`Hình của ${
            HUtils.Obj.get(data, "MaterialName") || "sản phẩm"
          }`}</div>
        )}
        <div
          className={`label ${tabIndex == 0 ? "" : "inactive"}`}
          onClick={() => this.onChangeTab(0)}
        >
          Tất cả ảnh
        </div>
      </div>
    );
  };
  renderContents = (data) => {
    const { tabIndex } = this.state;
    return (
      <div className="content">
        <div>
          {data &&
            data.map((v, i) => {
              return (
                <div
                  className="item"
                  key={`${i}-${tabIndex}`}
                  onClick={() => {
                    if (
                      !v.ImageUrlCompressLink &&
                      !v.ImageUrlWaterMarkLink &&
                      !v.ImageUrlOriginLink
                    ) {
                      HUI.Toast.showWarn("Ảnh bạn chọn không có link!");
                    } else {
                      this.onSelectImage(v);
                    }
                  }}
                >
                  <img
                    src={v.ImageUrlThumbnail}
                    // alt="Hình ảnh"
                    // title="Hình ảnh"
                  />
                  {v.selected && (
                    <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                      <button className="btn select">
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  renderTabOne = () => {
    const { isLoading, pageSize } = this.state;
    const data = this.getDataWithTab();
    return (
      <div>
        {!isLoading && data?.length > 0 && (
          <PaginationWithData
            containerClassName={"sc_modal_pagination_style"}
            sizePerPage={pageSize}
            data={data}
            hideWhenOnePage={true}
          >
            {({ dataPagination }) => {
              return this.renderContents(dataPagination);
            }}
          </PaginationWithData>
        )}
        {isLoading && this.renderLoading()}
        {!isLoading && data?.length == 0 && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              padding: 30,
              border: "1px solid #f0f0f0",
            }}
          >
            Không có dữ liệu!
          </div>
        )}
      </div>
    );
  };
  renderFilter = () => {
    // console.warn("this.state.options", this.state.options);
    const { options } = this.state;
    if (options) {
      return (
        <MyUI
          type="page_filter"
          dataList={{
            Data: this.state.all,
            ExtraData: this.state.extraDataInListAll,
          }}
          options={this.state.options}
          getConfigFromScreenConfig={this.getConfigFromScreenConfig}
          onUpdateCurrentFilter={(filter) => {
            this._currentFilter = filter;
            this.forceUpdate();
          }}
          fnList={{
            requestList: ({ filterQuery } = {}) => {
              this.getList({
                customQuery: filterQuery,
                cbSuccess: (response) => {
                  this.setState({
                    all: HUtils.Obj.get(response, "Data.Data"),
                    extraDataInListAll: HUtils.Obj.get(
                      response,
                      "Data.ExtraData"
                    ),
                  });
                },
              });
            },
          }}
        />
      );
    }
    return <div></div>;
  };
  renderTabAll = () => {
    const { pageSize, isLoading } = this.state;
    let { currentPage } = this.state;
    return (
      <div>
        {this.renderFilter()}
        {!isLoading && (
          <MyTableFilterData
            data={this.getDataWithTab()}
            currentFilter={this._currentFilter}
          >
            {({ dataFiltered }) => {
              if (dataFiltered.length < pageSize * (currentPage - 1)) {
                currentPage = 1;
              }
              return (
                <PaginationWithData
                  containerClassName={"sc_modal_pagination_style"}
                  sizePerPage={pageSize}
                  data={dataFiltered}
                  hideWhenOnePage={true}
                  forcePage={currentPage}
                  onPageChange={(crp) => {
                    this.setState({
                      currentPage: crp,
                    });
                  }}
                >
                  {({ dataPagination }) => {
                    return this.renderContents(dataPagination);
                  }}
                </PaginationWithData>
              );
            }}
          </MyTableFilterData>
        )}
        {isLoading && this.renderLoading()}
      </div>
    );
  };
  renderFooter = () => {
    const { textSubmit } = this.props;
    return (
      <div className="footer">
        <button className="btn cancel" onClick={() => this.closeModal()}>
          Hủy
        </button>
        <button className="btn insert" onClick={() => this.onSubmit()}>
          {textSubmit}
        </button>
      </div>
    );
  };
  render() {
    const { tabIndex } = this.state;
    const { isBlog } = this.props;
    return (
      <div className="sc_modal_select_photos">
        {this.renderTabs()}
        {isBlog !== true && tabIndex === 1 && this.renderTabOne()}
        {tabIndex === 0 && this.renderTabAll()}
        {this.renderFooter()}
      </div>
    );
  }
}
export default SCModalSelectPhotos;
