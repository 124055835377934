import React from 'react'
import {MyUI} from '../../../../Components'
import {HStoreRedux, HText} from '../../../../Helpers'

class SC_CategotyLeft extends React.Component{
    render(){
        const {data, tagList, fnList, filter} = this.props;
        const _productRecent = HStoreRedux.getState().shop.recent;
        return(
            <div className="side-2">
                {
                    data && data.GrpMaterialName &&
                    <div className="page-title">
                        <h1>{data && data.GrpMaterialName}</h1>
                    </div>
                }
                
                {
                    // HConfig.fnList.getIsDevMode() &&
                    <MyUI type="sc_filter" fnList={fnList} defaultValue={filter}/>
                }
                {
                    _productRecent && _productRecent.length>0 &&
                    <div className="block block-recently-viewed-products">
                        <div className="title">
                            <strong>{HText.get("text_recent_product")}</strong>
                        </div>
                        <div className="listbox" style={{display: 'block'}}>
                            <ul className="list">
                                {
                                    _productRecent.map((v, i)=>{
                                        return <MyUI type="sc_card_product_recent" data={v} key={i}/>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                }
                {
                    // HConfig.fnList.getIsDevMode() == true &&
                    <MyUI type="sc_tags" data={tagList}/>
                }
            </div>
        )
    }
}
export default SC_CategotyLeft