import React from 'react'
import {
  CCard,
} from '../../MyCore'

class MyUI_Box extends React.Component {    
  render(){
    const {style} = this.props;
    return (
      <CCard style={{padding:'2px',...style}}>
        {this.props.children}
      </CCard>
    )
  } 
}

export default MyUI_Box