import React from 'react'
import { MyUI } from 'apps/Components'
import './styles/sc_input_radio.css'

class SC_InputRadio extends React.Component{
    onChangeValue = (value) =>{
        const {onChange} = this.props;
        onChange && onChange(value)
    }
    render(){
        const {label, value} = this.props;
        return(
            <div className="sc_input_radio" onClick={()=>this.onChangeValue(value)}>
                <MyUI 
                    type="input_radio"  
                    style={{position: "relative", cursor: "pointer"}} 
                    defaultValue={value} 
                    onChange={()=>this.onChangeValue(value)}/>
                <label className="sc_input_radio_label">{label}</label>
            </div>
        )
    }
}
export default SC_InputRadio