import React from "react";
import { MyModal, MyUI } from "../../../../Components";
import { HLink, HConfig } from "../../../../Helpers";
import Popover from "../../../../../components/MyUI/Types/popover";
class SC_EditorProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    const { data } = this.props;
    this.setState({
      data: data,
    });
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.data != nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }
    return true;
  }
  onOpenImage = (imgLink, { opts } = {}) => {
    if (imgLink) {
      const { fnFomModal } = opts;
      HLink.push(imgLink, true);
      fnFomModal?.fnClose && fnFomModal.fnClose();
    }
  };
  onChangeRepresentativePhoto = (item, { opts } = {}) => {
    const { fnList } = this.props;
    MyModal.Helpers.showConfirm(
      `Bạn có chắc muốn đổi hình này thành hình đại diện?`,
      {
        title: "Xác nhận",
        onOK: (cModal, { fnClose }) => {
          const { fnFomModal } = opts;
          fnList?.fnRequestAvatar && fnList.fnRequestAvatar(item);
          fnClose && fnClose();
          fnFomModal?.fnClose && fnFomModal.fnClose();
        },
      }
    );
  };
  onDeleteImage = (item, { opts } = {}) => {
    const { fnList } = this.props;
    MyModal.Helpers.showConfirm(`Bạn có chắc muốn xóa hình này?`, {
      title: "Xác nhận",
      onOK: (cModal, { fnClose }) => {
        const { fnFomModal } = opts;
        fnList?.fnRequestDelete && fnList.fnRequestDelete(item);
        fnClose && fnClose();
        fnFomModal?.fnClose && fnFomModal.fnClose();
      },
    });
  };
  onChangeOrderImage = (field, value, item) => {
    const { fnList } = this.props;
    fnList?.fnOnChangeOrderImage &&
      fnList.fnOnChangeOrderImage(field, value, item);
  };
  onChangeProductOption = (value) => {
    const { fnList } = this.props;
    fnList?.fnOnChangeImageAttribute &&
      fnList.fnOnChangeImageAttribute("productAttribute", value, {
        cbSuccess: (newData) => {
          this.setState({
            data: newData,
          });
        },
      });
  };
  renderAction = (item, { fnFomModal } = {}) => {
    if (item) {
      const _ImageUrlOrigin = item.ImageUrlOrigin;
      const _ShowOnHomePage = item.ShowOnHomePage;
      return (
        <div className="dFaCjCw100">
          <div
            className="btn-external"
            title="Mở ảnh"
            onClick={() => {
              this.onOpenImage(_ImageUrlOrigin, { opts: { fnFomModal } });
            }}
          >
            <i className="fa fa-external-link-square" />
          </div>
          {_ShowOnHomePage !== true && (
            <div
              className="btn-avatar"
              title="Thay hình đại diện"
              onClick={() =>
                this.onChangeRepresentativePhoto(item, { opts: { fnFomModal } })
              }
            >
              <i className="fa fa-picture-o" />
            </div>
          )}
          <div
            className="btn-delete"
            title="Xóa hình"
            onClick={() => this.onDeleteImage(item, { opts: { fnFomModal } })}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      );
    }
    return <div></div>;
  };
  renderUIDefault = (item) => {
    if (item) {
      const _ShowOnHomePage = item.ShowOnHomePage;
      const _ImageUrlOrigin = item.ImageUrlOrigin;
      return (
        <div>
          {_ShowOnHomePage !== true && (
            <div
              title="Thay hình đại diện"
              className="btn-avatar"
              style={{
                position: "absolute",
                right: "0px",
                bottom: "5px",
              }}
              onClick={() => this.onChangeRepresentativePhoto(item)}
            >
              <i className="fa fa-picture-o"></i>
            </div>
          )}
          {_ShowOnHomePage !== true && (
            <div
              title="Xóa hình"
              className="btn-delete"
              style={{
                position: "absolute",
                right: "0px",
                top: "0px",
              }}
              onClick={() => this.onDeleteImage(item)}
            >
              <i className="fa fa-times"></i>
            </div>
          )}
          {_ImageUrlOrigin && (
            <div
              title="Mở ảnh"
              className="btn-external"
              style={{
                position: "absolute",
                left: "0px",
                bottom: "5px",
              }}
              onClick={() => this.onOpenImage(_ImageUrlOrigin)}
            >
              <i className="fa fa-external-link-square"></i>
            </div>
          )}
        </div>
      );
    }
    return <div></div>;
  };
  renderUIType1WithOptions = (item) => {
    if (item) {
      const { optionsImage } = this.props;
      const _ShowOnHomePage = item.ShowOnHomePage;
      return (
        <div
          className="btn-fnc"
          title="Chức năng"
          onClick={() => {
            MyModal.Helpers.showMyUI2(
              "sc_modal_editor_attribute_image_product",
              {
                data: item,
                optionsImage: optionsImage,
                onChangeOrderImage: this.onChangeOrderImage,
                onChangeProductOption: this.onChangeProductOption,
                renderAction: this.renderAction,
              },
              {
                title: "Chỉnh sửa",
                size: "md",
                className: "md-img-fnc",
                showHeader: false,
              }
            );
          }}
          style={_ShowOnHomePage ? { background: "var(--color-active)" } : {}}
        >
          <i className="fa fa-ellipsis-h"></i>
        </div>
      );
    }
    return <div></div>;
  };
  renderUIType1Default = (item) => {
    if (item) {
      const _ShowOnHomePage = item.ShowOnHomePage;
      return (
        <Popover
          type="popover"
          advancedOptions={{ className: "imgFn-editor" }}
          overlay={this.renderAction(item)}
        >
          <div
            className="btn-fnc"
            title="Chức năng"
            style={_ShowOnHomePage ? { background: "var(--color-active)" } : {}}
          >
            <i className="fa fa-ellipsis-h"></i>
          </div>
        </Popover>
      );
    }
    return <div></div>;
  };
  renderUIType1 = (item) => {
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
    if (_haveProductOptions) {
      return this.renderUIType1WithOptions(item);
    }
    return this.renderUIType1Default(item);
  };
  render() {
    const { uiType } = this.props;
    const { data } = this.state;
    switch (uiType) {
      case 1:
        return this.renderUIType1(data);
      default:
        return this.renderUIDefault(data);
    }
  }
}
export default SC_EditorProductImage;
