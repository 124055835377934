import React from 'react'
import { MyUI } from 'apps/Components'
import { HText } from 'apps/Helpers'

class sc_product_grid extends React.Component{
    render(){
        const {data, cardType} = this.props;
        if(data?.length>0){
            return(
                <div className="product-grid" style={{position: "relative"}}>
                    <div className="item-grid layout-grid-category">
                        {data.map((product,index)=>{
                            let _cardType = "sc_card_product";
                            let _dataCard = product;
                            if(product.Type){
                                switch (product.Type) {
                                    case "GrpMaterial":
                                      _cardType = "sc_card_category_in_search";
                                      break;
                                    case "Material":
                                      _cardType = "sc_card_product";
                                      break;
                                    case "BlogPost":
                                      _cardType = "sc_card_blog_in_search";
                                      break;
                                    default:
                                      break;
                                  }
                            }
                            if(product.Data){
                                _dataCard = product.Data;
                            }
                            return <MyUI type={_cardType} key={index} data={_dataCard}/>
                        })} 
                    </div>
                </div>
            )
        }
        return(<div className="no-data">{HText.get("text_category_no_items")}</div>)
    }
}
export default sc_product_grid