import React from "react";
import {
  MyTableFilterData,
  MyTableFilter,
  PaginationWithData,
} from "../../../../Components";
import "./styles/sc_list.css";

class SC_List extends React.Component {
  render() {
    const { data, renderItem } = this.props;
    if (data) {
      return (
        <div className="sc_list">
          <MyTableFilter
            contents={[
              {
                type: "search",
                more: '{"ui":{"showSuffix":true, "showPre":false}, "search":{"instant":true, "nosymbol": true}, "placeholder": "Tìm kiếm"}',
              },
            ]}
            dataList={data}
            onChangeFilter={(filter) => {
              this._currentFilter = filter;
              this.forceUpdate();
            }}
          />
          <MyTableFilterData data={data} currentFilter={this._currentFilter}>
            {({ dataFiltered }) => {
              return (
                <PaginationWithData
                  sizePerPage={20}
                  data={dataFiltered}
                  className="sc_modal_pagination_style"
                  hideWhenOnePage
                >
                  {({ dataPagination }) => {
                    return dataPagination.map((v, i) => {
                      if (renderItem) {
                        return renderItem(v, i);
                      }
                    });
                  }}
                </PaginationWithData>
              );
            }}
          </MyTableFilterData>
        </div>
      );
    }
  }
}
export default SC_List;
