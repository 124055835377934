import React from 'react'
import {
  TheFooter,
  TheHeader,
  TheContent
} from './index'
import {
  Redirect,
} from 'react-router-dom'
import {MyUI} from '../../Components'
import {HApiAxios, HStoreRedux, HConfig,HLocalStorage} from '../../Helpers'
import HGA from '../../../helpers/GA'
import FB_ChatMessenger from '../../../components/MyUI/TypesMore/fb_chatmessenger';
import MyUI_ZaloChat from 'components/MyUI/TypesMore/zalo_chat'
import {connect} from 'react-redux'

class TheLayout extends React.Component {
  componentWillUnmount(){
    document.removeEventListener("resize", this.handleUIResize)
  }
  componentDidMount(){
    this.requestDataLayout();
    this.requestUpdateCart();
    this.handleUIResize();
    HGA.trackingPageView({
      page: window.location.pathname + window.location.search,
      hideUser: true
    })
    window.addEventListener("resize", this.handleUIResize)
  }
  requestDataLayout=()=>{
    HApiAxios.generic({
      request: {
        method: 'POST',
        path: 'DashboardForWebsite',
        name: 'GetMenuPage'
      },
      data: {},
      successCallBack: (response)=>{
        if(response.Data){
          const _dataLayout = response.Data;
          if(_dataLayout){
            const _menus = _dataLayout.Menu;
            const _header = _dataLayout.Header;
            const _footer = _dataLayout.Footer;
            const _adminButtons = _dataLayout.Admin;
            const _banner = _dataLayout.Banner;
            const _optionList = _dataLayout.OptionList;
            const _appLogo = _dataLayout.AppLogo;
            const _userInfo = _dataLayout.UserInfo;
            const _socialItem = _dataLayout.SocialItem;
            let _config = _dataLayout.Config; 
            let _objUpdate = {
              isLoadedGetMenuPage: true
            };
            if(_menus){ _objUpdate.categoryMenu= _menus; }
            if(_header){ _objUpdate.headerLink= _header; }
            if(_adminButtons){ _objUpdate.adminButtons= _adminButtons; }
            if(_footer){ _objUpdate.footerLink = _footer; }
            if(_banner){_objUpdate.banner= _banner;}
            if(_appLogo){_objUpdate.appLogo = _appLogo}
            if(typeof(_config) === "string"){
              try {
                _config = JSON.parse(_config);
                _objUpdate.config = _config;

              } catch (error) {
                console.log("Parse error", error);
              }              
            }
            if(_optionList){
              _objUpdate.optionList = _optionList;
              const _appName = HConfig.fnList.getConfig("appName");
              let _dOpt = {};
              Object.keys(_optionList).forEach((v)=>{
                if(Array.isArray(_optionList[v])){
                  let _hasDefault = false;
                  _optionList[v]?.length> 0 && 
                  _optionList[v].forEach((a, i)=>{
                    if(
                      typeof a === "object" && 
                      !Array.isArray(a) &&
                      a.hasOwnProperty("IsDefault") && 
                      a.hasOwnProperty("Value") &&
                      a.hasOwnProperty("Text_Filter") &&
                      a.hasOwnProperty("Text")){
                        if(a.IsDefault === true){
                          _dOpt[v] = a.Value;
                          _hasDefault = true
                        }
                        if(
                          i === _optionList[v].length - 1 && 
                          _hasDefault === false){
                            _dOpt[v] = _optionList[v][0].Value
                        }
                      }
                      
                  })
                  
                }
              })
              HLocalStorage.setObject(`${_appName}_defaultOptions`, _dOpt)
            }
            if (_userInfo) {
              _objUpdate.userInfo = _userInfo;
            }
            if (_socialItem) {
              _objUpdate.socialItem = _socialItem;
            }
            console.warn("dispatch", _objUpdate);              
            HStoreRedux.dispatch({type: 'setObjInShop', ..._objUpdate})
          }
        }
      },
      errorCallBack: (error, response)=>{
      }
    })
  }
  requestUpdateCart = () => {
    const { cartProducts } = this.props;
    if (cartProducts?.length > 0) {
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "DashboardForWebsite/GetListMaterial4Website",
        },
        data: {
          IsGetDataSimple: true,
          MaterialId: this.getCurrentCartIds(),
          PageSize: cartProducts.length
        },
        successCallBack: (response) => {
          const dataUpdate = response?.Data?.Data;
          if (dataUpdate) {
            let _cart = HLocalStorage.getObject("cart", []);
            let _cartUpdate = []
            _cart.forEach((v) => {
              dataUpdate.forEach((a) => {
                if (a.MaterialId === v.MaterialId) {
                  // Trong trang cart lúc đầu có bổ sung 1 số field ở client tiện cho việc sử dụng tính toán so sánh nên khi update cũng phải tính lại luôn
                  // Gồm có [_Discount_Text, _QuantityMin, _SellPriceDiscount, _SellPriceOriginal] được lấy từ Discount Info
                  let _newDiscountInfo = a.DiscountInfo;
                  let _oldDiscountInfo = v.DiscountInfo;
                  let _itm = {
                    ...v,
                    ...a
                  }
                  if (_newDiscountInfo) {
                    if (
                      _newDiscountInfo.DiscountMoney !==
                        _oldDiscountInfo?.DiscountMoney ||
                      _newDiscountInfo.QuantityMin !==
                        _oldDiscountInfo?.QuantityMin ||
                      _newDiscountInfo.Discount_Text !==
                        _oldDiscountInfo?.Discount_Text
                    ) {
                      _itm._Discount_Text = _newDiscountInfo.Discount_Text;
                      _itm._QuantityMin = _newDiscountInfo.QuantityMin;
                      _itm._SellPriceDiscount = _itm.SellPrice - _newDiscountInfo.DiscountMoney;
                      _itm._SellPriceOriginal = _itm.SellPrice;
                    }
                  }
                  else {
                    delete (_itm._Discount_Text);
                    delete (_itm._QuantityMin);
                    delete (_itm._SellPriceDiscount);
                    delete (_itm._SellPriceOriginal);
                  }
                    _cartUpdate.push(_itm);
                }
              })
            })
            HStoreRedux.dispatch({type: 'setObjInShop', cartProducts: _cartUpdate})
            HLocalStorage.setObject("cart", _cartUpdate);
          }
         },
        errorCallBack: (error, response) => { }
      })
    }
  }
  getCurrentCartIds = () => {
    const { cartProducts } = this.props;
    if (cartProducts?.length > 0) {
      let _ids = "";
      let _space = ",";
      cartProducts.forEach((v, i) => {
        if (v.MaterialId) {
          if (i === cartProducts.length - 1) {
            _space = ""
          }
          _ids += v.MaterialId + _space
        }
      })
      return _ids;
    }
    return ""
  }
  handleUIResize = () =>{
    this.changeUiHeader()
  }
  changeUiHeader = () =>{
    const header_notify = document.getElementById("header-notify");
    if (header_notify) {
      if (header_notify.scrollWidth > header_notify.offsetWidth) {
        const textElm = document.getElementById("txt-notify");
        if (textElm) {
          const styleSheet = document.styleSheets[0];
          const keyframes = `@-webkit-keyframes scroll-horizontal {
            0% { 
              -moz-transform: translateX(100%);
              -webkit-transform: translateX(100%);
              }
              100% { 
              -moz-transform: translateX(-${textElm.offsetWidth}px);
              -webkit-transform: translateX(-${textElm.offsetWidth}px);
              }
          }`;
          styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        }

        header_notify.classList.add("scroll");
      } else {
        header_notify.classList.remove("scroll");
      }
    }
  }
  render(){
    const {config} = this.props;
    const overlayseason = config?.overlaySeason;
    const isShowStopWorking = HConfig.fnList.getConfig("temporarilyStopWorking");
    if(isShowStopWorking){
      return <Redirect to={HConfig.fnList.getPathHomePageLogged(this.props, '/temporarily-stop-working')}/>
    }
    return (
        <div className="c-app c-default-layout">
          {
            overlayseason && HConfig.fnList.getIsDevMode() == true &&
            <MyUI type="myui_overlay_season" configOverlay={overlayseason}/>
          }
          <div className="c-wrapper">
            <TheHeader afterRender={()=>this.handleUIResize()}/>
            <div className="c-body">
              <TheContent/>
            </div>
            <TheFooter/>
          </div>
          <MyUI type="sc_gotop"/>
          <FB_ChatMessenger 
            pageId={`${HConfig.fnList.getConfig('facebookPageId')}`}
            appId={`${HConfig.fnList.getConfig('facebookAppId')}`}
            htmlRef={HConfig.fnList.getConfig('appName')}
            timeout={3000}
          />
          <MyUI_ZaloChat oaid={ HConfig.fnList.getConfig("zaloOAID")}/>
        </div>
      )
  }
}
const mapStateToProps = state =>{
  return{
    config: state.shop.config,
    cartProducts: state.shop.cartProducts
  }
}
export default connect(mapStateToProps,null)(TheLayout)