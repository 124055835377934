import { createStore, combineReducers} from 'redux';

const initialState = {
  home: null,  
  headerLink: [
    // {Title:"Blog",Link:"http://google.com"},
    // {Title:"Liên hệ với vườn",Link:"http://google.com"},
  ],
  footerLink: [
    // {
    //   Title: "Thông tin",
    //   Data:[
    //     {Title:"Về chúng tôi",Link:"/"},
    //     {Title:"Giao hàng",Link:"/"},
    //   ]      
    // },
    // {
    //   Title: "Dịch vụ khách hàng",
    //   Data:[
    //     {Title:"Câu hỏi thường gặp",Link:"/"},
    //     {Title:"Trợ giúp",Link:"/"},
    //     {Title:"Privacy policy",Link:"/policy"},
    //   ]      
    // },
    // {
    //   Title: "Tài khoản của tôi",
    //   Data:[
    //     {Title:"Thông tin khách hàng",Link:"/"},
    //     {Title:"Địa chỉ",Link:"/"},
    //     {Title:"Đơn đặt hàng",Link:"/"},
    //     {Title:"Giỏ hàng",Link:"/"},
    //     {Title:"Yêu thích",Link:"/wishlist"},
    //   ]      
    // },
    // {
    //   Title: "Contact us",
    //   Data:[
    //     {Title:"31-33 Nguyễn Văn Đậu, Bình Thạnh, TP. HCM",Icon:""},
    //     {Title:"Ajumagarden@gmail.com",Icon:""},
    //     {Title:"0978487735",Icon:""},
    //   ]      
    // },
  ],
  categoryMenu: [
    // {Title:"Rau củ quả",Link:"http://google.com"},
    // {Title:"Trái cây",Link:"http://google.com"},
    // {Title:"Hoa tươi",Link:"http://google.com"},
    // {Title:"Mật ong",Link:"http://google.com"},
    // {Title:"Nước ép",Link:"http://google.com"},
    // {Title:"Đồ khô",Link:"http://google.com"},
    // {Title:"Thực phẩm chế biến",Link:"http://google.com"},
  ],
  cartProducts: [],
  wishlist: [],
  recent: [],
  total: 0,
  totalAfterDiscount: 0,
  adminButtons:null,
  isLoadedGetMenuPage: false,
  banner: [],
  config: null,
  optionList: null,
  userInfo: null,
  socialItem: null
}

export const shop = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'setObjInShop':
      if(rest["cartProducts"]){
        let _sum = 0;
        let _sumAfterDiscount = 0;
        if (rest?.cartProducts?.length > 0) {
          const cat = rest.cartProducts;
          for (let i = 0; i < cat.length; i++) {
            const item = cat[i];
            const discount_inf = item.DiscountInfo;
            _sum += parseFloat(item.SellPrice) * parseFloat(item.Quantity); // Can chinh lai tam thoi them totalAfterDiscount de k bi anh huong nhung cho khac
            if (
              discount_inf?.QuantityMin < 1 ||
              item.Quantity >= discount_inf?.QuantityMin
            ) {
              _sumAfterDiscount +=
                item.Quantity * (item.SellPrice - discount_inf?.DiscountMoney);
            } else {
              _sumAfterDiscount += parseFloat(item.SellPrice) * parseFloat(item.Quantity);
            }
          }
        }
        // console.warn("_sum, _sumAfterDiscount: ", _sum, _sumAfterDiscount);
        state.total = _sum;
        state.totalAfterDiscount = _sumAfterDiscount;
      }
      return {...state, ...rest }
    default:
      return state
  }
}