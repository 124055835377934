import React from 'react'
import {MyLoadingApp} from '../../../apps/Components'
import {SApiAxios} from '../../../apps/Services'
import {HUtils, HLocalStorage} from '../../../apps/Helpers'
import HUI from '../../../helpers/UI'
import HText from '../../../helpers/Text'
import HFilter from '../../../helpers/Filter';
import HConstant from '../../../helpers/Constant';

import debounce from '../../../libs/Lodash/debounce';
import ResizeObserver from '../../../libs/ResizeObserverPolyfill/ResizeObserver';

class PageExt extends React.Component {  
  constructor(props){
    super(props);
    this.state={
      response: null,
      options: null,
      list: null,
      screenConfig: null,
      dataFiltered: null,
    };
    this.initPage();
    this._sharedFnList = {      
      fnGetProjectId: this.getProjectId,
      fnGetParamsURL: this.getParamsURL,
      fnGetScreenCode: this.getScreenCode,
      fnGetOptions: this.getOptions,
      fnGetApiController: this.getApiController,
      fnGetRequestData: this.getRequestData,
      fnGetListObj: this.getListObject,
      fnGetListData: this.getListData,
      fnGetListExtraData: this.getListExtraData,
      fnGetConfigFilter: this.getConfigFilter,
      fnGetCanEditOfField: this.getCanEditOfField,
      fnGetCanShowOfField: this.getCanShowOfField,
      fnRequestList: this.requestList,
      fnGetConfigFromScreenConfig: this.getConfigFromScreenConfig,
      fnGetConfigFromConfigPageByKey: this.getConfigFromConfigPageByKey,
      fnRequestUpdate: (row, fieldName, cellValue, opts)=>{//fnUpdateCell tu version solid
        // console.warn("fnRequestUpdate:",row,fieldName,cellValue);
        this.requestUpdateField({
          row: row,
          requestData:{
            Id: row.Id,
            Values: [
              {FieldName: fieldName, NewValue: cellValue}
            ]
          },
          opts: opts
        })                              
      },
    }
  }
  initPage=()=>{
    this.state.pageLoading = true;
  }
  componentWillUnmount(){
    if(this._ro){
      this._ro.disconnect();
    }
  } 
  initObserver=()=>{
    this._ro = new ResizeObserver((entries) => {
      // console.warn("rorororo",entries);
      // for (let entry of entries) {
      //   const cr = entry.contentRect;
      //   console.log('Element:', entry.target);
      //   console.log(`Element size: ${cr.width}px x ${cr.height}px`);
      //   console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
      // }
      this.onWindowResized();
    });
    this._ro.observe(document.body);
  }
  onWindowResized = () => {
    if (this._debouncedResize) this._debouncedResize.cancel();
    this._debouncedResize = debounce(() => {
      this.resizeWindow()
    },200);
    this._debouncedResize();
  };
  resizeWindow=()=>{
    this.forceUpdate();
  }
  getParamsURL=()=>{
    const {match} = this.props;
    return HUtils.Obj.get(match,'params');
  }
  setTitle=(value)=>{
    const {configPage,setTitle} = this.props;
    const _header = this.getConfigFromScreenConfig("Header");
    const _title = _header && _header.title;
    let _newTitle = "";
    if(value){
      _newTitle = value;
    }
    else if(_title){
      _newTitle = _title
    }
    else if(configPage && configPage.Title){
      _newTitle = configPage.Title
    }
    if(setTitle){
      setTitle(_newTitle)
    }  
    document.title = _newTitle  
  }
  getOptions=()=>{
    return this.state.options;
  }
  getListObject=()=>{
    return this.state.list;
  }
  getListData=()=>{
    const list = this.getListObject();
    if(list && list.Data){
      return  list.Data;
    }
    return null;
  }
  getListExtraData=()=>{
    const list = this.getListObject();
    if(list && list.ExtraData){
      return  list.ExtraData;
    }
    return null;
  }
  getFromResponseByKey=(key)=>{
    const {response} = this.state;
    if(response && response[key]){
      return response[key]
    }
    return null;
  }
  getExtraOfFieldByKey=(fieldName, key)=>{
    const _extraData = this.getListExtraData();
    const _columns = _extraData && _extraData.Columns;
    if(_columns && _columns[fieldName]){
      const _fieldConfig = _columns[fieldName];
      if(_fieldConfig[key]!=null){
        return _fieldConfig[key]
      }
    }
    return null;
  }
  getCanEditOfField=(fieldName)=>{
    let _canEdit = this.getExtraOfFieldByKey(fieldName,HConstant.ExtraDataColumn.CanEdit);
    if(_canEdit===true){
      return true;
    }
    return false;
  }
  getCanShowOfField=(fieldName)=>{
    // console.warn("getCanShowOfField:",fieldName,this);
    let _canShow = this.getExtraOfFieldByKey(fieldName,HConstant.ExtraDataColumn.CanShow);
    if(_canShow===true){
      return true;
    }
    return false;
  }
  getScreenConfigs=(options)=>{
    let _screenConfigs = {};
    try {
      if(options && options.Configs && options.Configs.ScreenConfig){
        _screenConfigs = JSON.parse(options.Configs.ScreenConfig)
      }
    } catch (error) {
      console.warn('Parse JSON error:',error,options);
    }    
    return _screenConfigs
  }
  getScreenCode=()=>{
    const {configPage} = this.props;
    let _screenCode;
    if(configPage.ScreenCode){
      _screenCode = configPage.ScreenCode;
    }
    return _screenCode;
  }
  getProjectId=()=>{//dung cho trang su dung projectId
    const {match} = this.props;
    let _projectId;
    if(match){
      _projectId = HUtils.Obj.get(match,'params.projectId');
    }
    // console.warn("getProjectId:",this.props,_projectId);
    return _projectId;
  }
  getConfigFromScreenConfig=(key)=>{//Config from ScreenConfig: from Options: Table, Header, Filter ...
    const {customConfig} = this.props;
    const {screenConfig} = this.state;
    let config = {}
    if(screenConfig && screenConfig[key]){
      config = screenConfig[key]
    }
    if(customConfig && customConfig[key]){
      config = {...config, ...customConfig[key]};//
    }
    // van de config tableProps
    return config;
  }
  getConfigFromCustomConfig=(key)=>{//Config from customConfig
    const {customConfig} = this.props;
    let config = {}
    if(customConfig && customConfig[key]){
      config = customConfig[key]
    }
    return config;
  }
  getConfigFromPageConfig=(key)=>{//Config from PageConfig: Config,ConfigObj
    const {configPage} = this.props;
    // console.warn("getConfigFromPageConfig:",configPage);
    let _config = configPage.ConfigObj;
    if(_config && _config[key]!=null){
      return _config[key];
    }
  }
  getConfigFromConfigPageByKey=(key)=>{
    const {configPage} = this.props;
    if(configPage && configPage[key]){
      return configPage[key];
    }
    return null
  }
  getApiController=({customApi}={})=>{
    const {configPage} = this.props;
    return customApi || configPage.APIName;
  }
  getConfigFilter=()=>{
    let _configFilter = {
      show: true, 
      showSearch: true,       //Show search
      searchNoSymbol: false,  //Search ko dau
      require4ShowData: false,//Ko nho dung lam gi
      listInList: [],
      //new
      className: "",          //className MyFitler
      classNameItem: "col-12 col-sm-6 col-md-4 col-lg-3", //className item Myfilter
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Filter');
    if(_configFromOptions){
      _configFilter = Object.assign(_configFilter,_configFromOptions);
    }
    return _configFilter;
  }
  getConfigHeader=()=>{
    let _configHeader = {
      show: true, 
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Header');
    if(_configFromOptions){
      _configHeader = Object.assign(_configHeader,_configFromOptions);
    }
    return _configHeader;
  }
  hideLoadingApp=()=>{ MyLoadingApp.Helpers.hide(); }
  showLoadingApp=(opts)=>{ MyLoadingApp.Helpers.show(opts); }
  renderPageLoading=()=>{
    return (
      <div>Loading.....</div>
    )
  } 
  getRequestData=(obj)=>{
    const {configPage,match}=this.props;
    let _requestData = {
      ScreenGUID: configPage.Id,
    };

    let _isClient = this.getConfigFromPageConfig('isClient');//man hinh o client, can merge screenguid va requestData từ screen category
    // console.warn("getRequestData: ",_requestData);
    if(_isClient){
      let _requestDataInCategory = this.getConfigFromCategoryScreenList("RequestData")
      let _screenIdInCategory = this.getConfigFromCategoryScreenList("Id")      
      // console.warn("isClient:",_requestDataInCategory,_screenIdInCategory);
      if(_screenIdInCategory){
        _requestData.ScreenGUID = _screenIdInCategory;
      }
      if(_requestDataInCategory){
        _requestData = {..._requestData,..._requestDataInCategory};
      }
    }

    if(configPage && configPage.RequestData){
      let _rq = configPage.RequestData
      if(typeof (_rq) === "string"){
        try {
          _rq = JSON.parse(_rq)
        } catch (error) {
          console.warn("parse error", error)
        }
      }
      _requestData = {..._requestData, ..._rq}
    }
    if(match && match.params){
      _requestData = {..._requestData, ...match.params}
    }
    if(obj){
      _requestData = {..._requestData, ...obj}
    }

    
    return _requestData;
  } 
  getConfigFromCategoryScreenList=(fieldName)=>{
    const {configPage} = this.props;
    const _cateList = HLocalStorage.getObject("CATEGORY_LIST");
    if(_cateList){
      const _screenCode = HUtils.Obj.get(configPage, "ScreenCode")
      let _screenConfig = _cateList.find((v)=>v.ScreenCode === _screenCode);

      if(_screenConfig && _screenConfig[fieldName]){
        return _screenConfig[fieldName]
      }
    }
  }
  requestOptions=({customApi,notShowLoadingApp,cbSuccess,customQuery}={})=>{
    const {configPage}=this.props;
    this.showLoadingApp({notShowLoadingApp});
    this.setState({
      isLoadingOptions: true,
      errorOptions: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: 'POST',
          path: customApi || configPage.APIName,
          name: 'Options'
        },
        data:this.getRequestData(customQuery),
        successCallBack:(response)=>{
          // console.warn('res:',response);          
          this.setState({
            pageLoading: false,
            options: response.Data,
            screenConfig: this.getScreenConfigs(response.Data),
            isLoadingOptions: false,
            errorOptions: null,
          },()=>{
            const pageConfigs = this.getConfigFromScreenConfig('Page');
            // console.warn("Page:",pageConfigs);
            if(pageConfigs && pageConfigs.updateUIWhenResize==true){
              this.initObserver();
            }
            if(cbSuccess){
              cbSuccess(response);
            }
            this.hideLoadingApp({notShowLoadingApp});
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingOptions: false,
            errorOptions: error || response,
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
          })          
        }
      })
    });    
  }
  requestList=({customApi,notShowLoadingApp,customQuery,cbSuccess}={})=>{
    const {configPage}=this.props;
    this.showLoadingApp({notShowLoadingApp});
    this.setState({
      isLoadingList: true,
      errorList: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: 'POST',
          path: customApi || configPage.APIName,
          name: 'List'
        },
        data: this.getRequestData(customQuery),
        successCallBack:(response)=>{
          // console.warn('res:',response);
          cbSuccess && cbSuccess(response)
          this.setState({
            response: response,
            pageLoading: false,
            list: response.Data,
            isLoadingList: false,
            errorList: null,
            lastGetData: new Date().getTime()
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingList: false,
            errorList: error || response,
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
          })          
        }
      })
    })    
  }
  requestUpdateField=({customApi,row,requestData,optsUpdateData,opts}={})=>{
    const {configPage}=this.props;
    const {component,hideMsgUpdate,fnUpdateUILoading,fnUpdateUIError,fnSuccessCallBack,fnErrorCallBack} = opts || {};
    const _tableConfig = this.getConfigFromScreenConfig('Table')
    // this.showLoadingApp();
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: customApi || configPage.APIName,
        name: 'UpdateFields'
      },
      data:requestData,
      successCallBack:(response)=>{
        // console.warn('res:',response, opts);
        HUtils.updateDataWithNewData(row,response.Data,{...optsUpdateData});

        if(fnUpdateUILoading){
          fnUpdateUILoading(false,{...opts});
        }
        if(fnUpdateUIError){
          fnUpdateUIError(null,{...opts});
        }
        if(fnSuccessCallBack){
          fnSuccessCallBack(response,{...opts});
        }

        let _isHideMsgUpdateConfig = _tableConfig && _tableConfig.hideMsgUpdate || false;
        if(_isHideMsgUpdateConfig ==true || (hideMsgUpdate == true)){
          console.log("Update success");
        }
        else{
          let _msg = response.Msg;
          _msg && HUI.Toast.showSuccess(_msg);
        }        


        this.forceUpdate();
        //
        // this.setState({
        //   pageLoading: false,
        //   list: response.Data
        // })
        // this.hideLoadingApp();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
        if(fnErrorCallBack){
          fnErrorCallBack(error || response.Msg)
        }
        // this.hideLoadingApp();
      }
    })
  }
  requestAddNew=({cbSuccess})=>{
    const {configPage}=this.props;
    console.warn('this._cTable', this._cTable)
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: configPage.APIName,
        name: 'Add'
      },
      data: this.getRequestData(),
      successCallBack:(response)=>{
        console.warn('res:',response);
        cbSuccess && cbSuccess(response);
        this.requestList({notShowLoadingApp: true});
        this.forceUpdate();
        //
        // this.setState({
        //   pageLoading: false,
        //   list: response.Data
        // })
        // this.hideLoadingApp();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
        // this.hideLoadingApp();
      }
    })
  }
  requestAddMulti=(obj)=>{
    const {configPage}=this.props;
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: configPage.APIName,
        name: 'AddMulti'
      },
      data: this.getRequestData(obj),
      successCallBack:(response)=>{
        console.warn('res:',response);
        this.requestList({notShowLoadingApp: true});
        this.forceUpdate();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
      }
    })
  }
  requestDetail=({method,customApi,detailId}={})=>{
    const {configPage}=this.props;
    this.setState({
      isLoadingDetail: true,
      errorDetail: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: method || 'GET',
          path: customApi || configPage.APIName,
          name: `Detail/${detailId}`
        },
        data: this.getRequestData(),
        successCallBack:(response)=>{
          console.warn('res:',response);
          this.setState({
            dataDetail: response.Data,
            isLoadingDetail: false,
            errorDetail: null
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingDetail: false,
            errorDetail: null
          })
        }
      })
    })    
  }
  requestDelete=(row, {customApi, cbSuccess, outerData}={})=>{
    const {configPage}=this.props;
    let _query = {Id: row.Id};
    let _pathName = `Delete/${row.Id}`;
    
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: customApi || configPage.APIName,
        name: _pathName
      },
      data: _query,
      successCallBack:(response)=>{
        HUI.Toast.showSuccess(response.Msg);
        let data = this.state.list;
        if(data && data.Data){
          for(let i=0;i<data.Data.length;i++){
            if(data.Data[i].Id===row.Id){
              data.Data[i]._isDeleted = true;
              data.Data.splice(i,1);
              break;
            }
          }
        }
        if(outerData){
          for(let i=0;i<outerData.length;i++){
            if(outerData[i].Id===row.Id){
              outerData[i]._isDeleted = true;
              outerData.splice(i,1);
              break;
            }
          }
          cbSuccess && cbSuccess(outerData)
        }
        this.setState({
            list: data
        })
        this.forceUpdate()
      },
      errorCallBack:(error,response)=>{
        HUI.Toast.showError(error)
      }
    })    
  }
  requestAddNewLink=(Id, fieldName, link, name, {cb}={})=>{
    const {configPage} = this.props;
    SApiAxios.generic({
      request:{
          method: 'POST',
          path: 'ProjectPhoto',
          name: 'AddFiles',
      },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
          Files: [{
            Url: link,
            Name: name,
          }],
      },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cb){
            cb(response)
          }
        },
        errorCallBack:(error,response)=>{
          if(cb){
            cb(response)
          }
      }
    })
  }
  requestGetPhoto=(Id, fieldName, {cbSuccess, cbError})=>{
    const {configPage} = this.props;
    SApiAxios.generic({
      request:{
          method: 'POST',
          type: /*name_prefix*/ 'project_photo_' + 'get_photos',
          url: '/api/v1/ProjectPhoto/GetPhotos',
      },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
      },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cbSuccess){
            cbSuccess(response)
          }
        },
        errorCallBack:(error,response)=>{
          if(cbError){
            cbError(response)
          }
      }
    })
  }
  requestSaveAvatar=(Id, fieldName, Item, {cbSuccess})=>{
    const {configPage} = this.props;
    SApiAxios.generic({
      request:{
          method: 'POST',
          type: /*name_prefix*/ 'project_photo_' + 'save_avatar',
          url: '/api/v1/ProjectPhoto/SaveAvatar',
        },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
          FileUrl: Item.FileUrl,
        },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cbSuccess){
            cbSuccess(response)
          }
        },
        errorCallBack:(error,response)=>{
      }
    })
  }
  uploadMultifiles=(Id, fieldName, Files, {cbSuccess, cbError})=>{
    const {configPage} = this.props;
    SApiAxios.generic({
      request:{
        method: 'UPLOAD',
        type: /*name_prefix*/ 'project_photo_' + 'upload',
        url: '/api/v1/ProjectPhoto/Upload',
      },
      files: Files,
      customHeader: {
        ProjectId: '',
        ScreenCode: configPage && configPage.ScreenCode,
        Id: Id,
        FieldName: fieldName,
      },
      successCallBack:(response)=>{
        if(response.Msg){
          HUI.Toast.showSuccess(response.Msg);
        }
        if(cbSuccess){
          cbSuccess(response)
        }
      },
      errorCallBack:(error,response)=>{
        if(cbError){
          cbError(error || response.Msg);
        }
      }
    })
  }
  fnAddOptions=({source,value,text})=>{
    const {options} = this.state;
    console.warn('fnAddOptions:',source,value,text,this.options);
    options[source].push({
      Value: value,
      Text: text
    });
    console.warn('fnAddOptions:',this.options);
  }
  updateDataWithFilter=(filter, dataList, {fnAfterFilter}={})=>{
    let data = dataList || this.getListData();
    if(filter){
      let _dataList = data.Data || data;
      let _arrFiltered = _dataList || [];
      if (filter && Object.keys(filter).length>0 && _dataList){
        let _keysFilter = Object.keys(filter);
        for (let l=0;l<_keysFilter.length;l++){
          let _fieldName = _keysFilter[l];
          let _filter = filter[_fieldName];   
          // console.warn("filter:",_filter);           
          if (_filter.value && _filter.isServer!==true && _dataList){      
            let _filterValue = _filter.value;    
            if(_filter.type=='ArrayFilter'){            
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  let _s = v[_fieldName];
                  for (let k=0;k<_filterValue.length;k++){
                    if (_s == _filterValue[k]){
                      return true;
                    }
                  }
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }
            else if(_filter.type=='TextFilter'){
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  return HFilter.hasTextInObj({value:v},_filterValue);               
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }   
            else if(_filter.type=='SelectFilter'){
              _arrFiltered = _arrFiltered.filter((v,i)=>{
                if (v){
                  let _s = v[_fieldName];
                  if(_s == _filterValue){
                    return true;
                  }                
                }
                if (!v.hasOwnProperty(_fieldName)){
                  return true;
                }
                return false;
              });
            }
            else if(typeof _filter.value=='object'){
              if(_filter.value.type=='CustomFilter'){
                if(_filter.value.start && _filter.value.end){
                  // console.warn("filter CustomFilter:",_filter);
                  _arrFiltered = _arrFiltered.filter((v,i)=>{
                    if (v){
                      let _s = v[_fieldName];
                      // console.warn("check:",_s,_s>= _filter.value.start,_s<=_filter.value.end);
                      if(_s>= _filter.value.start && _s<=_filter.value.end){
                        return true;
                      }                
                    }
                    if (!v.hasOwnProperty(_fieldName)){
                      return true;
                    }
                    return false;
                  });
                }              
              }
            }
                   
          }
        }
      }
      else{
        _arrFiltered = _dataList;
      }

      if(filter._SearchText && filter._SearchText.value && filter._SearchText.isServer!==true){
        _arrFiltered = this._onSearchText(filter._SearchText.value,_arrFiltered);
        console.warn("_arrFiltered", _arrFiltered)
      }
      this.setState({
        dataFiltered:_arrFiltered
      },()=>{
        fnAfterFilter && fnAfterFilter();
      })
    }
    this.forceUpdate()
  }
  _onSearchText=(searchText,dataList)=>{
    let _arrFiltered = null;
    let _dataList = dataList || this.state.dataList;
    if (searchText && _dataList){
      _arrFiltered = _dataList.filter((v,i)=>{
        return HFilter.hasTextInObj(v,searchText,this._optsSearch);
      });
    }
    if(dataList){
      return _arrFiltered;
    }
  }
  render(){
    if(this.state.pageLoading){
      return this.renderPageLoading();
    }    
    if(this.renderPage){
      return this.renderPage();
    }
    return (
      <div>PageExt</div>
    )
  } 
}

export default PageExt