import React from 'react'
import MyUI from '../../../../../components/MyUI/MyUI'
class ScLoading extends React.Component{
    render(){        
        return (
            <div className="title" style={{height:'calc(100vh - 300px)',minHeight:'100px',background:'#ffffff80',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <MyUI type="spinner_dots"/>
            </div>
        )
    }
}
export default ScLoading