import React from 'react'
import {CButton} from '../../../../../components/MyCore'
import {MyModal} from '../../../../Components'
import HApiAxios from '../../../../../helpers/ApiAxios'
import './styles/sc_toolbar_admin.css'
import {connect} from 'react-redux';

class SC_Toolbar_Admin extends React.Component{
    requestActive=(active)=>{
        const {slug} = this.props;
        if(slug){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'Dashboard/UpdateActiveWithSlug'
                },
                data: {
                    Slug: slug,
                    IsActive: active
                },
                successCallBack: (response)=>{
                    window.location.reload()
                },
                errorCallBack: (error, response)=>{

                }
            })
        }
    }
    render(){
        const {fnList, status,adminButtons,ShowBtnCreatePost, ShowBtnNewest, title} = this.props;
        // const adminButtons = HStoreRedux.getState().shop.adminButtons
        // console.warn("render toolbar:",adminButtons,this.props);
        if(!adminButtons){
            return <div></div>
        }
        return(
            <div className="sc_toolbar_admin">
                <div className="status-content">
                    {
                        (status!=null && adminButtons && adminButtons.ShowBtnActive == true)&&
                        <CButton style={{backgroundColor: status == true ? "var(--color-active)" : "var(--color-danger)"}} onClick={()=>{
                            MyModal.Helpers.showConfirm(`Bạn có chắc muốn${status==true?' ngừng ':' '}đăng ${title} này?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                                if(fnList && fnList.setActiveStatus){
                                    fnList.setActiveStatus(!status);
                                }
                                else{
                                    this.requestActive(!status);
                                } 
                                fnClose && fnClose()
                            }})
                        }}>{status == true ? "Đã đăng" : "Chưa đăng"}</CButton>
                    }
                </div>
                <div className="right">
                    {
                        ShowBtnNewest && adminButtons.ShowBtnEditPost == true && 
                        <div className="edit-content">
                            <CButton style={{backgroundColor: "var( --color-primary)",}} onClick={()=>{
                                MyModal.Helpers.showConfirm(`Bạn có chắc muốn hiển thị ${title} này trong mục mới nhất ở trang chủ không?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                                    if(fnList && fnList.setNewest){
                                        fnList.setNewest();
                                    }
                                    fnClose && fnClose()
                                }})
                            }}>Mới nhất</CButton>
                        </div>
                    }
                    {
                        adminButtons.ShowBtnEditPost == true &&
                        <div className="edit-content">
                            <CButton onClick={()=>{
                                fnList && fnList.onEdit && fnList.onEdit()
                            }}>Chỉnh sửa</CButton>
                        </div>
                    }
                    {
                        adminButtons.ShowBtnCreatePost == true && ShowBtnCreatePost!==false &&
                        <div className="add-new">
                            <CButton onClick={()=>{
                                fnList && fnList.onAddNew && fnList.onAddNew()
                            }}>Tạo mới</CButton>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps=dispatch=>{
    return{
    }
}
const mapStateToProps=(state)=>{
    if(state.shop && state.shop.adminButtons){
        return {
            adminButtons: state.shop.adminButtons
        }
    }
    return {};
}
export default connect(mapStateToProps,mapDispatchToProps)(SC_Toolbar_Admin)