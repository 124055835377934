import React from "react";
import { HText, HConfig } from "apps/Helpers";
import HGA from "helpers/GA";
import "./styles/sc_menu.css";

class SC_Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showSubMenu: false,
      menuActive: null,
      indexActive: null
    };
    this.idElmMenu = "#category-menu"
  }
  componentDidMount() {
    if (this.isCollapseFixed()) {
      this.setState({
        showMenu: true,
      });
    }
  }
  isCollapseFixed = () => {
    if (window.location.pathname === "/" && HConfig.fnList.getConfig("menuCategoryHomeFixed") !== false) {
      return true;
    }
    return false;
  };
  getStyleSubMenu = (indexMenuActive) => {
      const _elmMenu = document.getElementById(this.idElmMenu);
      let _stl = {};
      if(_elmMenu){
        const _elmMenuActive = indexMenuActive!==null && document.getElementById(`#category_item_${indexMenuActive}`);
         _stl.minWidth= _elmMenu.offsetWidth;
         if(_elmMenuActive){
           _stl.top = _elmMenuActive.offsetHeight * (indexMenuActive)
         }

      }
      return _stl
  };
  onCollapse = () => {
    const { showMenu } = this.state;
    if (!this.isCollapseFixed()) {
      this.setState({
        showMenu: !showMenu,
      });
    }
  };
  onHoverMenu = (item, index) => {
    this.setState({
      menuActive: item,
      showSubMenu: true,
      indexActive: index
    });
  };
  onLeaveMenu = () => {
    this.setState({
      showSubMenu: false,
      menuActive: null,
      indexActive: null
    });
  };

  renderSubmenu = () => {
    const { menuActive, showSubMenu, indexActive } = this.state;
    let _itemChildren = menuActive?.ItemChildren;
    if (_itemChildren?.length > 0) {
      let _ui = [];
      for (let i = 0; i < _itemChildren.length; i++) {
        const _itm = _itemChildren[i];
        const name = _itm.Name;
        const link = _itm.APIUrl;
        _ui.push(
          <li
            className="mega-menu-categories"
            key={i}
            onClick={() => {
              HGA.trackingEventClick({
                action: "click_item_menu_category",
                label: name,
              });
            }}
          >
            <a href={link}>
              <span>{name}</span>
            </a>
          </li>
        );
      }
      return (
        <div
          id="category-navigation-submenu"
          className="category-navigation-submenu"
          style={{ display: showSubMenu ? "block" : null , ...this.getStyleSubMenu(indexActive)}}
        >
          <ul className="category-navigation-submenu-list">{_ui}</ul>
        </div>
      );
    }
  };
  render() {
    const { data } = this.props;
    const { showMenu, menuActive } = this.state;
    if (data) {
      return (
        <div className="sc_menu">
          <span
            className="category-navigation-title"
            onClick={() => this.onCollapse()}
          >
            {HText.get("text_category")}
          </span>
          <div className="category-navigation-list-wrapper">
            <ul
              id={this.idElmMenu}
              className="category-navigation-list sticky-flyout"
              style={{ display: showMenu ? "block" : "none" }}
              onMouseLeave={() => this.onLeaveMenu()}
            >
              {data &&
                data.map((v, i) => {
                  const name = v.Name;
                  const link = v.APIUrl;
                  return (
                    <li
                      id={`#category_item_${i}`}
                      className={`mega-menu-categories${menuActive?.Name == name ? " active": ""}`}
                      key={i}
                      onClick={() => {
                        HGA.trackingEventClick({
                          action: "click_item_menu_category",
                          label: name,
                        });
                      }}
                    >
                      <a href={link} onMouseEnter={() => this.onHoverMenu(v, i)}>
                        <span>{name}</span>
                      </a>
                    </li>
                  );
                })}
              {this.renderSubmenu()}
            </ul>
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_Menu;
