import React from 'react'
import {MyModal, MyUI} from '../../../../Components'
import {CButton} from '../../../../../components/MyCore'
import './styles/sc_editor_banner.css'
import {HApiAxios, HUI, HConfig} from '../../../../Helpers'
 
class SC_Editor_Banner extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            bannerList: [],
            newLink: "",
        }
    }
    componentDidMount(){
        this.requestGetBanner();
    }
    requestGetBanner=()=>{
        HApiAxios.generic({
            request: {
                method: 'POST',
                url: 'Dashboard/GetDataImageSlide'
            },
            data: {},
            successCallBack: (response)=>{
                if(response.Data){
                    this.setState({
                        bannerList: [...response.Data]
                    })
                }
            },
            errorCallBack: (error, response)=>{

            }
        })
    }
    requestUpload=(item, files)=>{
        HApiAxios.generic({
            request: {
                method: 'UPLOAD',
                path: "ProjectPhoto",
                name: "Upload"
            },
            files: files,
            customHeader: {
                ProjectId: '',
                ScreenCode: 'EZYWEB_COLLECTION',
                Id: item.Id,
                FieldName: 'Files_0',
              },
            successCallBack: (response)=>{
                this.requestGetBanner()
            },
            errorCallBack:(error,response)=>{
            }
        })
    }
    requestDelete=(item)=>{
        const Id = item.Id;
        if(Id){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: `EzyWeb_Collection/Delete/${Id}`
                },
                data: {},
                successCallBack: (response)=>{
                    let {bannerList} = this.state;
                    // bannerList = bannerList.filter((i)=>i.Id !== Id);
                    for(let i=0;i<bannerList.length;i++){
                        let _item = bannerList[i];
                        if(_item.Id == Id){
                            bannerList.splice(i, 1);
                        }
                    }
                    this.setState({
                        bannerList: bannerList
                    })
                },
                errorCallBack: (error, response)=>{}
            })
        }
    }
    requestUpdateField=(item, oldValue, fieldName, newValue)=>{
        // console.warn("old, new", oldValue, newValue)
        if(newValue!==null && newValue!=="" && newValue !== oldValue){
            const _query = {
                Id: item.Id,
                Values: [
                    {FieldName: fieldName, NewValue: newValue},
                ]
            }
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'EzyWeb_Collection/UpdateFields'
                },
                data: _query,
                successCallBack: (response)=>{
                    HUI.Toast.showSuccess(response.Msg);
                    this.requestGetBanner()
                },
                errorCallBack: (error, response)=>{}
            })
        }
    }
    requestAddNew=(link)=>{
        if(link){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'EzyWeb_Collection/AddHomeSlide'
                },
                data: {
                    Link: link
                },
                successCallBack: (response)=>{
                    MyModal.Helpers.hide();
                    window.location.reload()
                },
                errorCallBack: (error, response)=>{}
            })
        }
    }
    onChangeNewLink=(value)=>{
        this.setState({
            newLink: value
        })
    }
    openModalAddNew=()=>{
        const {newLink} = this.state;
        let ui = 
        (<div style={{width: "100%", display: 'flex'}}>
            <div style={{width: "85%", display:"flex"}}>
                <div style={{minWidth: '100px', height: '36px', backgroundColor: 'var(--color-secondary)', color: "#fff", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Link</div>
                <input type="text" style={{width: "100%"}} placeholder="Nhập đường dẫn banner" defaultValue={newLink} onChange={(e)=>this.onChangeNewLink(e.currentTarget.value)}/>
            </div>
            <CButton style={{width: "15%", borderRadius: 0}} color="success" onClick={()=>this.requestAddNew(this.state.newLink)}>Tạo mới</CButton>
        </div>)
        MyModal.Helpers.showComponent(ui,{
            title: 'Tạo mới banner',
            closeButton:  true,
            closeOnBackdrop: false,
            size: 'lg'
        })
    }
    render(){
        const {bannerList} = this.state;
        return(
            <div className="sc_editor_banner">
                <div className="btn-add-wrapper">
                    <CButton className="btn-add-new" color="success" onClick={()=>this.openModalAddNew()}>Tạo mới</CButton>
                </div>
                <div className="content">
                    {
                        bannerList!=null && bannerList.map((v,i)=>{
                            return (
                                <div key={i} style={{marginRight:'0.5rem', display: 'flex', flexDirection: 'column'}}>
                                    <div style={{position: 'relative'}} key={i}>
                                        <img src={v.ImageUrl || HConfig.fnList.getConfig("imageProductDefault")} />
                                        <div title={"Xóa hình"} style={{width:'25px',height:'25px',position:'absolute',right:'0px',top:'5px',cursor:'pointer',color:'white',background:'#e71313',textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{
                                            MyModal.Helpers.showConfirm(`Bạn có chắc muốn xóa hình này?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                                                this.requestDelete(v)
                                                fnClose && fnClose()
                                            }})
                                            
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </div>
                                        {
                                            !v.ImageUrl &&
                                            <div title={"Tải ảnh"} style={{width:'25px',height:'25px',position:'absolute',right:'0px',bottom:'5px',cursor:'pointer',color:'white',background:'var(--color-secondary)',textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                {/* <i className="fa fa-picture-o"></i> */}
                                                <label htmlFor="banner-file" style={{clear:'both', margin: 0}}><i className="fa fa-upload"></i></label>
                                                <input id="banner-file" type="file" accept="*" onChange={(e)=>this.requestUpload(v,e.target.files)} style={{display: 'none'}}/>
                                            </div>
                                        }
                                    </div> 
                                    <div style={{display: 'flex'}} key={`${i}-${new Date().getTime()}`}>
                                        <div className="group-input" style={{margin: '10px 0px'}}>
                                            <MyUI type="input_text" defaultValue={v.Link} style={{width: '285px'}} onBlur={(e, value)=>this.requestUpdateField(v, v.Link, "Title", value)}/>
                                            <label className={v.Link ? "active"  :""} style={{background: "#fff"}}>Link</label>
                                        </div>
                                        <div className="group-input" style={{margin: '10px 0px'}}>
                                            <MyUI type="input_text" defaultValue={v.OrderNo} style={{width: '100px'}} onBlur={(e, value)=>this.requestUpdateField(v, v.OrderNo, "OrderNo", parseInt(value))}/>
                                            <label className={v.OrderNo !=null ? "active"  :""} style={{background: "#fff"}}>OrderNo</label>
                                        </div>                                           
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
export default SC_Editor_Banner