import React from 'react'
import {HStoreRedux, HUtils} from '../../Helpers';
import BannerLeft from '../../../scss/img/banner-1.png'
import BannerRight from '../../../scss/img/banner-2.png'
import {connect} from 'react-redux'

//AWO-199
class TheBanner extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            contentWidth: window.innerWidth
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidthBanner)
      }
    componentDidMount(){
        this.setWidthBanner();
        window.addEventListener('resize', this.setWidthBanner)
    }
    setWidthBanner=()=>{
        let _bannerContainer = document.getElementById("banner-event");
        if(_bannerContainer){
            _bannerContainer.style.width = `${1200+120*2+20}px`
        }
        this.setState({
            contentWidth: window.innerWidth
        })
    }
    getBannerListByPosition=(pos)=>{
        const {banner} = this.props;
        let _bannerList = [];
        if(banner && banner.length>0 && pos){
            _bannerList = banner.filter((v)=>v.Position === pos);
        }
        return _bannerList
    }
    render(){
        const {contentWidth} = this.state;
        const bannerLeftList = this.getBannerListByPosition("left");
        const bannerRightList = this.getBannerListByPosition("right");
        if(contentWidth > 1520){
            return(
                <div className="banner" id="banner-event">
                    <div className="banner-left">
                        {
                            bannerLeftList.map((v,i)=>{
                                return (
                                    <a href={v.Link} key={`left-${i}`} title={v.Name}>
                                        <img src={v.ImageUrl} title={v.Name}/>
                                    </a>
                                )
                            })
                        }
                    </div>
                    <div className="banner-right">
                        {
                            bannerRightList.map((v,i)=>{
                                return (
                                    <a href={v.Link} key={`right-${i}`} title={v.Name}>
                                        <img src={v.ImageUrl} title={v.Name}/>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        return(
            <div></div>
        )
    }
}
const mapStateToProps=state=>{
    return{
        banner: state.shop.banner
    }
}
export default connect(mapStateToProps,null)(TheBanner)