import React from 'react'
import HGA from 'helpers/GA'
 import {HConfig, HText, HEncode} from 'apps/Helpers'


class SC_CardProductRecent extends React.Component{
    render(){
        const {data} = this.props;
        if(data){
            const _enCodeId = HEncode.Base64.encode(JSON.stringify(data.MaterialId));
            const _LinkDetail = `${data.LinkDetail ? data.LinkDetail : ""}?recently=${_enCodeId}`;
            const _MaterialName = data.MaterialName ;
            const _ImageUrlThumnail = data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
            return(
                <li>
                    <a className="product-picture" href={_LinkDetail} title={`${HText.get("text_product_title")} ${_MaterialName}`} onClick={()=>{
                        HGA.trackingEventClick({
                            action: 'click_product_of_recent',
                            label: _MaterialName
                        })
                    }}>
                        <img alt={`${HText.get("text_product_title_image")} ${_MaterialName}`} src={_ImageUrlThumnail} title={`${HText.get("text_product_title")} ${_MaterialName}`}/>
                    </a>
                    <a className="product-name" href={_LinkDetail} onClick={()=>{
                        HGA.trackingEventClick({
                            action: 'click_product_of_recent',
                            label: _MaterialName
                        })
                    }}>{_MaterialName}</a>
                </li>
            )
        }
        return <div></div>
    }
}
export default SC_CardProductRecent