const ShopText = {
  vi: {
    /* Chung */
    btn_see_more: "Xem thêm",
    btn_close: "Đóng",
    btn_ok: "Đồng ý",
    btn_reset: "Làm lại",
    btn_save: "Lưu",
    msg_error_login_google:
      "Liên kết Google thất bại! Hãy thử đăng xuất tài khoản google rồi thử lại.",
    text_warning_title: "Thông báo",
    text_login_facebook_failed: "Đăng nhập với facebook thất bại",
    /* Form địa chỉ */
    text_form_receiver: "Người nhận",
    text_form_address_name: "Họ và tên",
    text_form_address_address: "Địa chỉ",
    text_form_address_email: "Địa chỉ email",
    text_form_address_phone: "Số điện thoại",
    text_form_address_note: "Ghi chú",
    text_form_address_time: "Thời gian giao hàng",
    text_form_address_empty: "Vui lòng nhập đầy đủ thông tin yêu cầu!",
    text_form_address_empty_name: "Vui lòng nhập họ và tên người nhận!",
    text_form_address_empty_your_name: "Vui lòng nhập họ và tên của bạn!",
    text_form_address_empty_address: "Vui lòng nhập địa chỉ người nhận!",
    text_form_address_empty_your_address: "Vui lòng nhập địa chỉ của bạn!",
    text_form_address_empty_phone: "Vui lòng nhập số điện thoại người nhận!",
    text_form_address_empty_your_phone: "Vui lòng nhập số điện thoại của bạn!",
    text_form_address_invalid_phone: "Số điện thoại người nhận không hợp lệ!",
    text_form_address_invalid_your_phone: "Số điện thoại của bạn không hợp lệ!",
    text_form_address_empty_field:
      "Vui lòng nhập họ và tên, địa chỉ và số điện thoại nhận hàng!",
    text_form_your_address_empty_field:
      "Vui lòng nhập họ và tên, địa chỉ và số điện thoại của bạn!",
    text_form_address_unchange_field: "Chưa có sự thay đổi thông tin nào!",
    text_form_is_other_receiver: "Bạn không phải người nhận?",
    text_form_title_bill_info: "Thông tin của bạn",
    text_form_title_receiver_info: "Thông tin người nhận",
    text_form_receiver_name: "Họ tên người nhận",
    text_form_receiver_phone: "Số điện thoại người nhận",
    text_form_receiver_address: "Địa chỉ người nhận",
    /* Trang cart */
    text_title_cart_page: "Giỏ hàng",
    text_step_cart_confirm: "Xác nhận",
    text_step_cart_complete: "Hoàn thành",
    text_shipping_fee: "Phí vận chuyển",
    text_shipping_describe:
      "Chúng tôi sẽ liên hệ và báo giá cước vận chuyển cụ thể với quý khách.",
    text_payment_method: "Phương thức thanh toán",
    text_shipping_products: "Danh sách sản phẩm",
    text_payment_describe_1: "Chuyển khoản hoặc thanh toán khi nhận hàng",
    text_payment_describe_2:
      "Tùy loại sản phẩm, Chúng tôi sẽ liên hệ với bạn sớm nhất",
    text_shipping_address: "Lựa chọn địa chỉ giao hàng",
    text_save_new_address: "Lưu địa chỉ mới",
    text_choose_address: "Chọn địa chỉ",
    text_modal_choose_address_title: "Chọn địa chỉ giao hàng",
    text_social_login: "Đăng nhập với",
    text_cart_column_isorder: "Đặt hàng",
    text_cart_column_product: "Sản phẩm",
    text_cart_column_attribute: "Loại",
    text_cart_column_price: "Giá",
    text_cart_column_quantity: "Số lượng",
    text_cart_column_total: "Tổng tiền",
    text_cart_column_delete: "Hủy bỏ",
    text_cart_out_of_stock: "Hết hàng",
    text_cart_inventory_notify: (number) => `Chỉ còn ${number}`,
    text_cart_invalid_quantity: "Số lượng không hợp lệ!",
    text_cart_quantity_over: "Số lượng không được vượt quá",
    text_cart_delete_baseon_quantity: "Bạn muốn xóa sản phẩm này?",
    text_cart_total: "Tiền tạm tính",
    text_cart_total_note:
      "Chúng tôi sẽ sớm liên lạc với bạn để xác nhận đơn hàng và chi phí!",
    text_cart_checkout: "Tiếp tục",
    text_confirm_order_title: "Xác nhận đơn hàng",
    text_confirm_shipping_address_title: "Địa chỉ giao hàng",
    text_confirm_order_date: "Ngày",
    text_confirm_order_total: "Tiền tạm tính",
    text_confirm_order_payment_title: "Thanh toán",
    text_confirm_order_payment_describe:
      "Thanh toán khi nhận hàng - Chúng tôi sẽ liên lạc bạn để xác nhận.",
    text_confirm_order_ship_title: "Vận chuyển",
    text_confirm_order_ship_describe: "Giao hàng tận nơi",
    text_confirm_order_receiver: "Người nhận",
    text_confirm_order_customer: "Khách hàng",
    text_confirm_order_ship_value: "Liên hệ",
    text_confirm_order_ship_fee: "Phí vận chuyển",
    text_confirm_order_order: "Đặt hàng",
    text_cart_no_items: "Giỏ hàng không có sản phẩm nào!",
    text_cart_confirm_delete_title: "Xác nhận xóa",
    text_cart_confirm_delete_content: (text) =>
      `Bạn muốn xóa ${text} khỏi giỏ hàng!`,
    text_warning_no_items_to_order: "Bạn chưa chọn sản phẩm để đặt hàng!",
    text_warning_have_outofstock_item:
      "Có sản phẩm vượt quá số lượng trong cửa hàng, vui lòng kiểm tra lại!",
    text_complete_order_title: "Hoàn thành",
    /* Trang category */
    text_search_title: "Tìm kiếm",
    text_filter_price: "Khoảng giá",
    text_recent_product: "Sản phẩm xem gần đây",
    text_popular_tag: "Thẻ phổ biến",
    text_btn_search: "Tìm kiếm",
    text_filter_order: "Sắp xếp theo",
    text_order_newest: "Mới nhất",
    text_order_price_high_to_low: "Giá cao đến thấp",
    text_order_price_low_to_high: "Giá thấp đến cao",
    text_pagination_pre: "Hiển thị",
    text_pagination_suf: "mỗi trang",
    text_grid_view: "Dạng lưới",
    text_list_view: "Danh sách",
    text_filter_color: "Màu sắc",
    text_category_no_items: "Không có sản phẩm nào!",
    /*Sản phẩm*/
    text_product_add_wishlist: "Yêu thích",
    text_product_add_cart: "Đặt hàng",
    text_product_title_image: "Hình ảnh",
    text_product_title: "Hiển thị chi tiết của",
    text_product_out_of_stock: "Hết hàng",
    /*Header*/
    text_category: "Danh mục sản phẩm",
    text_placeholder_search: "Tìm kiếm sản phẩm",
    text_account_user: "Xin chào",
    text_btn_login: "Đăng nhập",
    text_btn_login_facebook: "Đăng nhập với Facebook",
    text_btn_login_google: "Đăng nhập với Google",
    text_welcom_login: "Chào bạn, mời đăng nhập",
    text_search_result: "Kết quả tìm kiếm",
    /*Modal add cart*/
    text_modal_btn_go_cart: "Mua ngay",
    text_modal_btn_go_wishlist: "Xem danh sách yêu thích",
    text_modal_quantity: "Số lượng",
    text_modal_btn_add_cart: "Thêm vào giỏ",
    text_modal_title_add_cart: "Đã thêm vào giỏ hàng",
    text_modal_title_add_wish: "Đã thêm vào danh sách yêu thích",
    text_modal_continue_shopping: "Tìm thêm sản phẩm",
    /*Modal update cart*/
    text_modal_btn_update: "Cập nhật",
    /*Modal add new address */
    text_modal_address_title: "Thêm địa chỉ",
    text_modal_address_title_update: "Chỉnh sửa địa chỉ",
    text_modal_address_save_as_default: "Đặt làm địa chỉ mặc định",
    text_modal_address_btn_refill: "Làm lại",
    text_modal_address_btn_save: "Lưu",
    /*Mini preview cart*/
    text_mini_preview_unit: "sản phẩm",
    text_mini_preview_describe_1: "Có ",
    text_mini_preview_describe_2: "trong giỏ hàng",
    text_mini_preview_total: "Tổng tiền",
    text_mini_preview_btn_go_cart: "Giỏ hàng",
    text_mini_preview_empty: "Chưa có sản phẩm trong giỏ hàng!",
    text_mini_preview_quantity: "Số lượng",
    /*Menu account*/
    text_account: "Tài khoản",
    text_menu_wishlist: "Yêu thích",
    text_menu_address_book: "Sổ địa chỉ",
    text_menu_bills: "Đơn hàng",
    text_menu_logout: "Đăng xuất",
    text_menu_login: "Đăng nhập",
    text_menu_bag: "Giỏ hàng",
    /*Trang sổ địa chỉ*/
    text_address_book_title: "Sổ địa chỉ",
    text_address_book_add_new: "Thêm địa chỉ",
    text_address_book_btn_edit: "Chỉnh sửa",
    text_address_book_btn_delete: "Xóa",
    text_address_book_no_items: "Bạn chưa lưu địa chỉ nào!",
    text_address_book_default: "Địa chỉ mặc định",
    text_address_book_confirm_remove: "Bạn muốn xóa địa chỉ này?",
    text_address_book_confirm_delete_title: "Xác nhận xóa",
    /*Trang danh sách đơn hàng*/
    text_bill_title: "Đơn hàng của bạn",
    text_bill_status_all: "Tất cả",
    text_bill_status_delivered: "Đã giao",
    text_bill_status_pending: "Chưa giao",
    text_bill_status_discard: "Đã hủy",
    text_bill_total: "Tổng tiền",
    text_bill_go_detail: "Xem chi tiết đơn hàng",
    text_bill_detail_title: "Đơn hàng",
    text_bill_detail_delivery_info: "Thông tin nhận hàng",
    text_bill_detail_delivery_status: "Trạng thái đơn hàng",
    text_bill_no_items: "Bạn chưa có đơn hàng nào!",
    text_bill_detail_customer: "Khách hàng",
    text_bill_detail_receiver: "Người nhận",
    text_bill_detail_phone: "SĐT",
    text_bill_detail_email: "Email",
    text_bill_detail_address: "Địa chỉ",
    text_bill_detail_note: "Ghi chú",
    text_bill_detail_delivery_time: "Thời gian giao hàng",
    /*Trang chi tiết sản phẩm */
    text_detail_tab_info_title: "Thông tin sản phẩm",
    text_detail_tab_contact_title: "Liên hệ - Tư vấn",
    text_detail_shipping_fee_des: "không bao gồm phí vận chuyển",
    text_detail_contact_order: "Liên hệ",
    /*Trang wishlist  */
    text_wishlist_title: "Yêu thích",
    text_wishlist_column_isorder: "Đặt hàng",
    text_wishlist_column_image: "Hình ảnh",
    text_wishlist_column_product: "Sản phẩm",
    text_wishlist_column_attribute: "Loại sản phẩm",
    text_wishlist_column_price: "Đơn giá",
    text_wishlist_column_quantity: "Số lượng",
    text_wishlist_column_total: "Tổng tiền",
    text_wishlist_column_remove: "Xóa",
    text_wishlist_btn_add_bag: "Thêm vào giỏ hàng",
    text_wishlist_btn_choose_type: "Chọn",
    text_wishlist_no_items: "Chưa có sản phẩm trong dánh sách yêu thích!",
    text_wishlist_warning_noitems_order: "Bạn chưa chọn sản phẩm đặt hàng!",
    text_wishlist_warning_item_outofstock: (name, number) =>
      `Sản phẩm ${name} ${number > 0 ? `chỉ còn ${number}` : "đã hết hàng"}!`,
    text_wishlist_warning_item_miss_field: (name) =>
      `Sản phẩm ${name} chưa chọn loại sản phẩm!`,
    text_wishlist_add_to_bag_success: "Đã thêm vào giỏ hàng",
    /*Trang bolg */
    text_blog_comment_title: "Để lại bình luận",
    text_blog_comment: "Bình luận",
    /*Trang slug */
    text_slug_no_exist: "Không có thông tin này!",
    /********* Meta default ********/
    /* ---- Trang  address book*/
    meta_keywords_default_address_book: "Sổ địa chỉ",
    meta_description_default_address_book: "Danh sách địa chỉ mặc định của bạn",
    /* ---- Trang  cart*/
    meta_keywords_default_cart: "Giỏ hàng, thanh toán, đặt hàng",
    meta_description_default_cart:
      "Giỏ hàng của bạn, bạn sẽ đặt mua sản phẩm tại đây",
    /* ---- Trang  wishlist*/
    meta_keywords_default_wishlist: "Wishlist, Yêu thích",
    meta_description_default_wishlist: "Dánh sách sản phẩm bạn yêu thích",
    /* ---- Others Text */
    text_notify: "Xin thông báo",
  },
  en: {
    /* Chung */
    btn_see_more: "See more",
    btn_close: "Close",
    btn_ok: "Submit",
    msg_error_login_google:
      "Link google failed! Try logout google account and login again.",
    text_warning_title: "Notice",
    text_login_facebook_failed: "Sign in with Facebook failed",
    btn_reset: "Retry",
    btn_save: "Save",
    /*Form địa chỉ */
    text_form_receiver: "Receiver",
    text_form_address_name: "Name",
    text_form_address_address: "Shipping Address",
    text_form_address_email: "Email",
    text_form_address_phone: "Phone Number",
    text_form_address_note: "Note",
    text_form_address_time: "Delivery Time",
    text_form_address_empty:
      "Please fill in the required shipping information!",
    text_form_address_empty_name: "Please fill in receiver name!",
    text_form_address_empty_your_name: "Please fill in your name!",
    text_form_address_empty_address: "Please fill in receiver address!",
    text_form_address_empty_your_address: "Please fill in your address!",
    text_form_address_empty_phone: "Please fill in receiver phone number!",
    text_form_address_empty_your_phone: "Please fill in your phone number!",
    text_form_address_invalid_phone: "Invalid receiver phone number!",
    text_form_address_invalid_your_phone: "Invalid your phone number!",
    text_form_address_empty_field:
      "Please fill in receiver name, address and phone number!",
    text_form_your_address_empty_field:
      "Please fill in your name, address and phone number!",
    text_form_address_unchange_field: "There are no changes!",
    text_form_is_other_receiver: "You are not the receiver?",
    text_form_title_bill_info: "Your Infomation",
    text_form_title_receiver_info: "Receiver Infomation",
    text_form_receiver_name: "Receiver's Name",
    text_form_receiver_phone: "Receiver's Phone Number",
    text_form_receiver_address: "Receiver's Address",
    /* Trang cart */
    text_title_cart_page: "Shopping Bag",
    text_step_cart_confirm: "Confirm",
    text_step_cart_complete: "Complete",
    text_shipping_fee: "Shipping Fee",
    text_shipping_describe:
      "We'll contact and discuss particularly about shipping fee to you soon.",
    text_payment_method: "Payment Methods",
    text_shipping_products: "Your products",
    text_payment_describe_1: "Transfer or cash on delivery",
    text_payment_describe_2:
      "Depending on product, We'll contact you as soon as we can",
    text_shipping_address: "Shipping Address",
    text_save_new_address: "Add new",
    text_choose_address: "Select address",
    text_modal_choose_address_title: "Choose delivery address",
    text_social_login: "Sign in with",
    text_cart_column_isorder: "Order",
    text_cart_column_product: "Item",
    text_cart_column_attribute: "Type",
    text_cart_column_price: "Price",
    text_cart_column_quantity: "Quantity",
    text_cart_column_total: "Total",
    text_cart_column_delete: "Delete",
    text_cart_out_of_stock: "Out of stock",
    text_cart_inventory_notify: (number) => `Only ${number} left`,
    text_cart_invalid_quantity: "Invalid quantity!",
    text_cart_quantity_over: "Quantity must be under",
    text_cart_delete_baseon_quantity: "Do you want to remove this item?",
    text_cart_total: "Total",
    text_cart_total_note:
      "We'll contact you soon to confirm the order and cost!",
    text_cart_checkout: "Continue",
    text_confirm_order_title: "Confirm Order",
    text_confirm_shipping_address_title: "Shipping address",
    text_confirm_order_date: "Date",
    text_confirm_order_total: "Total",
    text_confirm_order_payment_title: "Payment",
    text_confirm_order_payment_describe:
      "Cash on delivery - We'll contact you to confirm order.",
    text_confirm_order_ship_title: "Shipping",
    text_confirm_order_ship_describe: "Delivery to door",
    text_confirm_order_receiver: "Receiver",
    text_confirm_order_customer: "Customer",
    text_confirm_order_ship_fee: "Shipping fee",
    text_confirm_order_ship_value: "Contact",
    text_confirm_order_order: "Order",
    text_cart_no_items: "There are no items in your bag!",
    text_cart_confirm_delete_title: "Confirm",
    text_cart_confirm_delete_content: (text) =>
      `Do you really want to remove ${text} from your bag!`,
    text_warning_no_items_to_order: "You have not selected product to order!",
    text_warning_have_outofstock_item:
      "There are products that are out of stock, please check again!",
    text_complete_order_title: "Complete order",
    /* Trang category */
    text_search_title: "Search",
    text_filter_price: "Price",
    text_recent_product: "Recently Viewed Products",
    text_popular_tag: "Popular Tags",
    text_btn_search: "Search",
    text_filter_order: "Order by",
    text_order_newest: "Newest",
    text_order_price_high_to_low: "Highest to lowest price",
    text_order_price_low_to_high: "Lowest to highest price",
    text_pagination_pre: "Show",
    text_pagination_suf: "each page",
    text_grid_view: "Grid view",
    text_list_view: "List view",
    text_filter_color: "Color",
    text_category_no_items: "There are no products!",
    /*Sản phẩm*/
    text_product_add_wishlist: "Add to wishlist",
    text_product_add_cart: "Add to bag",
    text_product_title_image: "Image",
    text_product_title: "Show detail",
    text_product_out_of_stock: "Out of stock",
    /*Header*/
    text_category: "Product category ",
    text_placeholder_search: "Enter something...",
    text_account_user: "Hi",
    text_btn_login: "Sign in",
    text_btn_login_facebook: "Sign in with Facebook",
    text_btn_login_google: "Sign in with Google",
    text_welcom_login: "Welcome you, let's sign in",
    text_search_result: "Search result",
    /*Modal add cart*/
    text_modal_btn_go_cart: "Checkout",
    text_modal_btn_go_wishlist: "Go to wishlist",
    text_modal_quantity: "Quantity",
    text_modal_btn_add_cart: "Add to bag",
    text_modal_title_add_cart: "Added to your bag",
    text_modal_title_add_wish: "Added to your wishlist",
    text_modal_continue_shopping: "Continue shopping",
    /*Modal update cart*/
    text_modal_btn_update: "Update",
    /*Modal add new address */
    text_modal_address_title: "Add new address",
    text_modal_address_title_update: "Update address",
    text_modal_address_save_as_default: "Save as default address",
    text_modal_address_btn_refill: "Retry",
    text_modal_address_btn_save: "Save",
    /*Mini preview cart*/
    text_mini_preview_unit: "item(s)",
    text_mini_preview_describe_1: "You have",
    text_mini_preview_describe_2: "in your bag",
    text_mini_preview_total: "Total",
    text_mini_preview_btn_go_cart: "View Bag",
    text_mini_preview_empty: "No items in your shopping bag!",
    text_mini_preview_quantity: "Quantity",
    /*Menu account*/
    text_account: "Account",
    text_menu_wishlist: "Wishlist ",
    text_menu_address_book: "Address Book",
    text_menu_bills: "Your orders",
    text_menu_logout: "Sign out",
    text_menu_login: "Sign in",
    text_menu_bag: "Bag",
    /*Trang sổ địa chỉ*/
    text_address_book_title: "Your Address Book",
    text_address_book_add_new: "Add new address",
    text_address_book_btn_edit: "Update",
    text_address_book_btn_delete: "Delete",
    text_address_book_no_items: "You have not saved any address!",
    text_address_book_default: "Default",
    text_address_book_confirm_remove: "Do you want to delete this address?",
    text_address_book_confirm_delete_title: "Confirm",
    /*Trang danh sách đơn hàng*/
    text_bill_title: "Your orders",
    text_bill_status_all: "All",
    text_bill_status_delivered: "Done",
    text_bill_status_pending: "Pending",
    text_bill_status_discard: "Canceled",
    text_bill_total: "Total",
    text_bill_go_detail: "View detail order",
    text_bill_detail_title: "Order",
    text_bill_detail_delivery_info: "Delivery Infomation",
    text_bill_detail_delivery_status: "Order Status",
    text_bill_no_items: "You have not had any order!",
    text_bill_detail_customer: "Customer",
    text_bill_detail_receiver: "Shipping information",
    text_bill_detail_phone: "Phone",
    text_bill_detail_email: "Email",
    text_bill_detail_address: "Address",
    text_bill_detail_note: "Note",
    text_bill_detail_delivery_time: "Delivery time",
    /*Trang chi tiết sản phẩm */
    text_detail_tab_info_title: "Product Description",
    text_detail_tab_contact_title: "Contact - Consulting",
    text_detail_shipping_fee_des: "shipping fee is not included",
    text_detail_contact_order: "Contact",
    /*Trang wishlist  */
    text_wishlist_title: "Wishlist",
    text_wishlist_column_isorder: "Order",
    text_wishlist_column_image: "Image",
    text_wishlist_column_product: "Item",
    text_wishlist_column_attribute: "Type",
    text_wishlist_column_price: "Price",
    text_wishlist_column_quantity: "Quantity",
    text_wishlist_column_total: "Total",
    text_wishlist_column_remove: "Delete",
    text_wishlist_btn_add_bag: "Add to bag",
    text_wishlist_btn_choose_type: "Select",
    text_wishlist_no_items: "You have not loved any product!",
    text_wishlist_warning_noitems_order:
      "Have you select the products to order yet!",
    text_wishlist_warning_item_outofstock: (name, number) =>
      `Item ${name} ${
        number > 0 ? `just only ${number} left` : "out of stock"
      }!`,
    text_wishlist_warning_item_miss_field: (name) =>
      `Item ${name} has not been selected type!`,
    text_wishlist_add_to_bag_success: "Added to your bag",
    /*Trang bolg */
    text_blog_comment_title: "Give your comment",
    text_blog_comment: "Comment",
    /*Trang slug */
    text_slug_no_exist: "This information is not founded!",
    /********* Meta default ********/
    /* ---- Trang  address book*/
    meta_keywords_default_address_book: "Address Book",
    meta_description_default_address_book: "Your default delivery address list",
    /* ---- Trang  cart*/
    meta_keywords_default_cart: "Shopping bag, cash, order",
    meta_description_default_cart:
      "Your shopping bag, you can order everything in here",
    /* ---- Trang  wishlist*/
    meta_keywords_default_wishlist: "Wishlist",
    meta_description_default_wishlist:
      "Your wishlist, you can save everything you like in here",
    /** Others Text */
    text_notify: "would like to inform",
  },
  addTextFrmConfig: (configText) => {
    if (typeof configText === "object") {
      Object.keys(configText).forEach((k) => {
        if (typeof configText[k] === "object") {
          Object.keys(configText[k]).forEach((l) => {
            ShopText[l][k] = configText[k][l];
          });
        }
      });
    }
  },
};
export default ShopText;
