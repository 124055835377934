import React from 'react'
import MyPage from './MyPage';
import MyPageHelpers from './MyPageHelpers';
class MyPageFromConfig extends React.Component {    
  render(){
    const {configPage, customConfig, fnList} = this.props;
    console.log("configPage", configPage)
    return (
      <MyPage {...this.props} customConfig={customConfig||{}} configInConfigPage={MyPageHelpers.parseConfigInConfigPage(configPage)}/>
    )
  } 
}

export default MyPageFromConfig