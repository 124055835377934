import React from 'react'
import PageExt from 'components/MyPage/Types/_ext';
import {HApiAxios, HText, HConfig, HLocalStorage,HUtils, HLink, HEncode} from 'apps/Helpers';
import MyPageFromConfig from 'components/MyPage/MyPageFromConfig'
import MyUI from 'components/MyUI/MyUI'

class SC_Slug extends PageExt {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            error: null,
            isLoading: true
        }
        this.parseParams();
        this._fnList = {
            getParams: ()=>{
                return this._params;
            },
            getConfig: ()=>{
                return this._config;
            },
            changeState:(obj={})=>{
                let _query = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
                window.history.pushState({},document.title,window.location.pathname + '?' + _query)
            }
        }
    }
    componentDidMount(){
        this.requestSlug()
    }
    parseParams=()=>{
        this._params = HLink.getAllUrlParams(window.location.href);
        this._config = {};
        if(this._params){
            // console.warn("_params", this._params);
            const _appName = HConfig.fnList.getConfig("appName");
            const _filterRange = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "filterPriceRange") || {min: 0, max: 2000000}
            const _pagesize = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "pageSizeOptions");
            const _sortType = HUtils.Obj.get(HLocalStorage.getObject(`${_appName}_defaultOptions`, {}), "CommandType", 1)
            this._config.page = this._params.page || 1;
            // this._config.viewMode = this._params.viewMode || 0;
            this._config.pageSize = this._params.pageSize || HLocalStorage.getObject(`${_appName}_pageSize`, _pagesize?.[0].Value);
            this._config.sortType = this._params.sortType || _sortType;
            this._config.priceMin = this._params.priceMin || _filterRange.min;
            this._config.priceMax = this._params.priceMax || _filterRange.max;                       
        }
    }

    requestSlug=()=>{
        const params = this.props.match && this.props.match.params;
        const _query = {
            Page: parseInt(this._config.page),
            CommandType: parseInt(this._config.sortType),
            PageSize: parseInt(this._config.pageSize),
            PriceMin: parseInt(this._config.priceMin),
            PriceMax: parseInt(this._config.priceMax)
        }
        if(params && params.Slug){
            this.setState({
                isLoading: true
            },()=>{
                HApiAxios.generic({
                    request: {
                        method: 'POST',
                        path: "DashboardForWebsite",
                        name: "GetListDataWithSlug"
                    },
                    data:{
                        Slug: params.Slug,
                        ..._query
                    },
                    successCallBack: (response)=>{
                        if(response.Data){
                            this.setState({
                                data: response.Data,
                                isLoading: false,
                                error:false
                            },()=>{
                                const _slug = HUtils.Obj.get(response, "Data.ExtraData.SlugInfo.Slug");
                                const _isExistedSlug = _slug ? true : false;
                                if(_isExistedSlug === false){
                                    const _queryFromRecentlyView = this.getQueryDirectFromRecentlyViewSignal();
                                    if(_queryFromRecentlyView?.MaterialId){
                                        this.requestNewSlugRecentlyProduct(_queryFromRecentlyView.MaterialId)
                                    }
                                }
                                else{
                                    const _entity = HUtils.Obj.get(response, "Data.ExtraData.SlugInfo.EntityName");
                                    if(_entity === "Material" && window.location.href.indexOf("recently") > -1){
                                        HLink.setNewUrlWithoutReload(_slug)
                                    }
                                }
                            })
                        }
                    },
                    errorCallBack:(error,response)=>{
                        this.setState({
                            isLoading: false,
                            error: error
                        })
                    }
                })
            })            
        }
    }
    requestNewSlugRecentlyProduct = (id) =>{
        this.setState({
            isLoading: true
        },()=>{
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    path: "DashboardForWebsite",
                    name: `GetOneMaterial4Website/${id}`
                },
                successCallBack: (response)=>{
                    const newSlug = HUtils.Obj.get(response, "Data.Slug");
                    if(newSlug){
                        HLink.push(newSlug)
                    }
                },
                errorCallBack:(error,response)=>{
                    this.setState({
                        isLoading: false,
                        error: error
                    })
                }
            })
        })  
    }
    getEntityName=()=>{
        const {data} = this.state;
        return HUtils.Obj.get(data,"ExtraData.SlugInfo.EntityName");
    }
    getQueryDirectFromRecentlyViewSignal = () =>{
        let _allParams = HLink.getAllUrlParams(window.location.href);
        if(_allParams?.recently){
            return{
                MaterialId: HEncode.Base64.decode(_allParams.recently)
            }
        }
        return null;
    }
    renderCategory=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Category"
                    }}
                    fnList={this._fnList}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderProduct=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Product_Detail"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderBlog=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Blog"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderTag=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Tags"
                    }}
                    fnList={this._fnList}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderPageContent=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "Sc_Page_Content"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderQuickOrderGrp=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_QuickOrderGroup"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderQuickOrder=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_QuickOrder"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderQuickOrderGrp_V2=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_QuickOrder_Combination"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    render(){
        const {isLoading} = this.state;
        const entity = this.getEntityName();
        // console.warn("render:",isLoading,slugName)
        if(entity!=null){
            return(
                <div>
                    {
                        entity == "Material" && this.renderProduct()
                    }
                    {
                        entity == "GrpMaterial" && this.renderCategory()
                    }
                    {
                        entity == "BlogPost" && this.renderBlog()
                    }
                    {
                        entity == "Tag" && this.renderTag()
                    }
                    {
                        entity == "Topic" && this.renderPageContent()
                    }
                    {
                        entity == "QuickOrder" && this.renderQuickOrder()
                    }
                    {
                        entity == "QuickOrderGrp" && this.renderQuickOrderGrp()
                    }
                    {
                        entity == "QuickOrderGrp_V2" && this.renderQuickOrderGrp_V2()
                    }
                </div>
            )
        }
        return(
            <div className="master-wrapper-content">
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="section">
                            {
                                isLoading!==false?
                                <MyUI type="sc_loading"/>
                                :
                                <div className="title">{HText.get("text_slug_no_exist")}</div>
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_Slug