import React from 'react'
import {MyLoadingApp} from '../../../../Components';
import {HApiAxios, HUtils} from '../../../../Helpers'
import SCBillDetail from './SC_Bill_Detail'

class SC_Bill_Public extends SCBillDetail{
    constructor(props){
        super(props);
        this._isPublic = true;
    }
    _getGUID=()=>{
        return HUtils.Obj.get(this.props,'match.params.GUID');        
    }
    requestGetBill=()=>{
        const GUID = this._getGUID();
        // console.warn("GUID",this.props,GUID);
        if(GUID){
            this.showLoadingApp()
            HApiAxios.generic({
                request:{
                    method: 'POST',
                    url: `BillFromWeb/GetBillInfoWebWithGuid/${GUID}`
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        this.setState({
                            data: response.Data
                        })
                    }
                    this.hideLoadingApp()
                },
                errorCallBack: (error, response)=>{
                    MyLoadingApp.Helpers.show({error: error || response.Msg})
                }
            })
        }
    }
}
export default SC_Bill_Public