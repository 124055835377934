import React from "react";
import MyPageTypes from "../../../../components/MyPage/MyPageTypes";
import MyLoadingComponent from "../../../../components/MyLoading/MyLoadingComponent";

import SC_Home from "./Types/SC_Home";
import SC_Category from "./Types/SC_Category";
import SC_PageContent from "./Types/SC_PageContent";
import SC_ProductDetail from "./Types/SC_Product_Detail";
import SC_Slug from "./Types/SC_Slug";
import SC_BlogHome from "./Types/SC_BlogHome";
import SC_Blog from "./Types/SC_Blog";
import SC_Search from "./Types/SC_Search";
import SC_Bill_Detail from "./Types/SC_Bill_Detail";
import ScBillPublic from "./Types/SC_Bill_Public";
import SC_Tags from "./Types/SC_Tags";
import SC_Admin from "./Types/SC_Admin";
import SC_QuickOrder from "./Types/SC_QuickOrder";
import SC_QuickOrderGroup from "./Types/SC_QuickOrderGroup";
import SC_QuickOrderUser from "./Types/SC_QuickOrderUser";
import SC_QuickOrderCombination from "./Types/SC_QuickorderCombination";
import SC_StopWorking from "./Types/SC_Stop_Working";
// const SC_ProductDetail = React.lazy(() => import('./Types/SC_Product_Detail'));
// const SC_Slug = React.lazy(() => import('./Types/SC_Slug'));
// const SC_BlogHome = React.lazy(() => import('./Types/SC_BlogHome'));
// const SC_Blog = React.lazy(() => import('./Types/SC_Blog'));
// const SC_Search = React.lazy(() => import('./Types/SC_Search'));
// const SC_Bill_Detail = React.lazy(() => import('./Types/SC_Bill_Detail'));
// const SC_Tags = React.lazy(() => import('./Types/SC_Tags'));

const SC_ProductWishlist = React.lazy(() => import("./Types/SC_WishList"));
const SC_Cart = React.lazy(() => import("./Types/SC_Cart"));
const SC_AddressBook = React.lazy(() => import("./Types/SC_AddressBook"));
const SC_HistoryBill = React.lazy(() => import("./Types/SC_HistoryBill"));

const AppMyPage = {
  init() {
    MyPageTypes.addTypes("sc_home", (props) => {
      return <SC_Home {...props} />;
    });
    MyPageTypes.addTypes("sc_category", (props) => {
      return <SC_Category {...props} />;
    });
    MyPageTypes.addTypes("sc_page_content", (props) => {
      return <SC_PageContent {...props} />;
    });
    MyPageTypes.addTypes("sc_product_detail", (props) => {
      return <SC_ProductDetail {...props} />;
    });
    MyPageTypes.addTypes("sc_product_wishlist", (props) => {
      return <SC_ProductWishlist {...props} />;
    });
    MyPageTypes.addTypes("sc_cart", (props) => {
      return <SC_Cart {...props} />;
    });
    MyPageTypes.addTypes("sc_slug", (props) => {
      return <SC_Slug {...props} />;
    });
    MyPageTypes.addTypes("sc_blog_home", (props) => {
      return <SC_BlogHome {...props} />;
    });
    MyPageTypes.addTypes("sc_blog", (props) => {
      return <SC_Blog {...props} />;
    });
    MyPageTypes.addTypes("sc_addressbook", (props) => {
      return <SC_AddressBook {...props} />;
    });
    MyPageTypes.addTypes("sc_history_bill", (props) => {
      return <SC_HistoryBill {...props} />;
    });
    MyPageTypes.addTypes("sc_search", (props) => {
      return <SC_Search {...props} />;
    });
    MyPageTypes.addTypes("sc_quickorderuser", (props) => {
      return <SC_QuickOrderUser {...props} />;
    });
    MyPageTypes.addTypes("sc_bill_detail", (props) => {
      return <SC_Bill_Detail {...props} />;
    });
    MyPageTypes.addTypes("sc_bill_public", (props) => {
      return <ScBillPublic {...props} />;
    });
    MyPageTypes.addTypes("sc_tags", (props) => {
      return <SC_Tags {...props} />;
    });
    MyPageTypes.addTypes("sc_admin", (props) => {
      return <SC_Admin {...props} />;
    });
    MyPageTypes.addTypes("sc_quickorder", (props) => {
      return <SC_QuickOrder {...props} />;
    });
    MyPageTypes.addTypes("sc_quickordergroup", (props) => {
      return <SC_QuickOrderGroup {...props} />;
    });
    MyPageTypes.addTypes("sc_quickorder_combination", (props) => {
      return <SC_QuickOrderCombination {...props} />;
    });
    MyPageTypes.addTypes("sc_stop_working", (props) => {
      return <SC_StopWorking {...props} />;
    });
  },
};
AppMyPage.init();
export default AppMyPage;
