import React from 'react'
import {HFormat, HUtils, HConfig, HText} from 'apps/Helpers'
import {MyModal} from 'apps/Components'
import HShopUtils from 'apps/shoppingCart/helpers/Utils'
import './styles/_ext_cart_bill.css'
class ExtCart extends React.Component{
    initCollapseMobile = (data) =>{
        if(data && data.length > 0){
            let {expandMobile} = this.state;
            for(let i=0; i<data.length; i++){
                expandMobile[i] = false
            }
            this.setState({
                expandMobile: expandMobile
            })
        }
    }
    collapseMobileClick=(index)=>{
        let {expandMobile} = this.state;
        expandMobile[index] = !expandMobile[index]
        this.setState({
            expandMobile: expandMobile
        })
    }
    chackHasValue = (value) =>{
      return HShopUtils.checkHasValue(value)
    }
    getTotalMoney = (data) =>{
        const _QuantityMin = data._QuantityMin;
        const _Quantity = data.Quantity;
        const _SellPriceDiscount = data._SellPriceDiscount;
        const _SellPrice = data.SellPrice;
        if(this.chackHasValue(_QuantityMin) && _Quantity >= _QuantityMin){
            return HFormat.Money.formatMoneyForAjuma(_Quantity*_SellPriceDiscount)
        }
        return HFormat.Money.formatMoneyForAjuma(_Quantity*_SellPrice);
    }
    getConfigInventory=(item)=>{
      let _config = {};
      let _dontCareInventory = HUtils.Obj.get(item,"Inventory.DontCareInventory");
      let _contentDisplayOnWeb =  HUtils.Obj.get(item,"Inventory.ContentDisplayOnWeb");
      let _quantityInStock =  HUtils.Obj.get(item,"Inventory.Quantity");
      if(_dontCareInventory !== true){
          if(_quantityInStock <= 0){
              _config.show = true;
              _config.displayText = _contentDisplayOnWeb || HText.get("text_cart_out_of_stock"); 
          }
          else if(item.Quantity > _quantityInStock && _quantityInStock > 0){
              _config.show = true;
              _config.displayText = HText.get("text_cart_inventory_notify")(_quantityInStock)
          }
      }
      return _config
  }
    renderToggleMobile = (index) =>{
        const {expandMobile} = this.state;
        return(
            <div className="toggle-mobile-btn"
                onClick={()=>this.collapseMobileClick(index)}>
                    <i className={expandMobile[index] ? "fa fa-angle-up" : "fa fa-angle-down"} />
            </div>
        )
    }
    renderPrice = (data, { className, isMobile } = {}) => {
      const _Quantity = data.Quantity;
      const _SellPriceText = data.SellPriceText;
      const _QuantityMin = data._QuantityMin;
      const _SellPriceDiscount = data._SellPriceDiscount;
      const _SellPriceOriginal = data._SellPriceOriginal;
      let _uiPriceOrigin = null;
      let _uiPriceAfterDiscount = null;
      let _uiPriceDefault = null;
      if (this.chackHasValue(_QuantityMin) && _Quantity >= _QuantityMin) {
        _uiPriceOrigin = (
          <span
            className="product-unit-price origin"
            style={{
              textDecoration: "line-through",
              fontSize: 13,
              ...isMobile ? {marginLeft: "1rem"} : {marginRight: "1rem"},
            }}
          >
            {HFormat.Money.formatMoneyForAjuma(_SellPriceOriginal)}
          </span>
        );
        _uiPriceAfterDiscount = (
          <span className="product-unit-price">
            {HFormat.Money.formatMoneyForAjuma(_SellPriceDiscount)}
          </span>
        );
      } else {
        _uiPriceDefault = (
          <span className="product-unit-price">{_SellPriceText}</span>
        );
      }
      if (isMobile) {
        return (
          <div className={className}>
            {_uiPriceDefault}
            {_uiPriceAfterDiscount}
            {_uiPriceOrigin}
          </div>
        );
      }
      return (
        <div className={className}>
          {_uiPriceDefault}
          {_uiPriceOrigin}
          {_uiPriceAfterDiscount}
        </div>
      );
    };
    renderUpdateAttribute = (item, index, fnList) =>{
        MyModal.Helpers.showMyUI(
            "sc_modal_update_options",
            {
              data: item,
              index: index,
              fnList: fnList
            },
            { title: `Chỉnh sửa ${item?.MaterialName}`, closeButton: true, size: "lg", className: "mmd-card-product mmd-cart-update" }
        );
    }
    renderItemInventory = (item) =>{
        const _configShowInventory = this.getConfigInventory(item);
        if(_configShowInventory?.show){
            return (
              <div
                className="product-inventory-msg"
              >
                *{_configShowInventory.displayText}
              </div>
            );
        }
    }
    renderItemDiscount = (item) =>{
        const _QuantityMin = item._QuantityMin; //client
        const _Discount_Text = item._Discount_Text; //client
        if(this.chackHasValue(_QuantityMin) && _QuantityMin > 1){
            return <div className="product-coupon">{_Discount_Text}</div>
        }
    }
    renderItemAttributesMobile = (item, index) =>{
      const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
      if(_haveProductOptions){
          return(
              <div className="footable-row-detail-row">
                  <div className="footable-row-detail-name">{HText.get("text_wishlist_column_attribute")}: </div>
                  <div className="footable-row-detail-value">
                      {this.renderItemAttributes(item, index)}
                  </div>
              </div>
          )
      }
  }
}
export default ExtCart