import React from "react";
import './styles/zalo_chat.css'

class MyUI_ZaloChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { oaid } = this.props;
    return (
      <div
        className="zalo-chat-widget"
        data-oaid={oaid}
        data-welcome-message="Rất vui khi được hỗ trợ bạn!"
        data-autopopup="0"
        data-width=""
        data-height=""
      ></div>
    );
  }
}

export default MyUI_ZaloChat;
