import React from 'react'
import {HApiAxios, HLink, HUtils, HUI} from '../../../../Helpers'
import {MyModal, MyUI} from '../../../../Components'
import HShopUtils from '../../../helpers/Utils'

class SC_Editor_PageContent extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tabIndex: 0,
            content: {
                Title: null,
                IsActive: false,
                Body: null
            },
            dataWillUpdate: {}
        }
    }
    componentDidMount(){
        const {data} = this.props;
        if(data){
            this.setState({
                content: {
                    Title: data.Title,
                    Body:data.Body||"",
                    IsActive: data.IsActive,
                    MetaTitle: data.MetaTitle || "",
                    MetaKeyWords: data.MetaKeyWords || "",
                    MetaDescription: data.MetaDescription || "",
                    Slug: data.Slug || "",
                    ParentId: data.ParentId || ""
                },
            })
        }
    }
    requestUpdateContent=()=>{
        const {data, fnList,isNew} = this.props;
        const {dataWillUpdate} = this.state;

        let _values = [];
        let _keys = Object.keys(dataWillUpdate);
        for(let i=0; i<_keys.length; i++){
            // console.warn("_values", dataWillUpdate, _keys, data)
            const k = _keys[i];
            if(isNew){
                _values.push({FieldName: k, NewValue: dataWillUpdate[k]})
            }
            else if(dataWillUpdate[k] != data[k]){
                _values.push({FieldName: k, NewValue: dataWillUpdate[k]})
            }
        }

        if(data || isNew == true){
            if(_values && _values.length == 0){
                HUI.Toast.showWarn("Nội dung chưa có thay đổi nào!");
                return;
            }
            if((dataWillUpdate.hasOwnProperty("Slug") == true && (dataWillUpdate.Slug == "" || dataWillUpdate.Slug == null)) || (isNew == true && dataWillUpdate.hasOwnProperty("Slug") == false)){
                HUI.Toast.showWarn("Bạn chưa nhập link cho bài viết này!");
                return;
            }
            const _url = isNew == true ? 'Dashboard/CreateDataWithSlug' : 'Dashboard/UpdateDataWithSlug'
            const _query = isNew == true ? {Values: _values, Type: 'Topic'} : {Values: _values, Slug: data.Slug}
            // console.warn("_query", _query)
            HApiAxios.generic({
                request:{
                    method: 'POST',
                    url: _url
                },
                data: _query,
                successCallBack: (response)=>{
                    HUI.Toast.showSuccess(response.Msg)
                    const _slug = HUtils.Obj.get(response, "Data.Slug");
                    if(isNew || _slug !== data.Slug){
                        if(_slug){
                            HLink.push(_slug)
                        }
                    }     
                    else{
                        fnList && fnList.updateContent(response.Data);
                    }
                    MyModal.Helpers.hide()
                },
                errorCallBack: (error, response)=>{
                    // MyLoadingApp.Helpers.show({error: error||response.Msg});
                }
            })
        }
    }
    onSave=()=>{
        if(this._editor_html && this._editor_html.getData){
            let _content = this._editor_html.getData();
            if(this.state.content.Body!=_content && _content!=null){
                this.state.content.Body = _content;
                this.state.dataWillUpdate.Body = _content;
            }
            this.requestUpdateContent();
        }
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index
        })
    }
    onChangeValue=(field, value)=>{
        const {isNew} = this.props;
        let {dataWillUpdate, content} = this.state;
        if(field == "Title" && isNew == true){
            const _slugGeneration = HShopUtils.slugGeneration(value);
            content.Slug = _slugGeneration;
            dataWillUpdate.Slug = _slugGeneration;
        }
        dataWillUpdate[field] = value;
        content[field] = value;
        this.setState({
            content: content,
            dataWillUpdate: dataWillUpdate,
        })
    }
    renderTab = () =>{
        const {tabIndex} = this.state;
        return(
            <div>
                <div className={`label ${tabIndex==0?'':'inactive'}`} onClick={()=>{this.onChangeTab(0)}}>Thông tin</div>
                <div className={`label ${tabIndex==1?'':'inactive'}`} onClick={()=>{this.onChangeTab(1)}}>Nội dung</div>                        
            </div>
        )
    }
    renderContent = () =>{
        const {content, tabIndex} = this.state;
        const {isNew, options} = this.props;
        return(
            <div style={{minHeight:'120px',width:'100%',marginLeft:'0.5rem'}}>
                {
                    tabIndex == 0 &&
                    <div>
                        <div className="group-input">
                            <MyUI type="input_text" defaultValue={content.Title} onBlur={(e, newValue)=>this.onChangeValue("Title", newValue)} style={{height: '40px'}}/>
                            <label className={content.Title ? "active" : ""}>Tiêu đề</label>
                        </div>
                        <div className="group-input">
                            <MyUI type="input_text" defaultValue={content.Slug} onBlur={(e, newValue)=>this.onChangeValue("Slug", newValue)} style={{height: '40px'}}/>
                            <label className={content.Slug ? "active" : ""}>Link</label>
                        </div>
                        {
                            options && (
                                <div className="group-input">
                                    <MyUI type="select2" placeholder="Chọn group" multi={false} style={{height: '40px', border: '0px'}} simpleValue options={options} value={content.ParentId} onChange={(value)=>this.onChangeValue("ParentId", value)}/>
                                </div>
                            )
                        }
                        <div className="group-input">
                            <MyUI type="input_text" placeholder="Meta Title" style={{marginBottom:'0.5rem'}} defaultValue={content.MetaTitle} onBlur={(e, newValue)=>this.onChangeValue("MetaTitle", newValue)}/>
                            <label className={content.MetaTitle ? "active" : ""}>Meta Title</label>
                        </div>
                        <div className="group-input">
                            <MyUI type="input_text" style={{marginBottom:'0.5rem'}} placeholder="Meta KeyWords" defaultValue={content.MetaKeyWords} onBlur={(e, newValue)=>this.onChangeValue("MetaKeyWords", newValue)}/>
                            <label className={content.MetaKeyWords? "active" : ""}>Meta KeyWords</label>
                        </div>
                        <div className="group-input">
                            <MyUI type="input_textarea" style={{marginBottom:'0.5rem'}} placeholder="Meta Description" defaultValue={content.MetaDescription} onBlur={(e, newValue)=>this.onChangeValue("MetaDescription", newValue)}/>
                            <label className={content.MetaDescription? "active" : ""}>Meta Description</label>
                        </div>
                    </div>
                }
                {
                    (content.Body!=null ||isNew == true )&&
                    <div style={{display:`${tabIndex==1?'block':'none'}`}}>
                        <MyUI 
                            type="html_editor_ckeditor" 
                            className="html-content" 
                            html={content.Body} 
                            pluginsMore= {{
                                toolbar: ["htmlEmbed"],
                            }}
                            innerRef={(r)=>{
                            this._editor_html = r;
                            }}
                        />
                    </div>    
                }
            </div>
        )
    }
    render(){
        // console.warn("props", this.props)
        return(
            <div className="sc_editor_blog">
                <div className="blog-row">
                    { this.renderTab() }
                    { this.renderContent() }
                </div>                                          
                <div className="blog-row">
                    <div></div>
                    <button onClick={()=>this.onSave()} className="btn-save-edit" style={{float: 'right'}}>Cập nhật</button>
                </div>
            </div>
        )
    }
}
export default SC_Editor_PageContent