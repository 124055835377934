import React, { Component } from 'react';
import MyIconSvgTypes from './MyIconSvgTypes';
class MySVG extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }
  render() {
    const {type} = this.props;
    if(type && MyIconSvgTypes.Types[type]){
      const _ui = MyIconSvgTypes.Types[type](this.props);
      return _ui;
    }
    return (
      <div>MyIconSVG No Type</div>
    )
  }
}

export default MySVG;
