import React from 'react'
import './styles/sc_breakcumb.css'

class SC_BreakCumb extends React.Component{
    renderBreakcumb=()=>{
        const {data} = this.props;
        let _ui = [];
        if(data && data.length>0){
            for(let i=0; i <data.length - 1; i++){
                const _item = data[i]
                _ui.push(
                    <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={i}>
                        <a itemProp="item" href={_item.Link}>{_item.Title}</a>
                        <span itemProp="name" className="delimiter">{_item.Title}</span>
                        <meta itemProp="position" content={i+1} />
                    </li>
                )
            }
            _ui.push(
                <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={data.length-1}>
                    <strong className="current-item">{data[data.length-1].Title}</strong>
                    <span itemProp="name" className="delimiter" style={{display:'none'}}>{data[data.length-1].Title}</span>
                    <meta itemProp="position" content={data.length} />
                </li>
            )
        }
        return _ui
    }
    render(){
        return(
            <div className="breadcrumb">
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <a itemProp="item" href="/">Trang chủ</a>
                        <span itemProp="name" className="delimiter">Trang chủ</span>
                        <meta itemProp="position" content="1" />
                    </li>
                    {
                        this.renderBreakcumb()
                    }
                </ol>
            </div>
        )
    }
}
export default SC_BreakCumb