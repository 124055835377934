import React from "react";
import PageExt from "components/MyPage/Types/_ext";
import { HUtils, HFormat } from "apps/Helpers";
import "./styles/sc_quickordergroup.css";

class SC_QuickOrderExt extends PageExt {
  getTotalMoney = (array) => {
    let _totalMoney = 0;
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i++) {
        const _p = array[i];
        const _Quantity = _p.Quantity;
        const _SellPrice = _p.SellPrice;
        const _QuantityMin = HUtils.Obj.get(_p, "DiscountInfo.QuantityMin");
        const _DiscountMoney = HUtils.Obj.get(_p, "DiscountInfo.DiscountMoney");
        let _tt = _Quantity * _SellPrice;
        if (_QuantityMin >= 0 && _DiscountMoney && _Quantity >= _QuantityMin) {
          _tt = (_SellPrice - _DiscountMoney) * _Quantity;
        }
        _totalMoney += _tt;
      }
    }

    return _totalMoney;
  };
  renderPriceProduct = (item) => {
    const _IsCall4WebOrder = item.IsCall4WebOrder;
    const _SellPriceText = item.SellPriceText;
    const _SellPrice = item.SellPrice;
    const _UnitName = item.UnitName;
    const _QuantityMin = HUtils.Obj.get(item, "DiscountInfo.QuantityMin");
    const _Quantity = item.Quantity;
    const _DiscountMoney = HUtils.Obj.get(item, "DiscountInfo.DiscountMoney");
    const _priceAfterDiscount =
      _QuantityMin >= 0 && _DiscountMoney && _Quantity >= _QuantityMin
        ? HFormat.Money.formatMoneyForAjuma(_SellPrice - _DiscountMoney)
        : 0;

    return (
      <span
        className="product-unit-price"
        style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
      >
        {!_IsCall4WebOrder && _priceAfterDiscount !== 0 && (
          <span className="price-discount">{_priceAfterDiscount}</span>
        )}
        <div>
          <span
            className={`price-actual${
              _priceAfterDiscount !== 0 ? " has-discount" : ""
            }`}
          >
            {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
          </span>
          {!_IsCall4WebOrder && _UnitName && <span>{` / ${_UnitName}`}</span>}
        </div>
      </span>
    );
  };
  renderProductDetail = (item) => {
    const _Slug = item.Slug;
    const _MaterialCode = item.MaterialCode;
    const _MaterialName = item.MaterialName;
    return (
      <div className="product-name">
        <a href={_Slug}>
          {_MaterialCode}
          <br />
          {_MaterialName}
        </a>
        {this.renderProductCoupon(item)}
      </div>
    );
  };
  renderProductCoupon = (item) => {
    const _Discount_Text = HUtils.Obj.get(item, "DiscountInfo.Discount_Text");
    const _QuantityMin = HUtils.Obj.get(item, "DiscountInfo.QuantityMin");

    return (
      _QuantityMin > 1 &&
      _Discount_Text && <div className="product-coupon">{_Discount_Text}</div>
    );
  };
  renderProductTotal = (item) => {
    const _Quantity = item.Quantity;
    const _SellPrice = item.SellPrice;
    const _QuantityMin = HUtils.Obj.get(item, "DiscountInfo.QuantityMin");
    const _DiscountMoney = HUtils.Obj.get(item, "DiscountInfo.DiscountMoney");
    let _price = _SellPrice;
    if (_QuantityMin >= 0 && _DiscountMoney && _Quantity >= _QuantityMin) {
      _price = _SellPrice - _DiscountMoney;
    }
    return (
      <span className="product-subtotal">
        {HFormat.Money.formatMoneyForAjuma(_price * _Quantity)}
      </span>
    );
  };
}
export default SC_QuickOrderExt;
