import React from "react";
import { MyUI, MyModal } from "apps/Components";
import { HUtils } from "../../../../Helpers";
import { CButton } from "components/MyCore";
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import SC_GridView from "./sc_grid_view";
import "./styles/sc_quickorder_optional_order.css";

class SC_UserQuickOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      materialsList: this.getDataFromParent(),
      data: this.props.data,
    };
  }
  static getDerivedStateFromProps(props, state) {
    // console.warn("getDerivedStateFromProps",props,state);
    const defaultData = {
      MaterialId: null,
      MaterialCode: "TT",
      MaterialName: "",
      Quantity_Text: "",
    };
    const getDataFromProps = () => {
      const { data, noGenerate } = props;
      if (data?.length > 0) {
        if (!noGenerate) {
          let _isHasEmptyRow = false;
          data.forEach((v) => {
            if (v.MaterialName === "" && v.Quantity_Text === "") {
              return true;
            }
          });
          if (_isHasEmptyRow) {
            return data;
          }
          return [
            ...data,
            defaultData
          ];
        }
        return data;
      }
      return [defaultData];
    };
    if (props.data !== state.materialsList) {
      return {
        data: props.data,
        materialsList: getDataFromProps(),
      };
    }
    return null;
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.isVertical !== nextProps.isVertical) {
      this._gridView?.getStyle &&
        this._gridView.getStyle({
          otherSettings: this.getSettingsGrid(nextProps),
        });
    }
    return true;
  }
  getDataFromParent = () => {
    const { data, noGenerate } = this.props;
    const defaultData = this.getDefaultMaterial();
    if (data?.length > 0) {
      if (!noGenerate) {
        let _isHasEmptyRow = false;
        data.forEach((v) => {
          if (v.MaterialName === "" && v.Quantity_Text === "") {
            return true;
          }
        });
        if (_isHasEmptyRow) {
          return data;
        }
        return [
          ...data,
          defaultData
        ];
      }
      return data;
    }
    return [defaultData];
  };
  getDefaultMaterial = () => {
    return {
      MaterialId: null,
      MaterialCode: "TT",
      MaterialName: "",
      Quantity_Text: "",
    };
  };
  getSettingsGrid = (props) => {
    const { isVertical } = props;
    if (isVertical) {
      return [{ width: 0, column: 1 }];
    }
    return [
      { width: 1100, column: 3 },
      { width: 600, column: 2 },
      { width: 0, column: 1 },
    ];
  };
  checkValueZero = (value) => {
    if (!Number.isNaN(value) && parseFloat(value) === 0) {
      return true;
    }
    return false;
  };
  checkHasValue = (value) => {
    if (value === undefined) {
      return false;
    } else if (value === null) {
      return false;
    } else if (value === "") {
      return false;
    }
    return true;
  };
  onChangeValue = (field, value, index) => {
    const { onChange, noGenerate } = this.props;
    let { materialsList } = this.state;
    if (
      value !== materialsList[index][field]
    ) {
      materialsList[index][field] = value;
      onChange && onChange(index, materialsList[index]);
      if(this.checkHasValue(value) && index === materialsList.length - 1 &&
        !noGenerate)
        {
          materialsList.push(this.getDefaultMaterial());
        }
    }
    this.setState(
      {
        materialsList: materialsList,
      }
    );
  };
  onDeleteItem = (index) => {
    const { onDelete, noGenerate } = this.props;
    let { materialsList } = this.state;
    if (index == 0 && materialsList.length == 1 && !noGenerate) {
      materialsList[index] = this.getDefaultMaterial();
    } else {
      materialsList.splice(index, 1);
    }
    this.setState(
      {
        materialsList: materialsList,
      },
      () => {
        onDelete && onDelete(index);
      }
    );
  };
  renderItem = (item, index, opts) => {
    if (item) {
      const { isVertical } = this.props;
      const showCloseBtn = opts?.showCloseBtn;
      return (
        <div key={index} className={`item${isVertical ? " vertical" : ""}`}>
          <div>
            <label>Tên sản phẩm</label>
            <MyUI
              type="input_text"
              defaultValue={item.MaterialName}
              placeholder="Tên sản phẩm"
              onBlur={(e, newValue) => {
                this.onChangeValue("MaterialName", newValue, index);
              }}
            />
          </div>
          <div>
            <label>Số lượng</label>
            <MyUI
              type="input_text"
              defaultValue={item.Quantity_Text}
              placeholder="Số lượng"
              onBlur={(e, newValue) => {
                if (this.checkValueZero(newValue)) {
                  this.onDeleteItem(index);
                } else {
                  this.onChangeValue("Quantity_Text", newValue, index);
                }
              }}
            />
          </div>
          {
            !showCloseBtn && (
              <CButton
                className="btn-remove"
                onClick={() => this.onDeleteItem(index)}
              >
                <MyIconSvg
                  type="times"
                  width={20}
                  height={20}
                  fill="var(--color-secondary)"
                />
              </CButton>
            )
          }
        </div>
      );
    }
  };
  renderMaterialList = () => {
    const { noGenerate } = this.props;
    const { materialsList } = this.state;
    return materialsList.map((v, i) => {
      const showCloseBtn =
        !noGenerate && i === 0 && materialsList.length === 1 ? true : false;
      return this.renderItem(v, i, { showCloseBtn: showCloseBtn });
    });
  };
  render() {
    return (
      <div className="sc_card_optional_order">
        <SC_GridView
          ref={(r) => (this._gridView = r)}
          type="sc_grid_view"
          settings={this.getSettingsGrid(this.props)}
          renderComponent={() => this.renderMaterialList()}
        />
      </div>
    );
  }
}
export default SC_UserQuickOrder;
