import React from "react";
import { MyUI } from "apps/Components";
import { HText } from "apps/Helpers";
import Slider from "libs/ReactSlick/index";
import "./styles/sc_home.css";

class HomePromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      uiType: 1,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getConfigSlide);
  }
  componentDidMount() {
    this.getConfigSlide();
    window.addEventListener("resize", this.getConfigSlide);
  }
  getConfigSlide = () => {
    const { uiType } = this.state;
    const _screenWidth = window.innerWidth;
    const _numberItemToShow = this.getConfigFromConfigItem("NumberItemToShow");
    let _st = {
      dots: false,
      arrows: true,
      infinite: false,
      autoplay: false,
      speed: 2000,
    };
    switch (uiType) {
      case 0:
        if (_screenWidth >= 1281) {
          _st.slidesToShow = 5;
          _st.slidesToScroll = 5;
        } else if (_screenWidth <= 1280 && _screenWidth >= 769) {
          _st.slidesToShow = 4;
          _st.slidesToScroll = 4;
        } else if (_screenWidth <= 768 && _screenWidth >= 576) {
          _st.slidesToShow = 3;
          _st.slidesToScroll = 3;
        } else {
          _st.slidesToShow = 1;
          _st.slidesToScroll = 1;
        }
        break;
      case 1:
        if (_screenWidth >= 769) {
          _st.slidesToShow = _numberItemToShow;
          _st.slidesToScroll = _numberItemToShow;
        } else {
          _st.slidesToShow = 1;
          _st.slidesToScroll = 1;
        }
        break;
      default:
        break;
    }
    this.setState({
      settings: _st,
    });
  };
  getConfigFromConfigItem = (key) => {
    // const { config } = this.props;
    const config = {
      NumberItemToShow: 2,
      // Total: 3,
      // ConfigPagination: {
      //   using: true,
      //   method: "POST",
      //   apiUrl: "DashboardForWebsite/GetListMaterial4Website",
      // },
    }
    if (key && config?.[key]) {
      return config[key];
    }
    return null;
  };
  renderList = (data) => {
    const { uiType } = this.state;
    return data.map((v, i) => {
      let _cardType = "sc_card_product";
      if (uiType === 1) {
        _cardType = "sc_card_product_promotion";
      }
      return <MyUI type={_cardType} data={v} key={i} />;
    });
  };
  renderSlide = (data) => {
    const { settings } = this.state;
    return <Slider {...settings}>{this.renderList(data)}</Slider>;
  };
  renderOneLine = (data) => {
    return <div className="no-slide">{this.renderList(data)}</div>;
  };
  renderListWithPagination = (data) => {
    const { settings } = this.state;
    if (settings?.slidesToShow) {
      return (
        <MyUI
          type="sc_custom_slide"
          data={data}
          config={{
            total: this.getConfigFromConfigItem("Total"),
            configPagination: this.getConfigFromConfigItem("ConfigPagination"),
            numberToShow: settings?.slidesToShow,
          }}
          renderChildren={(dataFromLayout) => this.renderList(dataFromLayout)}
        />
      );
    }
  };
  renderContent = (data) => {
    const { settings } = this.state;
    const configPagination = this.getConfigFromConfigItem("ConfigPagination");
    if (configPagination?.using === true) {
      return this.renderListWithPagination(data);
    } else if (data.length < settings.slidesToShow) {
      return this.renderOneLine(data);
    } else if (data.length >= settings.slidesToShow) {
      return this.renderSlide(data);
    }
  };
  render() {
    const { data, config } = this.props;
    if (data?.length > 0) {
      const _title = config?.Title;
      const _link = config?.Link;
      return (
        <div className="home_promotion product-grid">
          <div className="title">
            <div className={`text${_link ? " has-link": ""}`}>{_title}</div>
            {
              _link && <div className="btn-see-more"><a href={_link}>{HText.get("btn_see_more")}</a></div>
            }
          </div>
          <div className="lst">{this.renderContent(data)}</div>
        </div>
      );
    }
    return <div></div>;
  }
}
export default HomePromotion;
