import React from 'react'
import {
  CButton, CTooltip
} from '../../MyCore'
import MyButtonHelpers from '../../MyButton/MyButtonHelpers';
const Buttons = (props) => {
  const {cButton,onClick} = props;
  return (
    <CButton {...MyButtonHelpers.parseConfigToProps(props)} onClick={onClick}>
      {MyButtonHelpers.getDisplay(props)}
    </CButton>
  )
}

export default Buttons
