import React from "react";
import { MyUI } from "../../../../Components";
import { HApiAxios, HUI, HUtils, HConfig } from "../../../../Helpers";
import "./styles/sc_editor_banner.css";

// Jira https://allianceitscvn.atlassian.net/browse/AWO-190
class SC_Editor_Home_Cat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeCatList: [],
    };
  }
  componentDidMount() {
    this.requestCategoryList();
  }
  requestCategoryList = () => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "EzyWeb_Collection/List",
      },
      data: {},
      successCallBack: (response) => {
        const _data = HUtils.Obj.get(response, "Data.Data");
        if (_data) {
          this.setState({
            homeCatList: _data.filter(
              (i) => i.CollectionType === "home_cat_product"
            ),
          });
        }
      },
      errorCallBack: (error, response) => {},
    });
  };
  requestUpload = (item, files) => {
    // console.warn("item", item)
    HApiAxios.generic({
      request: {
        method: "UPLOAD",
        path: "ProjectPhoto",
        name: "Upload",
      },
      files: files,
      customHeader: {
        ProjectId: "",
        ScreenCode: "EZYWEB_COLLECTION",
        Id: item.Id,
        FieldName: "Files_0",
      },
      successCallBack: (response) => {
        this.requestCategoryList();
      },
      errorCallBack: (error, response) => {},
    });
  };
  requestUpdateField = (item, oldValue, fieldName, newValue) => {
    // console.warn("old, new", oldValue, newValue)
    if (newValue !== null && newValue !== "" && newValue !== oldValue) {
      const _query = {
        Id: item.Id,
        Values: [{ FieldName: fieldName, NewValue: newValue }],
      };
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "EzyWeb_Collection/UpdateFields",
        },
        data: _query,
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          this.requestCategoryList();
        },
        errorCallBack: (error, response) => {},
      });
    }
  };
  render() {
    const { homeCatList } = this.state;
    // console.warn("homeCatList", homeCatList);
    return (
      <div className="sc_editor_banner">
        <div
          className="content"
          style={{ borderTop: "none", padding: 0, marginLeft: "8px" }}
        >
          {homeCatList != null &&
            homeCatList.map((v, i) => {
              const _img = HUtils.Obj.get(v, "Files_0.AvatarPhoto.FileUrl");
              return (
                <div
                  key={i}
                  style={{
                    marginRight: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ position: "relative" }} key={i}>
                    <img src={_img || HConfig.fnList.getConfig("imageProductDefault")} />
                    <div
                      title={"Tải ảnh"}
                      style={{
                        width: "25px",
                        height: "25px",
                        position: "absolute",
                        right: "0px",
                        bottom: "5px",
                        cursor: "pointer",
                        color: "white",
                        background: "var(--color-secondary)",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        htmlFor={`image ${v.Id}`}
                        style={{ clear: "both", margin: 0 }}
                      >
                        <i className="fa fa-upload" />
                      </label>
                      <input
                        id={`image ${v.Id}`}
                        type="file"
                        accept="*"
                        onChange={(e) => {
                          this.requestUpload(v, e.target.files);
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    key={`${i}-${new Date().getTime()}`}
                  >
                    <div className="group-input" style={{ margin: "10px 0px" }}>
                      <MyUI
                        type="input_text"
                        defaultValue={v.Title}
                        style={{ width: "385px" }}
                        onBlur={(e, value) =>
                          this.requestUpdateField(v, v.Title, "Title", value)
                        }
                      />
                      <label
                        className={v.Title ? "active" : ""}
                        style={{ background: "#fff" }}
                      >
                        Title
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default SC_Editor_Home_Cat;
