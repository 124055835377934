import React from "react";
import { MyUI } from "../../../../Components";
import "./styles/sc_modal_editor_attribute_image_product.css";

class SC_ModalEditorAttributeImageProduct extends React.Component {
  render() {
    const {
      optionsImage,
      data,
      onChangeOrderImage,
      onChangeProductOption,
      renderAction,
      fnFomModal,
    } = this.props;
    if (data) {
      const _OrderNo = data.OrderNo;
      const _ImageUrlOrigin = data.ImageUrlOrigin;
      const _ImageMaterialId = data.MaterialId; // MaterialId trong ImageList
      return (
        <div className="sc_modal_editor_attribute_image_product dFfCaSjCw100">
          <div className="header">
            <span
              className="dFaCjCw100"
              onClick={() => fnFomModal?.fnClose && fnFomModal.fnClose()}
            >
              <i className="fa fa-times" />
            </span>
          </div>
          <div className="content dFaSjCw100">
            <div className="dFaCjCw100" style={{ paddingRight: "1rem" }}>
              {_ImageUrlOrigin && <img src={_ImageUrlOrigin} />}
            </div>
            <div className="dFfCaSjCw100">
              <div className="action">
                {renderAction && renderAction(data, { fnFomModal: fnFomModal })}
              </div>
              <div className="dFfCaEjCw100">
                <div className="dFaCjCw100">
                  <div
                    className="group-input"
                    style={{ width: "100%", marginTop: 15 }}
                  >
                    <MyUI
                      type="input_text"
                      defaultValue={_OrderNo}
                      onBlur={(e, value) => {
                        if (value != _OrderNo) {
                          onChangeOrderImage &&
                            onChangeOrderImage("OrderNo", value, data);
                          fnFomModal?.fnClose && fnFomModal.fnClose();
                        }
                      }}
                    />

                    <label
                      className={_OrderNo != null ? "active" : ""}
                      style={{ background: "#fff", fontWeight: 500 }}
                    >
                      Thứ tự
                    </label>
                  </div>
                </div>
                {optionsImage && (
                  <div className="dFaCjCw100">
                    <div
                      className="group-input"
                      style={{ width: "100%", marginTop: 10 }}
                    >
                      <MyUI
                        type="select2"
                        options={optionsImage}
                        multi={false}
                        value={_ImageMaterialId}
                        simpleValue
                        onChange={(value) => {
                          onChangeProductOption &&
                            onChangeProductOption(value, data);
                          fnFomModal?.fnClose && fnFomModal.fnClose();
                        }}
                      />
                      <label
                        className={"active"}
                        style={{ background: "#fff", fontWeight: 500 }}
                      >
                        Loại sản phẩm
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_ModalEditorAttributeImageProduct;
