import React from "react";
import MyUI from "../MyUI";
import MyTableFilter from "../../MyTableFilter/MyTableFilter";
import MyTableFilterTypes from "../../MyTableFilter/MyTableFilterTypes";
import HUtils from "../../../helpers/Utils";
import HLocalStorage from "../../../helpers/LocalStorage";
import "./styles/page_header.css";

class MyUI_PageFilter extends React.Component {
  constructor(props) {
    super(props);
    this._currentFilter = {};
    this.state = {
      filter: [],
    };
    console.warn("constructor pf:", this.state);
  }
  componentDidMount() {
    this.getFilterFromExtraData();
    this.requestFilterLocal();
  }
  requestFilter=(filter, {noSaveToLocal}={})=>{
    const {fnList} = this.props;
    const _rememberFilter = this.getIsRememberFilter();
    if(_rememberFilter && !noSaveToLocal){
      this.saveFiltersToLocalStorage(filter);
    }
    this.updateCurrentFilter(filter, {
      cb: ({ isHasIsServer }) => {
        if (isHasIsServer) {
          if (fnList && fnList.requestList) {
            // console.warn('FilterServer:',filter);
            fnList.requestList({
              filterQuery: this.getFilterQuery(filter),
            });
          }
        } else {
          if (fnList && fnList.filterInLocal) {
            // console.warn('filterInLocal:',filter);
            fnList.filterInLocal(filter);
          }
        }
      },
    });
  }
  requestFilterLocal=()=>{
    const _isRememberFilter = this.getIsRememberFilter();
    if(_isRememberFilter){
      const _filtersInLocal = this.getFilterFromLocalStorage();
      if(_filtersInLocal && Object.keys(_filtersInLocal).length>0){
        this.requestFilter(_filtersInLocal, {noSaveToLocal: true});
      }
    }
  }
  getIsRememberFilter=()=>{
    const configFilter = this.getConfigFilter();
    return HUtils.Obj.get(configFilter, "rememberFilter");
  }
  getFilterFromLocalStorage = () =>{
    const {fnList} = this.props;
    const configFilter = this.getConfigFilter();
    const _rememberFilter = HUtils.Obj.get(configFilter, "rememberFilter");
    if(_rememberFilter){
      const _screenCode = fnList && fnList.fnGetScreenCode && fnList.fnGetScreenCode();
      let _filters = {};
      const _filtersInLocal = HLocalStorage.getObject(_screenCode);
      if(_filtersInLocal){
        try {
          _filters = JSON.parse(_filtersInLocal);
        } catch (error) {
          console.warn("MyUI_PageFilter parse error: ", error);
        }
        return _filters;
      }
    }
  }
  getItemFilterFromLocalByFieldName = (field) =>{
    const _filtersInLocal = this.getFilterFromLocalStorage();
    if(_filtersInLocal && _filtersInLocal[field]){
      return _filtersInLocal[field];
    }
    return null;
  }
  getConfigFilter = () => {
    const { getConfigFromScreenConfig } = this.props;
    const filterConfigs =
      getConfigFromScreenConfig && getConfigFromScreenConfig("Filter");
    let _configFilter = {
      show: true,
      showSearch: true, //Show search
      searchNoSymbol: false, //Search ko dau
      require4ShowData: false, //Ko nho dung lam gi
      listInList: [],
      //new
      className: "", //className MyFitler
      classNameItem: "col-12 col-sm-6 col-md-4 col-lg-3", //className item Myfilter
    };
    if (filterConfigs) {
      _configFilter = Object.assign(_configFilter, filterConfigs);
    }
    // console.warn('Filter', filterConfigs,_configFilter)
    return _configFilter;
  };
  getFilterFromExtraData = () => {
    const { contents, dataList } = this.props;
    const extraData = (dataList && dataList.ExtraData) || [];
    let _configFilter = this.getConfigFilter();
    let _config = [];

    if (_configFilter.showSearch == true) {
      _config.push({
        type: "search",
        // isFull:true,
        more: {
          ui: {
            showPre: false,
          },
        },
        ..._configFilter.configSearch,
      });
    }
    // console.warn('extraData:', extraData);
    if (extraData && extraData.Columns) {
      let _cols = extraData.Columns;
      let _keys = Object.keys(_cols);
      if (_keys && _keys.length > 0) {
        _keys.map((v, i) => {
          let _col = _cols[v];
          if (_col && _col.Filter && _col.Filter.type) {
            let _more = {};
            if (_col.Filter.more) {
              try {
                _more = JSON.parse(_col.Filter.more);
              } catch (error) {
                console.warn("error parse json filter more:", _col);
              }
            }
            const _isRemember = HUtils.Obj.get(_more, "isRemember");
            let _defaultValue = null;
            if(_isRemember){
              const _dfFilter = this.getItemFilterFromLocalByFieldName(v)
              _defaultValue = HUtils.Obj.get(_dfFilter, "value", null)
            }
            let _c = {
              type: _col.Filter.type,
              title: _col.Filter.title || _col.Title || _col.fieldName || v,
              fieldName: v,
              more: _more,
              ..._defaultValue && {defaultValue: _defaultValue}
            };
            if (_col.Filter.optionKey) {
              if (
                _col.Filter.optionKey.toLocaleLowerCase() == "inlist" ||
                _col.Filter.optionKey.toLocaleLowerCase().indexOf("_inlist") >
                  -1
              ) {
                _c.isUsingOptionInList = true;
              } else {
                _c.optionKey = _col.Filter.optionKey;
              }
            }
            _config.push(_c);
          }
        });
      }
    }
    this._dataConfigFilter = _config;
    // console.warn('_config:', _config);
    this.setState({
      filter: contents || _config,
    });
  };
  getFilterQuery = (_filter) => {
    // console.warn('getFilterQuery:',_filter);
    let filter = this.getCurrentFilter(_filter);
    const _keys = Object.keys(filter);
    let _query = {};
    for (let i = 0; i < _keys.length; i++) {
      if (filter[_keys[i]].isServer == true) {
        if (filter[_keys[i]].fieldQuery) {
          _query[filter[_keys[i]].fieldQuery] =
            filter[_keys[i]].valueFilterServer;
        } 
        else if (filter[_keys[i]].fQuery) {
          _query[filter[_keys[i]].fQuery] =
            filter[_keys[i]].valueFilterServer;
        } 
        else {
          _query[_keys[i]] = filter[_keys[i]].value;
        }
      }

      if (filter[_keys[i]].fQueryStart) {
        _query[filter[_keys[i]].fQueryStart] =
          filter[_keys[i]].value && filter[_keys[i]].value.start;
      }
      if (filter[_keys[i]].fQueryEnd) {
        _query[filter[_keys[i]].fQueryEnd] =
          filter[_keys[i]].value && filter[_keys[i]].value.end;
      }
      let _typeFilter = HUtils.Obj.get(filter, `${_keys[i]}.value.type`);
      // console.warn("_typeFilter:",_query,_typeFilter)
      if (_typeFilter == "CustomFilter" && filter[_keys[i]].isServer == true) {
        delete _query[_keys[i]];
      }
    }
    // console.warn('_query:',_query,filter);

    return _query;
  };
  getCurrentFilter = (filters) =>{
    const _newFilters = filters;
    const _isRememberFilter = this.getIsRememberFilter();
    if(_isRememberFilter){
      let _filtersInLocal = this.getFilterFromLocalStorage() || {};
      const _keys = Object.keys(_newFilters);
      for (let i = 0; i < _keys.length; i++) {
        const _k = _keys[i];
        const _it = _newFilters[_k];
        if(_filtersInLocal[_k]){
          let _typeCustom = HUtils.Obj.get(_it, "value.type");
          if(_typeCustom == "CustomFilter"){
              if(_it.value.start === null && _it.value.end === null){
                delete _filtersInLocal[_k];
              }
              else if(_it.isRemember){
                _filtersInLocal[_k] = _it;
              }
          }
          else if(!_typeCustom){
            if(_it.value === null){
              delete _filtersInLocal[_k];
            }
            else if(_it.isRemember){
              _filtersInLocal[_k] = _it;
            }
          }
        }
        else if(_it.isRemember){
          _filtersInLocal[_k] = _it;
        }
      }
      return _filtersInLocal;
    }
    return _newFilters
  }
  updateCurrentFilter = (obj, { cb }) => {
    const { onUpdateCurrentFilter } = this.props;
    if (onUpdateCurrentFilter) {
      onUpdateCurrentFilter(obj);
    }
    this._currentFilter = obj;
    this._isHasIsServer = this._isHasIsServer;
    let _currentIsServer = false;
    if (this._currentFilter) {
      let _keys = Object.keys(this._currentFilter);
      if (_keys && _keys.length > 0) {
        for (let k of _keys) {
          let _filterOfField = this._currentFilter[k];
          if (_filterOfField && _filterOfField.isServer == true) {
            _currentIsServer = true;
            break;
          }
        }
      }
    }
    if (this._isHasIsServer != true && _currentIsServer == true) {
      this._isHasIsServer = true;
    }
    if (cb) {
      cb({
        isHasIsServer: this._isHasIsServer,
      });
    }
    if (this._isHasIsServer == true && _currentIsServer == false) {
      this._isHasIsServer = false;
    }
  };
  saveFiltersToLocalStorage = (filters) =>{
    // console.warn("filters",filters)
    const {fnList} = this.props;
    const _filters = JSON.stringify(this.getCurrentFilter(filters));
    const _screenCode = fnList && fnList.fnGetScreenCode && fnList.fnGetScreenCode();
    HLocalStorage.setObject(_screenCode,_filters)
  }
  render() {
    const { contents, options, dataList, styles } = this.props;
    const {filter} = this.state;
    const configFilter = this.getConfigFilter();
    // console.warn('dataFilter', filter)
    if (configFilter && configFilter.show && filter.length>0) {
      return (
        <MyUI type="box" style={styles}>
          <MyTableFilter
            contents={this.state.filter}
            configFilter={this.getConfigFilter()}
            dataList={dataList}
            options={options}
            onChangeFilter={(filter) => {
              // console.warn('onChangeFilter:',filter);
              if (contents) {
                //Test UI
                console.warn("onChangeFilter:", filter);
              } else {
                this.requestFilter(filter)
              }
            }}
          />
        </MyUI>
      );
    }
    return <div></div>;
  }
}

export default MyUI_PageFilter;
