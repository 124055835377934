import React from 'react'
import HGA from '../../../../../helpers/GA'
import { HText } from 'apps/Helpers'

class SC_Tags extends React.Component{
    render(){
        const {data} = this.props;
        if(data && data.length>0){
            return(
                <div className="block block-popular-tags">
                    <div className="title">
                        <strong>{HText.get("text_popular_tag")}</strong>
                    </div>
                    <div className="listbox" style={{display: 'block'}}>
                        <div className="tags">
                            <ul>
                                {
                                    data.map((v,i)=>{
                                        return (
                                            <li key={i}><a href={v.Slug} style={{fontSize: 14}} onClick={()=>{
                                                HGA.trackingEventClick({
                                                    action: 'click_tag',
                                                    label: v.TagName
                                                }) 
                                            }}>{v.TagName}</a></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_Tags