import React from 'react'
import {Helmet} from "react-helmet";
import HShopUtils from '../../../helpers/Utils';
import { HUtils } from '../../../../Helpers'

class SC_Helmet extends React.Component{
    render(){
        const { data, buildSnippets, noindex} = this.props;
        const _MetaTitle = HUtils.Obj.get(data, "MetaTitle") || HShopUtils.getDefaultMetaDataByKey("MetaTitle"); 
        const _MetaDescription = HUtils.Obj.get(data, "MetaDescription") || HShopUtils.getDefaultMetaDataByKey("MetaDescription");
        const _MetaKeyWords = HUtils.Obj.get(data, "MetaKeyWords") || HShopUtils.getDefaultMetaDataByKey("MetaKeyWords");
        return (
            <Helmet>
                <title>{_MetaTitle}</title>
                <meta name="description" content={_MetaDescription} />
                <meta name="keywords" content={_MetaKeyWords} />
                {
                    buildSnippets && (
                        <script type="application/ld+json">{JSON.stringify(buildSnippets())}</script>
                    )
                }
                {
                    noindex && (
                        <meta name="robots" content="noindex"/>
                    )
                }
            </Helmet>
        )
    }
}
export default SC_Helmet