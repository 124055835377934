import React from "react";
import { Helmet } from "react-helmet";
import HUtils from "../../../helpers/Utils";
import HApiAxios from "../../../helpers/ApiAxios";
import MyUI from "../MyUI";
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          HApiAxios.generic({
            request: {
              method: "UPLOAD",
              path: "ProjectPhoto",
              name: "FileUploadFile",
            },
            files: [file],
            successCallBack: (response) => {
              const _urlImg = HUtils.Obj.get(
                response,
                "Data.Photos[0].FileUrl"
              );
              console.warn("_urlImg", _urlImg);
              if (_urlImg) {
                resolve({
                  default: _urlImg,
                });
              } else {
                reject();
              }
            },
            errorCallBack: (error, response) => {
              reject();
            },
          });
          // resolve({
          //     default: 'https://kynguyenlamdep.com/wp-content/uploads/2020/01/hinh-anh-dep-hoa-bo-cong-anh.jpg'
          // });
        })
    );
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}
class MyUI_Html_Editor extends React.Component {
  constructor(props) {
    super(props);
    if (props && props.innerRef) {
      props.innerRef(this);
    }
  }
  componentDidMount() {
    const me = this;

    if (window.ClassicEditor) {
      this.initEditor();
    } else {
      window.loadedCKEditor = function () {
        // console.warn("AAAAA");
        setTimeout(() => {
          //de ko bi khu.ng
          me.initEditor();
        }, 100);
      };
    }
  }
  initEditor = () => {
    const { html, pluginsMore } = this.props;
    // console.warn("html", html,this.props)
    if (window.ClassicEditor) {
      window.ClassicEditor.create(document.querySelector("#editor_blog"), {
        // plugins: [MyCustomUploadAdapterPlugin],
        extraPlugins: [MyCustomUploadAdapterPlugin, ...pluginsMore && pluginsMore.components || []],
        removePlugins: ["Markdown", "Title", "CKFinderUploadAdapter"],
        alignment: {
          options: [ 'left', 'center', 'right' , 'justify']
        },
        toolbar: [
          "heading",
          "|",
          "fontSize",
          "fontColor",
          "|",
          "bold",
          "italic",
          "|",
          "alignment",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "imageUpload",
          ...pluginsMore && pluginsMore.toolbar || []
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
        fontSize: {
          options: [9, 11, 13, "default", 17, 19, 21],
        }
        // simpleUpload:{
        //     uploadUrl: HConfig.fnList.getConfig('BaseUrl') + '/api/v1/ProjectPhoto/FileUploadFile',
        //     headers: {
        //         // 'X-CSRF-TOKEN': 'CSFR-Token',
        //         Authorization: `bearer ${HAuth.getAuthToken()}`,
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        // },
        // ckfinder2: {
        //     uploadUrl: HConfig.fnList.getConfig('BaseUrl') + '/api/v1/ProjectPhoto/FileUploadFile',
        // },
      })
        .then((newEditor) => {
          this._editor = newEditor;
          if (html) {
            this._editor.setData(html);
          }
          // const modelDocument = HUtils.Obj.get(this._editor,"model.document");
          // const viewDocument = HUtils.Obj.get(this._editor,"editing.view.document");
          // modelDocument.on( 'change:data', event => {
          // });
          // viewDocument.on( 'blur', ev => {
          //     console.warn("onBlur",ev)
          // });
          // viewDocument.on( 'focus', event => {
          // });
          console.warn("AA:", this._editor);
          this._editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new MyUploadAdapter(loader);
          };
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  getData = () => {
    if (this._editor) {
      return this._editor.getData();
    }
  };
  render() {
    const { style, className } = this.props;
    return (
      <div style={style} className={className}>
        <Helmet>
          <script src="/_extra/Ckeditor/ckeditor.js"></script>
        </Helmet>
        <div id="editor_blog"></div>
      </div>
    );
  }
}
export default MyUI_Html_Editor;
