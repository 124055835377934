import React from 'react'
import {MyModal} from '../../../../Components'
import { HText } from 'apps/Helpers';
import MyIconSvg from '../../MyIconSvg/MyIconSvg';
import './styles/sc_input_quantity.css'

class SC_Input_Quantity extends React.Component{
    constructor(props){
        super(props);
        this.state={
            _value: 1,
            config: {
                maxQuantity: 1000,
                minQuantity: 0
            }
        }
    }
    componentDidMount(){
        this.initialValue(this.props)
    }
    shouldComponentUpdate(nextProps){
        if(this.props.value !== nextProps.value){
            this.initialValue(nextProps)
        }
        return true;
    }
    initialValue = (props) =>{
        const {value, forceMinValue, minQuantity} = props;
        if(forceMinValue && value === minQuantity){
            this.setState({
                _value: forceMinValue
            })
        }
        else if(value!=null){
            this.setState({
                _value: value
            })
        }
    }
    checkValidValue=(value)=>{
        const {maxQuantity, minQuantity, onDelete} = this.props;
        const {config} = this.state;
        let _maxValue = maxQuantity!=null ? maxQuantity : config.maxQuantity;
        let _minValue = minQuantity !=null ? minQuantity : config.minQuantity;
        if(Number.isNaN(value) == true || value < 0){
            MyModal.Helpers.showMsg(
                HText.get("text_cart_invalid_quantity"), 
                {
                    title: HText.get("text_warning_title")
                }
            )
            return false;
        }
        else if(value == 0){
            if(value == _minValue){
                if(onDelete){
                    MyModal.Helpers.showConfirm(
                        HText.get("text_cart_delete_baseon_quantity"), 
                        {
                            title: HText.get("text_cart_confirm_delete_title"),
                            onOK: (cModal,{fnClose})=>{
                            onDelete();                
                            fnClose && fnClose();
                        }
                    })
                }
            }
            else{
                MyModal.Helpers.showMsg(
                    HText.get("text_cart_invalid_quantity"), 
                    {
                        title: HText.get("text_warning_title")
                    })
            }
            return false;
        }
        else if(value > _maxValue){
            MyModal.Helpers.showMsg(
                `${HText.get("text_cart_quantity_over")} ${_maxValue}!`, 
                {
                    title: HText.get("text_warning_title")
                })
            return false;
        }
        return true;
    }
    onChangeValue=(value)=>{
        this.setState({
            _value: value
        })
    }
    beforeOnChangeValue = (v) =>{
        if(v && v.indexOf(",")>-1){
            v = v.replace(",",".");
        }
        if(v && v.indexOf(".")>-1 && v.lastIndexOf(".")!=v.indexOf(".")){
            v = v.slice(0,v.indexOf(".")) + v.slice(v.indexOf(".")+1);
            return v;
        }
        return v;
    }
    updateValue=(value)=>{
        const {onChange, minQuantity, forceMinValue} = this.props;
        let _minValue = 1;
        let _valueUpdate = parseFloat(parseFloat(value).toFixed(1));
        
        if(minQuantity != null){
            _minValue = minQuantity
        }

        if(this.checkValidValue(_valueUpdate) == true){
            this.setState({
                _value: _valueUpdate
            },()=>{
                onChange && onChange(_valueUpdate)
            })
        }
        else if(this.checkValidValue(_valueUpdate) == false){
            this.setState({
                _value: forceMinValue || _minValue
            },()=>{
                onChange && onChange(_minValue)
            })
        }
    }
    renderDecBtn = () =>{
        const {showControlBtn, disabled, step} = this.props;
        const {_value} = this.state;
        const _decreaseStep = step > 0 ? step : 1;
        if(showControlBtn !== false){
            return(
                <button 
                    className="inc" 
                    onClick={()=>this.updateValue(parseFloat(_value) - _decreaseStep)} 
                    disabled={disabled} 
                    style={disabled?{opacity: "70%", cursor: "unset"}:{}}
                >
                    <MyIconSvg type="minus" width={13} height={13} fill="var(--color-secondary)"/>
                </button>
            )
        }
        
    }
    renderIncBtn = () =>{
        const {showControlBtn, disabled, step} = this.props;
        const {_value} = this.state;
        const _increaseStep = step > 0 ? step : 1;
        if(showControlBtn !== false){
            return(
                <button 
                    className="dec" 
                    onClick={()=>this.updateValue(parseFloat(_value) + _increaseStep)} 
                    disabled={disabled} 
                    style={disabled?{opacity: "70%", cursor: "unset"}:{}}
                >
                    <MyIconSvg type="plus" width={13} height={13} fill="var(--color-secondary)"/>
                </button>
            )
        }
    }
    renderInput = () =>{
        const {disabled, clearable, forceMinValue, value} = this.props;
        const {_value} = this.state;
        const _valueFocus = "";
        return(
            <input 
                className="qty-input" 
                type="text" 
                inputMode="decimal"
                style={
                    disabled ? 
                    {opacity: "70%", margin: '0px 4px'} :
                    {margin: '0px 4px'}} 
                disabled={disabled} 
                value={_value} 
                onFocus={()=>{
                    if(_value === forceMinValue && clearable){
                        this.onChangeValue(_valueFocus)
                    }
                }}
                onChange={(e)=>{
                    let v = this.beforeOnChangeValue(e.currentTarget.value);
                    this.onChangeValue(v);
                }}
                onBlur={(e)=>{
                    if(_value === _valueFocus && clearable){
                        console.warn("_value", _value)
                        this.updateValue(forceMinValue || value);
                    }
                    else{
                        this.updateValue(_value)
                    }
                }}
            />
        )
    }
    renderDefault=()=>{
        const {className} = this.props;
        return(
            <div className={`sc_input_quantity default ${className}`}>
                {this.renderDecBtn()}
                {this.renderInput()}
                {this.renderIncBtn()}
            </div>
        )
    }
    renderVertical=()=>{
        const {showControlBtn, className} = this.props;
        return(
            <div className="sc_input_quantity vertical">
                {
                    showControlBtn !== false &&
                    <div className={className}>
                        {this.renderIncBtn()}
                        {this.renderDecBtn()}
                    </div>
                }
                {this.renderInput()}
            </div>
        )
    }
    render(){
        const {isBtnVertical} = this.props;
        if(isBtnVertical == true){
            return this.renderVertical()
        }
        return this.renderDefault()
    }
}
export default SC_Input_Quantity