/*eslint-disable eqeqeq */

import axios from 'axios';
import HConsole from './Console';
import HUtils from './Utils';
const ApiAxios = {
  HConfig: null,
  Config:{
    baseUrl: 'http://nobaseurl',
    timeout: 120000,
    authKey: null,
    kAuthKey: 'AUTH_ACCESS_TOKEN_KEY',
  },
  initApiAxios(HConfig){
    ApiAxios.HConfig = HConfig;
    if(HConfig){
      let _baseURL = HConfig.fnList.getConfig('BaseUrl');
      if(_baseURL){
        ApiAxios.Config.baseUrl = _baseURL;
      }
      let _configApi = HConfig.fnList.getConfig('configApi');
      if(_configApi){
        ApiAxios.Config = Object.assign(ApiAxios.Config,_configApi);
      }
    }
    // console.warn("initApiAxios:",HConfig);
  },
  updateConfig(configApi){
    if(configApi){
      ApiAxios.Config = Object.assign(ApiAxios.Config,configApi);
    }
  },
  updateToken(token){
    ApiAxios.Config.authKey = token; 
  },
  getHeader(opts){
    var _header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'dataType': 'json',
      //'Access-Control-Allow-Origin':'*',
    }
    // console.warn('getHeader',ApiAxios);
    if(ApiAxios.Config.authKey == null){
      let _savedAccessToken = localStorage.getItem(ApiAxios.Config.kAuthKey);
      if(_savedAccessToken!=null){
        ApiAxios.Config.authKey = _savedAccessToken;
      }
    }
    if(ApiAxios.Config.authKey!=null && ApiAxios.Config.authKey.length>0){
      _header['Authorization'] = ApiAxios.Config.authKey;
    }
    if(opts && opts.customHeader){
      _header = Object.assign(_header,opts.customHeader);
    }
    return _header;
  },
  gas(opts){//google app script
  },
  fake(opts){
    if(opts && opts.fake){
      setTimeout(()=>{
        if(opts.fake.response){
          ApiAxios.share_success(opts,opts.fake.response);
        }
        else if(opts.fake.error){
          ApiAxios.share_catchError(opts,opts.fake.error);
        }
      },opts.fake.timeout||2000);
    }    
  },
  /**
   * 
   * @param {*} opts 
   * request {method, path, name, url}
   * customHeader
   * customOptions
   * file, files
   * successCallBack, errorCallBack
   * isHideMsgError
   */
  generic({request,data,successCallBack,errorCallBack,...opts}={}){
    if(request){
      let _request = request;
      let _url = _request.url;
      if(_request.path && _request.name){
        _url = `/api/v1/${_request.path}/${_request.name}`
      }
      else if(_request.url && _request.url.startsWith("/")==false && _request.url.startsWith("http")==false){       
        _url  = `/api/v1/${_request.url}`;
      }
      let _method = _request.method || 'POST';
      let _customHeader = {};
      let _data = data;
      if(_request.method=='POST'){
        _data = {
          ...{
            Url: window.location.href,
            DocumentWidth: window.document.body.offsetWidth
          },
          ...data
        }
      }      
      else if(_request.method=='GET'){
        if(data){
          _data = ApiAxios.buildDataForm(data);
        }
      }
      else if(_request.method=='UPLOAD'){
        _method = 'POST';
        _customHeader['Content-Type'] = 'multipart/form-data';
        if(opts.file!=null){
          let _f = opts.file;
          _data = new FormData();
          _data.append("file", _f);
        }
        else if(opts.files!=null){
          let _f = opts.files;
          _data = new FormData();
          for(let i=0;i<_f.length;i++){
            _data.append("file[]", _f[i]);
          }
        }
        console.warn('_data:',opts);

      }
      else if(_request.method=='FORM'){
        _method = 'POST';
        _customHeader['Content-Type'] = 'application/x-www-form-urlencoded';
        if(data){
          _data = ApiAxios.buildDataForm(data);
        }        
      }     
      if(_url && !_url.startsWith('http')){
        _url = ApiAxios.Config.baseUrl + _url;
      }
      if(opts.customHeader){//Merge custom Header
        for(let k in opts.customHeader){
          _customHeader[k] = encodeURIComponent(opts.customHeader[k]);
        }
      }
      let _options = {
        method: _method,
        headers: ApiAxios.getHeader({...opts,customHeader:_customHeader}),
        url: _url,
        timeout: ApiAxios.Config.timeout,
        data: _data,
      }           
      if(opts.customOptions){
        _options = Object.assign(_options,opts.customOptions);
      }
      // console.warn("Api generic:",request,_options,opts,_customHeader,JSON.stringify(_options));//return;      
      ApiAxios.fetch({
        request,data,successCallBack,errorCallBack,
        options:_options,
        ...opts,
        customHeader: _customHeader
      });
    }
    else{
      console.warn("Missing request!!!");
    }    
  },
  buildDataForm(json){
    return Object.keys(json).map(function(key) {
      return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
    }).join('&');
  },
  beforeFetch(opts){
  },
  afterFetch(opts,response,error){
    if(opts.isHideMsgError!=true){
      if(error!=null){
        var _msg = "Error!";
        var _status = 0;
        if(error.response!=null){
          _status = error.response.status;//401
          if(error.response.data!=null){
            if(error.response.data.Message!=null)
            {_msg = error.response.data.Message;}
            else if(error.response.data.error_description!=null)
            {_msg = error.response.data.error_description;}
          }
        }        
        HUtils.runFnConfig(ApiAxios.HConfig,'showError',[_msg]);
        if(_status==401){
          HUtils.runFnConfig(ApiAxios.HConfig,'afterLostSession',[]);
        }
      }
      else if( response!=null && response.StatusCode==0 && response.Msg!=null ){
        HUtils.runFnConfig(ApiAxios.HConfig,'showError',[response.Msg]);
      } 
    }
  },
  fetch(opts={}){        
    // ApiAxios.beforeFetch(opts);
    let _options = opts.options;
    // console.warn('Api fetch options:',opts,JSON.stringify(opts));return;
    axios(_options).then(function(response) {
      // console.log('axios response:',response);
      return response.data;
    }).catch(function (error) {
      // console.log('axios error:',error,Object.keys(error));
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      }
      throw error;
    })
    .then(response => {
      ApiAxios.share_success(opts,response);
    })
    .catch((error) => {
      ApiAxios.share_catchError(opts,error);
    });
  },
  fetchTest(){
    ApiAxios.generic({
      request:{
        method: 'POST',
        url: 'http://ip.jsontest.com/'
      }
    })
  },
  share_success(opts,response){
    console.log('%c API Success ['+opts.type+']:',HConsole.Style.api,response);
    this.afterFetch(opts,response,null);
    if(opts!=null){
      let _successCallBack = opts.successCallBack;      
      let _errorCallBack = opts.errorCallBack;
      let _statusCode = response.StatusCode;
      if(_statusCode==1 || response.access_token!=null){
        if(_successCallBack!=null){
          _successCallBack(response);
        }
      }
      else{
        if(_errorCallBack!=null){
          HUtils.runFnConfig(ApiAxios.HConfig,'afterErrorApi',['Error from api',{
            options: {
              data: opts.data,
              type: opts.type,
            },
            response: response,
          }]);
          _errorCallBack(null,response);
        }
      }
    }
  },
  share_catchError(opts,error){
    console.log('%c API Error:',HConsole.Style.alert,opts,error);
    this.afterFetch(opts,null,error);
    let _errorCallBack = opts.errorCallBack;
    if(_errorCallBack!=null){
      _errorCallBack(error,null);
    }
    HUtils.runFnConfig(ApiAxios.HConfig,'afterErrorApi',[error,{
      options: {
        data: opts.data,
        type: opts.type,
        options: opts.options
      },
      error_stack: error!=null?error.stack:'',
      error_message: error!=null?error.message:'',
    }]);
  },
}

window.ApiAxios = ApiAxios;
export default ApiAxios;