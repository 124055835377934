import React from "react";
// import GoogleLogin from "src/libs/GoogleLogin"
import { HConfig } from "apps/Helpers";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";

const LoginWithGoogle = (props) => {
  const { children, buttonText, onSuccess, onFailure } = props;
  const signIn = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (tokenResponse) => {
      console.warn("tokenResponse:", tokenResponse);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
          {}
        )
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            const {
              email,
              // name,
              // picture
            } = res.data;
            if (tokenResponse) {
              onSuccess && onSuccess(tokenResponse.access_token, email);
            } else {
              onFailure && onFailure()
            }
          }
        });
    },
  });
  return (
    <button
      style={{
        display: "inline-block",
        background: "#d14836",
        color: "#fff",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 2,
        border: "1px solid transparent",
        fontSize: 16,
        fontWeight: "bold",
        fontFamily: "Roboto",
      }}
      onClick={() => signIn()}
    >
      {children || buttonText}
    </button>
  );
};
const LoginSocialProvider = (props) => {
  return (
    <GoogleOAuthProvider clientId={HConfig.fnList.getConfig("googleClientId")}>
      <LoginWithGoogle {...props} />
    </GoogleOAuthProvider>
  );
};
export default LoginSocialProvider;

// import React, { Component } from 'react'
// import PropTypes from 'prop-types'

// class GoogleLogin extends Component {
//   constructor(props) {
//     super(props)
//     this.signIn = this.signIn.bind(this)
//     this.enableButton = this.enableButton.bind(this)
//     this.state = {
//       disabled: false,
//       // disabled: true
//     }
//   }
//   componentDidMount() {
//     ((d, s, id, cb) => {
//       const element = d.getElementsByTagName(s)[0]
//       const fjs = element
//       let js = element
//       js = d.createElement(s)
//       js.id = id
//       js.src = '//apis.google.com/js/client:platform.js'
//       fjs.parentNode.insertBefore(js, fjs)
//       js.onload = cb
//     })(document, 'script', 'google-login',()=>this.onLoginGoogle())
//   }
//   componentWillUnmount() {
//     this.enableButton = () => {}
//   }
//   enableButton() {
//     this.setState({
//       disabled: false
//     })
//   }
//   onLoginGoogle = () =>{
//     const { clientId, cookiePolicy, loginHint, hostedDomain, autoLoad, isSignedIn, fetchBasicProfile, redirectUri, discoveryDocs, onFailure, uxMode, scope, responseType } = this.props;
//     const params = {
//       client_id: clientId,
//       cookie_policy: cookiePolicy,
//       login_hint: loginHint,
//       hosted_domain: hostedDomain,
//       fetch_basic_profile: fetchBasicProfile,
//       discoveryDocs,
//       ux_mode: uxMode,
//       redirect_uri: redirectUri,
//       scope
//     }

//     if (responseType === 'code') {
//       params.access_type = 'offline'
//     }

//     window.gapi.load('auth2', () => {
//       this.enableButton()
//       if (!window.gapi.auth2.getAuthInstance()) {
//         window.gapi.auth2.init(params).then(
//           res => {
//             if (isSignedIn && res.isSignedIn.get()) {
//               this._handleSigninSuccess(res.currentUser.get())
//             }
//           },
//           err => console.warn("google load error", err)
//         )
//       }
//       if (autoLoad) {
//         this.signIn()
//       }
//     })
//   }
//   signIn(e) {
//     if (e) {
//       e.preventDefault() // to prevent submit if used within form
//     }
//     if (!this.state.disabled) {
//       const auth2 = window.gapi.auth2.getAuthInstance()
//       const { onSuccess, onRequest, onFailure, prompt, responseType } = this.props
//       const options = {
//         prompt
//       }
//       onRequest()
//       if (responseType === 'code') {
//         auth2.grantOfflineAccess(options).then(res => onSuccess(res), err => onFailure(err))
//       } else {
//         auth2.signIn(options).then(res => this._handleSigninSuccess(res), err => onFailure(err))
//       }
//     }
//   }
//   _handleSigninSuccess(res) {
//     /*
//       offer renamed response keys to names that match use
//     */
//     const basicProfile = res.getBasicProfile()
//     const authResponse = res.getAuthResponse()
//     res.googleId = basicProfile.getId()
//     res.tokenObj = authResponse
//     res.tokenId = authResponse.id_token
//     res.accessToken = authResponse.access_token
//     res.profileObj = {
//       googleId: basicProfile.getId(),
//       imageUrl: basicProfile.getImageUrl(),
//       email: basicProfile.getEmail(),
//       name: basicProfile.getName(),
//       givenName: basicProfile.getGivenName(),
//       familyName: basicProfile.getFamilyName()
//     }
//     this.props.onSuccess(res)
//   }

//   render() {
//     const { tag, type, style, className, disabledStyle, buttonText, children } = this.props
//     const disabled = this.state.disabled || this.props.disabled
//     const initialStyle = {
//       display: 'inline-block',
//       background: '#d14836',
//       color: '#fff',
//       width: '100%',
//       paddingTop: 10,
//       paddingBottom: 10,
//       borderRadius: 2,
//       border: '1px solid transparent',
//       fontSize: 16,
//       fontWeight: 'bold',
//       fontFamily: 'Roboto'
//     }
//     const styleProp = (() => {
//       if (style) {
//         return style
//       } else if (className && !style) {
//         return {}
//       }
//       return initialStyle
//     })()
//     const defaultStyle = (() => {
//       if (disabled) {
//         return Object.assign({}, styleProp, disabledStyle)
//       }
//       return styleProp
//     })()
//     const googleLoginButton = React.createElement(
//       tag,
//       {
//         onClick: this.signIn,
//         style: defaultStyle,
//         type,
//         disabled,
//         className
//       },
//       children || buttonText
//     )
//     return googleLoginButton
//   }
// }

// GoogleLogin.propTypes = {
//   onSuccess: PropTypes.func.isRequired,
//   onFailure: PropTypes.func.isRequired,
//   clientId: PropTypes.string.isRequired,
//   onRequest: PropTypes.func,
//   buttonText: PropTypes.string,
//   scope: PropTypes.string,
//   className: PropTypes.string,
//   redirectUri: PropTypes.string,
//   cookiePolicy: PropTypes.string,
//   loginHint: PropTypes.string,
//   hostedDomain: PropTypes.string,
//   children: PropTypes.node,
//   style: PropTypes.object,
//   disabledStyle: PropTypes.object,
//   fetchBasicProfile: PropTypes.bool,
//   prompt: PropTypes.string,
//   tag: PropTypes.string,
//   autoLoad: PropTypes.bool,
//   disabled: PropTypes.bool,
//   discoveryDocs: PropTypes.array,
//   uxMode: PropTypes.string,
//   isSignedIn: PropTypes.bool,
//   responseType: PropTypes.string,
//   type: PropTypes.string
// }

// GoogleLogin.defaultProps = {
//   type: 'button',
//   tag: 'button',
//   buttonText: 'Login with Google',
//   scope: 'profile email',
//   prompt: '',
//   cookiePolicy: 'single_host_origin',
//   fetchBasicProfile: true,
//   isSignedIn: false,
//   uxMode: 'popup',
//   disabledStyle: {
//     opacity: 0.6
//   },
//   onRequest: () => { }
// }

// export default GoogleLogin
