import React from 'react'
import "./styles/spinner_ring.css"

class MyUI_SpinnerRing extends React.Component {    
  render(){
    const {msg} = this.props;
    return (
      <div className="myui-spinnerring">
        <div className="myui-spinnerring-msg">{msg}</div>
      </div>
    )
  } 
}

export default MyUI_SpinnerRing