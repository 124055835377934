import React from "react";
import { MyTableFilter, MyUI } from "apps/Components";
// import { MyTableFilterData } from "apps/Components";
import { PaginationWithData } from "libs/ReactPaginate";
import { HConfig, HFormat, HUtils } from "apps/Helpers";
import ShopUtils from "apps/shoppingCart/helpers/Utils";
import HGA from 'helpers/GA';
import "./styles/sc_quickorder_group_products.css";

class SC_Group_Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 0,
      sortType: 1,
      groups: null,
      currentGroup: null,
      selectedArray: null,
      optionalArray: null,
      isReview: false,
      viewMode: 0,
      currentPageFilter: 1,
      currentPageReview: 1,
      currentPage: 1,
      dataSearchServer: [],
    };
    this.searchType = 1;
  }
  componentDidMount() {
    this.getSortTypeDefault();
    this.getListFromProps(this.props);
    this.setLastedViewMode();
  }

  /** get */
  getListFromProps = (props) =>{
    const {selectedProducts, optionalProducts} = props;
    if(selectedProducts){
      this.setState({
        selectedArray: selectedProducts
      })
    }
    if(optionalProducts){
      this.setState({
        optionalArray: optionalProducts
      })
    }
    this.forceUpdate();
  }
  getSortTypeDefault = () => {
    const { configFilter } = this.props;
    const _sortDefault = configFilter?.sortDefault;
    if (_sortDefault != null) {
      this.setState({
        sortType: _sortDefault,
      });
    }
  };
  getIsFilterBeing = () => {
    if (this._currentFilter?._SearchText) {
      return true;
    }
    return false;
  };
  getIsFilterServer = () => {
    const { configFilter } = this.props;
    return configFilter?.filterServer;
  };
  getTotalSelected = () => {
    const { selectedArray } = this.state;
    return selectedArray?.length || 0;
  };
  getTotalOptionalList = () =>{
    const _optL = this.getOptionalList();
    return _optL?.length;
  }
  getOptionalList = () =>{
    const {optionalArray} = this.state;
    return (optionalArray && optionalArray.filter((v)=>{
      if(v.Quantity_Text && v.MaterialName){
        return v
      }
    })) || [];
  }
  getForcePage = () => {
    const { currentPage, currentPageReview, currentPageFilter, isReview } =
      this.state;
    const _isFiltering = this.getIsFilterBeing();
    if (isReview) {
      return currentPageReview;
    } else if (_isFiltering) {
      return currentPageFilter;
    } else {
      return currentPage;
    }
  };
  getConfigByConfigField = (fieldConfig, key) => {
    if (this.props[fieldConfig]?.[key]) {
      return this.props[fieldConfig][key];
    }
  };
  getDataWithPage = (array, page_number) => {
    const { configPagination } = this.state;
    const pageSize = HUtils.Obj.get(configPagination, "pageSize", 10);
    if (array && array.length > 0) {
      return array.slice((page_number - 1) * pageSize, page_number * pageSize);
    }
    return [];
  };
  getQueryFilter = (forceSortType) => {
    const _textSearch = HUtils.Obj.get(
      this._currentFilter,
      "_SearchText.value"
    );
    const _filterServer = this.getConfigByConfigField(
      "configFilter",
      "filterServer"
    );
    if (_textSearch && _filterServer) {
      return {
        TextSearch: _textSearch,
        CommandType: forceSortType,
      };
    }
    return {
      CommandType: forceSortType,
    };
  };
  getNumOfFilterResult = () => {
    const { dataSearchServer } = this.props;
    return dataSearchServer?.length;
  };
  getItemInGroups = (item) => {
    const { data, dataSearchServer } = this.props;
    let _result = null;
    if (dataSearchServer?.length > 0) {
      dataSearchServer.forEach((v) => {
        if (v.MaterialId === item.MaterialId) {
          _result = v;
        }
      });
    }
    if (!_result && data?.length > 0) {
      data.forEach((v) => {
        if (v?.List?.length > 0) {
          v.List.forEach((a) => {
            if (a.MaterialId === item.MaterialId) {
              _result = a;
            }
          });
        }
      });
    }
    return _result;
  };
  getTotalSelectedMoney = () =>{
    const { selectedArray } = this.state;
    let _tt = 0;
    selectedArray?.length > 0 &&
      selectedArray.forEach((v) => {
        const _price = ShopUtils.getPriceProduct(v)
        _tt += v.Quantity * _price;
      });
    return _tt;
  }
  /** set */
  setQuantity4Data = (data) => {
    const { selectedArray } = this.state;
    if (data?.length > 0 && selectedArray) {
      data.forEach((v, i) => {
        let _item = v;
        let _itemSelected = selectedArray.find(
          (v) => v.MaterialId == _item.MaterialId
        );
        if (_itemSelected) {
          _item.Quantity = _itemSelected.Quantity;
        } else {
          _item.Quantity = 0;
        }
      });
    }
    return data;
  };
  setLastedViewMode = () =>{
    const { fnList } = this.props;
    if(fnList?.fnGetConfigFromLocalStorage){
      const _vM = fnList.fnGetConfigFromLocalStorage("viewMode");
      if(_vM !== null && _vM !== undefined){
        this.setState({
          viewMode: _vM
        })
      }
    }
  }
  /** onChange */
  onChangeFilter = (values) => {
    const { fnList } = this.props;
    fnList?.fnOnChangeFilter && fnList.fnOnChangeFilter(values);
  };
  onChangeTab = (index) => {
    this.setState({
      tabActive: index,
    });
  };
  onChangeReview = (value) => {
    this.setState({
      isReview: value,
    });
  };
  onChangePage = (value) => {
    const {fnList} = this.props;
    const { isReview } = this.state;
    const _isFiltering = this.getIsFilterBeing();
    if (isReview) {
      this.setState({
        currentPageReview: value,
      });
    } else if (_isFiltering) {
      this.setState({
        currentPageFilter: value,
      });
    } else {
      this.setState({
        currentPage: value,
      });
    }
    fnList?.fnScroll && fnList.fnScroll(0, 0)
  };
  onChangeSortType = (value) => {
    this.setState(
      {
        sortType: value,
      },
      () => {
        this.onChangeFilter(this.getQueryFilter(value));
      }
    );
  };
  onChangeSearch = () => {
    const _filterServer = this.getConfigByConfigField(
      "configFilter",
      "filterServer"
    );
    const _textSearch = HUtils.Obj.get(
      this._currentFilter,
      "_SearchText.value"
    );
    const _sortTypeDefault = this.getConfigByConfigField(
      "configFilter",
      "sortDefault"
    );

    if (_textSearch) {
      if (this.searchType === 1) {
        this.onChangeTab(-2);
        this.setState({
          currentPageFilter: 1,
        });
      }
      if (_filterServer) {
        this.onChangeSortType("0");
      }
    } else {
      this.onChangeTab(-1);
      this.onChangeSortType(_sortTypeDefault);
    }
  };
  onChangeItemQuantity = (item, value) => {
    const { fnList } = this.props;
    let { selectedArray } = this.state;
    let _itemWillUpdate = this.getItemInGroups(item);
    let _itemInSelectedArr =
      selectedArray &&
      selectedArray.find((i) => i.MaterialId == item.MaterialId);
    if (_itemWillUpdate) {
      _itemWillUpdate.Quantity = value;
      if (value > 0) {
        if (_itemInSelectedArr) {
          _itemInSelectedArr.Quantity = value;
        } else if (selectedArray) {
          selectedArray.unshift({ ...item, Quantity: value });
        } else {
          selectedArray = [];
          selectedArray.unshift({ ...item, Quantity: value });
        }
      } else {
        if (selectedArray) {
          selectedArray = selectedArray.filter(
            (v) => v.MaterialId !== item.MaterialId
          );
        }
      }
    }
    this.setState({
      selectedArray: selectedArray,
    },()=>{
      fnList?.fnOnChangeList && fnList.fnOnChangeList("selectedProducts", selectedArray)
    });
  };
  onSubmit = () =>{
    const {fnList} = this.props;
    const {selectedArray} = this.state;
    fnList?.fnOnSubmitSelectProduct && fnList.fnOnSubmitSelectProduct({
      optionalProducts: this.getOptionalList(),
      selectedProducts: selectedArray || [],
      total: HFormat.Money.formatMoneyForAjuma(this.getTotalSelectedMoney())
    })
  }
  onChangeOptionalList = (index, newItem) =>{
    const { fnList } = this.props;
    let { optionalArray } = this.state;
    if (!optionalArray) {
      optionalArray = [];
    }
    optionalArray[index] = newItem;
    this.setState({
      optionalArray: optionalArray,
    },()=>{
      fnList?.fnOnChangeList && fnList.fnOnChangeList("optionalProducts", optionalArray)
    });
  }
  onDeleteOptionalList = (index) =>{
    const { fnList } = this.props;
    let { optionalArray } = this.state;
    if (optionalArray) {
      optionalArray.splice(index, 1);
    }
    this.setState({
      optionalArray: optionalArray,
    },()=>{
      fnList?.fnOnChangeList && fnList.fnOnChangeList("optionalProducts", optionalArray)
    });
  }
  onChangeViewMode = (value) =>{
    this.setState({ viewMode: value },()=>{
      const {fnList} = this.props;
      fnList?.fnOnSaveConfig && fnList.fnOnSaveConfig("viewMode", value);
      switch (value) {
        case 0:
          HGA.trackingEventClick({
            action: "quickorder_viewMode_table"
          });
          break;
        case 1:
          HGA.trackingEventClick({
            action: "quickorder_viewMode_card"
          });
          break;
        default:
          break;
      }
    })
  }
  /** render */
  renderViewMode = () =>{
    const {viewMode} = this.state;
    return (
      <div className="dFaCjEw100">
        <div
          className={`tab-item${viewMode === 0 ? " active" : ""}`}
          title="Xem dạng bảng"
          onClick={() => this.onChangeViewMode(0)}
        >
          <i className="fa fa-table" style={{fontSize: 14}}/>
        </div>
        <div
          className={`tab-item${viewMode === 1 ? " active" : ""}`}
          title="Xem dạng lưới"
          onClick={() => this.onChangeViewMode(1)}
        >
          <i className="fa fa-th-large" style={{fontSize: 13}}/>
        </div>
      </div>
    );
  }
  renderFilter = () => {
    const { sortType, currentGroup, isReview } = this.state;
    const { configFilter } = this.props;
    const _sortList = configFilter?.sortList;
    const _reviewMode = configFilter?.reviewMode;
    const _showFilterSort = configFilter?.showFilterSort;
    const _showFilterSearch = configFilter?.showFilterSearch;
    const _filterServer = configFilter?.filterServer;
    const _totalSelected = this.getTotalSelected() + this.getTotalOptionalList();
    const _isFilter = this.getIsFilterBeing();
    return (
      <div
        className={`sc_group_products_filter${isReview ? " is-review" : ""}`}
      >
        {_showFilterSearch !== false && (
          <MyTableFilter
            contents={[
              {
                type: "search",
                more: '{"ui":{"showSuffix":true, "showPre":false}, "search":{"nosymbol":true}, "placeholder": "Tìm kiếm"}',
              },
            ]}
            styleContainer={isReview ? { display: "none" } : {}}
            dataList={currentGroup}
            onChangeFilter={(filter) => {
              this._currentFilter = filter;
              this.onChangeSearch();
              this.forceUpdate();
            }}
          />
        )}

        {_showFilterSort !== false && _sortList?.length > 0 && !isReview && (
          <div className="filter-sorting">
            <span>Sắp xếp theo</span>
            <select
              className="sortOptionsDropDown"
              value={sortType}
              onChange={(e) =>
                this.onChangeSortType(e.currentTarget.value)
              }
            >
              {_filterServer && _isFilter && <option value={"0"}></option>}
              {_sortList.map((v, i) => {
                return (
                  <option key={i} value={v.Value}>
                    {v.Text}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {_reviewMode && (
          <div className={`review-mode${isReview ? " active" : ""}`}>
            {isReview == true && (
              <button
                className="review"
                onClick={() => this.onChangeReview(false)}
              >
                Chọn thêm hàng hóa
              </button>
            )}
            <button
              className={`review${isReview ? " active" : ""}`}
              onClick={() => this.onChangeReview(true)}
            >
              Đã chọn {_totalSelected > 0 ? ` (${_totalSelected})` : null}
            </button>
          </div>
        )}
      </div>
    );
  };
  renderTab = (data) => {
    const { tabActive, isReview } = this.state;
    if (!isReview && data?.length > 0) {
      return (
        <div className="tabs">
          {this.renderCustomTabPre(tabActive)}
          {data.map((v, i) => {
            return (
              <div
                key={i}
                className={`tab-item${tabActive === i ? " active" : ""}`}
                onClick={() => this.onChangeTab(i)}
              >
                {v.Title}
              </div>
            );
          })}
          {this.renderCustomTabSuf(tabActive)}
        </div>
      );
    }
  };
  renderCustomTabPre = (tabActive) => {
    const _searchType = this.searchType;
    const _isFilter = this.getIsFilterBeing();
    return (
      <div className="tab-custom">
        {_searchType === 1 && _isFilter && (
          <div
            key={-2}
            className={`search-tab tab-item${
              tabActive === -2 ? " active" : ""
            }`}
            onClick={() => this.onChangeTab(-2)}
          >
            <i className="fa fa-search" />
            <strong>Kết quả tìm kiếm</strong>
            {this.renderNumberOfFilterResult()}
          </div>
        )}
        {/* <div
          key={-1}
          className={`optional-tab tab-item${tabActive === -1 ? " active" : ""}`}
          onClick={() => this.onChangeTab(-1)}
        >
          Tự chọn
        </div> */}
      </div>
    );
  };
  renderCustomTabSuf = (tabActive) => {
    return (
      <div className="tab-custom">
        <div
          key={-1}
          className={`optional-tab tab-item${tabActive === -1 ? " active" : ""}`}
          onClick={() => this.onChangeTab(-1)}
        >
          Tự chọn
        </div>
      </div>
    );
  };
  renderNumberOfFilterResult = () => {
    const _numOfFilterResult = this.getNumOfFilterResult();
    if (_numOfFilterResult > 0) {
      return <span className="number_result_filter">{_numOfFilterResult}</span>;
    }
  };
  renderContent = (data) => {
    const { configFilter } = this.props;
    const _filterServer = configFilter?.filterServer;
    if (_filterServer) {
      return this.renderContentFilterByServer(data);
    }
    return this.renderContentFilterByClient(data);
  };
  renderContentFilterByClient = (groups) => {};
  renderContentFilterByServer = (groups) => {
    const { tabActive, isReview } = this.state;
    if (groups?.length > 0) {
      return (
        <div className="content-filter-client">
          {this.renderContentReviewTab()}
          {this.renderContentSearchTab()}
          {this.renderContentCustomTab()}
          {!isReview &&
            groups.map((v, i) => {
              if (tabActive === i) {
                return this.renderList(this.setQuantity4Data(v.List), i);
              }
              return null;
            })}
        </div>
      );
    }
  };
  renderContentReviewTab = () => {
    const { selectedArray, isReview , viewMode} = this.state;
    const optionalList = this.getOptionalList();
    if (isReview) {
      if(optionalList?.length > 0 || selectedArray?.length > 0){
        return(
          <div>
            {
              optionalList?.length > 0 && (
                <div className="part-review">
                  <div className="title">Bạn đã tự chọn</div>
                  <MyUI
                    type="sc_quickorder_optional_order"
                    data={optionalList}
                    noGenerate={true}
                    isVertical={viewMode == 0 ? true : false}
                    onChange={(index, newItem) => {
                      this.onChangeOptionalList(index, newItem)
                    }}
                    onDelete={(index)=>{
                      this.onDeleteOptionalList(index)
                    }}
                    style={{marginBottom: 10}}
                  />
                </div>
              )
            }
            {
              selectedArray?.length > 0 && (
                <div className="part-review">
                  <div className="title">Bạn đã chọn từ danh sách</div>
                  {this.renderList(selectedArray, -3)}
                </div>
              )
            }
          </div>
        )
      } 
      return this.renderEmtyList();
    }
  };
  renderContentSearchTab = () => {
    const { dataSearchServer } = this.props;
    const { tabActive, isReview } = this.state;
    const _isFilter = this.getIsFilterBeing();
    if (isReview === false && tabActive === -2 && _isFilter) {
      return this.renderList(this.setQuantity4Data(dataSearchServer), -2);
    }
  };
  renderContentCustomTab = () => {
    const { tabActive, isReview, optionalArray, viewMode} = this.state;
    if (tabActive === -1 && !isReview) {
      return (
        <MyUI
          type="sc_quickorder_optional_order"
          data={optionalArray}
          isVertical={viewMode == 0 ? true : false}
          onChange={(index, newItem) => {
            this.onChangeOptionalList(index, newItem)
          }}
          onDelete={(index)=>{
            this.onDeleteOptionalList(index)
          }}
        />
      );
    }
  };
  renderList = (data, index) => {
    if (data?.length > 0) {
      const { viewMode } = this.state;
      const _usingServer = this.getConfigByConfigField(
        "configPagination",
        "usingServer"
      );
      const _pageSize = this.getConfigByConfigField(
        "configPagination",
        "pageSize"
      );
      if (_usingServer) {
        // TODO
      }
      return (
        <PaginationWithData
          key={index}
          containerClassName="pagination_style"
          data={data}
          forcePage={this.getForcePage()}
          sizePerPage={_pageSize}
          hideWhenOnePage={true}
          onPageChange={(selected) => {
            this.onChangePage(selected + 1);
          }}
        >
          {({ dataPagination }) => {
            if (viewMode) {
              return this.renderCardList(dataPagination, index);
            }
            return this.renderTable(dataPagination, index);
          }}
        </PaginationWithData>
      );
    } else {
      return this.renderEmtyList();
    }
  };
  renderEmtyList = () => {
    return (
      <div className="section">
        <div className="title">Danh sách rỗng!</div>
      </div>
    );
  };
  renderTable = (data, index) => {
    const { slug } = this.props;
    return (
      <table className="cart" id={`sc_group_products_${slug}`} key={index}>
        {this.renderTableHeader()}
        {this.renderTableContent(data)}
      </table>
    );
  };
  renderTableHeader = () => {
    const { configContent } = this.props;
    const _showAdmin = configContent?.showColumnAdminNote;
    return (
      <thead>
        <tr className="cart-header-row">
          <th className="item-count" style={{ display: "table-cell" }}></th>
          <th className="product-picture">Hình ảnh</th>
          <th className="product">Sản phẩm</th>
          <th className="unit-price">Giá</th>
          <th className="quantity">Số lượng </th>
          <th className="subtotal footable-last-column">Tổng tiền</th>
          {_showAdmin && <th className="admin">Ghi chú</th>}
        </tr>
      </thead>
    );
  };
  renderTableContent = (_data) => {
    const { tabIndex } = this.state;
    const _showAdmin = this.getConfigByConfigField(
      "configContent",
      "showColumnAdminNote"
    );
    let _ui = [];
    if (_data?.length > 0) {
      for (let i = 0; i < _data.length; i++) {
        let _item = _data[i];
        if (_item.hasOwnProperty("Quantity") != true) {
          _item.Quantity = 0;
        }
        const _Slug = _item.Slug;
        const _MaterialCode = _item.MaterialCode;
        const _MaterialName = _item.MaterialName;
        const _MaterialId = _item.MaterialId;
        const _ImageUrlThumnail =
          _item.ImageUrlThumnail ||
          HConfig.fnList.getConfig("imageProductDefault");
        const _IsCall4WebOrder = _item.IsCall4WebOrder;
        const _StepPrice = _item.StepPrice;
        const _Quantity = _item.Quantity;
        const _SellPriceText = _item.SellPriceText;
        const _AdminNote = _item.AdminNote;
        const _UnitName = _item.UnitName;
        const _QuantityMin = HUtils.Obj.get(_item, "DiscountInfo.QuantityMin");
        const _DiscountMoney = HUtils.Obj.get(
          _item,
          "DiscountInfo.DiscountMoney"
        );
        const _Discount_Text = HUtils.Obj.get(_item, "DiscountInfo.Discount_Text");
        const _price = ShopUtils.getPriceProduct(_item);
        const _priceAfterDiscount = 
          _QuantityMin >= 0 && _DiscountMoney && _Quantity >= _QuantityMin
            ? HFormat.Money.formatMoneyForAjuma(_price)
            : 0;
            
        _ui.push(
          <tr
            key={`${i}_p_${tabIndex}`}
            className="cart-item-row row-product-name"
          >
            <td className="item-count"></td>
            <td
              className="product"
              colSpan={5}
              style={{
                padding: "10px 10px",
                paddingBottom: "0px",
                display: "table-cell",
              }}
            >
              <a href={_Slug} className="product-name">
                {_MaterialCode} - {_MaterialName}
              </a>
              {
                _QuantityMin > 1 &&
                _Discount_Text && <div className="product-coupon">{_Discount_Text}</div>
              }
            </td>
          </tr>
        );
        _ui.push(
          <tr key={`${i}_${_MaterialId}_${tabIndex}`} className="cart-item-row">
            <td className="item-count" style={{ display: "table-cell" }}></td>
            <td className="product-picture">
              <a href={_Slug}>
                <img
                  alt={`Hình ảnh của ${_MaterialName}`}
                  src={_ImageUrlThumnail}
                  title={`Hiển thị chi tiết ${_MaterialName}`}
                />
              </a>
            </td>
            <td className="product">
              <a href={_Slug} className="product-name">
                {_MaterialCode}
                <br />
                {_MaterialName}
              </a>
              {
                _QuantityMin > 1 &&
                _Discount_Text && <div className="product-coupon">{_Discount_Text}</div>
              }
            </td>
            <td className="unit-price">
              <span
                className="product-unit-price"
                style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
              >
                {!_IsCall4WebOrder && _priceAfterDiscount !== 0 && (
                  <span className="price-discount">{_priceAfterDiscount}</span>
                )}
                <div>
                  <span
                    className={`price-actual${
                      _priceAfterDiscount !== 0 ? " has-discount" : ""
                    }`}
                  >
                    {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
                  </span>
                  {!_IsCall4WebOrder && _UnitName && (
                    <span>{` / ${_UnitName}`}</span>
                  )}
                </div>
              </span>
              {/* <span
                className="product-unit-price"
                style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
              >
                {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
                {!_IsCall4WebOrder && _UnitName && ` / ${_UnitName}`}
              </span> */}
            </td>
            <td className="quantity">
              <div className="dFfdRaiCjcC">
                <MyUI
                  type="sc_input_quantity"
                  className="quantity-control"
                  forceMinValue="0.0"
                  step={_StepPrice}
                  minQuantity={0}
                  clearable={true}
                  disabled={_IsCall4WebOrder}
                  value={_Quantity}
                  onChange={(value) => {
                    this.onChangeItemQuantity(_item, value);
                  }}
                />
              </div>
            </td>
            <td className="subtotal footable-last-column">
              <span className="product-subtotal">
                {HFormat.Money.formatMoneyForAjuma(_price * _Quantity)}
              </span>
            </td>
            {_showAdmin && <td className="admin">{_AdminNote}</td>}
          </tr>
        );

        _showAdmin &&
          _ui.push(
            <tr key={`${i}_l_${tabIndex}`} className="cart-item-row admin">
              <td colSpan={3}>{_AdminNote}</td>
            </tr>
          );
      }
      return <tbody>{_ui}</tbody>;
    }
  };
  renderCardList = (data, index) => {
    if (data?.length > 0) {
      const _showAdmin = this.getConfigByConfigField("configContent", "showColumnAdminNote");
  
      return (
        <div className="sc_group_list" key={index}>
          <MyUI
            type="sc_grid_view"
            data={data}
            cardType="sc_card_product_in_quickorder"
            config={{ showAdminNote: _showAdmin }}
            fnList={{
              fnOnChangeQuantity: (item, value) => {
                this.onChangeItemQuantity(item, value);
              },
            }}
          />
        </div>
      );
    }
  };
  renderFooter = () =>{
    const _totalMoney = this.getTotalSelectedMoney();
    return (
      <div className="sc_group_footer">
        <div className="total-area">
          <p className="total">
            Tổng tạm tính:{" "}
            <strong>{HFormat.Money.formatMoneyForAjuma(_totalMoney)}</strong>
          </p>
          <p className="note">* Không bao gồm sản phẩm tự chọn</p>
        </div>
        <button className="submit" onClick={() => this.onSubmit()}>
          <i className="fa fa-shopping-basket" />
          Tiến hành đặt hàng
        </button>
      </div>
    );
  }
  render() {
    const { data } = this.props;
    return (
      <div className="sc_group_products" id="quickorder-main">
        {this.renderViewMode()}
        {this.renderFilter()}
        <div className="sc_group_products_body">
          {this.renderTab(data)}
          {this.renderContent(data)}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}
export default SC_Group_Products;
