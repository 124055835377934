import React from 'react';


const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const StopWorkingSite = React.lazy(() => import('./components/MyPage/Types/SC_Stop_Working'));


const routes = [
  { path: '/temporarily-stop-working', exact: true, name: 'Temporarily Stop Working', component: StopWorkingSite },
  { path: '/', exact: false, name: 'Blank', component: TheLayout },
];

const routes_private = [];


const routes_dev = [
  { UIUrl:'/lien-he-dat-hang', ScreenCode:'PAGE_CLIENT_QUICK_ORDER_USER', Title:'Screen List', APIName: null, UIType: "SC_QuickOrderUser", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/dat-hang-nhanh-ket-hop', ScreenCode:'PAGE_CLIENT_QUICK_ORDER_COMBINATION', Title:'Screen List', APIName: null, UIType: "SC_QuickOrder_Combination", Config: "", RequestData: null, Id:"1"},
  // ...RoutesDev
]

const routes_simulate = [
  { UIUrl:'/', ScreenCode:'PAGE_CLIENT_HOME', Title:'Screen List', APIName: null, UIType: "SC_Home", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/home', ScreenCode:'PAGE_CLIENT_HOME', Title:'Screen List', APIName: null, UIType: "Dashboard", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/category/:Id', ScreenCode:'PAGE_CLIENT_CATEGORY', Title:'Screen List', APIName: null, UIType: "SC_Category", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/policy', ScreenCode:'PAGE_CLIENT_POLICY', Title:'Screen List', APIName: null, UIType: "SC_Page_Content", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/wishlist', ScreenCode:'PAGE_CLIENT_WISHLIST', Title:'Screen List', APIName: null, UIType: "SC_Product_Wishlist", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/product_detail/:ProductId', ScreenCode:'PAGE_CLIENT_DETAIL', Title:'Screen List', APIName: null, UIType: "SC_Product_Detail", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/cart', ScreenCode:'PAGE_CLIENT_CART', Title:'Screen List', APIName: null, UIType: "SC_Cart", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/blog/:Id', ScreenCode:'PAGE_CLIENT_BLOG', Title:'Screen List', APIName: null, UIType: "SC_Blog", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/blog', ScreenCode:'PAGE_CLIENT_BLOG', Title:'Screen List', APIName: null, UIType: "SC_Blog_Home", Config: "", RequestData: null, Id:"1"},
  //url 2 cấp sẽ bị sai khi push url
  { UIUrl:'/account-address', ScreenCode:'PAGE_CLIENT_ADDRESS', Title:'Screen List', APIName: null, UIType: "SC_AddressBook", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/account-bills', ScreenCode:'PAGE_CLIENT_BILL', Title:'Screen List', APIName: null, UIType: "SC_History_Bill", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/search', ScreenCode:'PAGE_CLIENT_SEARCH', Title:'Screen List', APIName: null, UIType: "SC_Search", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-content-banner', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-content-blog', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-content-material', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-content-topic', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-home-category', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/admin-content-footer', ScreenCode:'PAGE_CLIENT_ADMIN', Title:'Screen List', APIName: null, UIType: "SC_Admin", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/mb-:GUID', ScreenCode:'PAGE_CLIENT_BILL_DETAIL', Title:'Screen List', APIName: null, UIType: "SC_Bill_Detail", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/wo-:GUID', ScreenCode:'PAGE_CLIENT_BILL_DETAIL', Title:'Screen List', APIName: null, UIType: "sc_bill_public", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/wb-:GUID', ScreenCode:'PAGE_CLIENT_BILL_DETAIL', Title:'Screen List', APIName: null, UIType: "sc_bill_public", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/quickorder', ScreenCode:'PAGE_CLIENT_QUICK_ORDER', Title:'Screen List', APIName: null, UIType: "SC_QuickOrder", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/quickorder-:Slug', ScreenCode:'PAGE_CLIENT_QUICK_ORDER', Title:'Screen List', APIName: null, UIType: "SC_QuickOrder", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/quickordergrp-:Slug', ScreenCode:'PAGE_CLIENT_QUICK_ORDERGRP', Title:'Screen List', APIName: null, UIType: "SC_QuickOrderGroup", Config: "", RequestData: null, Id:"1"},
  { UIUrl:'/:Slug', ScreenCode:'PAGE_CLIENT_HOME', Title:'Screen List', APIName: null, UIType: "SC_Slug", Config: "", RequestData: null, Id:"1"},
]

const routes_test = [
]

export {
  routes,
  routes_private,
  routes_dev,
  routes_simulate,
  routes_test
}
