import React from "react"
import HGA from 'helpers/GA'
import './styles/sc_menu_mobile.css'

class SC_MenuMobile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            menuActive: null,
        }
    }
  onCloseMenu = () => {
    const { fnList } = this.props;
    fnList?.fnSetShowSidebar && fnList.fnSetShowSidebar(false);
  };
  onOpenSubMenu = (item) =>{
      const {menuActive} = this.state;
      let _nV = item;
      if(menuActive?.Name == item.Name){
        _nV = null
      }
    this.setState({
        menuActive: _nV
    })
  }
  renderHeaderList = (data) => {
    if (data?.length > 0) {
      return data.map((v, i) => {
        if (v.Slug !== "contactus" && v.Slug !== "blog") {
          return (
            <li
              key={i}
              onClick={() => {
                HGA.trackingEventClick({
                  action: "click_item_menu_header",
                  label: v.Title,
                });
              }}
            >
              <a title={v.Title} href={v.Slug}>
                <span>{v.Title}</span>
              </a>
            </li>
          );
        }
      });
    }
  };
  renderHeaderListMobile = (data) => {
    if (data?.length > 0) {
      return data.map((v, i) => {
        if (v.Slug === "contactus" || v.Slug === "blog") {
          return (
            <li
              key={i}
              onClick={() => {
                HGA.trackingEventClick({
                  action: "click_item_menu_header",
                  label: v.Title,
                });
              }}
            >
              <a title={v.Title} href={v.Slug}>
                <span>{v.Title}</span>
              </a>
            </li>
          );
        }
      });
    }
  };
  renderCategoryList = (data) => {
      const {menuActive} = this.state;
    if (data?.length > 0) {
      return data.map((v, i) => {
          const _itmChildren = v.ItemChildren;
        return (
          <li
            className="mega-menu-categories root-category-items"
            key={i}
            onClick={() => {
              HGA.trackingEventClick({
                action: "click_item_menu_category_mobile",
                label: v.Name,
              });
            }}
          >
            <div className="item">
                <a href={v.APIUrl} className={`${menuActive?.Name == v.Name ? "active": ""}`}>
                    <span>{v.Name}</span>
                </a>
                {_itmChildren?.length>0 && 
                    <span 
                        className="menu-collapse-icon" 
                        onClick={()=>this.onOpenSubMenu(v)}
                    >
                        <i className={menuActive?.Name == v.Name ? "fa fa-angle-up" : "fa fa-angle-down"}/>
                    </span>
                }
            </div>
            {
                _itmChildren?.length>0 && (
                    <div className="item-collapse" style={{display: menuActive?.Name == v.Name ? "block" : "none"}}>
                        {
                            _itmChildren.map((v, i)=>{
                                return(
                                    <a key={i} href={v.APIUrl}>{v.Name}</a>
                                )
                            })
                        }
                    </div>
                )
            }
          </li>
        );
      });
    }
  };
  render() {
    const { headerList, categoryList, showSidebar } = this.props;
    return (
      <div
        className={`sc_menu_mobile header-menu categories-in-side-panel ${
          showSidebar ? "open" : ""
        }`}
      >
        <div className="close-menu" onClick={() => this.onCloseMenu()}>
          <span>Close</span>
        </div>
        <ul className="mega-menu">
          {this.renderHeaderList(headerList)}
          {this.renderCategoryList(categoryList)}
          {this.renderHeaderListMobile(headerList)}
        </ul>
      </div>
    );
  }
}
export default SC_MenuMobile