import React from 'react'
import ExtCart from './_ext_cart_bill'
import {HText, HConfig, HUtils} from 'apps/Helpers'
import {MyUI, MyModal} from 'apps/Components'
import MyIconSvg from '../../MyIconSvg/MyIconSvg'
import './styles/sc_wishlist_table.css'

class SC_WishlistTable extends ExtCart{
    constructor(props){
        super(props);
        this.state = {
            isShowAttribute: false,
            expandMobile: [],
            data: []
        }
    }
    componentDidMount(){
        const {data} = this.props;
        this.initCollapseMobile(data);
        this.checkShowAttributeColumn(data);
    }
    checkShowAttributeColumn = (data) =>{
        let _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions")
        if(_haveProductOptions && data?.length>0){
            let _itemHaveAttributes = data.find((v)=> v.AttributeList != null);
            if(_itemHaveAttributes){
                this.setState({
                    isShowAttribute: true
                })
            }
        }
    }
    onChangeField=(field, value, index)=>{
        const {fnList} = this.props;
        fnList?.fnOnChangeField && fnList.fnOnChangeField(field, value, index)
    }
    removeItem = (index) =>{
        MyModal.Helpers.showConfirm("Bạn có chắc muốn xóa sản phẩm này ra khỏi danh sách yêu thích?", {title: "Xác nhận xóa", onOK: (cModal, {fnClose})=>{
            let {expandMobile} = this.state;
            expandMobile = expandMobile.filter((v, i)=>i!=index);
            this.setState({
                expandMobile: expandMobile
            },()=>{
                const {fnList} = this.props;
                fnList?.fnOnRemoveItem && fnList.fnOnRemoveItem(index)
                fnClose && fnClose()
            })
        }})
    }
    renderTableHeader=()=>{
        const {isShowAttribute} = this.state;
        return(
            <thead>
                <tr className="cart-header-row">
                    <th className="item-count" style={{display: 'table-cell'}}></th>
                    <th className="add-to-cart">{HText.get("text_wishlist_column_isorder")}</th>
                    <th className="product-picture">{HText.get("text_wishlist_column_image")}</th>
                    <th className="product">{HText.get("text_wishlist_column_product")}</th>
                    {
                        isShowAttribute && <th className="product attributes">{HText.get("text_wishlist_column_attribute")}</th>

                    }
                    <th className="unit-price">{HText.get("text_wishlist_column_price")}</th>
                    <th className="quantity">{HText.get("text_wishlist_column_quantity")}</th>
                    <th className="subtotal footable-last-column">{HText.get("text_wishlist_column_total")}</th>
                    <th className="remove-from-cart footable-first-column">{HText.get("text_wishlist_column_remove")}</th>
                </tr>
            </thead>
        )
    }
    renderTableContent=(data)=>{
        let _ui = [];
        if(data.length>0){
            for(let i=0; i<data.length; i++){
                const _item = data[i];
                _ui.push(this.renderItem(_item, i))
                _ui.push(this.renderItemMobile(_item, i))
            }
        }
        return(
            <tbody>
                {_ui}
            </tbody>
        )
    }
    renderItem = (item, index) =>{
        const {isShowAttribute} = this.state;
        const _IsOrder = item.IsOrder;
        const _MaterialName = item.MaterialName;
        const _ImageUrlThumnail = item.ImageCurrentAttribute || item.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
        const _Slug = item.Slug;
        const _Quantity = item.Quantity;
        const _QuantityMin = item._QuantityMin;
        
        return (
            <tr className="cart-item-row" key={index}>
                <td className="item-count" style={{display: 'table-cell'}}>
                   {this.renderToggleMobile(index)}
                </td>
                <td className="add-to-cart">
                    {/* <input 
                        type="checkbox" 
                        name="addtocart" 
                        checked={item.hasOwnProperty('IsOrder') ? _IsOrder : false} 
                        onChange={(e)=>this.onChangeField("IsOrder", !_IsOrder, index)}
                    /> */}
                    <MyUI 
                        type="input_check" 
                        defaultValue={_IsOrder} 
                        onChange={(e, v)=>this.onChangeField("IsOrder", !_IsOrder, index)}
                        style={{marginRight: "0.5rem", position: 'relative'}}
                    />
                </td>
                <td className="product-picture">
                    <a href={_Slug}>
                        <div className="product-iventory-mobile">{this.renderItemInventory(item)}</div>
                        <div className="product-picture-container">
                            <img alt={`${HText.get("text_product_title_image")} ${_MaterialName}`} src={_ImageUrlThumnail} title={`${HText.get("text_product_title")} ${_MaterialName}`}/>
                            {
                                (this.chackHasValue(_QuantityMin)) ? <span><MyIconSvg type="sale" width={25} height={25} fill="var(--color-primary-bold)"/></span> : null
                            }
                        </div>
                    </a>
                </td>
                <td className="product">
                    <a href={_Slug} className="product-name">{_MaterialName}</a>
                    {this.renderItemDiscount(item)}
                    {this.renderItemInventory(item)}
                </td>
                {
                    isShowAttribute && (
                        <td className="product attributes">{this.renderItemAttributes(item, index)}</td>
                    )
                }
                <td className="unit-price">
                    {this.renderPrice(item, {className: "dFaCjCw100 "})}
                </td>
                <td className="quantity">
                    <MyUI 
                        type="sc_input_quantity" 
                        className = "quantity-control" 
                        showControlBtn={false}                             
                        value={_Quantity} 
                        onChange={(value)=>{this.onChangeField("Quantity", value, index)}} 
                        onDelete={()=>{this.removeItem(index)}}
                    />
                </td>
                <td className="subtotal footable-last-column">
                    <span className="product-subtotal">{this.getTotalMoney(item)}</span>
                </td>
                <td className="remove-from-cart footable-first-column">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <i className="fa fa-remove" onClick={()=>this.removeItem(index)}/>
                    </div>
                </td>
            </tr>

        )
    }
    renderItemAttributes = (item, index) =>{
        const _configShowInventory = this.getConfigInventory(item);
        const _QuantityInStock =  HUtils.Obj.get(item,"Inventory.Quantity");
        const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
        const curAtbs = item.OrderDetail_AttributeList; //client
        const itmAtbs = item.AttributeList;

        if(_haveProductOptions && curAtbs){
            let _ui = []
            let _kAtb = Object.keys(curAtbs);
            let _outOfStock = _configShowInventory.show && _QuantityInStock == 0;
            _ui = _kAtb.map((v, i)=>{
                let _valueId = curAtbs[v];
                let _value = null;
                let _itmAtb = itmAtbs.find((a)=>a.Code === v);
                if(_itmAtb && _itmAtb.Children){
                    let _itmAtbCrn = _itmAtb.Children.find((b)=>b.Id === _valueId);
                    if(_itmAtbCrn){
                        _value = _itmAtbCrn.TextDisplay;
                    }
                }
                if(_value){
                    return <div key={i} className="product-item-attribute">{_value}{i !== (_kAtb.length - 1) ? <span>-</span>:null}</div>
                }
            })
            return(
                <div className={`product-attributes${_outOfStock == true ? " disabled": ""}`} onClick={()=>this.renderUpdateAttribute(item, index, {
                    fnUpdateAttribute: (newData)=>{
                        const {fnList} = this.props;
                        if(newData && fnList?.fnOnChangeField){
                            let _keys = Object.keys(newData);
                            for(let i=0; i<_keys.length; i++){
                                const _field = _keys[i];
                                fnList.fnOnChangeField(_field, newData[_field], index)
                            }
                        }
                    }
                })}>
                    {_ui}
                    <MyIconSvg type="update" width={14} height={14} fill={"var(--color-secondary)"} style={{marginLeft: "0.25rem"}}/>
                </div>
            )
        }
        return(
            <div className="product-attributes disabled" onClick={()=>this.renderUpdateAttribute(item, index, {
                fnUpdateAttribute: (newData)=>{
                    const {fnList} = this.props;
                    if(newData && fnList?.fnOnChangeField){
                        let _keys = Object.keys(newData);
                        for(let i=0; i<_keys.length; i++){
                            const _field = _keys[i];
                            fnList.fnOnChangeField(_field, newData[_field], index)
                        }
                    }
                }
            })}>
                {HText.get("text_wishlist_btn_choose_type")}
            </div>
        )
    }
    renderItemMobile = (item, index) =>{
        const {expandMobile, isShowAttribute} = this.state;
        const _IsOrder = item.IsOrder;
        const _MaterialName = item.MaterialName;
        const _Slug = item.Slug;
        const _Quantity = item.Quantity;
        return(
            <tr className="footable-row-detail" style={expandMobile[index]==false ? {display: 'none'}: {}} key={`mobile ${index}`}>
                <td className="footable-row-detail-cell" colSpan="3">
                    <div className="footable-row-detail-inner">
                        <div className="footable-row-detail-row">
                            <div className="footable-row-detail-name">{HText.get("text_wishlist_column_product")}:</div>
                            <div className="footable-row-detail-value">
                                <a href={_Slug} className="product-name">{_MaterialName}</a>
                            </div>
                        </div>
                        <div className="footable-row-detail-row">
                            <div className="footable-row-detail-name">{HText.get("text_wishlist_column_price")}:</div>
                            <div className="footable-row-detail-value">
                                {this.renderPrice(item, {className: "dFaCjFSw100",isMobile: true})}
                            </div>
                        </div>
                        {
                            isShowAttribute && this.renderItemAttributesMobile(item, index)
                        }
                        <div className="footable-row-detail-row">
                            <div className="footable-row-detail-name">{HText.get("text_wishlist_column_quantity")}:</div>
                            <div className="footable-row-detail-value">
                                <MyUI type="sc_input_quantity" 
                                    className = "quantity-control"
                                    showControlBtn={false} 
                                    value={_Quantity} 
                                    onChange={(value)=>{this.onChangeField("Quantity", value, index)}} 
                                    onDelete={()=>{this.removeItem(index)}}
                                />
                                {this.renderItemDiscount(item)}
                            </div>
                        </div>
                        <div className="footable-row-detail-row action">
                            <div className="dFaCjFSw100">
                                <MyUI 
                                    type="input_check" 
                                    defaultValue={_IsOrder} 
                                    onChange={(e, v)=>this.onChangeField("IsOrder", !_IsOrder, index)}
                                    style={{marginRight: "0.5rem", position: 'relative'}}
                                />
                                {/* <input type="checkbox" checked={_IsOrder} onChange={(e)=>this.onChangeField("IsOrder", !_IsOrder, index)}/> */}
                                <div className="footable-row-detail-name">{HText.get("text_wishlist_column_isorder")}</div>
                            </div>
                            <div className="footable-row-detail-delete" onClick={()=>{
                                this.removeItem(index)
                            }}>{HText.get("text_wishlist_column_remove")}</div>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }
    render(){
        const {data} = this.props;
        if(data){
            return(
                <div className="table-wrapper">
                    <table className="cart">
                        { this.renderTableHeader() }
                        { this.renderTableContent(data) }
                    </table>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_WishlistTable