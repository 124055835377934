import HGA from '../../helpers/GA'
const ShopUtils = {
  setOnErrorImg: function(query){
    // let _allImg = document.getElementsByTagName("img");
    // let _allImg = document.querySelectorAll(".page-body img");
    let _allImg = document.querySelectorAll(query);
    // console.warn("AllImg:",_allImg);
    if(_allImg){
      for (var i = 0; i < _allImg.length; i++) {
        let _img = _allImg[i];
        _img.onerror = function (err) {
          ShopUtils.onErrorImg(_img);
        }
      };
    }
  },
  onErrorImg: function(el){
    if(el){
      var url = el.getAttribute("src");
      console.warn("onErrorImg:",url);
      HGA.trackingEventErrorImage({
        currentUrl: window.location.href,
        imgUrl: url
      })
    }    
  }
}

export default ShopUtils;