import React from 'react';
import PageCategoryExt from './SC_Category';
import {HApiAxios,HLink,HConfig, HText, HLocalStorage, HUtils} from 'apps/Helpers';
import { MyUI } from 'apps/Components';
import './styles/common.css';
import './styles/sc_search.css';

class SC_Search extends PageCategoryExt{
    constructor(props){
        super(props);        
        this.initPage();
        this.parseConfig();
    }
    componentDidMount(){
        this.getCategory();
    }
    initPage=()=>{        
        this.state.categoryDetail = {
            GrpMaterialName: HText.get("text_search_title"),
        }
        this.state.textSearch="";
        //
        this._params = HLink.getAllUrlParams(window.location.href);
        this._configProps = {};
        if(this._params){
            // console.warn("_params", this._params);
            const _filterRange = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "filterPriceRange") || {min: 0, max: 2000000}
            const _pagesize = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "pageSizeOptions");
            const _appName = HConfig.fnList.getConfig("appName");
            const _sortType = HUtils.Obj.get(HLocalStorage.getObject(`${_appName}_defaultOptions`, {}), "CommandType", 1)

            this._configProps.page = this._params.page || 1;
            this._configProps.viewMode = this._params.viewMode || 0;
            this._configProps.pageSize = this._params.pageSize || HLocalStorage.getObject(`${_appName}_pageSize`, _pagesize?.[0].Value);
            this._configProps.sortType = this._params.sortType || _sortType;
            this._configProps.priceMin = this._params.priceMin || _filterRange.min;
            this._configProps.priceMax = this._params.priceMax || _filterRange.max;
            if(this._params.hasOwnProperty('query')){
                this._configProps.query= decodeURIComponent(this._params.query);
                this.state.textSearch = decodeURIComponent(this._params.query);
            } 
        }

        this._fnList = {
            getParams: ()=>{
                return this._params;
            },
            getConfig: ()=>{
                return this._configProps;
            },
            changeState:(obj={})=>{                
                let _query = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
                console.warn("changeState:",obj,_query);
                window.history.pushState({},document.title,window.location.pathname + '?' + _query)
            }
        }
    }    
    getQuery=()=>{
        let _all = HLink.getAllUrlParams(window.location.href);
        let _query = null;
        if(this.state.textSearch){
            _query = decodeURIComponent(this.state.textSearch)
        }
        else if(_all && _all.query){
            _query = decodeURIComponent(_all.query)
        }
        return _query
    }
    getCategory=({customQuery}={})=>{
        const _query = this.getQuery();
        if(_query){
            this.showLoadingApp()
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'DashboardForWebsite/GetListDataWithSearch'
                },
                data: {
                    TextSearch: _query,
                    ...this.getFilterQuery(),
                    ...customQuery
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        const _data = response.Data;
                        if(_data.Data){
                            this.setState({
                                category: _data.Data
                            })
                        }
                        if(_data.ExtraData && _data.ExtraData.GrpMaterial){
                            this.setState({
                                categoryDetail: _data.ExtraData.GrpMaterial
                            })
                        }
                        if(_data.Total!=null){
                            this.setState({
                                total: _data.Total
                            })
                        }
                        this.hideLoadingApp()
                    }
                },
                errorCallBack: (err, response)=>{
                    this.hideLoadingApp()
                }
            })
        }
    }
    renderBreakcumb=()=>{
        return <div></div>
    }
    onSearch = () =>{
        // HLink.setNewUrlWithoutReload(`/search?query=${this.state.textSearch}`)
        this.changePage(1);
        this.updateURL("query", this.state.textSearch)
        this.updateURL("page", 1);
        this.getCategory({customQuery: {Page: 1}})
    }
    renderSearch=()=>{
        return(
            <div className="search-box-search">
                <input type="text" className="search-box-text" placeholder={HText.get("text_placeholder_search")} value={this.state.textSearch} 
                onChange={(e)=>{this.setState({textSearch: e.currentTarget.value})}}
                onKeyPress={(ev)=>{
                  if(ev.key == "Enter"){
                    this.onSearch();
                  }
                }}
                /> 
                <button className="btn-search" onClick={()=>this.onSearch()}>{HText.get("text_btn_search")}</button>
            </div>
        )
    }
} 
export default SC_Search