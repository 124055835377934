import React from 'react'
import {MyUI} from 'apps/Components'
import MyIconSvg from '../../MyIconSvg/MyIconSvg'
import { HConfig, HUtils, HText} from 'apps/Helpers'
import HShopUtils from 'apps/shoppingCart/helpers/Utils'
import './styles/sc_card_product_in_cart.css'

class SC_CardProductInCart extends React.Component{
    getConfigInventory=(item)=>{
        let _config = {};
        let _dontCareInventory = HUtils.Obj.get(item,"Inventory.DontCareInventory");
        let _contentDisplayOnWeb =  HUtils.Obj.get(item,"Inventory.ContentDisplayOnWeb");
        let _quantityInStock =  HUtils.Obj.get(item,"Inventory.Quantity");
        if(_dontCareInventory !== true){
            if(_quantityInStock <= 0){
                _config.show = true;
                _config.displayText = _contentDisplayOnWeb; 
            }
            else if(item.Quantity > _quantityInStock && _quantityInStock > 0){
                _config.show = true;
                _config.displayText = `Chỉ còn ${_quantityInStock}` 
            }
        }
        return _config
    }
    renderInventory = (item) =>{
        const _configShowInventory = this.getConfigInventory(item);
        if(_configShowInventory?.show){
            return (
              <div
                className="product-inventory-msg"
              >
                *{_configShowInventory.displayText}
              </div>
            );
        }
    }
    renderDiscount = (data) =>{
        const _QuantityMin = data._QuantityMin; //client
        const _Discount_Text = data._Discount_Text; //client
        if(HShopUtils.checkHasValue(_QuantityMin) && _QuantityMin > 1){
            return <div className="product-coupon">
                <MyIconSvg type="flash" width={15} height={15} fill="#F1C40F"/>
                {_Discount_Text}
            </div>
        }
    }
    render(){
        const {data, fnList, renderPrice, renderAttribute} = this.props;
        if(data){
            const _IsOrder = data.IsOrder;
            const _Slug = data.Slug;
            const _MaterialName = data.MaterialName;
            const _Quantity = data.Quantity;
            const _ImageUrlThumnail = data.ImageCurrentAttribute || data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
            const _QuantityMin = data._QuantityMin; 
            const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
            const _AttributeList = data.AttributeList;
            const _isShowAttribute = (_haveProductOptions && _AttributeList?.length>0) ? true : false    
            return (
                <div className="sc_card_product_in_cart">
                    {this.renderDiscount(data)}
                    <div className="sc_item_row">
                        <div className="product-picture">
                            <a href={_Slug}>
                                <div className="product-picture-container">
                                    <img alt={`${HText.get("text_product_title_image")} ${_MaterialName}`} src={_ImageUrlThumnail} title={`${HText.get("text_product_title")} ${_MaterialName}`}/>
                                </div>
                            </a>
                        </div>
                        <div className="product-description">
                            <div className="product-info">
                                <div className="product-name">
                                    <a href={_Slug}>{_MaterialName}</a>
                                </div>
                                <div className="product-price">
                                    {renderPrice && renderPrice(data)}
                                </div>
                                {
                                    _isShowAttribute && (
                                        <div className="product-attribute">
                                            {renderAttribute && renderAttribute(data)}
                                        </div>
                                    )
                                }
                                
                            </div>
                            <div className="product-order">
                                <div className="product-inventory">{this.renderInventory(data)}</div>
                                <div className="product-quantity">
                                    <MyUI 
                                        type="sc_input_quantity" 
                                        className = "quantity-control" 
                                        value={_Quantity} 
                                        onChange={(value)=>{fnList?.fnOnChangeField && fnList.fnOnChangeField("Quantity", value)}} 
                                        onDelete={()=>{fnList?.fnOnDelete && fnList.fnOnDelete()}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sc_item_row product-action">
                        <div className="dFaCjFSw100">
                            <MyUI 
                                type="input_check" 
                                defaultValue={_IsOrder} 
                                onChange={(e, v)=>{
                                    fnList?.fnOnChangeField && fnList.fnOnChangeField("IsOrder", v)
                                }}
                                style={{marginRight: "0.5rem", position: 'relative'}}
                            />
                            {HText.get("text_cart_column_isorder")}
                        </div>
                        <div className="product-remove" onClick={()=>{
                            fnList?.fnOnRemove && fnList.fnOnRemove()
                        }}>{HText.get("text_cart_column_delete")}</div>
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_CardProductInCart 