import React from "react";
import PageExt from "../../../../../components/MyPage/Types/_ext";
import { MyUI, MyLoadingApp, MyModal } from "../../../../Components";
import { HUtils, HAuth, HApiAxios } from "../../../../Helpers";
import { connect } from "react-redux";
import "./styles/common.css";

class SC_QuickOrderUser extends PageExt {
  constructor(props) {
    super(props);
    this.state = {
      shipping: {
        CustomerName: null,
        CustomerPhone: null,
        CustomerAddress: null,
        CustomerEmail: null,
        CustomerDeliveryId: null,
        AddDelivery: false,
        Note: null,
        DeliveryTimePlanId: "0",
        DeliveryTimePlanText: "Nhanh nhất có thể",
      },
      deliveryTimePlan: null,
      addressBook: null,
      errorForm: null,
      materialList: null,
      availableMaterialList: null,
    };
  }
  componentDidMount() {
    this.requestCustomerDelivery();
    this.requestAvailableMaterialList();
    this.getDeliveryTimePlanFromStore(this.props);
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.optionList != nextProps.optionList) {
      this.getDeliveryTimePlanFromStore(nextProps);
    }
    return true;
  }
  requestCustomerDelivery = () => {
    if (HAuth.isLoggedIn() == true) {
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "AlliancePosSupplierDelivery/GetCustomerDelivery4Web",
        },
        data: {
          CustomerId: HAuth.getUserId(),
        },
        successCallBack: (response) => {
          const _data = HUtils.Obj.get(response, "Data", []);
          const { shipping } = this.state;
          if (_data.length > 0) {
            const _defaultAddress = _data.find((i) => i.IsDefault == true);
            let _shipping = shipping;
            if (_defaultAddress) {
              _shipping = {
                CustomerName: _defaultAddress.Receiver,
                CustomerPhone: _defaultAddress.Phone,
                CustomerAddress: _defaultAddress.Address,
                CustomerEmail: _defaultAddress.Email,
                Note: _defaultAddress.Note,
              };
            }
            this.setState({
              addressBook: _data,
              shipping: _shipping,
            });
          }
        },
        errorCallBack: (error, response) => {
          MyLoadingApp.Helpers.show({ error: error || response.Msg });
        },
      });
    }
  };
  requestAvailableMaterialList = () => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "DashboardForWebsite/GetMaterialHasInventory"
      },
      successCallBack: (response)=>{
        const data = HUtils.Obj.get(response, "Data");
        if(data){
          this.setState({availableMaterialList: data})
        }
      },
      errorCallBack: (error, response)=>{
        console.warn("requestAvailableMaterialList error, response: ",error, response)
      }
    })
  };
  requestSubmitOrder = () => {
    const { shipping } = this.state;
    const items = this.getFinalMaterialList();
    let _url = "EzyWeb_OrderWithoutLogin/ConfirmToOrder";
    if (this.checkValidForm() == true) {
      if (items.length === 0) {
        MyModal.Helpers.showMsg("Không có sản phẩm để tiến hành đặt hàng!", {
          title: "Thông báo",
        });
        return;
      }
      MyLoadingApp.Helpers.show({
        msg: "Đang gửi yêu cầu...",
      });

      if (shipping.hasOwnProperty("DeliveryTimePlanText")) {
        delete shipping.DeliveryTimePlanText;
      }
      if (HAuth.isLoggedIn() == true) {
        shipping.CustomerId = HAuth.getUserId();
        _url = "EzyWeb_Order/ConfirmToOrder";
      }
      HApiAxios.generic({
        request: {
          method: "POST",
          url: _url,
        },
        data: {
          Items: items,
          ...shipping,
        },
        successCallBack: (response) => {
          this.setState(
            {
              orderComplete: true,
              messageOrder: HUtils.Obj.get(response, "Data.MessageOrder"),
            },
            () => {
              MyLoadingApp.Helpers.hide();
              window.scrollTo(0, 0);
            }
          );
        },
        errorCallBack: (err, response) => {
          MyLoadingApp.Helpers.show({ error: err || response.Msg });
        },
      });
    }
  };
  getDeliveryTimePlanFromStore = (obj) => {
    let { shipping } = this.state;
    let _dTP = HUtils.Obj.get(obj, "optionList.DeliveryTimePlan");
    if (_dTP) {
      const _currentHour = new Date().getHours();
      if (_currentHour !== null) {
        _dTP = _dTP.filter((v) => v.Value > _currentHour || v.Value === "0");
      }
      // console.warn("_currentHour", _currentHour)
      shipping.DeliveryTimePlanId = _dTP[0].Value;
      shipping.DeliveryTimePlanText = _dTP[0].Text_Filter;
      this.setState({
        deliveryTimePlan: _dTP,
        shipping: shipping,
      });
    }
  };
  getFinalMaterialList = () => {
    const { materialList } = this.state;
    let _fL = [];
    if (materialList) {
      for (let i = 0; i < materialList.length > 0; i++) {
        const _item = materialList[i];
        if (_item.MaterialName && _item.Quantity_Text) {
          _fL.push(_item);
        }
      }
    }
    return _fL;
  };
  checkValidPhone = (phoneNum) => {
    const _regexPhone = /^[0-9]*$/;
    if (phoneNum) {
      return _regexPhone.test(phoneNum);
    }
    return false;
  };
  checkValidForm = () => {
    const { shipping } = this.state;
    let _check = true;
    if (shipping.CustomerPhone == null || shipping.CustomerPhone == "") {
      this.setState({
        errorForm: "Vui lòng nhập số điện thoại!",
      });
      _check = false;
    } else if (this.checkValidPhone(shipping.CustomerPhone) == false) {
      this.setState({
        errorForm: "Số điện thoại không hợp lệ!",
      });
      _check = false;
    }
    return _check;
  };
  onChangeForm = (field, value) => {
    let { shipping, deliveryTimePlan } = this.state;
    shipping[field] = value;
    if (field === "DeliveryTimePlanId") {
      const _item =
        deliveryTimePlan && deliveryTimePlan.find((v) => v.Value === value);
      // console.warn("_item", _item)
      shipping.DeliveryTimePlanText = _item && _item.Text;
    }
    this.setState({
      shipping: shipping,
      errorForm: null,
    });
  };
  renderHelmet = () => {
    const meta = {
      MetaTitle: "Đặt hàng nhanh",
      MetaKeyWords: "Giỏ hàng, thanh toán, đặt hàng, đặt hàng nhanh",
      MetaDescription: "Đặt hàng nhanh",
    };
    return <MyUI type="sc_helmet" data={meta} noindex={true} />;
  };
  renderShippingPart = () => {
    const { shipping, addressBook, errorForm, deliveryTimePlan } = this.state;
    return (
      <div className="panel-group-right">
        <MyUI
          type="sc_cart_address"
          data={shipping}
          options={{
            expectedTimeDeliveryList: deliveryTimePlan,
            addressBook: addressBook,
          }}
          config={{
            showSignIn: false,
            showAddNewAddress: false,
          }}
          fnList={{
            onChangeForm: (field, value) => {
              this.onChangeForm(field, value);
            },
          }}
        />
        {errorForm && (
          <div
            style={{
              color: "#f74258",
              textAlign: "center",
              margin: "0px 0px 10px",
              fontSize: 13,
            }}
          >
            {errorForm}
          </div>
        )}
        {this.renderSubmit()}
      </div>
    );
  };
  renderCartPart = () => {
    const {availableMaterialList} = this.state;
    return (
      <div className="panel-group-left">
        <MyUI
          type="sc_quickorder_user"
          options = {{
            availableMaterialList: availableMaterialList
          }}
          onChange={(materialList) => {
            this.setState({
              materialList: materialList,
            });
          }}
        />
      </div>
    );
  };
  renderSubmit = () => {
    return (
      <div className="dFaCjCtaCw100" style={{ padding: "40px 0px" }}>
        <div className="checkout-buttons">
          <button
            type="submit"
            className="button-1 checkout-button"
            onClick={() => {
              MyModal.Helpers.showConfirm(
                "Bạn đã chắc chắn muốn đặt đơn hàng này?",
                {
                  title: "Xác nhận",
                  onOK: (cModal, { fnClose, fnShowLoading }) => {
                    this.requestSubmitOrder();
                    fnClose();
                  },
                }
              );
            }}
          >
            Tiến hành đặt hàng
          </button>
        </div>
      </div>
    );
  };
  renderOrder = () => {
    const title = "Đặt hàng nhanh";
    return (
      <div className="page shopping-cart-page quickorder-user">
        <div className="page-title">
          <h1>{title}</h1>
        </div>
        <div className="page-body">
          {this.renderCartPart()}
          {this.renderShippingPart()}
        </div>
      </div>
    );
  };
  renderCompleteOrder = () => {
    const { messageOrder } = this.state;
    const title = "Hoàn tất đơn hàng";
    return (
      <div className="page">
        <div className="page-title">
          <h1>{title}</h1>
        </div>
        <div className="page-body">
          <div className="section">
            <div
              className="title"
              style={{ textAlign: "center", textTransform: "none" }}
            >
              <MyUI type="html" html={messageOrder} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { orderComplete } = this.state;
    return (
      <div className="master-wrapper-content">
        {this.renderHelmet()}
        <div className="master-column-wrapper">
          <div className="center-1">
            {orderComplete ? this.renderCompleteOrder() : this.renderOrder()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    optionList: state.shop.optionList,
  };
};
export default connect(mapStateToProps, null)(SC_QuickOrderUser);
