import React from "react";
import MyUITypes from "../../../../components/MyUI/MyUITypes";
import MyLoadingComponent from "../../../../components/MyLoading/MyLoadingComponent";

/** Control */
import HTML_Editor_CkeditorJS from "../../../../components/MyUI/Types/html_editor_ckeditor";
import InputText from "../../../../components/MyUI/Types/input_text";
import InputTextarea from "../../../../components/MyUI/Types/input_textarea";
import Popover from "../../../../components/MyUI/Types/popover";
import InputCheck from "../../../../components/MyUI/Types/input_check";
import Select2 from "../../../../components/MyUI/Types/select2";
import InputRadio from "../../../../components/MyUI/Types/input_radio";
import PageFilter from "../../../../components/MyUI/Types/page_filter";
import Box from "../../../../components/MyUI/Types/box";
import SpinnerDots from "../../../../components/MyUI/TypesMore/spinner_dots";
import SpinnerRing from "../../../../components/MyUI/TypesMore/spinner_ring";
import CommonLogin from "./Types/sc_login_userpass";
import MListHorizontal from "../../../../components/MyUI/TypesMobile/m_list_horizontal";
import MyButton from "../../../../components/MyUI/Types/my_button";
import Form from "../../../../components/MyUI/Types/form";

/** Home */
import HomeTopSlide from "./Types/home_topslide";
import HomeCatProduct from "./Types/home_cat_product";
import HomeNewComing from "./Types/home_new_coming";
import HomeRecentBlog from "./Types/home_recent_blog";
import HomeBestSeller from "./Types/home_best_seller";
import ScHomeBlogPost from "./Types/sc_home_blog_post";
import HomePromotion from "./Types/home_promotion";

import ScGrid from "./Types/sc_product_grid";
import ScList from "./Types/sc_product_list";
import ScTags from "./Types/sc_tags";
import ScHeaderSearch from "./Types/sc_header_search";
import ScGoTop from "./Types/sc_goTop";
import ScGoQuickorderUser from "./Types/sc_go_quickerorder_user";
import ScCardProductMiniShopping from "./Types/sc_card_product_minishopping";
import ScModalMiniShoppingCart from "./Types/sc_modal_mini_shoppingcart";
import ScCardProduct from "./Types/sc_card_product";
import ScCardBanner from "./Types/sc_card_banner";
import ScTab from "./Types/sc_tab";
import ScBreakcumb from "./Types/sc_breakcumb";
import SC_CategoryLeft from "./Types/sc_category_left";
import SC_CategoryTop from "./Types/sc_category_top";
import SC_ProductImages from "./Types/sc_product_images";
import SC_ProductShortDesc from "./Types/sc_product_shortdesc";
import SC_AdminList from "./Types/sc_admin_list";
import SC_Login from "./Types/sc_login";
import SC_Loading from "./Types/sc_loading";
import SC_EditorProductFull from "./Types/sc_editor_product_full";
import SC_EditorTestApi from "./Types/sc_editor_testapi";
import SC_EditorProduct from "./Types/sc_editor_product";
import SC_EditorBlog from "./Types/sc_editor_blog";
import SC_EditorPageContent from "./Types/sc_editor_pagecontent";
import SC_ToolbarAdmin from "./Types/sc_toolbar_admin";

import SC_Filter from "./Types/sc_filter";
import SC_CardProductRecent from "./Types/sc_card_product_recent";
import SC_ProductRelative from "./Types/sc_product_relative";
import SC_ProductTag from "./Types/sc_product_tag";
import SC_BlogPost from "./Types/sc_blog_post";
import SC_EditorBanner from "./Types/sc_editor_banner";
import SC_Input_Quantity from "./Types/sc_input_quantity";
import SC_EditorHomeCat from "./Types/sc_editor_home_cat";
import SC_ModalSelectPhotos from "./Types/sc_modal_select_photos";
import SC_EditorFooter from "./Types/sc_editor_footer";
import SC_Helmet from "./Types/sc_helmet";
import SC_UserQuickOrder from "./Types/sc_quickorder_user";
import SC_List from "./Types/sc_list";
import SC_Project_Attribute from "./Types/sc_product_attribute";
import SC_Editor_Product_Image from "./Types/sc_editor_product_image";
import SC_Modal_Editor_Attribute_Image_Product from "./Types/sc_modal_editor_attribute_image_product";
import SC_WishlistTable from "./Types/sc_wishlist_table";
import SC_CardProductInCart from "./Types/sc_card_product_in_cart";
import SC_InputRadio from "./Types/sc_input_radio";
import SC_Menu from "./Types/sc_menu";
import SC_MenuMobile from "./Types/sc_menu_mobile";
import SC_Editor_Upload_Image from "./Types/sc_editor_upload_image";
import SC_ModalOptionUploadImage from "./Types/sc_modal_option_upload_image";
import SC_OrderProgress from "./Types/sc_order_progress";
import SC_GroupProducts from "./Types/sc_quickorder_group_products";
import SC_GuildLine from "./Types/sc_guildline";
import SC_CardOptionalOrder from "./Types/sc_quickorder_optional_order";
import SC_CardCategoryInSearch from "./Types/sc_card_category_in_search";
import SC_CardBlogInSearch from "./Types/sc_card_blog_in_search";
import SC_CardProductInQuickorder from "./Types/sc_card_product_in_quickorder";
import SC_GridView from "./Types/sc_grid_view";
import SC_CardProductPromotion from "./Types/sc_card_product_promotion";
import SC_CustomSlide from "./Types/sc_custom_slide";
import SC_CardProductInHome from "./Types/sc_card_product_in_home";

const SC_ModalAddCart = React.lazy(() => import("./Types/sc_modal_addcart"));
const SC_ModalAddCartWithOptions = React.lazy(() =>
  import("./Types/sc_modal_addcart_with_options")
);
const SC_ModalUpdateOptions = React.lazy(() =>
  import("./Types/sc_modal_update_options")
);
const SC_CartAddress = React.lazy(() => import("./Types/sc_cart_address"));
const SC_CartTotal = React.lazy(() => import("./Types/sc_cart_total"));
const SC_CartBill = React.lazy(() => import("./Types/sc_cart_bill"));
const SC_PreviewBill = React.lazy(() => import("./Types/sc_preview_bill"));
const SC_PreviewCart = React.lazy(() => import("./Types/sc_preview_cart"));
const SC_PreviewShipping = React.lazy(() =>
  import("./Types/sc_preview_shipping")
);
const SC_PreviewTotal = React.lazy(() => import("./Types/sc_preview_total"));
const SC_QuickOrderAddress = React.lazy(() =>
  import("./Types/sc_quickorder_address")
);
const SC_QuickOrderPreviewAddress = React.lazy(() =>
  import("./Types/sc_quickorder_preview_address")
);
const SC_QuickorderPreviewBill = React.lazy(() =>
  import("./Types/sc_quickorder_preview_bill")
);
const SC_Address = React.lazy(() => import("./Types/sc_address"));
const SC_BillCard = React.lazy(() => import("./Types/sc_bill_card"));

const ReactInputRange = React.lazy(() =>
  import("../../../../components/MyUI/Types/react_input_range")
);
const ReactPaginationBox = React.lazy(() =>
  import("../../../../components/MyUI/Types/react_pagination_box")
);
const MyUI_OverlaySeason = React.lazy(() =>
  import("../../../../components/MyUI/TypesMore/overlay_season")
);

const AppMyUI = {
  init() {
    /* Common type */
    MyUITypes.addTypes("popover", (props) => {
      return <Popover {...props} />;
    });
    MyUITypes.addTypes("m_list_horizontal", (props) => {
      return <MListHorizontal {...props} />;
    });
    MyUITypes.addTypes("box", (props) => {
      return <Box {...props} />;
    });
    MyUITypes.addTypes("input_radio", (props) => {
      return <InputRadio {...props} />;
    });
    MyUITypes.addTypes("spinner_dots", (props) => {
      return <SpinnerDots {...props} />;
    });
    MyUITypes.addTypes("spinner_ring", (props) => {
      return <SpinnerRing {...props} />;
    });
    MyUITypes.addTypes("html_editor_ckeditor", (props) => {
      return <HTML_Editor_CkeditorJS {...props} />;
    });
    MyUITypes.addTypes("input_text", (props) => {
      return <InputText {...props} />;
    });
    MyUITypes.addTypes("input_textarea", (props) => {
      return <InputTextarea {...props} />;
    });
    MyUITypes.addTypes("input_check", (props) => {
      return <InputCheck {...props} />;
    });
    MyUITypes.addTypes("page_filter", (props) => {
      return <PageFilter {...props} />;
    });
    MyUITypes.addTypes("box", (props) => {
      return <Box {...props} />;
    });
    MyUITypes.addTypes("select2", (props) => {
      return <Select2 {...props} />;
    });
    MyUITypes.addTypes("myui_overlay_season", (props) => {
      return <MyUI_OverlaySeason {...props} />;
    });
    MyUITypes.addTypes("my_button", (props) => {
      return <MyButton {...props} />;
    });
    MyUITypes.addTypes("form", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <Form {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("common_login", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <CommonLogin {...props} />
        </React.Suspense>
      );
    });

    /** Shopping Cart Type */
    /** Card */
    MyUITypes.addTypes("sc_card_product", (props) => {
      return <ScCardProduct {...props} />;
    });
    MyUITypes.addTypes("sc_card_banner", (props) => {
      return <ScCardBanner {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_recent", (props) => {
      return <SC_CardProductRecent {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_minishopping", (props) => {
      return <ScCardProductMiniShopping {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_in_cart", (props) => {
      return <SC_CardProductInCart {...props} />;
    });
    MyUITypes.addTypes("sc_card_category_in_search", (props) => {
      return <SC_CardCategoryInSearch {...props} />;
    });
    MyUITypes.addTypes("sc_card_blog_in_search", (props) => {
      return <SC_CardBlogInSearch {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_in_quickorder", (props) => {
      return <SC_CardProductInQuickorder {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_promotion", (props) => {
      return <SC_CardProductPromotion {...props} />;
    });

    /** Home */
    MyUITypes.addTypes("home_topslide", (props) => {
      return <HomeTopSlide {...props} />;
    });
    MyUITypes.addTypes("home_cat_product", (props) => {
      return <HomeCatProduct {...props} />;
    });
    MyUITypes.addTypes("home_new_coming", (props) => {
      return <HomeNewComing {...props} />;
    });
    MyUITypes.addTypes("home_best_seller", (props) => {
      return <HomeBestSeller {...props} />;
    });
    MyUITypes.addTypes("home_recent_blog", (props) => {
      return <HomeRecentBlog {...props} />;
    });
    MyUITypes.addTypes("home_promotion", (props) => {
      return <HomePromotion {...props} />;
    });
    MyUITypes.addTypes("sc_home_blog_post", (props) => {
      return <ScHomeBlogPost {...props} />;
    });

    /** Modal */
    MyUITypes.addTypes("sc_modal_mini_shoppingcart", (props) => {
      return <ScModalMiniShoppingCart {...props} />;
    });
    MyUITypes.addTypes("sc_modal_addcart", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_ModalAddCart {...props}/>
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_modal_addcart_with_options", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_ModalAddCartWithOptions {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_modal_update_options", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_ModalUpdateOptions {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_modal_select_photos", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_ModalSelectPhotos {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_modal_editor_attribute_image_product", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_Modal_Editor_Attribute_Image_Product {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_modal_option_upload_image", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <SC_ModalOptionUploadImage {...props} />
        </React.Suspense>
      );
    });

    /** Others */
    MyUITypes.addTypes("sc_category_left", (props) => {
      return <SC_CategoryLeft {...props} />;
    });
    MyUITypes.addTypes("sc_category_top", (props) => {
      return <SC_CategoryTop {...props} />;
    });
    MyUITypes.addTypes("sc_filter", (props) => {
      return <SC_Filter {...props} />;
    });
    MyUITypes.addTypes("sc_product_grid", (props) => {
      return <ScGrid {...props} />;
    });
    MyUITypes.addTypes("sc_product_list", (props) => {
      return <ScList {...props} />;
    });
    MyUITypes.addTypes("sc_product_attribute", (props) => {
      return <SC_Project_Attribute {...props} />;
    });
    MyUITypes.addTypes("sc_tags", (props) => {
      return <ScTags {...props} />;
    });
    MyUITypes.addTypes("sc_login", (props) => {
      return <SC_Login {...props} />;
    });
    MyUITypes.addTypes("sc_product_images", (props) => {
      return <SC_ProductImages {...props} />;
    });
    MyUITypes.addTypes("sc_product_shortdesc", (props) => {
      return <SC_ProductShortDesc {...props} />;
    });
    MyUITypes.addTypes("sc_product_relative", (props) => {
      return <SC_ProductRelative {...props} />;
    });
    MyUITypes.addTypes("sc_product_tag", (props) => {
      return <SC_ProductTag {...props} />;
    });
    MyUITypes.addTypes("sc_tab", (props) => {
      return <ScTab {...props} />;
    });
    MyUITypes.addTypes("sc_gotop", (props) => {
      return <ScGoTop {...props} />;
    });
    MyUITypes.addTypes("sc_go_quickorder_user", (props) => {
      return <ScGoQuickorderUser {...props} />;
    });
    MyUITypes.addTypes("sc_header_search", (props) => {
      return <ScHeaderSearch {...props} />;
    });
    MyUITypes.addTypes("sc_cart_address", (props) => {
      return <SC_CartAddress {...props} />;
    });
    MyUITypes.addTypes("sc_cart_total", (props) => {
      return <SC_CartTotal {...props} />;
    });
    MyUITypes.addTypes("sc_cart_bill", (props) => {
      return <SC_CartBill {...props} />;
    });
    MyUITypes.addTypes("sc_preview_bill", (props) => {
      return <SC_PreviewBill {...props} />;
    });
    MyUITypes.addTypes("sc_preview_cart", (props) => {
      return <SC_PreviewCart {...props} />;
    });
    MyUITypes.addTypes("sc_preview_shipping", (props) => {
      return <SC_PreviewShipping {...props} />;
    });
    MyUITypes.addTypes("sc_preview_total", (props) => {
      return <SC_PreviewTotal {...props} />;
    });
    MyUITypes.addTypes("sc_breakcumb", (props) => {
      return <ScBreakcumb {...props} />;
    });
    MyUITypes.addTypes("sc_blog_post", (props) => {
      return <SC_BlogPost {...props} />;
    });
    MyUITypes.addTypes("sc_address", (props) => {
      return <SC_Address {...props} />;
    });
    MyUITypes.addTypes("sc_bill_card", (props) => {
      return <SC_BillCard {...props} />;
    });
    MyUITypes.addTypes("react_input_range", (props) => {
      return <ReactInputRange {...props} />;
    });
    MyUITypes.addTypes("react_pagination_box", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          
          <ReactPaginationBox {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_editor_blog", (props) => {
      return <SC_EditorBlog {...props} />;
    });
    MyUITypes.addTypes("sc_editor_pagecontent", (props) => {
      return <SC_EditorPageContent {...props} />;
    });
    MyUITypes.addTypes("sc_editor_product", (props) => {
      return <SC_EditorProduct {...props} />;
    });
    MyUITypes.addTypes("sc_toolbar_admin", (props) => {
      return <SC_ToolbarAdmin {...props} />;
    });
    MyUITypes.addTypes("sc_admin_list", (props) => {
      return <SC_AdminList {...props} />;
    });
    MyUITypes.addTypes("sc_loading", (props) => {
      return <SC_Loading {...props} />;
    });
    MyUITypes.addTypes("sc_editor_product_full", (props) => {
      return <SC_EditorProductFull {...props} />;
    });
    MyUITypes.addTypes("sc_editor_testapi", (props) => {
      return <SC_EditorTestApi {...props} />;
    });
    MyUITypes.addTypes("sc_editor_banner", (props) => {
      return <SC_EditorBanner {...props} />;
    });
    MyUITypes.addTypes("sc_input_quantity", (props) => {
      return <SC_Input_Quantity {...props} />;
    });
    MyUITypes.addTypes("sc_editor_home_cat", (props) => {
      return <SC_EditorHomeCat {...props} />;
    });
    MyUITypes.addTypes("sc_editor_footer", (props) => {
      return <SC_EditorFooter {...props} />;
    });
    MyUITypes.addTypes("sc_helmet", (props) => {
      return <SC_Helmet {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_user", (props) => {
      return <SC_UserQuickOrder {...props} />;
    });
    MyUITypes.addTypes("sc_list", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          
          <SC_List {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_editor_product_image", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          
          <SC_Editor_Product_Image {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_wishlist_table", (props) => {
      return <SC_WishlistTable {...props} />;
    });
    MyUITypes.addTypes("sc_input_radio", (props) => {
      return <SC_InputRadio {...props} />;
    });
    MyUITypes.addTypes("sc_menu", (props) => {
      return <SC_Menu {...props} />;
    });
    MyUITypes.addTypes("sc_menu_mobile", (props) => {
      return <SC_MenuMobile {...props} />;
    });
    MyUITypes.addTypes("sc_editor_upload_image", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          
          <SC_Editor_Upload_Image {...props} />
        </React.Suspense>
      );
    });
    MyUITypes.addTypes("sc_order_progress", (props) => {
      return <SC_OrderProgress {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_group_products", (props) => {
      return <SC_GroupProducts {...props} />;
    });
    MyUITypes.addTypes("sc_guildline", (props) => {
      return <SC_GuildLine {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_optional_order", (props) => {
      return <SC_CardOptionalOrder {...props} />;
    });
    MyUITypes.addTypes("sc_grid_view", (props) => {
      return <SC_GridView {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_address", (props) => {
      return <SC_QuickOrderAddress {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_preview_address", (props) => {
      return <SC_QuickOrderPreviewAddress {...props} />;
    });
    MyUITypes.addTypes("sc_quickorder_preview_bill", (props) => {
      return <SC_QuickorderPreviewBill {...props} />;
    });
    MyUITypes.addTypes("sc_custom_slide", (props) => {
      return <SC_CustomSlide {...props} />;
    });
    MyUITypes.addTypes("sc_card_product_in_home", (props) => {
      return <SC_CardProductInHome {...props} />;
    });
  },
};
AppMyUI.init();
export default AppMyUI;
