import React from "react";
import { MyUI } from "../../../../Components";
import { HApiAxios } from "../../../../Helpers";
import HGA from "../../../../../helpers/GA";
import { CFade } from "../../../../../components/MyCore";

class HomeCatProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      tabList: [],
      data: [],
      catInfoHeight: 342,
    };
  }
  componentWillUnmount() {
    // window.removeEventListener("resize", this._setStyle);
  }
  componentDidMount() {
    // window.addEventListener("resize", this._setStyle);
    this._getData();
    const _tabList = this._getConfigByKey("TabItem", []);
    if (_tabList.length > 0) {
      let _tabActive = 0;
      for (let i = 0; i < _tabList.length; i++) {
        if (_tabList[i].IsActive == true) {
          _tabActive = i;
        }
      }
      this.setState({
        tabIndex: _tabActive,
        tabList: _tabList,
      });
    }
    // this._setStyle()
  }
  _setStyle = () =>{
    const _config = this._getConfigGrp()
    const pdIdElm = `${_config?.LinkCategory}-products`
    const pdElm = document.getElementById(pdIdElm)
    if(pdElm){
      if(pdElm?.offsetHeight){
        this.setState({
          catInfoHeight: pdElm.offsetHeight
        })
      }
    }
  }
  _getConfigByKey = (key, df) => {
    const { config } = this.props;
    if (config && config[key]) {
      return config[key];
    }
    return df;
  };
  _getConfigGrp = () => {
    const configGrp = this._getConfigByKey("GrpMaterial", {});
    return {
      BigImageUrl:
        configGrp.ImageUrl ||
        "https://posapi.allianceitsc.com/Upload/ALLIANCE_WEB_SITE/Thumbs/0011102_rau-cu-qua.png",
      Title: configGrp.GrpMaterialName || "Category",
      LinkCategory: configGrp.Link || "/",
    };
  };
  _getData = () => {
    const { data } = this.props;
    this.setState({
      data,
    });
  };
  _onChangeTab = (tab, index) => {
    HGA.trackingEventClick({
      action: "click_item_home_tab_of_cat",
      label: "Tab_" + tab.Title,
    });
    this.setState(
      {
        tabIndex: index,
      },
      () => {
        if (tab && tab.ApiName && tab.RequestData) {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              HApiAxios.generic({
                request: {
                  method: "POST",
                  path: "DashboardForWebsite",
                  name: tab.ApiName,
                },
                data: {
                  PageSize: 4,
                  ...tab.RequestData,
                },
                successCallBack: (res) => {
                  let _data = [];
                  if (res.Data) {
                    _data = res.Data;
                    if (_data.Data) {
                      _data = _data.Data;
                    }
                  }
                  this.setState({
                    data: _data,
                    isLoading: false,
                  });
                },
                errorCallBack: (error, response) => {
                  // MyLoadingApp.Helpers.show({error: error || response.Msg})
                  this.setState({
                    isLoading: false,
                    error: error || response,
                  });
                },
              });
            }
          );
        }
      }
    );
  };
  render() {
    let _data = this.state.data;
    let _config = this._getConfigGrp();
    const { tabIndex, tabList, catInfoHeight } = this.state;

    return (
      <div className="spc spc-categories landscape">
        <div className="spc-header">
          <ul className="navigation">
            {tabList.length > 0 &&
              tabList.map((v, i) => {
                return (
                  <li
                    className={tabIndex == i ? "tab active" : "tab"}
                    key={i}
                    onClick={() => this._onChangeTab(v, i)}
                  >
                    <span>{v.Title}</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="spc-body">
          <div
            className="category-info"
            style={{
              height: catInfoHeight,
            }}
          >
            <h2
              className="category-title"
              onClick={() => {
                HGA.trackingEventClick({
                  action: "click_item_home_category",
                  label: _config.Title,
                });
              }}
            >
              <a href={_config.LinkCategory} title={_config.Title}>
                {_config.Title}
              </a>
            </h2>
            <div className="category-picture">
              <a
                href={_config.LinkCategory}
                title={_config.Title}
                onClick={() => {
                  HGA.trackingEventClick({
                    action: "click_item_home_category",
                    label: _config.Title,
                  });
                }}
              >
                <img
                  src={_config.BigImageUrl}
                  alt={`Hiển thị sản phẩm trong thư mục ${_config.Title}`}
                />
              </a>
            </div>
          </div>
          <div className="spc-header mobile">
            <ul className="navigation">
              {tabList.length > 0 &&
                tabList.map((v, i) => {
                  return (
                    <li
                      className={tabIndex == i ? "tab active" : "tab"}
                      key={i}
                      onClick={() => this._onChangeTab(v, i)}
                    >
                      <span>{v.Title}</span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="category-products">
            <MyUI
              type="sc_grid_view"
              id={`${_config?.LinkCategory}-products`}
              settings={[
                { width: 681, column: 2 },
                { width: 0, column: 1 },
              ]}
              layoutStyle={{
                gap: 0,
              }}
              renderComponent={() => {
                return (
                  _data.length > 0 &&
                  _data.map((product, index) => {
                    return (
                      <CFade
                        timeout={500}
                        key={`${index}_${product.MaterialId}`}
                      >
                        <MyUI
                          type="sc_card_product_in_home"
                          key={index}
                          data={product}
                          from="home_cat_product"
                          onClick={() => {
                            HGA.trackingEventClick({
                              action: "click_item_home_product_of_cat",
                              label: product.MaterialName,
                            });
                          }}
                        />
                      </CFade>
                    );
                  })
                );
              }}
              fnList={{
                updateStyle: () => {
                  this._setStyle()
                },
              }}
            />
            {this.state.isLoading === true && (
              <div className="ui-loading">
                <MyUI type="spinner_dots" />
              </div>
            )}
            {/* <div className="product-grid active">
                <div className="item-grid">
                {_data.length>0 && _data.map((product, index)=>{
                  return (
                    <CFade timeout={500} key={`${index}_${product.MaterialId}`}>
                      <MyUI type="sc_card_product_in_home" key={index} data={product} from="home_cat_product" onClick={()=>{
                        HGA.trackingEventClick({
                          action: 'click_item_home_product_of_cat',
                          label: product.MaterialName
                        })
                      }}/>
                    </CFade>
                  )                                    
                })}
                </div>
                {
                  this.state.isLoading===true &&
                  <div className="ui-loading">
                    <MyUI type="spinner_dots"/>
                  </div>
                }               
              </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default HomeCatProduct;
