import React from 'react'
import MyPageTypes from './MyPageTypes';
class MyPage extends React.Component {    
  render(){  
    const {configPage} = this.props;
    console.log("configPage:",configPage);
    let _typePage = (configPage && configPage.UIType) || 'Table';
    if(_typePage){
      _typePage = _typePage.toLowerCase();
    }
    if(configPage && _typePage){
      if(MyPageTypes.Types[_typePage]){
        return MyPageTypes.Types[_typePage](this.props);
      }
    }  
    console.warn("MyPage not config:",configPage);
    return (
      <div>MyPage not config</div>
    )
  } 
}

export default MyPage