import React from 'react'
import {HApiAxios,HUtils,HUI, HLink} from '../../../../Helpers'
import { MyModal, MyUI} from '../../../../Components'
import HShopUtils from '../../../helpers/Utils'

class SC_Editor_Product extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dataProduct: {},
            tabIndex: 0,
            UnitList: null,
            GrpMaterialList: null
        }
    }
    componentDidMount(){
        this.requestOptions()
    }
    requestOptions=()=>{
        HApiAxios.generic({
            request: {
                method: 'POST',
                path: "AlliancePosMaterial",
                name: "GetOptionValueForWeb"
            },
            successCallBack: (response)=>{  
                const _UnitList = HUtils.Obj.get(response, "Data.UnitList");
                const _GrpMaterialList = HUtils.Obj.get(response, "Data.GrpMaterialList");
                if(_UnitList){
                    this.setState({
                        UnitList: _UnitList
                    })
                }
                if(_GrpMaterialList){
                    this.setState({
                        GrpMaterialList: _GrpMaterialList
                    })
                }
            },
            errorCallBack:(error,response)=>{
            }
        })
    }
    requestAddNewMaterial=()=>{
        const {dataProduct} = this.state;
        console.warn("_values", dataProduct)
        if(dataProduct.hasOwnProperty('MaterialName') == false || dataProduct.hasOwnProperty('Slug') == false){
            HUI.Toast.showWarn("Không có thông tin để tạo mới sản phẩm!");
            return;
        }
        if(dataProduct.Slug == "" || dataProduct.Slug == null){
            HUI.Toast.showWarn("Bạn chưa nhập link cho sản phẩm này!");
            return;
        }            
        const _url = "AlliancePosMaterial/AddNewMaterialFromWeb"
        const _query = dataProduct
        this.setState({
            isSubmiting: true
        },()=>{
            HApiAxios.generic({
                request:{
                    method: 'POST',
                    url: _url
                },
                data: _query,
                successCallBack: (response)=>{
                    this.setState({
                        isSubmiting: false
                    },()=>{
                        const _slug = HUtils.Obj.get(response, "Data.Slug");
                        if(_slug){
                            HLink.push(_slug);
                        }
                        MyModal.Helpers.hide();
                        window.location.reload();
                    })                        
                },
                errorCallBack: (error, response)=>{
                    this.setState({
                        isSubmiting: false
                    })
                }
            })
        })
    }
    onSave=()=>{
        // if(this._editor_html && this._editor_html.getData){
        //     let _content = this._editor_html.getData();
        //     if(_content){
        //         this.state.dataProduct.FullDescription = _content;
        //     }            
        //     this.requestAddNewMaterial();
        // }
        this.requestAddNewMaterial();

    }
    onChangeValue=(field, value)=>{
        const {isNew} = this.props;
        let {dataProduct} = this.state;
        if(field == "MaterialName" && isNew == true){
            dataProduct.Slug = HShopUtils.slugGeneration(value);
        }
        dataProduct[field] = value;
        this.setState({
            dataProduct: dataProduct,
        })
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index
        })
    }
    render(){
        const {dataProduct,tabIndex,isSubmiting, UnitList, GrpMaterialList} = this.state;
        const {isNew} = this.props;
        // console.warn("props:",this.props,this.state);
        return(
            <div className="sc_editor_blog">
                <div className="blog-row">
                    <div className="label">Tên</div>
                    <MyUI type="input_text" style={{marginLeft:'0.5rem'}} placeholder="Tên" defaultValue={dataProduct.MaterialName} onBlur={(e, newValue)=>this.onChangeValue("MaterialName", newValue)}/>
                </div>
                {
                    isNew == true &&
                    <div className="blog-row">
                        <div className="label">Link</div>
                        <MyUI type="input_text" style={{marginLeft:'0.5rem'}} placeholder="Link" defaultValue={dataProduct.Slug} onBlur={(e, newValue)=>this.onChangeValue("Slug", newValue)}/>
                    </div>
                }
                <div className="blog-row">
                    <div>
                        <div className={`label ${tabIndex==0?'':'inactive'}`} onClick={()=>{this.onChangeTab(0)}}>Sản phẩm</div>
                    </div>
                    <div style={{minHeight:'120px',width:'100%',marginLeft:'0.5rem'}}>
                        {
                            tabIndex==0 &&
                            <div>

                                <MyUI type="input_text" style={{marginBottom:'0.5rem'}} placeholder="Mã sản phẩm" defaultValue={dataProduct.MaterialCode} onBlur={(e, newValue)=>this.onChangeValue("MaterialCode", newValue)}/>
                                <MyUI type="input_text" style={{marginBottom:'0.5rem'}} placeholder="Đơn giá" defaultValue={dataProduct.SellPriceAfterVAT} onBlur={(e, newValue)=>this.onChangeValue("SellPriceAfterVAT", newValue)}/>
                                <MyUI type="select2" style={{marginBottom:'0.5rem'}} placeholder="Đơn vị" options={UnitList} simpleValue multi={false} value={dataProduct.UnitId} onChange={(newValue)=>this.onChangeValue("UnitId", newValue)}/>
                                <MyUI type="select2" style={{marginBottom:'0.5rem'}} placeholder="Loại sản phẩm" options={GrpMaterialList} simpleValue multi={false} value={dataProduct.GrpMaterialId} onChange={(newValue)=>this.onChangeValue("GrpMaterialId", newValue)}/> 
                            </div>
                        }                   
                    </div>                    
                </div>
                <div className="blog-row">
                    <div></div>
                    <button disabled={isSubmiting} onClick={()=>this.onSave()} className="btn-save-edit" style={{float: 'right'}}>
                        {
                            isSubmiting===true &&
                            <i className="fa fa-spinner fa-spin"/>
                        }
                        Thêm mới
                    </button>
                </div>
                {
                    isSubmiting===true &&
                    <div className="pAFull-loading"><i className="fa fa-spinner fa-spin"/></div>
                }
            </div>
        )
    }
}
export default SC_Editor_Product