import React from "react";
import { HApiAxios, HUtils, HText, HLink, HUI } from "../../../../Helpers";
import { MyModal, MyUI } from "../../../../Components";
import View from "../../../../../libs/CKEditorUI/ckeditor5-ui/src/view";
import HShopUtils from '../../../helpers/Utils'
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import "./styles/sc_editor_blog.css";

class ImageAvailableButton extends View {
  constructor(locale) {
    super(locale);

    const bind = this.bindTemplate;

    // Views define their interface (state) using observable attributes.
    this.set("elementClass", "ck-button");
    this.setTemplate({
      tag: "button",

      // The element of the view can be defined with its children.
      children: ["Chọn ảnh có sẵn"],
      attributes: {
        class: [
          "ck",

          // Observable attributes control the state of the view in DOM.
          bind.to("elementClass"),
        ],
      },
      on: {
        // Views listen to DOM events and propagate them.
        click: bind.to("clicked"),
      },
    });
  }
  render() {
    super.render();
  }
}
class InsertImageAvailable {
  constructor(editor, { data } = {}) {
    this._editor = editor;
    this.data = data;
  }
  init() {
    const editor = this._editor;

    editor.ui.componentFactory.add("insertImage", (locale) => {
      const view = new ImageAvailableButton(locale);
      //   view.set( {
      //     icon: ,
      // } );

      // Callback executed once the image is clicked.
      view.on("clicked", () => {
        // console.log("The view has been clicked!");
        // const imageUrl = prompt("Image URL");
        return MyModal.Helpers.showMyUI2(
          "sc_modal_select_photos",
          {
            data: this.data,
            isBlog: true,
            textSubmit: "Chèn ảnh",
            submit: (items) => {
              if (Array.isArray(items)) {
                for (let i = 0; i < items.length; i++) {
                  setTimeout(() => {
                    editor.model.change((writer) => {
                      console.warn(
                        "items, i",
                        items,
                        i,
                        editor.model.document.selection
                      );
                      const imageElement = writer.createElement("image", {
                        src: items[i],
                      });
                      // Insert the image in the current selection location.
                      editor.model.insertContent(
                        imageElement,
                        editor.model.document.selection,
                        "after"
                      );
                    });
                  }, 1000);
                }
              }
            },
          },
          {
            title: "Chọn ảnh",
            closeButton: true,
            className: "mmui2",
            closeOnBackdrop: false,
          }
        );
      });
      return view;
    });
  }
}
class SC_Editor_Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataBlog: {
        Title: null,
        BodyOverview: null,
        IsActive: false,
        Body: null,
      },
      tabIndex: 0,
      dataWillUpdate: {},
    };
  }
  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        dataBlog: {
          Title: data.Title,
          BodyOverview: data.BodyOverview,
          Body: data.Body || "",
          IsActive: data.IsActive,
          MetaTitle: data.MetaTitle || "",
          MetaKeyWords: data.MetaKeyWords || "",
          MetaDescription: data.MetaDescription || "",
          Slug: data.Slug || "",
          AllowComment: data.AllowComment,
        },
        ImageUrl: HUtils.Obj.get(data, "Files_0.Photos[0].FileUrl"),
      });
    }
  }
  requestUpdateBlog = () => {
    const { data, fnList, isNew } = this.props;
    const { dataWillUpdate } = this.state;

    let _values = [];
    let _keys = Object.keys(dataWillUpdate);
    for (let i = 0; i < _keys.length; i++) {
      const k = _keys[i];
      if (isNew) {
        _values.push({ FieldName: k, NewValue: dataWillUpdate[k] });
      } else if (dataWillUpdate[k] != data[k]) {
        _values.push({ FieldName: k, NewValue: dataWillUpdate[k] });
      }
    }
    if (data || isNew == true) {
      if (_values.length == 0 && this.checkImageChange() == false) {
        HUI.Toast.showWarn("Bài viết chưa có chỉnh sửa mới!");
        return;
      } else if (_values.length == 0 && this.checkImageChange() == true) {
        MyModal.Helpers.hide();
        window.location.reload();
        return;
      }
      if (
        (dataWillUpdate.hasOwnProperty("Slug") == true &&
          (dataWillUpdate.Slug == "" || dataWillUpdate.Slug == null)) ||
        (isNew == true && dataWillUpdate.hasOwnProperty("Slug") == false)
      ) {
        HUI.Toast.showWarn("Bạn chưa nhập link cho bài viết này!");
        return;
      } else {
        const _url =
          isNew == true
            ? "Dashboard/CreateDataWithSlug"
            : "Dashboard/UpdateDataWithSlug";
        const _query =
          isNew == true
            ? { Values: _values, Type: "BlogPost" }
            : { Values: _values, Slug: data.Slug };
        HApiAxios.generic({
          request: {
            method: "POST",
            url: _url,
          },
          data: _query,
          successCallBack: (response) => {
            HUI.Toast.showSuccess(response.Msg);
            const _slug = HUtils.Obj.get(response, "Data.Slug");
            if (isNew || _slug !== data.Slug) {
              if (_slug) {
                HLink.push(_slug);
              }
            } else {
              fnList && fnList.updateBlog(response.Data);
            }
            MyModal.Helpers.hide();
          },
          errorCallBack: (error, response) => {
            // MyLoadingApp.Helpers.show({error: error||response.Msg});
          },
        });
      }
    }
  };
  requestUpload = (files) => {
    const { data } = this.props;
    HApiAxios.generic({
      request: {
        method: "UPLOAD",
        path: "ProjectPhoto",
        name: "Upload",
      },
      files: files,
      customHeader: {
        ProjectId: "",
        ScreenCode: "EZYWEB_BLOG_POST",
        Id: data.Id,
        FieldName: "Files_0",
      },
      successCallBack: (response) => {
        HUI.Toast.showSuccess(response.Msg);
        this.setState({
          ImageUrl: URL.createObjectURL(files[0]),
        });
      },
      errorCallBack: (error, response) => {},
    });
  };
  checkImageChange = () => {
    const { data } = this.props;
    const { ImageUrl } = this.state;
    let _isChange = true;
    if (
      ImageUrl == null ||
      ImageUrl === HUtils.Obj.get(data, "Files_0.Photos[0].FileUrl")
    ) {
      _isChange = false;
    }
    return _isChange;
  };
  insertImageAvailablePlugin = (editor) => {
    return new InsertImageAvailable(editor);
  };
  onChangeValue = (field, value) => {
    const { isNew } = this.props;
    let { dataBlog, dataWillUpdate } = this.state;
    if (field == "Title" && isNew == true) {
      const _slugGeneration = HShopUtils.slugGeneration(value);
      dataBlog.Slug = _slugGeneration;
      dataWillUpdate.Slug = _slugGeneration;
    }
    dataBlog[field] = value;
    dataWillUpdate[field] = value;

    this.setState({
      dataBlog: dataBlog,
      dataWillUpdate: dataWillUpdate,
    });
  };
  onChangeTab = (index) => {
    this.setState({
      tabIndex: index,
    });
  };
  onSave = () => {
    // this.requestUpdateBlog()
    if (this._editor_html && this._editor_html.getData) {
      let _content = this._editor_html.getData();
      if (this.state.dataBlog.Body != _content && _content != null) {
        this.state.dataBlog.Body = _content;
        this.state.dataWillUpdate.Body = _content;
      }
      this.requestUpdateBlog();
    }
  };
  renderTab = () => {
    const { tabIndex } = this.state;
    const { isNew } = this.props;
    return (
      <div>
        <div
          className={`label ${tabIndex == 0 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(0);
          }}
        >
          Thông tin
        </div>
        {isNew != true && (
          <div
            className={`label ${tabIndex == 1 ? "" : "inactive"}`}
            onClick={() => {
              this.onChangeTab(1);
            }}
          >
            Hình ảnh
          </div>
        )}
        {
          // (dataBlog.Body!=null || isNew == true ) &&
          <div
            className={`label ${tabIndex == 3 ? "" : "inactive"}`}
            onClick={() => {
              this.onChangeTab(3);
            }}
          >
            Nội dung
          </div>
        }
        <div
          className={`label ${tabIndex == 4 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(4);
          }}
        >
          Cấu hình
        </div>
      </div>
    );
  };
  renderContent = () => {
    const { dataBlog, ImageUrl, tabIndex } = this.state;
    const { isNew } = this.props;
    return (
      <div
        style={{
          width: "100%",
          marginLeft: "0.5rem",
          minHeight: 525
        }}
      >
        {tabIndex == 0 && (
          <div>
            <div className="group-input">
              <MyUI
                type="input_text"
                placeholder="Tiêu đề"
                style={{ marginBottom: "1rem" }}
                defaultValue={dataBlog.Title}
                onBlur={(e, newValue) => this.onChangeValue("Title", newValue)}
              />
              <label className={dataBlog.Title ? "active" : ""}>Tiêu đề</label>
            </div>
            {
              // isNew == true &&
              <div className="group-input">
                <MyUI
                  type="input_text"
                  placeholder="Link"
                  style={{ marginBottom: "1rem" }}
                  defaultValue={dataBlog.Slug}
                  onBlur={(e, newValue) => this.onChangeValue("Slug", newValue)}
                />
                <label className={dataBlog.Slug ? "active" : ""}>Link</label>
              </div>
            }
            <div className="group-input">
              <MyUI
                type="input_text"
                placeholder="Meta Title"
                style={{ marginBottom: "1rem" }}
                defaultValue={dataBlog.MetaTitle}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaTitle", newValue)
                }
              />
              <label className={dataBlog.MetaTitle ? "active" : ""}>
                Meta Title
              </label>
            </div>
            <div className="group-input">
              <MyUI
                type="input_text"
                style={{ marginBottom: "1rem" }}
                placeholder="Meta KeyWords"
                defaultValue={dataBlog.MetaKeyWords}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaKeyWords", newValue)
                }
              />
              <label className={dataBlog.MetaKeyWords ? "active" : ""}>
                Meta KeyWords
              </label>
            </div>
            <div className="group-input">
              <MyUI
                type="input_textarea"
                style={{ marginBottom: "1rem" }}
                placeholder="Meta Description"
                defaultValue={dataBlog.MetaDescription}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaDescription", newValue)
                }
              />
              <label className={dataBlog.MetaDescription ? "active" : ""}>
                Meta Description
              </label>
            </div>

            <div className="group-input">
              <MyUI
                type="input_textarea"
                placeholder="Tóm tắt"
                style={{ marginBottom: "1rem" }}
                defaultValue={dataBlog.BodyOverview}
                onBlur={(e, newValue) =>
                  this.onChangeValue("BodyOverview", newValue)
                }
              />
              <label className={dataBlog.BodyOverview ? "active" : ""}>
                Tóm tắt
              </label>
            </div>
          </div>
        )}
        {tabIndex == 1 && isNew != true && (
          <div className="dropzone" style={{minHeight: 525}}>
            {
              ImageUrl && 
              <img 
                src={ImageUrl} 
                style={{
                  float: "left",
                  position: "relative",
                  marginRight: "0.5rem",
                }}
              />
            }
            <label
              htmlFor="blog-file"
              className="btn-upload"
              style={{ clear: "both" }}
              title="Upload"
            >
              <MyIconSvg type="plus" width={20} height={20} fill={"var(--color-secondary)"}/>
            </label>
            <input
              id="blog-file"
              type="file"
              accept="*"
              onChange={(e) => this.requestUpload(e.target.files)}
              style={{ display: "none" }}
            />
          </div>
        )}
        {(dataBlog.Body != null || isNew == true) && (
          <div style={{ display: `${tabIndex == 3 ? "block" : "none"}` }}>
            <MyUI
              type="html_editor_ckeditor"
              html={dataBlog.Body}
              className="html-content"
              innerRef={(r) => {
                this._editor_html = r;
              }}
              pluginsMore={{
                components: [this.insertImageAvailablePlugin],
                toolbar: ["insertImage"],
              }}
            />
          </div>
        )}
        {tabIndex === 4 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: 200,
              padding: 5,
              border: "1px solid #c4c4c4",
              width: "100%",
              backgroundColor: "#fff",
              minHeight: 525
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <MyUI
                type="input_check"
                style={{ marginBottom: "0px" }}
                defaultValue={dataBlog.AllowComment}
                onChange={(e, newValue) =>
                  this.onChangeValue("AllowComment", newValue)
                }
              />
              <div style={{ marginLeft: 5 }}>Cho phép bình luận</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  render() {
    return (
      <div className="sc_editor_blog">
        <div className="blog-row">
          {this.renderTab()}
          {this.renderContent()}
        </div>
        <div className="blog-row">
          <div></div>
          <button
            onClick={() => this.onSave()}
            className="btn-save-edit"
            style={{ float: "right" }}
          >
            Cập nhật
          </button>
        </div>
      </div>
    );
  }
}
export default SC_Editor_Blog;
