import React from "react";
import PageExt from "components/MyPage/Types/_ext";
import { MyUI, MyLoadingApp } from "apps/Components";
import {
  HUtils,
  HConfig,
  HLocalStorage,
  HApiAxios,
  HUI,
  HAuth,
  HText,
} from "apps/Helpers";
import { connect } from "react-redux";
import "./styles/common.css";
import "./styles/sc_quickorder_combination.css";

class SC_QuickOrderCombination extends PageExt {
  constructor(props) {
    super(props);
    this.state = {
      groups: null,
      searchResult: null,
      orderProducts: {
        optionalProducts: null,
        selectedProducts: null,
        total: null
      },
      pageInfo: null,
      step: 0,
      sortType: 1,
      stepList: [
        { Value: 1, Text: "Chọn sản phẩm", Icon: "fa fa-shopping-basket"},
        { Value: 2, Text: "Thông tin giao hàng", Icon: "fa fa-map-marker", FontSize: 16 },
        { Value: 3, Text: "Kiểm tra đơn hàng", Icon: "fa fa-file-text-o", FontSize: 16 },
        { Value: 4, Text: "Hoàn thành", Icon: "fa fa-check", FontSize: 16 },
      ],
      shippingInfo: {
        CustomerName: null,
        CustomerPhone: null,
        CustomerAddress: null,
        CustomerEmail: null,
        CustomerDeliveryId: null,
        AddDelivery: false,
        Note: null,
        DeliveryTimePlanId: null,
        DeliveryTimePlanText: null,
        PaymentTypeId: null,
        PaymentTypeText: null,
        ...!HAuth.isLoggedIn() && {
            IsOtherReceiver: false,
            OtherReceiverName: null,
            OtherReceiverPhone: null,
            OtherReceiverAddress: null,
        }
      },
      dataAfterOrder: null
    };
    this.initDataOrder();
    this.initStep();
  }
  componentDidMount() {
    const { configPage } = this.props;
    const _data = HUtils.Obj.get(configPage, "Data.Data.Detail");

    if (_data) {
      this.setState({
        groups: _data,
      });
      const _extra = HUtils.Obj.get(configPage, "Data.ExtraData");
      const _titlePage = HUtils.Obj.get(configPage, "Data.Data.Description");
      if (_extra) {
        const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
        const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
        const _pageConfig = HUtils.Obj.get(_extra, "PageConfig");
        this.setState({
          pageInfo: { ..._grpInfo, ..._slugInfo, titlePage: _titlePage },
          pageConfig: _pageConfig,
        });
      }
    } else {
      // this.requestList();
    }
    this.requestAddressBook();
    this.onLeavePage();
  }
  /** Init */
  initDataOrder = () =>{
    let {orderProducts} = this.state;
    const optionalProducts = this.getValueFromSessionStorage("optionalProducts", []);
    const selectedProducts = this.getValueFromSessionStorage("selectedProducts", []);
    if(optionalProducts){
      orderProducts.optionalProducts = optionalProducts;
    }
    if(selectedProducts){
      orderProducts.selectedProducts = selectedProducts;
    }
    this.setState({
      orderProducts: orderProducts
    })
  }
  initStep = () =>{
    const forceStep = this.getForceStep()
    if(forceStep){
      this.state.step = forceStep;
      this.onSaveToSessionStorage("forceStep", null);
    }
  }
  /** Request */
  requestList = ({ customQuery } = {}) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        HApiAxios.generic({
          request: this.getRequestStep1(),
          data: {
            Slug: this.getSlug(),
            PageSize: 1000,
            ...customQuery,
          },
          successCallBack: (response) => {
            this.setState({
              isLoading: false,
            },()=>{
              const _data = HUtils.Obj.get(response, "Data.Data.Detail");
              if (_data?.length > 0) {
                this.setState({
                  groups: _data,
                });
                const _extra = HUtils.Obj.get(response, "Data.ExtraData");
                const _titlePage = HUtils.Obj.get(
                  response,
                  "Data.Data.Description"
                );
                if (_extra) {
                  const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
                  const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
                  const _pageConfig = HUtils.Obj.get(_extra, "PageConfig");
                  this.setState({
                    pageInfo: {
                      ..._grpInfo,
                      ..._slugInfo,
                      titlePage: _titlePage,
                    },
                    pageConfig: _pageConfig,
                  });
                }
              }
            });
          },
          errorCallBack: (err, response) => {
            this.setState({
              isLoading: false,
            },()=>console.warn("err, response", err, response));
          },
        });
      }
    );
  };
  requestSearch = ({ customQuery } = {}) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        HApiAxios.generic({
          request: this.getRequestStep1(),
          data: {
            Slug: this.getSlug(),
            PageSize: 1000,
            ...customQuery,
          },
          successCallBack: (response) => {
            const _data = HUtils.Obj.get(response, "Data.Data.Detail");
            if (_data?.length > 0) {
              this.setState({
                searchResult: _data,
                isLoading: false,
              });
            }
          },
          errorCallBack: (err, response) => {
            this.setState({
              isLoading: false,
            });
          },
        });
      }
    );
  };
  requestOrder = () => {
    let _url = "EzyWeb_OrderWithoutLogin/ConfirmToOrder";
    if(HAuth.isLoggedIn() === true){
      _url = "EzyWeb_Order/ConfirmToOrder";
    }
    MyLoadingApp.Helpers.show({
      msg: "Đang gửi yêu cầu..."
    });
    HApiAxios.generic({
      request: {
        method: "POST",
        url: _url,
      },
      data: this.getRequestDataOrder(),
      successCallBack: (response) => {
        this.setState(
          {
            step: 3,
            dataAfterOrder: HUtils.Obj.get(response, "Data"),
            orderProducts: null
          },
          () => {
            this.onClearSessaionStorage();
            this.onScrollTo(0, 0);
            MyLoadingApp.Helpers.hide();
          }
        );
      },
      errorCallBack: (err, response) => {
        MyLoadingApp.Helpers.show({ error: err || response.Msg });
      },
    });
  };
  requestAddressBook = () => {
    if (HAuth.isLoggedIn() == true) {
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "AlliancePosSupplierDelivery/GetCustomerDelivery4Web",
        },
        data: {
          CustomerId: HAuth.getUserId(),
        },
        successCallBack: (response) => {
          const data = response.Data;
          if (data?.length > 0) {
            const _defaultAddress = data.find(
              (i) => i.IsDefault == true
            );
            const { shippingInfo } = this.state;
            let _deliveryInfo = {};
            if (_defaultAddress) {
              _deliveryInfo = {
                CustomerName: _defaultAddress.Receiver,
                CustomerPhone: _defaultAddress.Phone,
                CustomerAddress: _defaultAddress.Address,
                CustomerEmail: _defaultAddress.Email,
                Note: _defaultAddress.Note,
              };
            }
            this.setState({
              shippingInfo: { ...shippingInfo, ..._deliveryInfo },
              addressBook: data
            });
          }
        },
        errorCallBack: (error, response) => {
          console.warn("error, response", error, response)
        },
      });
    }
  };

  /** Get */
  getDataWithFormat = (groups) => {
    let rs = [];
    if (groups?.length > 0) {
      groups.forEach((v) =>
        rs.push({ Title: v.Description, List: v.MaterialForWebInfo })
      );
    }
    return rs;
  };
  getDataFilter = (groups) =>{
    let _rs = []
    if(groups?.length>0){
        groups.forEach((v)=>{
            if(v?.MaterialForWebInfo?.length >0){
                v.MaterialForWebInfo.forEach((a)=>{
                    const itmDuplicate = _rs.find((k)=> k.MaterialId === a.MaterialId);
                    if(!itmDuplicate){
                        _rs.push(a);
                    }
                })
            }
        })
    }
    return _rs
  }
  getSlug = () => {
    const { pageInfo } = this.state;
    return HUtils.Obj.get(
      pageInfo,
      "Slug",
      window.location.pathname && window.location.pathname.split("/")?.[1]
    );
  };
  getRequestStep1 = () => {
    return {
      method: "POST",
      url: "DashboardForWebsite/GetListDataWithSlug",
    };
  };
  getSortTypeDefault = () => {
    const _appName = HConfig.fnList.getConfig("appName");
    const _sortType = HUtils.Obj.get(
      HLocalStorage.getObject(`${_appName}_defaultOptions`, {}),
      "CommandType",
      1
    );
    if (_sortType !== null) {
      return _sortType;
    }
    return 1;
  };
  getNumberProducts = () =>{
    const { orderProducts} = this.state;
    const { optionalProducts, selectedProducts} = orderProducts;
    return (optionalProducts?.length ? optionalProducts.length : 0) + (selectedProducts?.length ? selectedProducts.length : 0)
  }
  getRequestDataOrder = () =>{
    const {shippingInfo} = this.state;
    let spInfo = shippingInfo;
    delete spInfo.PaymentTypeText;
    delete spInfo.DeliveryTimePlanText;
    if(HAuth.isLoggedIn() === true){
      spInfo.CustomerId = HAuth.getUserId()
    }
    return{
      ...spInfo,
      Items: this.getFinalProductList()
    }
  }
  getFinalProductList = () =>{
    const { orderProducts } = this.state;
    const { optionalProducts, selectedProducts } = orderProducts;
    let bill = [];
    if(selectedProducts?.length > 0){
      bill = [...bill, ...selectedProducts]
    }
    if(optionalProducts?.length > 0){
      bill = [...bill, ...optionalProducts]
    }
    return bill;
  }
  getValueFromLocalStorage = (key) =>{
    const storageName = this.getStorageName();
    let objStorage = HLocalStorage.getObject(storageName);
    if(key){
      if(objStorage?.[key]){
        return objStorage[key]
      }
      return null;
    }
    return objStorage;
  }
  getValueFromSessionStorage = (key, df) =>{
    const storageName = this.getStorageName();
    let objStorage = sessionStorage.getItem(storageName);
    if(objStorage){
      try {
        objStorage = JSON.parse(objStorage);
        if(key){
          if(objStorage[key]){
            return objStorage[key];
          }
          return df;
        }
        return objStorage;
      } catch (error) {
        console.warn("parse session error", error);
      }
    }
    return null;
  }
  getStorageName = () =>{
    const appName = HConfig.fnList.getConfig("appName");
    const slug =  window.location.pathname.split("/")?.[1];//this.getSlug();
    return `${appName}_${slug}`;
  }
  getForceStep = () =>{
    return this.getValueFromSessionStorage("forceStep")
  }
  /** Check */
  checkHasProductToOrder = () =>{
    const {orderProducts} = this.state;
    const {optionalProducts, selectedProducts} = orderProducts;
    // console.warn("optionalProducts, selectedProducts", optionalProducts, selectedProducts) 
    if(optionalProducts?.length === 0 && selectedProducts?.length === 0){
      HUI.Toast.showWarn("Vui lòng chọn sản phẩm đặt hàng!");
      return false;
    }
    return true;
  }
  checkValidShippingInfo = () =>{
    const { shippingInfo } = this.state;
    const { IsOtherReceiver } = shippingInfo;
    let _check = true;

    if (!IsOtherReceiver) {
      if (
        !this.checkValidField(shippingInfo.CustomerName) &&
        !this.checkValidField(shippingInfo.CustomerAddress) &&
        !this.checkValidField(shippingInfo.CustomerPhone)
      ) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_field"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.CustomerName)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_name"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.CustomerPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_phone"),
        });
        _check = false;
      } else if (!this.checkValidPhone(shippingInfo.CustomerPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_invalid_phone"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.CustomerAddress)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_address"),
        });
        _check = false;
      }
    } else {
      if (
        !this.checkValidField(shippingInfo.CustomerName) &&
        !this.checkValidField(shippingInfo.CustomerAddress) &&
        !this.checkValidField(shippingInfo.CustomerPhone) &&
        !this.checkValidField(shippingInfo.OtherReceiverName) &&
        !this.checkValidField(shippingInfo.OtherReceiverAddress) &&
        !this.checkValidField(shippingInfo.OtherReceiverPhone)
      ) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty"),
        });
        _check = false;
      } else if (
        !this.checkValidField(shippingInfo.CustomerName) &&
        !this.checkValidField(shippingInfo.CustomerAddress) &&
        !this.checkValidField(shippingInfo.CustomerPhone)
      ) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_your_address_empty_field"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.CustomerName)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_your_name"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.CustomerPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_your_phone"),
        });
        _check = false;
      } else if (!this.checkValidPhone(shippingInfo.CustomerPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_invalid_your_phone"),
        });
        _check = false;
      } else if (
        !this.checkValidField(shippingInfo.OtherReceiverName) &&
        !this.checkValidField(shippingInfo.OtherReceiverAddress) &&
        !this.checkValidField(shippingInfo.OtherReceiverPhone)
      ) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_field"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.OtherReceiverName)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_name"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.OtherReceiverPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_phone"),
        });
        _check = false;
      } else if (!this.checkValidPhone(shippingInfo.OtherReceiverPhone)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_invalid_phone"),
        });
        _check = false;
      } else if (!this.checkValidField(shippingInfo.OtherReceiverAddress)) {
        this.setState({
          invalidShippingInfoText: HText.get("text_form_address_empty_address"),
        });
        _check = false;
      }
    }

    return _check;
  }
  checkValidPhone=(phoneNum)=>{
    const _regexPhone = /^[0-9]*$/;
    if (phoneNum) {
      return _regexPhone.test(phoneNum);
    }
    return false;
  }
  checkValidField=(value)=>{
    if(value === undefined || value === null || value === ""){
        return false
    }
    return true;
  }
  /** On Action */
  onSubmitSelectProduct = (products) =>{
    this.setState({
      orderProducts: products
    },()=>{
      if(this.checkHasProductToOrder()){
        this.onChangeStep(1)
      }
    })
  }
  onChangeStep = (value) =>{
    const {step} = this.state;
    let _stepChange = value;
    if(step === 3){
      _stepChange = 0
    }
    this.setState({
      step: _stepChange
    },()=>this.onScrollTo(0, 0))
  }
  onScrollTo=(x, y)=>{
    window.scrollTo(x, y)
  }
  onChangeShippingInfo = (field, value) =>{
    let { shippingInfo } = this.state;
    shippingInfo.hasOwnProperty(field) && (shippingInfo[field] = value);
    this.setState({ shippingInfo: shippingInfo , invalidShippingInfoText: null});
  }
  onSubmitShippingInfo = () =>{
    if(this.checkValidShippingInfo()){
      this.onChangeStep(2)
    }
  }
  onSaveToLocalStorage = (key, obj) =>{
    const storageName = this.getStorageName();
    let objStorage = this.getValueFromLocalStorage();
    if(!objStorage){
      objStorage = {}
    }
    objStorage[key] = obj;
    HLocalStorage.setObject(storageName, objStorage)
  }
  onSaveToSessionStorage = (key, obj) =>{
    const storageName = this.getStorageName();
    let objStorage = this.getValueFromSessionStorage();
    if(!objStorage){
      objStorage = {}
    }
    objStorage[key] = obj;
    try {
      sessionStorage.setItem(storageName, JSON.stringify(objStorage))
    } catch (error) {
      console.warn("stringify session error", error);
    }
  }
  onClearSessaionStorage = () =>{
    const storageName = this.getStorageName();
    try {
      sessionStorage.setItem(storageName, JSON.stringify({}))
    } catch (error) {
      console.warn("stringify session error", error);
    }
  }
  onLeavePage = () =>{
    window.onbeforeunload = () => {
      const {step} = this.state;
      if (step === 2) {
        return '';
      }
    };
  }
  /** Render */
  renderHelmet = () => {
    const { pageInfo } = this.state;
    const meta = {
      MetaTitle: HUtils.Obj.get(
        pageInfo,
        "MetaKeyTitle",
        `${HConfig.fnList.getConfig("metaName")} - Đặt hàng nhanh`
      ),
      MetaKeyWords: HUtils.Obj.get(
        pageInfo,
        "MetaKeyword",
        `${HConfig.fnList.getConfig("metaName")}, Quickorder, Đặt hàng nhanh`
      ),
      MetaDescription: HUtils.Obj.get(
        pageInfo,
        "Description",
        `${HConfig.fnList.getConfig("metaName")} - Đặt hàng nhanh`
      ),
    };
    return <MyUI type="sc_helmet" data={meta}/>;
  };
  renderTitlePage = () => {
    const { pageInfo } = this.state;
    const titleHeader = HUtils.Obj.get(pageInfo, "titlePage", "Đặt hàng nhanh");
    return (
      <div className="page-title">
        <h1>{titleHeader}</h1>
      </div>
    );
  };
  renderContentPage = () => {
    const {isLoading}= this.state;
    return (
      <div className="page-body" style={isLoading ? {display: 'none'} : {}}>
        {this.renderContentStep()}
      </div>
    );
  };
  renderStep = () => {
    const { stepList, step } = this.state;
    return (
      <MyUI
        type="sc_order_progress"
        stepList={stepList}
        stepActive={step}
        fnList={{
          fnOPreviousStep: (index) =>{
            if(index <= step){
              this.onChangeStep(index)
            }
          }
        }}
      />
    );
  };
  renderContentStep = () => {
    const { step } = this.state;
    switch (step) {
      case 0:
        return this.renderContentStep1();
      case 1:
        return this.renderContentStep2();
      case 2:
        return this.renderContentStep3();
      case 3:
        return this.renderContentStep4();
      default:
        break;
    }
  };
  renderContentStep1 = () => {
    const { optionList, showAdmin } = this.props;
    const { groups, pageInfo, searchResult, orderProducts } = this.state;
    const configFilter = {
      sortList:
        HUtils.Obj.get(optionList, "CommandType") ||
        HUtils.Obj.get(
          HConfig.fnList.getConfig("categoryConfig"),
          "sortOptions"
        ),
      sortDefault: this.getSortTypeDefault(),
      reviewMode: true,
      filterServer: true,
    };
    const configContent = {
      showColumnAdminNote: showAdmin,
    };
    const configPagination = {
      pageSize: 12,
    };
    return (
      <div className="quickorder-step">
        <MyUI
          type="sc_quickorder_group_products"
          slug={pageInfo?.Slug}
          data={this.getDataWithFormat(groups)}
          selectedProducts={orderProducts?.selectedProducts}
          optionalProducts={orderProducts?.optionalProducts}
          dataSearchServer={this.getDataFilter(searchResult)}
          configFilter={configFilter}
          configContent={configContent}
          configPagination={configPagination}
          fnList={{
            fnOnChangeFilter: (query) => {
              this.requestSearch({ customQuery: query });
            },
            fnOnSubmitSelectProduct: (products) => {
              this.onSubmitSelectProduct(products);
            },
            fnScroll: (x, y) => {
              this.onScrollTo(x, y);
            },
            fnOnChangeList: (field, obj)=>{
              this.onSaveToSessionStorage(field, obj);
            },
            fnOnSaveConfig: (field, obj)=>{
              this.onSaveToLocalStorage(field, obj);
            },
            fnGetConfigFromLocalStorage: (key) =>{
              return this.getValueFromLocalStorage(key)
            }
          }}
        />
      </div>
    );
  };
  renderContentStep2 = () =>{
    const {optionList} = this.props;
    const {
      shippingInfo,
      orderProducts,
      addressBook,
      invalidShippingInfoText,
    } = this.state;
    return (
      <MyUI
        type="sc_quickorder_address"
        data={shippingInfo}
        options={{
          ...optionList,
          addressBook,
        }}
        total={orderProducts.total}
        error={invalidShippingInfoText}
        numberProducts={this.getNumberProducts()}
        fnList={{
          fnOnChangeShippingInfo: (field, value) => {
            this.onChangeShippingInfo(field, value);
          },
          fnSubmitShippingInfo: () => {
            this.onSubmitShippingInfo();
          },
          fnAfterLogin: () => {
            this.onSaveToSessionStorage("forceStep", 1);
            window.location.reload();
          },
        }}
      />
    );
  }
  renderContentStep3 = () =>{
    const { shippingInfo, orderProducts } = this.state;
    return (
      <div>
        <MyUI
          type="sc_quickorder_preview_bill"
          data={this.getFinalProductList()}
        />
        <MyUI
          type="sc_quickorder_preview_address"
          data={shippingInfo}
          total={orderProducts?.total}
          fnList={{
            fnOnOrder: () => {
              this.requestOrder();
            },
          }}
        />
      </div>
    );
  }
  renderContentStep4 = () =>{
    const {dataAfterOrder} = this.state;
    const { MessageOrder, MessageAfterOrderSuccess} = dataAfterOrder || {};
    return (
      <div className="part">
        <div className="title">{HText.get("text_complete_order_title")}</div>
        <div className="content">
          {dataAfterOrder && (
            <div className="text-complete">
              {MessageAfterOrderSuccess && (
                <MyUI
                  type="html"
                  html={MessageAfterOrderSuccess}
                  data={dataAfterOrder}
                />
              )}
              <MyUI type="html" html={MessageOrder} />
            </div>
          )}
        </div>
      </div>
    );
  }
  renderEmpty = () =>{
    return(
      <div className="section">
        <div className="title">Không có dữ liệu!</div>                   
      </div>
    )
  }
  render() {
    const {groups, isLoading} = this.state;

    return (
      <div className="master-wrapper-content">
        {this.renderHelmet()}
        <div className="master-column-wrapper">
          <div className="center-1 quick-order quick-order-combination">
            {this.renderTitlePage()}
            {
              groups && (
                <>
                  {this.renderStep()}
                  {this.renderContentPage()}
                </>
              )
            }
            {isLoading == true && <MyUI type="sc_loading" />}
            {
              (!groups && !isLoading) && this.renderEmpty()
            }
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    optionList: state.shop.optionList,
    showAdmin: state.shop.adminButtons?.ShowBtnAdmin,
  };
};
export default connect(mapStateToProps, null)(SC_QuickOrderCombination);
