import React from "react";
import { HApiAxios } from "apps/Helpers";
import "./styles/sc_custom_slide.css";

class SC_CustomSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      currentPage: 1,
      totalPage: 1,
    };
  }
  componentDidMount() {
    const { data, config } = this.props;
    const { numberToShow, total } = config || {};
    if (data?.length > 0) {
        let _dataFirst = [];
      if (data.length > numberToShow) {
        _dataFirst = data.slice(0, numberToShow);
        }
      else {
          _dataFirst = data
        }
      this.setState({
        data: _dataFirst,
      });
    }
    if (total > numberToShow) {
      this.setState({
        totalPage: Math.ceil(total / numberToShow),
      });
    }
  }
  onNext = () => {
    const { config } = this.props;
    const { configPagination } = config || {};
    const { currentPage } = this.state;
    // goi api
    // cap nhat data
    // cap nhat current page
    this.setState(
      {
        currentPage: currentPage + 1,
      },
      () => {
        if (configPagination?.apiUrl) {
          HApiAxios.generic({
            request: {
              method: configPagination?.method || "POST",
              url: configPagination.apiUrl,
            },
            data: {
              Page: currentPage + 1,
            },
            successCallBack: (response) => {},
            errorCallBack: (error, response) => {},
          });
        }
      }
    );
  };
  onPre = () => {
    const { config } = this.props;
    const { configPagination } = config || {};
    const { currentPage } = this.state;
    // goi api
    // cap nhat data
    // cap nhat current page
    this.setState({
      currentPage: currentPage - 1,
    });
  };
  render() {
    const { data, totalPage, currentPage } = this.state;
    if (data?.length > 0) {
      const { renderChildren } = this.props;
      return (
        <div className="sc_custom_slide">
          {renderChildren && (
            <div className="main-list">{renderChildren(data)}</div>
          )}
          {totalPage > 1 && currentPage > 1 && (
            <div className="paginate-control-pre">
              <button className="btn btn-pre" onClick={() => this.onPre()}>
                <i className="fa fa-angle-left" />
              </button>
            </div>
          )}
          {totalPage > 1 && currentPage < totalPage && (
            <div className="paginate-control-nxt">
              <button className="btn btn-nxt" onClick={() => this.onNext()}>
                <i className="fa fa-angle-right" />
              </button>
            </div>
          )}
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_CustomSlide;
