import React from "react";
import { HConfig, HUtils } from "apps/Helpers";
import './styles/sc_card_blog_in_search.css';

class SC_CardBlogInSearch extends React.Component {
  render() {
    const { data } = this.props;
    if (data) {
      const _Title = data.Title;
      const _Slug = data.Slug;
      const _ImageUrlThumnail =
        HUtils.Obj.get(data, "Files_0.Photos[0].FileUrl") ||
        HConfig.fnList.getConfig("imageProductDefault");
      const _IsActive = data.IsActive;
      const _CreatedDate_Text = data.CreatedDate_Text;
      const _MetaDescription = data.MetaDescription;
      return (
        <div className="item-box sc_card_search_blog">
          <div className="product-item sevenspikes-ajaxcart">
            <div className="picture">
              <a href={_Slug} title={_Title}>
                <img
                  loading="lazy"
                  alt={_Title}
                  title={_Title}
                  src={_ImageUrlThumnail}
                />
              </a>
              {_IsActive === false && (
                <span className="product-out-of-stock">Chưa đăng</span>
              )}
            </div>
            <div className="details">
              <span className="post-date">{_CreatedDate_Text}</span>
              <h2 className="product-title">
                <a href={_Slug} title={_Title}>
                  {_Title}
                </a>
              </h2>
              <div className="description">{_MetaDescription}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default SC_CardBlogInSearch;
