import React from 'react'
import {
  CButton
} from '../../MyCore'
import Tippy from '../../../libs/TippyjsReact';
class MyUI_Popover extends React.Component {   
  _getContent=()=>{
    const {overlay,showButton,title,onCancel,onOK} = this.props;
    return (
      /*style={{minWidth:'150px'}}*/
      <div>
        {
          title!=null &&
          <div className="cpo-header">{title}</div>
        }
        {
          overlay!=null &&
          <div className="cpo-content">{overlay}</div>
        }
        {
          showButton &&
          <div className="cpo-footer">
            <div style={{textAlign:'right'}}>
              <CButton size="sm" color="secondary" onClick={()=>{
                console.warn("Ref:",this._cPopoverInstance);
                if(onCancel){
                  onCancel({fnHide:this.hide})
                }
                else{
                  this.hide();
                }          
              }}>Close</CButton>
              <CButton size="sm" color="primary" onClick={()=>{
                if(onOK){
                  onOK({fnHide:this.hide})
                }
              }}>OK</CButton>
            </div>
          </div>          
        }        
      </div>
    )
  } 
  _getAdvancedOptionsProps=()=>{
    return this.props.advancedOptions;
  }
  hide=()=>{
    if(this._cPopoverInstance){
      this._cPopoverInstance.hide();
    }
  }
  render(){
    return (
      <Tippy 
        content={this._getContent()} 
        placement={this.props.placement || 'top'}
        trigger="click"
        hideOnClick={true}
        interactive={true}
        theme={'cpopover'} 
        fnGetInstance={(r)=>{this._cPopoverInstance=r;}}
        appendTo={document.getElementById('root')}
        {...this._getAdvancedOptionsProps()}>
        {this.props.children}
      </Tippy>
    )    
  } 
}

export default MyUI_Popover