import { HConfig } from "apps/Helpers";

export function initZaloScript() {
  if (HConfig.fnList.getConfig("zaloOAID")) {
    (function (d, s, id) {
      var js,
        fjs = d.getElementById("root");
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://sp.zalo.me/plugins/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "zalo-jssdk");
  }
}
