import React from "react";
import { HApiAxios, HLink, HText } from "../../../../Helpers";
import HGA from "../../../../../helpers/GA";
import "./styles/sc_header_search.css";

class SC_HeaderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: "",
      resultSearch: [],
      panelResultDisplay: true
    };
  }

  onChangeTextSearch = (value) => {
    this.setState(
      {
        textSearch: value,
      },
      () => {
        if (this.timer != null) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          if (this.state.textSearch?.length > 1) {
            this.requestSearch(this.state.textSearch);
            this.setState({
              panelResultDisplay: true
            })
          }
        }, 1000);
      }
    );
  };

  requestSearch = (textSearch) => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "DashboardForWebsite/MaterialSearchList",
      },
      data: {
        FullTextSearch: textSearch,
        PageSize: 8,
      },
      successCallBack: (response) => {
        this.setState({
          resultSearch: response.Data,
        });
      },
      errorCallBack: (err, response) => {},
    });
  };

  _pushSearch = () => {
    HLink.push(`/search?query=${this.state.textSearch}`);
  };
  render() {
    const { resultSearch, panelResultDisplay } = this.state;
    const { style } = this.props;
    return (
      <div
        className="sc_header_search"
        onMouseEnter={() => this.setState({ panelResultDisplay: true })}
        onMouseLeave={() => this.setState({ panelResultDisplay: false })}
      >
        <div className="search-box store-search-box" style={style}>
          <input
            type="text"
            className="search-box-text"
            autoComplete="off"
            placeholder={HText.get("text_placeholder_search")}
            value={this.state.textSearch}
            onChange={(e) => {
              this.onChangeTextSearch(e.currentTarget.value);
            }}
            onKeyPress={(ev) => {
              // console.warn('text', this.state.textSearch)
              if (ev.key === "Enter") {
                this._pushSearch();
              }
            }}
            // onBlur={(e)=>{this.setState({resultSearch: []})}}
          />
          <button
            type="submit"
            className="button-1 search-box-button"
            onClick={(ev) => {
              this._pushSearch();
              HGA.trackingEventClick({
                action: "click_btn_search_header",
              });
            }}
          ></button>
          <ul
              className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
              style={
                this.state.textSearch && panelResultDisplay
                  ? { display: "block", top: "43px", left: "5px", width: "391px" }
                  : { display: "none", top: "43px", left: "5px", width: "391px" }
              }
              onMouseEnter={() => this.setState({ panelResultDisplay: true })}
            >
              <div className="seach-close-button">
                <span className="title">{HText.get("text_search_result")}</span>
                <span
                  className="icon"
                  onClick={() => this.setState({ panelResultDisplay: false })}
                >
                  <i className="fa fa-times" />
                </span>
              </div>
              {
                resultSearch?.length > 0 && 
                resultSearch.map((v, i) => {
                  return (
                    <li className="ui-menu-item" key={i}>
                      <a
                        className="ui-corner-all"
                        href={v.Link}
                        onClick={() => {
                          HGA.trackingEventClick({
                            action: "click_item_search_header",
                            label: v.Text,
                          });
                        }}
                      >
                        <span>{v.Text}</span>
                      </a>
                    </li>
                  );
                })}
            </ul>
        </div>
      </div>
    );
  }
}
export default SC_HeaderSearch;
