import React from "react";
import { HConfig, HFormat, HUtils } from "apps/Helpers";
import { MyUI } from "apps/Components";
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import ShopUtils from "apps/shoppingCart/helpers/Utils";
import "./styles/sc_card_product_in_quickorder.css";

class SC_CardProductInQuickorder extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.defaultData) {
      return {
        defaultData: props.data,
        data: props.data != null ? props.data : null,
      };
    }
    return null;
  }
  state = {
    data: this.props.data != null ? this.props.data : null,
    defaultData: this.props.data,
  };
  onChangeQuantity = (value) => {
    const { fnList } = this.props;
    let { data } = this.state;
    data.Quantity = value;
    this.setState(
      {
        data: data,
      },
      () => fnList?.fnOnChangeQuantity && fnList.fnOnChangeQuantity(data, value)
    );
  };
  render() {
    const { data } = this.state;
    if (data) {
      const {config} = this.props;
      if (data.hasOwnProperty("Quantity") != true) {
        data.Quantity = 0;
      }
      const _Slug = data.Slug;
      const _MaterialCode = data.MaterialCode;
      const _MaterialName = data.MaterialName;
      const _MaterialId = data.MaterialId;
      const _ImageUrlThumnail =
        data.ImageUrlThumnail ||
        HConfig.fnList.getConfig("imageProductDefault");
      const _IsCall4WebOrder = data.IsCall4WebOrder;
      const _StepPrice = data.StepPrice;
      const _Quantity = data.Quantity;
      const _SellPriceText = data.SellPriceText;
      const _AdminNote = data.AdminNote;
      const _UnitName = data.UnitName;
      
        const _QuantityMin = HUtils.Obj.get(data, "DiscountInfo.QuantityMin");
        const _DiscountMoney = HUtils.Obj.get(
          data,
          "DiscountInfo.DiscountMoney"
      );
      const _Discount_Text = HUtils.Obj.get(data, "DiscountInfo.Discount_Text");
        const _price = ShopUtils.getPriceProduct(data);
        const _priceAfterDiscount = 
          _QuantityMin >= 0 && _DiscountMoney && _Quantity >= _QuantityMin
            ? HFormat.Money.formatMoneyForAjuma(_price)
          : 0;
          const _showAdminNote = config?.showAdminNote;
      return (
        <div className="sc_card_product_in_quickorder" key={_MaterialId}>
          <div className="is-order">
          {
                _QuantityMin > 1 &&
                _Discount_Text && <div className="product-coupon">{_Discount_Text}</div>
              }
            <span
              className={`${_Quantity > 0 ? "active" : ""}`}
              onClick={() => {
                _Quantity > 0 && this.onChangeQuantity(0);
              }}
            >
              {_Quantity > 0 && (
                <MyIconSvg type="check" className="icon-tick-bold" />
              )}
            </span>
          </div>
          <div className="main-content">
            <div className="picture">
              <a href={_Slug}>
                <img src={_ImageUrlThumnail} alt={_MaterialName} />
              </a>
            </div>
            <div className="details">
              <div className="product-sku">{_MaterialCode}</div>
              <div className="product-name">{_MaterialName}</div>
              <div
                className="product-price"
                style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
              >
                {/* {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
                {!_IsCall4WebOrder && _UnitName && ` / ${_UnitName}`} */}
                {!_IsCall4WebOrder && _priceAfterDiscount !== 0 && (
                  <span className="price-discount">{_priceAfterDiscount}</span>
                )}
                <div className="price-actual">
                  <span
                    className={`${
                      _priceAfterDiscount !== 0 ? "has-discount" : ""
                    }`}
                  >
                    {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
                  </span>
                  {!_IsCall4WebOrder && _UnitName && (
                    <span>{` / ${_UnitName}`}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="quantity">
              <MyUI
                type="sc_input_quantity"
                className="quantity-control"
                forceMinValue="0.0"
                step={_StepPrice}
                minQuantity={0}
                clearable={true}
                disabled={_IsCall4WebOrder}
                value={_Quantity}
                onChange={(value) => {
                  this.onChangeQuantity(value);
                }}
              />
            </div>
          </div>
          {_showAdminNote && (
            <div className="note">{_AdminNote ? _AdminNote : "--"}</div>
          )}
          <div className="footer">
            <div className="total">
              {HFormat.Money.formatMoneyForAjuma(_price * _Quantity)}
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_CardProductInQuickorder;
