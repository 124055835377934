const BuildVersion = {
  buildCode: 2,
  buildDate: '29/1/2021',
  historyBuild: {
    2:{
      note: 'Remove footer, AWO-208'
    }
  }
}

window._BuildVersion_ = BuildVersion;
export default BuildVersion;